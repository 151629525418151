<template>
	<div class="align-center bg-f2f pb-16">
        <v-container class="h-100">
            <v-card variant="flat" color="black01" class="w-100 d-flex justify-center cursor-help" elevation="0" rounded="0" 
                href="https://kingdoms.untrading.org/build-and-grow-your-kingdom" target="_blank">
                <div variant="flat" color="black01" class="w-50 my-15" elevation="0" rounded="0" align="start">
                    <div class="sub-page-h1">
                        Earn $5 & more in $UN for each friend you invite! 🤑
                    </div>
                    <div class="mt-6">
                        Welcome to the ultimate journey of investment mastery and leadership. At Untrading, we empower you to transform your leadership and financial acumen into building a community that thrives on collective growth and success. Ready to rise from a Knight to a King? Let's embark on this exciting journey together.
                    </div>
                    <div class="mt-6 ml-n2">
                        <v-avatar v-for="group in kingdomGroupSizes" :key="group.groupSize" :image="group.icon" size="80"></v-avatar>
                    </div>
                </div>
            </v-card>
            <v-card color="transparent" elevation="0" rounded="0" max-width="1408" class="mx-auto pt-8">
                <v-row no-gutters class="mt-10">
                    <v-col cols="5">
                        <div v-if="kingdomGroup.icon" class="text-right" style="width: 144px">
                            <v-btn color="white01" elevation="0" icon size="48" class="cursor-auto" style="z-index: 1;" :ripple="false">
                                <v-avatar :image="kingdomGroup.icon" size="48"></v-avatar>
                            </v-btn>
                        </div>
                        <UserAvatar :avatar="$tools.bigAvatar(user.avatar)" :size="144" class="mx-auto mt-n10"></UserAvatar>
                        <h1 class="body-h2 mt-8 text-primaryGrey ellipsis-line">{{ user.name }}</h1>
                        <v-divider class="my-7"></v-divider>
                        <div class="mt-5">
                            <h2 class="body-h4 text-primaryGrey">Group Size</h2>
                            <h5 class="body-p text-primaryGrey">{{ kingdomGroup.groupSize }}</h5>
                        </div>
                        <div class="mt-5">
                            <h2 class="body-h4 text-primaryGrey">Title</h2>
                            <h5 class="body-p text-primaryGrey">{{ kingdomGroup.title }}</h5>
                        </div>
                        <div class="mt-5">
                            <h2 class="body-h4 text-primaryGrey">Members</h2>
                            <h5 class="body-p text-primaryGrey">{{ kingdomGroup.memberCount }}</h5>
                        </div>
                    </v-col>
                    <v-col cols="1" align="center">
                        <v-divider vertical></v-divider>
                    </v-col>
                    <v-col cols="6">
                        <div v-if="loading">
                            <v-skeleton-loader type="list-item-avatar-two-line" color="transparent"></v-skeleton-loader>
                            <div class="ml-15">
                                <v-skeleton-loader v-for="i in 5" :key="`skeleton-loader-${i}`" type="list-item-avatar-two-line" color="transparent"></v-skeleton-loader>
                            </div>
                        </div>
                        <div>
                            <UserReferralTreeview :items="userReferralNodes"></UserReferralTreeview>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import KingdomAPI from '@/api/kingdom.js';
import UserReferralCodeAPI from '@/api/user-referral-code.js';
import UserReferralTreeview from '@/components/user/UserReferralTreeview';
export default {
    data(){
        return {
            kingdomGroup: {},
            loading: false,
            userReferralNodes: [],
            kingdomGroupSizes: [
                {
                    groupSize: "Knight",
                    icon: "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/3d433f23-42e7-4b3c-2a6b-c34d84ed2900/public"
                },
                {
                    groupSize: "Sheikh",
                    icon: "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f16f10a9-07bb-414e-caed-69f01c28cd00/public"
                },
                {
                    groupSize: "Sultan",
                    icon: "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/64f8f1a8-0b14-440a-e9b1-a9be1d5b6e00/public"
                },
                {
                    groupSize: "Jiangjun",
                    icon: "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/dbb6a7a0-c211-4d61-6c94-4ba729f22e00/public"
                },
                {
                    groupSize: "King/Queen",
                    icon: "https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b96f5e3e-11ee-4c88-3af5-78b617b1bc00/public"
                }
            ]
        }
    },
    components: { UserReferralTreeview },
    created(){

    },
    mounted(){
        // 获取推荐的用户数据
        this.getReferrals();
    },
    computed: {
        ...mapGetters(['user']),
    },
    watch:{
        'user.wallet': {
            handler(n, o) {
                if(n) {
                    // 根据钱包地址获取王国团体
                    this.getKingdomGroup();
                }
            },
            immediate: true 
        }
    },
    methods: {
        // 根据钱包地址获取王国团体
        async getKingdomGroup() {
            let res = await KingdomAPI.getKingdomGroup(this.user.wallet);
            let data = res.data;
            if(data.success) {
                this.kingdomGroup = data.data;
            }
        },
        // 获取推荐的用户数据
        async getReferrals() {
            this.loading = true;
            let res = await UserReferralCodeAPI.getReferrals();
            let data = res.data;
            if(data.success) {
                this.userReferralNodes = [data.data];
            }
            this.loading = false;
        },
    }
}
</script>
<style scoped>

</style>