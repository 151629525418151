<template>
    <div>
        <v-menu activator="#menu-activator" open-on-hover location="top">
            <v-card class="pa-3 text-primaryGrey" rounded="0">Apps</v-card>
        </v-menu>
        <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
            <template v-slot:activator="{ props }">
                <v-icon id="menu-activator" :color="color" class="pointer" :size="size" v-bind="props">mdi mdi-apps</v-icon>
            </template>
            <v-card class="pa-8" color="black">
                <v-list class="flex" bg-color="black">
                    <v-row no-gutters>
                        <!-- <v-col cols="4" class="pa-0">
                            <v-card class="pa-1" width="90" rounded="0" elevation="0" color="transparent" link href="/uncryptos">
                                <v-card-text class="px-0 py-3 text-center">
                                    <div class="d-flex justify-center">
                                        <Logo type="icon" app="unCryptos" size="36"></Logo>
                                    </div>
                                    <div class="text-primaryGrey mt-2">unCryptos</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" class="pa-0">
                            <v-card class="pa-1" width="90" rounded="0" elevation="0" color="transparent" link href="/unnfts">
                                <v-card-text class="px-0 py-3 text-center">
                                    <div class="d-flex justify-center">
                                        <Logo type="icon" app="unNFTs" size="32"></Logo>
                                    </div>
                                    <div class="text-primaryGrey mt-3">unNFTs</div>
                                </v-card-text>
                            </v-card>
                        </v-col> -->
                        <v-col cols="4" class="pa-0">
                            <v-card class="pa-1" width="90" rounded="0" elevation="0" color="transparent" link href="https://testnet.untrading.org/uncryptos">
                                <v-card-text class="px-0 py-3 text-center">
                                    <div class="d-flex justify-center">
                                        <Logo type="icon" app="unCryptos" env="testnet" size="36"></Logo>
                                    </div>
                                    <div class="text-primaryGrey mt-2">unCryptos Testnet</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" class="pa-0">
                            <v-card class="pa-1" width="90" rounded="0" elevation="0" color="transparent" link href="https://testnet.untrading.org/unnfts">
                                <v-card-text class="px-0 py-3 text-center">
                                    <div class="d-flex justify-center">
                                        <Logo type="icon" app="unNFTs" env="testnet" size="32"></Logo>
                                    </div>
                                    <div class="text-primaryGrey mt-3">unNFTs Testnet</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" class="pa-0">
                            <v-card class="pa-1" width="90" rounded="0" elevation="0" color="transparent" link href="/un">
                                <v-card-text class="px-0 py-3 text-center">
                                    <div class="d-flex justify-center">
                                        <Logo type="icon" app="un"></Logo>
                                    </div>
                                    <div class="text-primaryGrey mt-2" size="36">UN Token</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
export default {
    props: {
        // 图标大小
        size: {
            type: [Number, String],
            default: 28
        },
        // 主题颜色
        color: {
            type: String,
            default: null
        },
    },
    data(){
        return {
            menu: false,
        }
    },
    components: { Logo },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>