<template>
	<div class="markdown prose">
        <p>"It’s not about being right—it’s about maximizing when you are."</p>
        <h3 class="text-black01 font-weight-bold">Untrading empowers tactical traders like you by eliminating the roadblocks to success, aligning with the strategic philosophy of George Soros:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">No Commissions: </span>In traditional markets, commissions can significantly eat into your profits. Untrading ensures that every penny you earn from a successful trade stays in your pocket, directly enhancing your financial strategy.</li>
            <li><span class="text-black01 font-weight-bold">No Market Predictions: </span>The market's future is uncertain. Rather than wasting time on predictions, Untrading encourages you to react to market shifts as they happen, capitalizing on your insights when they are most potent. This approach minimizes the need for constant market watching and reduces the psychological pressure of trying to predict the unpredictable.</li>
            <li><span class="text-black01 font-weight-bold">Just Pure Asymmetric Opportunities: </span>Untrading creates an environment where you can exploit opportunities where the potential rewards vastly outweigh the risks. This resonates with the Soros doctrine of maximizing gains when your market call is correct. It's about turning those moments of being right into significant financial leverage, without the market's usual drag of inefficiencies.</li>
        </ul>
        <p >In a world where the focus is often on being right, Untrading shifts the paradigm to being effective when you are right. It's a platform that supports the savvy trader in making the most out of their strategic foresight and tactical execution, embodying the essence of Soros's approach to trading — where the emphasis is on the magnitude of success in the moments that count, rather than on a continuous stream of correct predictions.</p>
    </div>
</template>