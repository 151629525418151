<template>
	<div class="markdown prose">
        <p>Let’s determine the level of involvement you’d like in managing your investments:</p>
        <p>Would you prefer to take full control of your trades, actively navigating the markets like a skilled race car driver? Or would you rather rely on our advanced autopilot system, which executes trades automatically based on your chosen strategy?</p>
        <p>Our autopilot modes <span class="text-black01 font-weight-bold">(Planetary, Stellar or Galactic)</span> are inspired by iconic investors:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Soros Mode: </span>Frequent, low-risk profit-taking.</li>
            <li><span class="text-black01 font-weight-bold">Dalio Mode: </span>Diversified, balanced returns.</li>
            <li><span class="text-black01 font-weight-bold">Buffett Mode: </span>Strategic, long-term exits.</li>
        </ul>
        <p>Starting with autopilot is highly recommended—it ensures consistent execution while helping you learn the nuances of trading. As you gain experience, you can transition to manual trading with a portion of your capital.</p>
        <p>What’s your preference?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot - Set It and Forget It' },
                { code: 'Manual', message: 'Crypto Racetrack - Hands-On and Tactical' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>