<template>
	<div>
        <v-card class="pb-16 mt-16" rounded="0" elevation="0" color="transparent">
             <h2 class="body-text-btn text-primaryGrey">
                Provenance
            </h2>
            <v-divider class="mt-2 mb-7"></v-divider>
            <!-- pc -->
            <v-timeline side="end" density="compact" truncate-line="both"  v-if="responsive == 'pc'">
                <v-timeline-item size="24" v-for="(provenance, i) in provenances" :key="i" fill-dot :dot-color="i == 0 ? '7b7' : 'c9c'">
                    <div class="d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                            <span v-if="provenance.user">
                                <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                            </span>
                            <span class="ml-5">
                                <div class="body-h4 text-primaryGrey">
                                    <span v-if="provenance.size && provenance.size != 0">{{ $tools.formatNumber(provenance.size, cryptoWrapped.sizeDecimals) + ' ' }}</span>
                                    <span class="gradient-underline-hover gradient-underline">
                                        <router-link :to="`/uncrypto/${provenance.wrappedCurrency?.toLowerCase()}/${provenance.extendedCryptoId}`" class="text-primaryGrey gradient-text-hover">
                                            {{ provenance.wrappedCurrency + ' #' + $tools.formatNumber(provenance.tokenId, 0) }}
                                        </router-link>    
                                    </span>
                                </div>
                                <span class="body-h4 text-primaryGrey" v-function="provenance.contractFunction"></span>
                                <span v-if="provenance.contractFunction != 'UNWRAP_EXPIRED'">
                                    <span class="body-h4 text-primaryGrey" v-if="provenance.user.username !== provenance.user.wallet">{{ provenance.user.username }}</span>
                                    <span class="body-h4 text-primaryGrey" v-else>{{ $tools.privacyField(provenance.user.username) }}</span>
                                </span>
                                <div class="body-p-small text-primaryGrey">
                                    <span v-time="provenance.timestamp"></span>
                                    <span>{{ ' (' + $tools.formatEngLishDatetime(provenance.timestamp) + ')'}}</span>
                                </div>
                            </span>
                        </div>
                        <div v-if="provenance.transactionHash != null" class="right">
                            <span class="body-h4 mr-4 text-primaryGrey">{{ (provenance.limitPrice && provenance.limitPrice != 0) ? ('@' + $tools.formatNumber(provenance.limitPrice, cryptoWrapped.priceDecimals) + ' ' + provenance.paymentToken) : '' }}</span>
                            <a :href="$tools.getBlockchainExplorerUrl(provenance.blockchain) + '/tx/' + provenance.transactionHash" class="del-underline" target="_blank">
                                <v-icon class="text-gray gradient-text-hover mt-n1" size="16" icon="fa-solid fa-up-right-from-square"></v-icon>
                            </a>
                        </div>
                    </div>
                </v-timeline-item>
            </v-timeline>
            <!-- mobile -->
            <v-timeline side="end" density="compact" truncate-line="both"  v-if="responsive == 'mobile'">
                <v-timeline-item size="24" v-for="(provenance, i) in provenances" :key="i" fill-dot :dot-color="i == 0 ? '7b7' : 'c9c'">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6">
                            <div class="d-flex align-center">
                                <span v-if="provenance.user">
                                    <UserAvatar :avatar="provenance.user.avatar" :username="provenance.user.username"></UserAvatar>
                                </span>
                                <span class="ml-5">
                                    <!-- 数量 -->
                                    <div class="body-h4 text-primaryGrey">
                                        <span v-if="provenance.size && provenance.size != 0">{{ $tools.formatNumber(provenance.size, cryptoWrapped.sizeDecimals) + ' ' }}</span>
                                        <span class="gradient-underline-hover gradient-underline">
                                            <router-link :to="`/uncrypto/${provenance.wrappedCurrency?.toLowerCase()}/${provenance.extendedCryptoId}`" class="text-primaryGrey gradient-text-hover">
                                                {{ provenance.wrappedCurrency + ' #' + $tools.formatNumber(provenance.tokenId, 0) }}
                                            </router-link>    
                                        </span>
                                    </div>
                                    <!-- 事件 -->
                                    <span class="body-h4 text-primaryGrey" v-function="provenance.contractFunction"></span>
                                    <!-- 用户 -->
                                    <div v-if="provenance.contractFunction != 'UNWRAP_EXPIRED'">
                                        <span class="body-h4 text-primaryGrey" v-if="provenance.user.username !== provenance.user.wallet">{{ provenance.user.username }}</span>
                                        <span class="body-h4 text-primaryGrey" v-else>{{ $tools.privacyField(provenance.user.username) }}</span>
                                        <!-- 价格和链接 -->
                                        <div v-if="provenance.transactionHash != null" >
                                            <span class="body-h4 text-primaryGrey" :class="[provenance.paymentToken ? 'mr-4' : 'mr-0']">{{ (provenance.limitPrice && provenance.limitPrice != 0) ? ('@' + $tools.formatNumber(provenance.limitPrice, cryptoWrapped.priceDecimals) + ' ' + provenance.paymentToken) : '' }}</span>
                                            <a :href="$tools.getBlockchainExplorerUrl(provenance.blockchain) + '/tx/' + provenance.transactionHash" class="del-underline" target="_blank">
                                                <v-icon class="text-gray gradient-text-hover mt-n1" size="16" icon="fa-solid fa-up-right-from-square"></v-icon>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- 时间 -->
                                    <div class="body-p-small text-primaryGrey">
                                        <span v-time="provenance.timestamp"></span>
                                        <span>{{ ' (' + $tools.formatEngLishDatetime(provenance.timestamp) + ')'}}</span>
                                    </div>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-timeline-item>
            </v-timeline>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CryptoProvenanceAPI from '@/api/crypto-provenance.js';
import CryptoWrappedAPI from '@/api/crypto-wrapped.js';
import CryptoQuoteAPI from '@/api/crypto-quote.js';
export default {
    data(){
        return {
            // 数据集合
            provenances: [],
            // 加密货币封装资产
            cryptoWrapped: {},
            // 加密货币报价资产
            cryptoQuote: {},
        }
    },
    props: {
        // 主键
        cryptoId: {
            type: String,
            default: null
        },
        // 区块链
        blockchain: {
            type: String,
            default: null
        },
        // 合约代币地址
        contractAddress: {
            type: String,
            default: null
        },
        // 支付代币地址
        paymentTokenAddress: {
            type: String,
            default: null
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive']),
    },
    watch:{
        cryptoId: {
            handler(newVal, oldVal) {
                // 根据加密货币主键查询历史记录
                this.getProvenances();
            },
            immediate: true
        },
        contractAddress: {
            handler(newVal, oldVal) {
                // 根据区块链和地址查询加密货币封装资产详情
                this.getCryptoWrappedDetail();
            },
            immediate: true
        },
        paymentTokenAddress: {
            handler(newVal, oldVal) {
                // 根据区块链和地址查询加密货币报价资产详情
                this.getCryptoQuoteDetail();
            },
            immediate: true
        }
    },
    methods: {
        // 根据加密货币主键查询历史记录
        async getProvenances() {
            if(this.cryptoId == null) {
                return;
            }
            let res = await CryptoProvenanceAPI.getProvenances(this.cryptoId);
            let data = res.data;
            if(data.success) {
                // 根据区块链和地址查询加密货币报价资产详情
                await this.getCryptoQuoteDetail();
                this.provenances = data.data;
            }
        },
        // 根据区块链和地址查询加密货币封装资产详情
        async getCryptoWrappedDetail() {
            if(this.contractAddress == null) {
                return;
            }
            let res = await CryptoWrappedAPI.getCryptoWrappedDetail(this.blockchain, this.contractAddress);
            let data = res.data;
            if(data.success) {
                this.cryptoWrapped = data.data;
            }
        },
        // 根据区块链和地址查询加密货币报价资产详情
        async getCryptoQuoteDetail() {
            if(this.paymentTokenAddress == null) {
                return;
            }
            let res = await CryptoQuoteAPI.getCryptoQuoteDetail(this.blockchain, this.paymentTokenAddress);
            let data = res.data;
            if(data.success) {
                this.cryptoQuote = data.data;
            }
        },
    }
}
</script>
<style scoped>
.right {
    position: absolute;
    right: 0px;
}
</style>