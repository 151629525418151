<template>
	<div class="align-center bg-f2f">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1400" class="mx-auto pt-8">
                <v-row no-gutters justify="center" align="start">
                    <v-col cols="12" sm="6" lg="4" class="mt-16 r-m-t-0">
                        <Step :steps="steps" :action="action"></Step>
                    </v-col>
                    <v-col cols="12" sm="6" lg="5" class="r-m-t-8">
                        <!--------------------------- POST --------------------------->
                        <div v-if="action == 'POST'" class="pb-16">
                            <div class="sub-page-h1 mb-6 text-primaryGrey">Verify your profile via 𝕏</div>
                            <v-card max-width="510" class="pa-8" rounded="lg" elevation="12" color="white01">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <div class="sidebar-h2 text-primaryGrey">Post a public tweet that contains your unique ID</div>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="my-4"></v-divider>
                                        <!-- <v-col cols="12" class="px-0">
                                            <div class="body-p mb-3 text-primaryGrey">Your unique ID is: </div>
                                            <div class="body-p">
                                                <v-text-field v-model="user.id" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                                            </div>
                                        </v-col> -->
                                        <v-col cols="12" class="px-0">
                                            <p class="pre-wrap body-p text-primaryGrey" v-html="tweetText"></p>
                                            <!-- <div class="body-p mb-3 text-primaryGrey">Tweet text: </div>
                                            <v-textarea v-model="tweetText" variant="solo" class="pre-wrap" hide-details readonly density="comfortable" rows="8"></v-textarea> -->
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <div class="mt-16">
                                <Button variant="primary" rounded="lg" color="black03" height="52" width="300" aria-label="Post Tweet" @click="postTweet">Post Tweet</Button>
                            </div>
                        </div>
                        <!--------------------------- URL --------------------------->
                        <div v-if="action == 'URL'">
                            <div class="sub-page-h1 mb-6 text-primaryGrey">Verify your profile via 𝕏</div>
                            <v-card max-width="510" class="pa-8" rounded="lg" elevation="12" color="white01">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <div class="sidebar-h2 text-primaryGrey">Post the URL of the tweet to verify your profile</div>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="my-3"></v-divider>
                                        <v-col cols="12" class="px-0">
                                            <div class="body-p text-primaryGrey mb-10">Copy & paste the tweet URL below to verify your account.</div>
                                            <div class="mb-10">
                                                <v-text-field v-model="tweetUrl" variant="outlined" label="Tweet URL" color="primaryGrey" class="fw-700" hide-details density="comfortable"></v-text-field>
                                            </div>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <div class="mt-16">
                                <Button :loading="verifyViaTwitterLoading" variant="primary" rounded="lg" color="black03" height="52" width="300" aria-label="Confirm" @click="verifyViaTwitter">Confirm</Button>
                            </div>
                        </div>
                        <!--------------------------- COMPLETION --------------------------->
                        <div v-if="action == 'COMPLETION'">
                            <div class="sub-page-h1 mb-6 text-primaryGrey">Verify your profile via 𝕏</div>
                            <v-card max-width="510" class="pa-8" rounded="lg" elevation="12" color="white01">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <div class="sidebar-h2 text-primaryGrey">Your account is now verified!</div>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="my-7"></v-divider>
                                        <v-col cols="12" class="px-0 text-primaryGrey">
                                            <div class="body-p">Well done! untrading.org has verified your account. Now you can collect, create, and sell on the marketplace.</div>
                                            <div class="body-p mt-3">Your X username will be used by untrading.org, and you can edit your profile later.</div>
                                            <!-- <div class="body-p mt-3">Your X username and profile picture will be used by untrading.org, and you cannot change them here. </div> -->
                                            <!-- <div class="body-p mt-3 mb-16">Add additional information to your profile page and verify your account via Instagram to increase your credibility.</div> -->
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <div class="mt-16">
                                <Button variant="primary" :loading="verifyViaTwitterLoading" :href="'/profile/' + user.username" target="_self" rounded="lg" color="black03" height="52" width="300" aria-label="View my Profile">View my Profile</Button>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 无效的链接弹窗 -->
        <v-dialog v-model="invalidUrlDialog" persistent content-class="v-echarts-dialog">
            <v-card max-width="462" class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <div class="sidebar-h2 text-primaryGrey">Invalid URL</div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="px-0">
                            <div class="body-p text-primaryGrey">{{ errorMessage }}</div>
                            <div class="body-p mt-3 text-primaryGrey">The URL you posted was invalid.</div>
                            <div class="body-p mt-3 text-primaryGrey">The URL should be copied from the "Share" icon under the tweet.</div>
                            <div class="mt-16 text-center">
                                <Button variant="primary" rounded="lg" color="black03" width="100" @click="invalidUrlDialog = false" aria-label="OK">OK</Button>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SocialAPI from '@/api/social.js';
import UserReferralCodeAPI from '@/api/user-referral-code.js';
export default {
    data(){
        return {
            // 操作
            action: 'POST',
            // 步骤
            steps: [
                { title: 'Post a public tweet', codes: ['POST'] },
                { title: 'Post the URL of the tweet', codes: ['URL'] },
                { title: 'Completion', codes: ['COMPLETION'] }
            ],
            // 推文链接
            tweetUrl: null,
            // 加载中
            verifyViaTwitterLoading: false,
            // 无效的链接弹窗
            invalidUrlDialog: false,
            // 错误信息
            errorMessage: ''
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user']),
        // 推文文本
        tweetText() {
            return "❤️ Verified my unique 🆔 on @untradingOrg using my $ETH wallet 📝. Thanks to #nFR policy, only 1 account per 💃🏼can be part of each #Flow for #FRs, preventing washtrading & other cheating practices. Join me in building a fairer #DeFi app! 🔥🔥🔥\n\n🔐 " + this.user.id + " 🔐";
        }
    },
    watch:{
        user: {
            handler(newVal, oldVal) {
                if (newVal.verified) {
                    this.$router.push('/');
                }
            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        // 发送推特链接
        postTwitterUrl(tweetText) {
            return "https://x.com/intent/tweet?text=" + encodeURIComponent(tweetText);
        },
        // 发送推文
        postTweet() {
            this.action = 'URL';
            window.open(this.postTwitterUrl(this.tweetText), '_blank');
        },
        // 通过 Twitter 验证账户
        async verifyViaTwitter() {
            this.verifyViaTwitterLoading = true;
            let param = {
                tweetUrl: this.tweetUrl
            }
            let res = await SocialAPI.verifyViaTwitter(param);
            let data = res.data;
            if(data.success) {
                // 验证成功，更新用户资料
                this.$bus.emit('emitGetMe', {});
                this.action = 'COMPLETION';
                // 打开推特分享推荐码
                this.shareUserReferralCode();
            } else {
                this.errorMessage = data.message;
                this.invalidUrlDialog = true;
                // this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
            this.verifyViaTwitterLoading = false;
        },
        // 打开推特分享推荐码
        async shareUserReferralCode() {
            let res = await UserReferralCodeAPI.getUserReferralCode();
            let data = res.data;
            if(data.success) {
                let userReferralCode = data.data;
                let userReferralCodeUrl = this.$config.domain + '/connectwallet?referralCodeUsed=' + userReferralCode.referralCode;
                let userReferralCodeText = '🚀 Exciting news! 🎁\n\nUse my referral code "' + userReferralCode.referralCode + '" to receive a special airdrop reward! 💰💎\n\nSign up at ' + userReferralCodeUrl + ' now and claim your $5 & more in $UN! 🤑\n\n💎 Don\'t miss out on this opportunity! 💎\n\n#untrading #Airdrop #Web3 #NFTs #DeFi';
                window.open(this.postTwitterUrl(userReferralCodeText), '_blank');
            }
        },
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
</style>