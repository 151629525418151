<template>
	<div class="layoutContent">
        <div class="Grid_root__iRfoa grid-style-13">
            <div class="page_progressBlur Grid_a__vY7M8">
                <div class="d-flex flex-column ga-2">
                    <h2 class="text_h2 text-quaternary">
                        Are you tired of platform-dictated payouts? 
                    </h2>
               </div>
            </div>
            <div class="Spacer_root__uoSvA" style="--height:24px"></div>
            <div class="page_progressBlur Grid_b___vQi_ r-m-t-6">
                <p class="fs-17 text-tertiary">
                    Untrading puts you in control - an economic platform for your influence
                </p>
            </div>
        </div>
        <div class="Spacer_root__uoSvA" style="--height:64px"></div>
        <div class="Grid_root__iRfoa grid-style-14">
            <div class="Grid_a__vY7M8">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Your Community, Your Rules</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Using blockchain, you can set your own terms and reward structures. You are in control of your payout plan.</p>
                </div>
            </div>
            <div class="Grid_b___vQi_ r-m-t-16">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Lasting Rewards</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Benefit from Originators' Rewards and Future Rewards, ensuring you profit from your community's long-term success.</p>
                </div>
            </div>
            <div class="Grid_c__Tv5Qw r-m-t-16">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">True Ownership - Your DAOs, Your Hedge Funds</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Unlike YouTube or X, where the payout rules are set by the platforms, you are in charge of your mini-DAOs and decentralized hedge pools.</p>
                </div>
            </div>
            <div class="Grid_d__WN1qv r-m-t-16">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Grow Together</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Untrading provides a platform where you can manage your community's economics as it grows - automatically and transparently.</p>
                </div>
            </div>
        </div>
        <p class="mt-16 fs-17 text-tertiary">
            Ready to lead your community into the future of decentralized finance?
        </p>
        <div class="mt-16">
            <Button href="https://kingdoms.untrading.org" variant="secondary" rounded="lg" color="black01" width="100" aria-label="">
                <span class="px-4">More<v-icon>mdi mdi-chevron-right</v-icon></span>
            </Button>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>