<template>
	<span>
        <v-btn icon variant="text" size="28" :disabled="page <= 1" @click="updatePage(page - 1)">
            <v-icon size="18">mdi mdi-chevron-left</v-icon>
        </v-btn>
        {{ page }}/{{ total }}
        <v-btn icon variant="text" size="28" :disabled="page >= total" @click="updatePage(page + 1)">
            <v-icon size="18">mdi mdi-chevron-right</v-icon>
        </v-btn>
	</span>
</template>
<script>
export default {
    props: {
        page: {
            type: [Number, String],
            required: true,
            default: 1
        },
        total: {
            type: [Number, String],
            required: true,
            default: 0
        }
    },
    data(){
        return {
            
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 更新页码
        updatePage(page) {
            this.$emit('updatePage', page);
        }
    }
}
</script>
<style scoped>

</style>