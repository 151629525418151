<template>
	<div class="message-box fixed bottom-0 bg-ffffff-212121">
        <div class="@md:rounded-3xl bg-ffffff-303030 shadow-md border-sm relative flex w-full cursor-text flex-col overflow-hidden rounded-2xl px-5 pb-[52px] pt-4 mt-6">
            <VueWriter :array="texts" :start="3000" :typeSpeed="30" :eraseSpeed="0" :delay="5000" :intervals="200" @typed="onTyped" />
            <div class="absolute bottom-3 right-3 mt-auto flex justify-end">
                <button :disabled="isSendDisabled" @click="send()" class="btn-primary bg-primary iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                    <v-icon color="white" size="18">mdi mdi-arrow-up</v-icon>
                </button>
            </div>
        </div>
        <div class="text-center text-5d5d5d-b4b4b4 fs-12 my-2">Tianming can make mistakes. Check important info.</div>
    </div>
</template>
<script>
export default {
    props: {
        messages: {
            type: Array,
            // [{ code: String, message: String }]
            default: [],
            required: false
        }
    },
    data(){
        return {
            // 是否发送禁用
            isSendDisabled: false,
            // 当前
            current: {},
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        // 监听消息框宽度
        this.watchMessageBoxWidth();
    },
    computed: {
        texts() {
            return this.messages.map(m => m.message);
        }
    },
    watch:{

    },
    methods: {
        // 监听消息框宽度
        watchMessageBoxWidth() {
            // 获取父级主体元素
            const parent = document.querySelector('#container');
            // 获取消息框元素集合
            const messageBoxs = document.querySelectorAll('.message-box');
            // 循环给每一个设置宽度
            messageBoxs.forEach(messageBox => {
                // 设置消息框宽度
                messageBox.style.width = `${parent.offsetWidth}px`;
                // 监听窗口大小变化重新设置消息框宽度
                window.addEventListener('resize', () => {
                    messageBox.style.width = `${parent.offsetWidth}px`;
                });
            });
        },
        onTyped(text) {
            let index = this.texts.indexOf(text);
            if (index > -1) {
                this.current = this.messages[index];
                this.isSendDisabled = false;
                setTimeout(() => {
                    this.isSendDisabled = true;
                }, 5200);
            }
        },
        send() {
            this.$emit('onSent', this.current);
        }
    }
}
</script>
<style scoped>

</style>