<template>
	<div class="bg-f2f align-center">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1400" class="mx-auto pt-7">
                <v-row no-gutters justify="center">
                    <!--------------------------- 同意规则 --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'AGREE_TO_RULES'">
                        <v-row no-gutters>
                            <v-col md="5" cols="12" sm="6" offset-md="1">
                                <Step :steps="steps" :action="action" class="ml-13 r-m-l-0"></Step>
                            </v-col>
                            <v-col cols="12" md="5" sm="6" class="r-m-t-8">
                                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-11 r-m-l-0" @click="$router.back(-1)"></v-icon>
                                <div class="mb-6 text-grey04 ml-11 r-m-l-0">
                                    <h1 class="sub-page-h1">Import an unNFT you own</h1>
                                </div>
                                <!--------------------------- unNFT info --------------------------->
                                <v-card max-width="462" color="transparent" class="ml-11 r-m-l-0" elevation="0" rounded="0">
                                    <h5 class="text_h6 text-balance text-primaryGrey">
                                        By uploading an item to this website, importing, minting or wrapping an unNFT, I represent and warrant that I am the owner of the item and have the right to upload, import, mint and wrap the unNFT to my wallet address. I also acknowledge that the untrading Protocol does not check or verify the copyright holders of the item. In the event that I do not own the copyright to the item or do not have the right to upload and mint the unNFT, I agree to indemnify and hold the Protocol and its affiliates harmless for any damages or losses resulting from my actions. I also acknowledge that any copyright disputes that may arise in the future are between myself and the third party, and the Protocol will not be held liable for any such disputes.
                                    </h5>
                                    <div class="mt-5">
                                        <v-checkbox v-model="agreeToRules" density="compact" class="my-checkbox font-weight-bold" color="primaryGrey">
                                            <template v-slot:label>
                                              <div>I have read and agree to the rules</div>
                                            </template>
                                        </v-checkbox>
                                    </div>
                                </v-card>
                                <div class="ml-11 mt-8 r-m-l-0">
                                    <Button variant="primary" :disabled="!agreeToRules" rounded="lg" color="black03" height="52" width="300" aria-label="Next" @click="action = 'OBTAIN_INFO'">Next</Button>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--------------------------- 选择文件 --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'OBTAIN_INFO'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <div class="ml-13 r-m-l-0">
                                    <div v-if="nft.file">
                                        <MintMedia :src="nft.file" @watchMediaFile="watchMediaFile"></MintMedia>
                                    </div>
                                    <div v-else>
                                        <el-upload class="mt-2 mb-10" ref="upload" disabled action="" accept="">
                                            <div :class="responsive == 'pc' ? 'upload ' : 'upload-m'"></div>
                                        </el-upload>
                                    </div>
                                    <Step :steps="steps" :action="action" class="mt-16"></Step>
                                </div>
                            </v-col>
                            <!--------------------------- Info--------------------------->
                            <v-col cols="12" md="5" sm="6">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'AGREE_TO_RULES'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 mt-3 ml-11 r-m-l-0">
                                    Import an unNFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Obtain the Contract Info</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-primaryGrey mb-6">
                                        Import an unNFT you own elsewhere!
                                    </h3>
                                    <h5 class="body-p text-primaryGrey">
                                        By importing the unNFTs you owned, you will be able to sell it on untrading.org protocol.
                                    </h5>
                                    <!-- 选择区块链 -->
                                    <h3 class="body-h4 mt-16 text-uppercase text-primaryGrey">Blockchain<span class="ml-1 text-red">*</span></h3>
                                    <div class="mt-3 mb-6">
                                        <span class="body-p text-primaryGrey">
                                            Choosing from supported blockchains
                                        </span>
                                    </div>
                                    <v-select v-model="selectedBlockchain" :label="isDisabledBlockchain ? '' : 'Blockchain *'" :items="supportedBlockchains" item-title="blockchain" :variant="isDisabledBlockchain ? 'solo' : 'outlined'" density="comfortable" :disabled="isDisabledBlockchain" hide-details return-object @update:modelValue="updateSelectedBlockchain">
                                        <template v-slot:selection="{ item }" >
                                            <img class="mr-3" :src="item.raw.icon" width="24" />
                                            <span>{{ item?.raw?.blockchain }}</span>
                                        </template>
                                    </v-select>
                                    <!-- 选择 unNFT -->
                                    <h3 class="body-h4 mt-16 mb-6 text-primaryGrey">Select an unNFT<span class="ml-1 text-red">*</span></h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Select an unNFT in your wallet from the drop down menu below.
                                    </h5>
                                    <v-select v-model="selectedNft" label="Select an unNFT *" :items="ownedNfts" item-title="normalizedMetadata.name" height="100" return-object clearable variant="outlined" density="comfortable" :loading="ownedNftsLoading" hide-details @update:modelValue="updateSelectedNft"></v-select>
                                    <h3 class="body-h4 mt-16 mb-6 text-primaryGrey">unNFT contract address<span class="ml-1 text-red">*</span></h3>
                                    <v-text-field v-model="nft.contractAddress" density="comfortable" variant="solo" hide-details readonly></v-text-field>
                                    <h3 class="body-h4 mt-16 mb-6 text-primaryGrey">unNFT Token ID<span class="ml-1 text-red">*</span></h3>
                                    <v-text-field v-model="nft.tokenId" density="comfortable" variant="solo" hide-details readonly></v-text-field>
                                </v-card>
                                <!--------------------------- Confirm --------------------------->
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" rounded="lg" color="black03" height="52" width="300" :disabled="isDisabledObtainInfoButton" @click="action = 'EXTRACTED_DATA';updateSelectedNft(selectedNft)" aria-label="Confirm">Confirm</Button>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pb-16" v-if="action == 'EXTRACTED_DATA' || action == 'NEW_LICENSE' || action == 'REWARD_RATIOS' || action == 'IMPORT' || action == 'CONFIRM'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <MintMedia :src="nft.file" @watchMediaFile="watchMediaFile" class="ml-13 r-m-l-0"></MintMedia>
                                <Step :steps="steps" :action="action" class="mt-16 ml-13 r-m-l-0"></Step>
                            </v-col>
                            <!--------------------------- EXTRACTED_DATA --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'EXTRACTED_DATA'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'OBTAIN_INFO'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 mt-3 ml-11 r-m-l-0">
                                    Import an unNFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Extracted data</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                   <h3 class="body-h4 mb-6 text-uppercase text-primaryGrey">
                                    Name<span class="ml-1 text-red">*</span>
                                   </h3>
                                   <v-text-field v-model="nft.name" variant="outlined" label="Name *" hide-details density="comfortable"></v-text-field>
                                   <h3 class="body-h4 mt-16 mb-6 text-uppercase text-primaryGrey">
                                    External link
                                   </h3>
                                   <v-text-field v-model="nft.externalUrl" variant="outlined" label="External link" hide-details density="comfortable"></v-text-field>
                                   <h3 class="body-h4 mt-16 mb-6 text-uppercase text-primaryGrey">
                                    Description
                                   </h3>
                                   <v-textarea v-model="nft.description" label="Description" variant="outlined" persistent-counter :counter="1000"></v-textarea>
                                </v-card>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-10" color="paleGreen" elevation="2" :rounded="0" v-if="false">
                                    <div class="mb-6">
                                        <span class="sidebar-h2 text-primaryGrey"></span>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 mb-6 text-uppercase text-primaryGrey">
                                     Properties
                                   </h3>
                                   <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Textual traits that show up as rectangles
                                   </h5>
                                   <v-row no-gutters>
                                        <v-col cols="2" v-for="n in 8" :key="n" class="ma-2">
                                            <v-text-field clearable v-model="nft.properties" variant="outlined" hide-details density="comfortable" disabled></v-text-field>
                                    </v-col>
                                   </v-row>
                                   <h3 class="body-h4 mb-6 mt-16 text-uppercase text-primaryGrey">
                                     Levels
                                   </h3>
                                   <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Numerical traits that show up as a progress bar
                                   </h5>
                                   <v-row no-gutters>
                                        <v-col cols="2" v-for="n in 8" :key="n" class="ma-2">
                                            <v-text-field clearable v-model="nft.levels" variant="outlined" hide-details density="comfortable" disabled></v-text-field>
                                    </v-col>
                                   </v-row>
                                   <h3 class="body-h4 mt-16 mb-6 text-uppercase text-primaryGrey">
                                     Stats
                                   </h3>
                                   <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Numerical traits that just show as numbers
                                   </h5>
                                   <v-row no-gutters>
                                        <v-col cols="2" v-for="n in 8" :key="n" class="ma-2">
                                            <v-text-field clearable v-model="nft.stats" variant="outlined" hide-details density="comfortable" disabled></v-text-field>
                                    </v-col>
                                   </v-row>
                                </v-card>
                                <!--------------------------- NEXT --------------------------->
                                <div class="ml-12 r-m-l-0 mt-8">
                                    <Button variant="primary" rounded="lg" color="black03" height="52" width="300" :disabled="isDisabledExtractedDataButton" @click="action = 'NEW_LICENSE'" aria-label="Next">Next</Button>
                                </div>
                            </v-col>
                            <!--------------------------- NEW_LICENSE --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'NEW_LICENSE'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'EXTRACTED_DATA'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 mt-3 ml-11">
                                    Import an unNFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Additional Attributes</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <div class="mb-6">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">department<span class="ml-1 text-red">*</span></h3>
                                    </div>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Choose one of the following department
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                As an NFT-framework-based investment platform, untrading.org is organized in four departments: Artwork Dept, Membership Dept, Collectible Dept and Crypto Dept.
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <div>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none body-p" :class="nft.department == 'Artwork' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Artwork'" aria-label="Artwork">Artwork</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none ml-2 body-p" :class="nft.department == 'Membership' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Membership'" aria-label="Membership">Membership</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none ml-2 r-m-l-0 body-p" :class="nft.department == 'Collectible' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Collectible'" aria-label="Collectible">Collectible</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none mt-2 r-m-l-2 body-p" disabled :class="nft.department == 'Cryptos' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Cryptos'" aria-label="Cryptos">Cryptos</v-btn>
                                    </div>
                                    <!-- ---------- Rarity ---------- -->
                                    <div class="mb-6 mt-16">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">Rarity</h3>
                                    </div>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        <span>The rarity rating of an unNFT is ultimately determined by the creator and the market. The criteria being used to evaluate it can vary. untrading allows you to assign the token's rarity.</span>
                                    </h5>
                                    <div class="mt-3">
                                        <v-select v-model="nft.rarity" label="Rarity" :items="rarityNames" variant="outlined" density="comfortable" hide-details clearable></v-select>
                                    </div>
                                    <!-- ---------- Sensitive ---------- -->
                                    <div class="mt-16">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">Explicit & Sensitive Content</h3>
                                    </div>
                                    <h6 class="mt-3 body-p mb-6 align-center d-flex">
                                        <span class="mr-10 text-primaryGrey">Set this item as explicit and sensitive content
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Setting your asset as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing untrading.org.
                                                </v-card>
                                            </v-menu>
                                        </span>
                                        <span class="font-weight-medium text-grey-darken-1">
                                            <v-switch v-model="nft.sensitiveContent" color="primary" density="comfortable" hide-details inset></v-switch>
                                        </span>
                                    </h6>
                                </v-card>
                                <!-- ---------- License ---------- -->
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <span class="sidebar-h2 text-primaryGrey"></span>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">License<span class="ml-1 text-red">*</span></h3>
                                    <h6 class="mt-3 mb-6">
                                        <span class="body-p text-primaryGrey">
                                           Choosing from one of six NFT-specific licenses
                                           <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    We support the “Can’t Be Evil” NFT Licenses by a16z that are inspried by the guiding principle in Web3 and Creative Commons. You must choose one of the six licenses for your unNFT.
                                                </v-card>
                                            </v-menu>
                                        </span>
                                    </h6>
                                    <v-select v-model="nft.license" :items="licenses" item-title="name" item-value="name" variant="solo" density="comfortable" hide-details readonly></v-select>
                                    <div class="mt-3 mb-6">
                                        <span class="body-p text-primaryGrey" v-for="license in licenses" :key="license.id">
                                           <span v-if="license.name == nft.license">{{ license.description }}</span>
                                        </span>
                                    </div>
                                </v-card>
                                <!--------------------------- NEXT --------------------------->
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" rounded="lg" color="black03" height="52" width="300" :disabled="isDisabledAdditionalAttributesButton" @click="action = 'REWARD_RATIOS'" aria-label="Next">Next</Button>
                                </div>
                            </v-col>
                            <!--------------------------- REWARD_RATIOS --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'REWARD_RATIOS'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'NEW_LICENSE'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 ml-11 r-m-l-0 mt-3">
                                    Import an unNFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <h2 class="mb-6">
                                        <span class="sidebar-h2 text-primaryGrey">Reward Ratios</span>
                                    </h2>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">
                                        Total Reward Ratio <span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT. Recommended 35%.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    Once imported, the Total Reward Ratio (f) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must mint a new unNFT.
                                                </div>
                                                <div class="mt-2">
                                                    You may find yourself unable to sell your unNFT if this value is too low or too high. 
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <v-text-field v-model="nft.totalRewardRatio" type="number" density="comfortable" min="5" max="50" variant="solo" hide-details :reverse="Number(nft.totalRewardRatio) > 0" readonly>
                                        <template v-slot:append-inner>%</template>
                                    </v-text-field>
                                    <h3 class="mt-16 mb-6 body-h4 text-uppercase text-primaryGrey">
                                        Originator Reward Ratio (OR)<span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h6 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split). Recommended 40%.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    The Originators' Token (o-token) may be viewed and managed on the Dashboard. Each unNFT consists of 100 o-tokens, 70 of which are sent to the originator(s) and 30 to untrading.org (For a limited time, all o-tokens designed for untrading.org are waived).
                                                </div>
                                                <div class="mt-2">
                                                    On your Dashboard, you can transfer o-tokens to other creators or originators.
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h6>
                                    <v-text-field v-model="nft.originatorRewardRatio" density="comfortable" type="number" min="5" max="50" variant="solo" hide-details :reverse="Number(nft.originatorRewardRatio) > 0" readonly>
                                        <template v-slot:append-inner>%</template>
                                    </v-text-field>
                                    <h3 class="mt-16 mb-6 body-h4 text-uppercase text-primaryGrey">
                                        Number of Generations <span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h6 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit. Recommended 10.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" aria-label="alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    Once imported, the Number of Generation (w) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must mint a new unNFT.
                                                </div>
                                                <div class="mt-2">
                                                    You may find yourself unable to sell your unNFT if this value is too low or too high. 
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h6>
                                    <v-text-field v-model="nft.numberOfGenerations" density="comfortable" type="number" min="5" max="20" variant="solo" hide-details :reverse="Number(nft.numberOfGenerations) > 0" readonly></v-text-field>
                                </v-card>
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" rounded="lg" color="black03" height="52" width="300" :disabled="isDisabledRewardRatiosButton" @click="gotoImport()" aria-label="Next">Next</Button>
                                </div>
                            </v-col>
                            <!--------------------------- IMPORT --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'IMPORT'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey" @click="action = 'REWARD_RATIOS'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 ml-11 r-m-l-0 mt-3">
                                    Import an unNFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Import Your unNFT</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h6 class="mt-3 body-p mb-16 text-primaryGrey">
                                        Finallze your unNFT by importing it on the untrading.org protocol. Once you import your unNFT, it cannot be updated or changed.
                                    </h6>
                                </v-card>
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" :loading="importLoading" rounded="lg" color="black03" height="52" width="300" @click="confirmUnNftInfo" aria-label="Import">Import!</Button>
                                </div>
                            </v-col>
                            <!--------------------------- Confirm --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'CONFIRM'">
                                <h1 class="sub-page-h1 mb-6 text-grey04 ml-11 r-m-l-0">
                                    Import an unNFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <h3 class="mb-6">
                                        <span class="sidebar-h2 text-primaryGrey">Import Your unNFT</span>
                                    </h3>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="mt-3 body-p mb-16 text-primaryGrey">Your unNFT is being imported...</h5>
                                </v-card>
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" :loading="importLoading" rounded="lg" color="black03" height="52" width="300" @click="confirmUnNftInfo" aria-label="Import">Import!</Button>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--------------------------- Completion --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'COMPLETION'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <UnNftMedia :nftId="nftId" class="ml-13 r-m-l-0"></UnNftMedia>
                            </v-col>
                            <v-col md="5" cols="12" sm="6" class="r-m-t-16">
                                    <h1 class="sub-page-h1 mb-6 text-grey04 ml-11 r-m-l-0">
                                        Import an unNFT you own
                                    </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <i class="mb-5 iconfont icon-complete text-primaryGrey"></i>
                                    <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your unNFT has been imported !</h2>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="body-p text-primaryGrey">
                                        Congratulations! Your artwork has offically been imported to untrading.org protocol.
                                    </h5>
                                    <h5 class="mt-5 body-p text-primaryGrey">
                                        Now it’s time to list it for sale on untrading!
                                    </h5>
                                </v-card>
                                <div class="ml-12 r-m-l-0 mt-8">
                                    <div class="d-flex justify-space-between" :class="responsive == 'mobile' ? ' flex-column' : ''">
                                        <Button variant="primary" :to="$tools.getUnNFTUrl(selectedBlockchain != null ? selectedBlockchain.blockchain : null, nftId)" rounded="lg"  color="black03" width="200" aria-label="View">View</Button>
                                        <!-- <router-link :to="$tools.getUnNFTUrl(selectedBlockchain != null ? selectedBlockchain.blockchain : null, nftId)" class="del-underline gradient-left-red-purple-200 r-m-t-4">
                                            <v-btn rounded="lg" elevation="2" color="primary" class="text-none text-white body-p-small-b" width="200" height="66" aria-label="View">View</v-btn>
                                        </router-link> -->
                                        <!-- <router-link :to="'/unnft/list/' + nftId" class="del-underline gradient-grey-200">
                                            <v-btn rounded="lg" elevation="2" color="hover03" class="text-none text-black01 body-p-small-b" width="200" height="66" aria-label="Sell">Sell</v-btn>
                                        </router-link> -->
                                        <Button variant="secondary" :to="'/unnft/list/' + nftId" rounded="lg" color="black01" width="200" class="r-m-t-4" aria-label="Sell">Sell</Button>
                                    </div>
                                    <Step :steps="steps" :action="action" class="mt-16"></Step>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 弹窗组件 -->
        <!-- Loading media file... -->
        <v-dialog v-model="loadingMediaFileDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-progress-linear indeterminate bg-opacity="1" bg-color="paleYellow" color="primary"></v-progress-linear>
            <v-card class="pa-8 bg-paleYellow" rounded="lg">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h2 class="sidebar-h2 text-primaryGrey">Loading media file...</h2>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <h5 class="body-p mb-16 text-primaryGrey">The InterPlanetary File System, known as IPFS, is a peer-to-peer network for sharing data in a distributed file system.</h5>
                            <h5 class="body-p gradient-underline-hover">
                                <a href="https://ipfs.io" aria-label="Ipfs" target="_black" class="gradient-text-hover text-primaryGrey del-underline">Learn about IPFS</a><v-icon class="ml-2 text-primaryGrey" icon="fa-solid fa-arrow-right-long" size="14"></v-icon>
                            </h5>
                            <div class="mt-16 text-center">
                                <Button variant="primary" rounded="lg" color="black03" width="200" @click="reload" aria-label="Interrupt upload">Interrupt upload</Button>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 无效的媒体文件弹窗组件 -->
        <v-dialog v-model="invalidMediaFileDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8" color="pinkTone" :rounded="0">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h2 class="sidebar-h2 text-primaryGrey">Invalid media file</h2>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <h5 class="body-p text-primaryGrey">{{ uploadErrorMessage }}</h5>
                            <h5 class="body-p mb-16 text-primaryGrey">Please select a supported NFT to import!</h5>
                            <div class="mt-16 text-center">
                                <Button variant="primary" rounded="lg" color="black03" width="200" aria-label="OK" @click="invalidMediaFileDialog = false">OK</Button>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 保存草稿 -->
        <SaveDraft :show="saveDraftDialog" @watchSaveDraft="watchSaveDraft"></SaveDraft>
        <!-- 确认信息弹窗 -->
        <ConfirmUnNftInfoDialog :show="confirmUnNftInfoDialog" :nft="nft" @watchConfirmUnNftInfoDialog="watchConfirmUnNftInfoDialog"></ConfirmUnNftInfoDialog>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask"></MetaMask>
        <Reown ref="Reown"></Reown>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import TestData from './data/test-data.json'
import IPFSAPI from '@/api/ipfs.js'
import SetAPI from '@/api/set.js'
import NFTAPI from '@/api/nft.js'
import NFTRarityAPI from '@/api/nft-rarity.js'
import MoralisAPI from '@/api/moralis.js'
import UnNftMedia from '@/components/nft/UnNftMedia.vue'
import MintMedia from '@/components/nft/MintMedia.vue'
import SaveDraft from '@/components/nft/SaveDraft.vue'
import ConfirmUnNftInfoDialog from '@/components/nft/ConfirmUnNftInfoDialog.vue'
import { Web3 } from "web3";
export default {
    data(){
        return {
            // 操作
            action: 'AGREE_TO_RULES',
            // 步骤
            steps: [
                { title: 'Agree to rules', codes: ['AGREE_TO_RULES'] },
                { title: 'Obtain contract info', codes: ['OBTAIN_INFO'] },
                { title: 'Display extracted data', codes: ['EXTRACTED_DATA'] },
                { title: 'Select a new license', codes: ['NEW_LICENSE'] },
                { title: 'Set reward ratios', codes: ['REWARD_RATIOS'] },
                { title: 'Import your unNFT', codes: ['IMPORT', 'CONFIRM'] },
                { title: 'Completion', codes: ['COMPLETION'] }
            ],
            // 同意规则
            agreeToRules: false,
            // 查询拥有的 NFT 加载中
            ownedNftsLoading: false,
            // 拥有的 NFT 集合
            ownedNfts: [],
            // 禁用区块链
            isDisabledBlockchain: false,
            // 选中的区块链
            selectedBlockchain: {},
            // 选中的 NFT 对象
            selectedNft: null,
            // 稀有度名称集合
            rarityNames: [],
            // 创建对象
            nft:{
                // 读取
                properties: null,
                levels: null,
                stats: null,
                // 输入
                setId: this.$route.query.setId,
                name: null,
                externalUrl: null,
                description: null,
                license: null,
                image: null,
                file: null,
                ipfsFileUrl: null,
                ipfsCid: null,  // ipfsCid 用于删除时使用
                totalRewardRatio: null,
                originatorRewardRatio: null,
                numberOfGenerations: null,
                blockchain: null,
                contractAddress: null,
                tokenId: null,
                transactionHash: null,
                department: null,
                rarity: null,
                category: null,
                sensitiveContent: false,
                metadata: null
            },
            // 元数据的 IPFS 链接
            metadataIpfsUrl: null,
            // 加载中
            importLoading: false,
            // 创建成功后的平台主键
            nftId: null,
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 保存草稿弹窗
            saveDraftDialog: false,
            // 确认信息弹窗
            confirmUnNftInfoDialog: false,
            // 路由要去的地方
            routeTo: null,
            // 加载媒体文件弹窗
            loadingMediaFileDialog: false,
            // 无效的媒体文件弹窗
            invalidMediaFileDialog: false,
            // 上传失败信息
            uploadErrorMessage: null,
        }
    },
    components: { UnNftMedia, MintMedia, SaveDraft, ConfirmUnNftInfoDialog },
    created(){
        // 加载缓存
        this.loadCache();
        // 获取所有的稀有度名称集合
        this.getRarityNames();
        // 查询套装
        this.getSetById(this.nft.setId);
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user', 'walletType', 'metaMask', 'licenses', 'blockchains','responsive']),
        // NFT 支持的区块链
        supportedBlockchains() {
            return this.blockchains.filter(b => b.unNFTContract != null);
        },
        // 禁用获得信息按钮
        isDisabledObtainInfoButton() {
            return !this.nft.blockchain || !this.nft.contractAddress || !this.nft.tokenId || !this.nft.file;
        },
        // 禁用提取信息按钮
        isDisabledExtractedDataButton() {
            return !this.nft.name || (this.nft.description != null && this.nft.description.length > 1000);
        },
        // 禁用添加属性按钮
        isDisabledAdditionalAttributesButton() {
            return !this.nft.department || !this.nft.license;
        },
        // 禁用奖励比例按钮
        isDisabledRewardRatiosButton() {
            let totalRewardRatio = Number(this.nft.totalRewardRatio);
            if(totalRewardRatio < 5 || totalRewardRatio > 50) {
                return true;
            }
            let originatorRewardRatio = Number(this.nft.originatorRewardRatio);
            if(originatorRewardRatio < 5 || originatorRewardRatio > 50) {
                return true;
            }
            let numberOfGenerations = Number(this.nft.numberOfGenerations);
            if(numberOfGenerations < 5 || numberOfGenerations > 20) {
                return true;
            }
            return false;
        },
    },
    watch:{
        // 监听步骤做缓存
        action(newVal, oldVal) {
            // 不是最后一步时候缓存，最后一步不缓存
            if(this.steps[this.steps.length - 1].codes.indexOf(newVal) == -1) {
                localStorage.setItem('untrading-import-step', newVal)
            }
        },
        // 监听参数变化做缓存
        nft: {
            handler(newVal, oldVal) {
                localStorage.setItem('untrading-import', JSON.stringify(newVal))
            },
            deep: true
        },
        'user.wallet': {
            handler(newVal, oldVal) {
                // 根据钱包地址分页查询所有的 UnNFTs
                this.getUnNFTsByWallet();
            },
            deep: true,
            immediate:true
        }
    },
    methods: {
        // 刷新页面
        reload() {
            location.reload();
        },
        // 加载缓存
        loadCache() {
            let action = localStorage.getItem('untrading-import-step');
            if(action) {
                this.action = action;
            }
            let nft = localStorage.getItem('untrading-import');
            if(nft) {
                // 更新数据，同时更新为新的套装主键
                let cacheNft = JSON.parse(nft);
                cacheNft.setId = this.nft.setId;
                this.nft = cacheNft;
                // 查询套装
                this.getSetById(this.nft.setId);
                // 选中的区块链
                this.selectedBlockchain = this.supportedBlockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 根据钱包地址分页查询所有的 UnNFTs
                this.getUnNFTsByWallet();
            } else {
                localStorage.removeItem('untrading-import-step');
                this.action = 'AGREE_TO_RULES';
            }
        },
        // 更新选择的区块链
        updateSelectedBlockchain(item) {
            //  更新区块链
            this.selectedBlockchain = item;
            this.nft.blockchain = item.blockchain;
            this.nft.contractAddress = null;
            this.nft.tokenId = null;
            this.nft.file = null;
            // 所拥有的 NFTs
            this.ownedNfts = [];
            // 重新查询 unNFTs 数据
            this.getUnNFTsByWallet();
        },
        // 更新选择的 NFT
        updateSelectedNft(selectedNft) {
            this.selectedNft = selectedNft;
            if(!selectedNft) {
                selectedNft = {
                    normalizedMetadata: {}
                };
            }
            this.nft.contractAddress = selectedNft.tokenAddress;
            this.nft.tokenId = selectedNft.tokenId;
            this.nft.name = selectedNft.normalizedMetadata.name;
            this.nft.description = selectedNft.normalizedMetadata.description;
            this.nft.image = selectedNft.normalizedMetadata.image;
            this.nft.file = selectedNft.normalizedMetadata.file;
            this.nft.license = selectedNft.normalizedMetadata.license;
            this.nft.externalUrl = selectedNft.normalizedMetadata.externalUrl;
            this.nft.department = selectedNft.normalizedMetadata.department;
            this.nft.rarity = selectedNft.normalizedMetadata.rarity;
            this.nft.category = selectedNft.normalizedMetadata.category;
            this.nft.totalRewardRatio = selectedNft.normalizedMetadata.totalRewardRatio;
            this.nft.originatorRewardRatio = selectedNft.normalizedMetadata.originatorRewardRatio;
            this.nft.numberOfGenerations = selectedNft.normalizedMetadata.numberOfGenerations;
        },
        // 根据主键查询套装
        async getSetById(setId) {
            if(!setId) {
                return;
            }
            let res = await SetAPI.getSetById(setId);
            let data = res.data;
            if(data.success) {
                this.isDisabledBlockchain = true;
                // 套装的区块链就是 NFT 的区块链
                this.nft.blockchain = data.data.blockchain;
                // 选中的区块链
                this.selectedBlockchain = this.supportedBlockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 合约地址
                this.nft.contractAddress = this.selectedBlockchain.unNFTContract;
            } else {
                this.isDisabledBlockchain = false;
                this.nft.setId = null;
                this.nft.blockchain = null;
                this.nft.contractAddress = null;
                this.selectedBlockchain = {};
            }
        },
        // 根据钱包地址分页查询所有的 unNFTs
        async getUnNFTsByWallet() {
            if(this.selectedBlockchain == null || this.selectedBlockchain.blockchain == null || this.user.wallet == null) {
                return;
            }
            this.selectedNft = null;
            this.ownedNftsLoading = true;
            // 下一次分页的光标
            let cursor = null;
            while(true) {
                // this.user.wallet
                // '0x8d894500675C860D86619cC77742b8Dca7eF74e2'
                let res = await MoralisAPI.getUnNFTsByWallet(this.selectedBlockchain.blockchain, this.user.wallet, cursor, true);
                let data = res.data;
                // let data = TestData;
                if(data.success) {
                    // 所拥有的 NFTs
                    this.ownedNfts = [...this.ownedNfts, ...data.data.records];
                    // 当前选中的 NFT
                    this.selectedNft = this.ownedNfts.filter(nft => nft.tokenAddress == this.nft.contractAddress && nft.tokenId == this.nft.tokenId)[0];
                    // 分页光标
                    cursor = data.data.countId;
                } else {
                    break;
                }
                // 光标为 null,停止循环
                if(!cursor) {
                    break;
                }
            }
            // 如果没有数据，那已经填入的参数也恢复初始
            if(this.selectedNft == null || this.selectedNft.tokenAddress == null) {
                // 更新选择的 NFT
                this.updateSelectedNft(null);
            } 
            this.ownedNftsLoading = false;
        },
        // 上传 URL 到 IPFS
        async uploadUrl() {
            // 加载媒体文件
            this.loadingMediaFileDialog = true;
            let paramsData = {
                url: this.selectedNft.normalizedMetadata.file,
                name: this.nft.name
            };
            let res = await IPFSAPI.uploadUrl(paramsData);
            let data = res.data;
            if(data.success) {
                this.nft.file = data.data.fileUrl;
                this.nft.ipfsFileUrl = data.data.httpUrl;
                this.nft.image = data.data.ipfsUrl;
                this.nft.ipfsCid = data.data.cid;
                // 关闭弹窗
                this.loadingMediaFileDialog = false;
                return true;
            } else {
                // this.$store.dispatch('snackbarMessageHandler', data.message);
                // 上传失败
                if(data.data) {
                    this.uploadErrorMessage = data.data[0];
                } else {
                    this.uploadErrorMessage = data.message;
                }
                this.invalidMediaFileDialog = true;
                // 关闭弹窗
                this.loadingMediaFileDialog = false;
                return false;
            }
        },
        // 监听媒体文件
        watchMediaFile(invalidMediaFileDialog) {
            this.invalidMediaFileDialog = invalidMediaFileDialog;
            this.selectedNft = null;
            this.nft.contractAddress = null;
            this.nft.tokenId = null;
            this.nft.file = null;
        },
        // 获取所有的稀有度名称集合
        async getRarityNames() {
            let res = await NFTRarityAPI.getRarityNames();
            let data = res.data;
            if(data.success) {
                this.rarityNames = data.data;
            }
        },
        // 下一步
        gotoImport() {
            let totalRewardRatio = Number(this.nft.totalRewardRatio);
            if(totalRewardRatio < 5 || totalRewardRatio > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Total Reward Ratio");
                return;
            }
            let originatorRewardRatio = Number(this.nft.originatorRewardRatio);
            if(originatorRewardRatio < 5 || originatorRewardRatio > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Originator Reward Ratio");
                return;
            }
            let numberOfGenerations = Number(this.nft.numberOfGenerations);
            if(numberOfGenerations < 5 || numberOfGenerations > 20) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Number Of Generations");
                return;
            }
            this.action = 'IMPORT';
        },
        // 确认信息
        confirmUnNftInfo() {
            if(!this.nft.blockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            if(!this.nft.contractAddress) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild contract address");
                return;
            }
            this.confirmUnNftInfoDialog = true;
        },
        // 导入NFT
        async importNFT() {
            // 确认中
            this.action = 'CONFIRM';
            // 加载中
            this.importLoading = true;
            // 上传 URL 到 IPFS
            let uploaded = await this.uploadUrl();
            // 文件加载失败
            if(!uploaded) {
                this.importLoading = false;
                return;
            }
            let res = await NFTAPI.importNFT(this.nft);
            let data = res.data;
            // 清除缓存
            localStorage.removeItem('untrading-import-step');
            localStorage.removeItem('untrading-import');
            if(data.success) {
                this.nftId = data.data;
                // 成功后进入下一步
                this.action = 'COMPLETION';
            } else {
                if(data.data) {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                }
                // 失败后回到上一步
                this.action = 'IMPORT';
            }
            // 加载完成
            this.importLoading = false;
        },
        // 监听保存草稿
        watchSaveDraft(save) {
            this.saveDraftDialog = false;
            // 不保存
            if(!save) {
                // 删除文件
                this.deleteFile(this.nft.ipfsCid);
                // 清除缓存
                localStorage.removeItem('untrading-import-step');
                localStorage.removeItem('untrading-import');
            }
            if(this.routeTo) {
                this.$router.push(this.routeTo.fullPath);
            }
        },
        // 监听确认信息弹窗
        watchConfirmUnNftInfoDialog(confirmed) {
            this.confirmUnNftInfoDialog = false;
            if(confirmed) {
                // 调用导入
                this.importNFT();
            }
        },
        // 删除文件
        async deleteFile(cid) {
            // 发出删除请求
            await IPFSAPI.delete(cid);
        }
    },
    beforeRouteLeave(to, from, next) {
        // 如果有没有步骤数据，则本身无缓存，不提示
        if(!localStorage.getItem('untrading-import-step')) {
            next();
        }
        if(this.routeTo) {
            next();
        } else {
            this.routeTo = to;
            this.saveDraftDialog = true;
            // 不加 next(); 就会阻止离开页面的
        }
    }
}
</script>
<style scoped>
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
.upload{
    width: 414px;
    height: 414px;
    border: 3px dashed #7b7b7b;
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    cursor: default;
}
:deep(.v-echarts-dialog){
    width: auto;
}
::v-deep .my-checkbox .v-label {
 font-size: 14px!important;
}
</style>