<template>
    <div class="slogan-container">
      <span v-show="currentSlogan?.message" :key="currentSlogan.message" class="slogan-text">
        {{ currentSlogan.message }}
      </span>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentSlogan: {},
        slogans: [
          { message: 'Win Together. Build States.' },
          { message: '互助共生，共建王国' },
          { message: 'Gagnons Ensemble. Construisons des Pays.' },
          { message: 'Ganemos Juntos. Construyamos Países.' },
          { message: 'Vinciamo Insieme. Costruiamo Paesi.' },
          { message: 'Vencemos Juntos. Construímos Países.' },
          { message: 'Zusammen Gewinnen. Länder Bauen.' },
          { message: 'Samen Winnen. Landen Bouwen.' },
          { message: 'побеждаем Вместе. Строим Страны.' },
          { message: 'Wygrywamy Razem. Budujemy Kraje.' },
          { message: '共に勝つ。国を築く。' },
          { message: 'साथ जीतें। देश बनाएं।' },
          { message: 'نفوز معًا. نبني دولًا' },
          { message: '함께 이기자. 나라를 세우자.' },
          { message: 'ננצח ביחד. נבנה מדינות.' },
          { message: 'Vind Sammen. Byg Lande.' },
          { message: 'Vinn Sammen. Bygg Land.' },
          { message: 'Vinn Tillsammans. Bygg Länder.' },
          { message: 'Voita Yhdessä. Rakenna Maita.' },
          { message: 'Thắng Cùng Nhau. Xây Dựng Quốc Gia.' },
          { message: 'ชนะด้วยกัน สร้างชาติ' },
          { message: 'Menang Bersama. Bangun Negara.' },
          { message: 'Menang Bersama. Bina Negara.' },
          { message: 'Manalo Nang Magkasama. Bumuo ng mga Bansa.' },
          { message: 'একসঙ্গে জয়ী হই। দেশ গড়ি।' },
          { message: 'ஒன்றாக வெல்வோம். நாடுகளை உருவாக்குவோம்.' },
          { message: 'కలిసి గెలుద్దాం. దేశాలను నిర్మిద్దాం.' },
          { message: 'Shinda Pamoja. Jenga Nchi.' },
          { message: 'Ṣẹgun Pọ̀. Kọ́ Àwọn Orílẹ̀-èdè.' },
          { message: 'Ci Nasara Tare. Gina Ƙasashe.' },
          { message: 'እንደተከበርን እንልካለን። አገሮችን እንገነባለን።' },
          { message: 'Nqoba Sisonke. Sakha Amazwe.' },
          { message: 'Kukunda Pamwechete. Kuvaka Nyika.' },
          { message: 'Phumelela Sisonke. Yakha Amazwe.' },
          { message: 'Guulaysanno Wada Jir. Dhiso Dawlado.' },
          { message: 'Nyɛ Yɛn Nkonim. Si Yɛn Amandze.' },
        ],
        index: 0,
      };
    },
    mounted() {
      this.nextSlogan();
    },
    watch: {
      index() {
        setTimeout(() => {
          this.nextSlogan();
        }, 2000); // 2秒切换一次（可以再调更快）
      }
    },
    methods: {
      nextSlogan() {
        this.currentSlogan = this.slogans[this.index];
        this.index = (this.index + 1) % this.slogans.length;
      },
    },
  };
  </script>
  
  <style scoped>
  .slogan-container {
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slogan-text {
    font-size: 14px;
    color: #666;
    animation: slide-up 0.2s ease-in-out; /* 0.2s 滚动速度 */
  }
  
  /* 平滑滚动动画（无闪烁） */
  @keyframes slide-up {
    from {
      transform: translateY(20px);
      opacity: 1;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  </style>
  