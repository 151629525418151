<template>
    <div class="flex flex-col mt-10 gap-2xl @md:gap-3xl">
        <div class="@container w-full max-w-container">
            <div class="relative w-full">
                <div class="relative z-0 grid grid-cols-12 mt-lg @md:mt-xl">
                    <div class="col-span-12 @md:col-span-10 @md:col-start-2">
                        <div class="col-span-12 overflow-hidden">
                            <div class="w-full mx-auto ease-curve-c duration-normal transition-opacity max-w-container-desktop relative">
                                <div class="relative h-full w-full" style="aspect-ratio: 16 / 9;">
                                    <div class="h-full w-full">
                                        <div class="relative h-full w-full overflow-hidden rounded-md aspect-16/9 ease-curve-d duration-normal bg-transparent transition-[background]">
                                         <img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/db50716b-6d7c-4f14-13b4-2a89332ab600/public"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="@container max-w-container grid w-full">
            <div class="mb-lg @container multi-columns:px-0 grid w-full grid-cols-12 max-w-container">
                <div class="col-span-12 @md:col-span-10 @md:col-start-2 @lg:col-span-8 @lg:col-start-3">
                    <div class="relative flex flex-col items-center text-center">
                        <div class="d-flex">
                            <div v-show="this.darkTheme == 0">
                                <Logo type="unCryptos" app="unCryptos" color="light" responsive="pc" size="150"></Logo>
                            </div>
                            <div v-show="this.darkTheme == 1" >
                                <Logo type="unCryptos" app="unCryptos" color="dark" responsive="pc" size="150"></Logo>
                            </div>
                        </div>
                        <h3 class="text_h3 scroll-mt-header-h max-w-[62.5rem] text-balance mt-md">No Fees, No Predictions</h3>
                        <div class="mt-md">
                            <p class="col-span-12 text-balance">Grow your profits through participation with Future Rewards.</p>
                        </div>
                        <h3 class="text_h3 scroll-mt-header-h max-w-[62.5rem] text-balance mt-md">Full Control</h3>
                        <div class="mt-md">
                            <p class="col-span-12 text-balance gradient-underline-hover">Keep your crypto in your wallet while earning risk-free "<a href="https://5173.foundation/" target="_blank" class="pointer text-tertiary del-underline link-underline-hover"><span class="gradient-text-hover">variable annuity</span></a>" rewards through Flows.</p>
                        </div>
                        <div class="mt-md">
                            <Button href="https://testnet.untrading.org/uncryptos" variant="primary" rounded="lg" color="black03" width="200" aria-label="Launch unCryptos">Launch unCryptos</Button>
                        </div>
                        <div class="mt-md">
                            <Market></Market>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Logo from '@/components/common/Logo';
    import Market from './Market.vue'; 
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
    
            }
        },
        components: { Logo, Market },
        created(){
    
        },
        mounted(){
    
        },
        computed: {
            ...mapGetters(['darkTheme']),
        },
        watch:{
    
        },
        methods: {
            
        }
    }
</script>
<style scoped></style>