<template>
	<div class="markdown prose">
        <p>Now that we know who inspires your trading style—Soros, Dalio, or Buffett—let’s talk about how you’d like to execute your strategy:</p>
        <p>Would you prefer:</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">AUTOPILOT - Set It and Forget It: </span>Let our system handle everything for you using proven strategies inspired by your chosen role model (Soros, Dalio, or Buffett).</li>
            <li><span class="text-black01 font-weight-bold">CRYPTO RACETRACK - Hands-On & Tactical: </span>Take full control of your trades with advanced tools and real-time insights.</li>
        </ol>
        <p>Which sounds more aligned with your goals?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'AUTOPILOT - Set It and Forget It' },
                { code: 'Manual', message: 'CRYPTO RACETRACK - Hands-On & Tactical' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>