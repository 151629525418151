<template>
	<div class="markdown prose">
        <p>Got it! You study the market. You make rational decisions. But even the most disciplined traders face moments where everything changes:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Your well-planned position? Liquidated in a sudden capitulation.</span></li>
            <li><span class="text-black01 font-weight-bold">That perfect trend? Destroyed by unexpected macro news.</span></li>
            <li><span class="text-black01 font-weight-bold">Your confidence? Used against you by institutions hunting liquidity.</span></li>
        </ul>
        <p >At <span class="text-black01 font-weight-bold">Untrading</span>, we give traders an edge:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Selling is just the start. </span>Keep earning with Future Rewards (FRs).</li>
            <li><span class="text-black01 font-weight-bold">No fees, no games, no predatory practices.</span></li>
            <li><span class="text-black01 font-weight-bold">Trade with confidence, knowing your success isn’t tied to market tricks.</span></li>
        </ul>
        <p >Because true strategy isn’t just about winning the trade—it’s about <span class="text-black01 font-weight-bold">winning beyond the trade.</span></p>
        <br><hr>
		<p>With your trading style defined and Untrading’s advantages clear, who inspires your trading journey at this stage? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>