<template>
	<div class="markdown prose">
        <p>Got it! Living for the trade? You know the rush—and the risks.</p>
        <p>Quick gains come with steep falls. Market manipulation, stop hunts, and volatility can erase profits instantly.</p>
        <h3 class="text-black01 font-weight-bold">Enter Untrading: Where selling isn't goodbye.</h3>
        <ul>
            <li>Keep earning after you exit</li>
            <li>Zero hidden fees</li>
            <li>Future Rewards on past trades</li>
        </ul>
        <p>Don't just trade. Untrade—and let your success compound even after you move on.</p>
        <br><hr>
		<p>Now that you’ve identified your trading style and discovered how Untrading can enhance your results, who inspires your approach to trading today? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>