<template>
	<div class="main-container">
        <v-container class="h-v-60 bg-black r-p-x-0">
            <div class="d-flex align-center justify-center h-50vh">
                <div class="layoutContent">
                    <div class="Grid_root__iRfoa grid-style-17">
                        <div class="Grid_a__vY7M8">
                            <div class="d-flex flex-column ga-10">
                                <h1 class="text_h1 text-quaternary">
                                    The Network State Connection
                                </h1>
                                <p class="text-p1">
                                    The connection between Untrading and the concept of Network States as proposed by Balaji Srinivasan is potentially revolutionary.
                                </p>
                                <div v-if="!token">
                                    <Button to="/connectwallet?redirectUrl=/uncryptos" variant="primary" rounded="lg" color="black03"  width="200" aria-label="Connect Wallet">Connect Wallet</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
        <v-container class="pa-0 bg-black r-p-x-0">
            <v-row no-gutters>
                <div class="layoutContent">
                    <div class="Grid_root__iRfoa grid-style-15">
                        <div class="page_progressBlur Grid_a__vY7M8 r-p-b-8">
                            <div class="d-flex flex-column ga-2">
                                <p class="text-p1 text-tertiary">
                                    Untrading provides a robust framework where decentralized finance (DeFi) can support and sustain economic activities even after assets are sold. This aligns well with the idea of Network States, which are essentially decentralized communities with their own economic systems, currencies, and governance.
                                </p>
                           </div>
                        </div>
                        <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                        <div class="page_progressBlur Grid_b___vQi_">
                            <p class="text-p1 text-tertiary">
                                Let's explore how these ideas intersect and how Untrading's Kingdom building feature aligns with and extends the Network State concept:
                            </p>
                        </div>
                    </div>
                    <div class="Grid_root__iRfoa grid-style-16" v-for="(item, i) in differences" :key="`difference-${i}`">
                        <div class="Grid_a__vY7M8 mt-16">
                            <h2 class="fs-31 text-secondary font-weight-bold">{{` ${i + 1}. ${item.title} `}}</h2>
                        </div>
                        <div class="Grid_c__Tv5Qw r-m-t-16">
                            <div class="ga-2 d-flex flex-column">
                                <h3 class="fs-21-bold text-quaternary font-weight-bold">Network States</h3>
                                <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                                <p class="fs-17 text-tertiary">{{ item.networkStates }}</p>
                            </div>
                        </div>
                        <div class="Grid_d__WN1qv r-m-t-16 r-m-b-16">
                            <div class="ga-2 d-flex flex-column font-weight-bold">
                                <h3 class="fs-21-bold text-primary">Untrading</h3>
                                <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                                <p class="fs-17 text-tertiary">{{ item.untrading }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                    <div class="Grid_root__iRfoa grid-style-17 pb-16">
                        <div class="page_progressBlur Grid_a__vY7M8 r-p-b-8">
                            <div class="d-flex flex-column markdown text-tertiary">
                                <p>
                                    Untrading's Kingdom building feature can be seen as a practical implementation of many Network State principles in the context of decentralized finance:
                                </p>
                                <ul class="pl-8 custom-ul">
                                    <li>Provides governance structure for digital-first communities.</li>
                                    <li>Allows creation of shared economies within each Kingdom.</li>
                                    <li>Offers a pathway for scaling influence and governance (Knight to King).</li>
                                    <li>Aligns economic incentives among community members.</li>
                                    <li>Enables global participation unrestricted by traditional borders.</li>
                                    <li>Represents a new form of digital citizenship through active participation.</li>
                                    <li>Allows for progressive decentralization as Kingdoms grow.</li>
                                </ul>
                                <p>
                                    Untrading’s Kingdom building feature could serve as a foundational economic layer for Network States, providing the financial infrastructure and governance mechanisms needed for effective operation. Each Kingdom could evolve into a Network State, aligning perfectly with the vision of Network States and potentially laying the groundwork for a new form of digital socio-economic organization.
                                </p>
                                <p>
                                    Moreover, the ERC-5173 standard's ability to maintain economic connections even after asset sales could be crucial for Network States, allowing for flexible membership while maintaining economic alignment.
                                </p>
                                <p>
                                    By implementing these concepts, Untrading is not just creating a new trading platform, but potentially laying the groundwork for a new form of digital socio-economic organization. 
                                </p>
                                <p>
                                    This alignment makes Untrading a natural and powerful extension of the revolutionary concept of Network States.
                                </p>
                           </div>
                        </div>
                    </div>
                </div>
            </v-row>
            <Footer class="bg-black"></Footer>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Footer from './v3/components/Footer.vue';
import Theme from '@/components/common/Theme';
export default {
    data(){
        return {
            currentUrl: window.location.href,
            // 对比数据
            differences: [
                {
                    title: 'Decentralized Governance',
                    networkStates: 'Emphasize decentralized, cloud-based governance structures.',
                    untrading: 'Kingdom building allows for decentralized, community-led governance within each kingdom.'
                },
                {
                    title: 'Digital-First Communities',
                    networkStates: 'Prioritize online communities that may later manifest in physical locations.',
                    untrading: 'Kingdoms are digital-first communities united by shared economic interests and governance structures.'
                },
                {
                    title: 'Shared Economy',
                    networkStates: 'Propose new economic models for community wealth creation.',
                    untrading: 'ERC-5173 and Future Rewards create a shared economic model where all participants benefit from collective success.'
                },
                {
                    title: 'Scalable Sovereignty',
                    networkStates: 'Suggest a path from online communities to recognized sovereign entities.',
                    untrading: 'Kingdom hierarchy (Knight to King) provides a framework for scaling governance and influence.'
                },
                {
                    title: 'Alignment of Incentives',
                    networkStates: 'Emphasize aligning incentives among community members.',
                    untrading: 'Zero commission model and shared rewards ensure aligned incentives among all participants.'
                },
                {
                    title: 'Cross-Border Collaboration',
                    networkStates: 'Envision global communities unrestricted by traditional borders.',
                    untrading: 'Allows for global participation in Kingdoms, transcending geographical boundaries.'
                },
                {
                    title: 'Tokenized Citizenship',
                    networkStates: 'Propose digital citizenship often represented by tokens.',
                    untrading: 'UN tokens and Kingdom participation serve as a form of "citizenship" in the Untrading ecosystem.'
                },
                {
                    title: 'Progressive Decentralization',
                    networkStates: 'Advocate for gradual decentralization of power and decision-making.',
                    untrading: 'Kingdom structure allows for progressive decentralization as communities grow and evolve.'
                },
                {
                    title: 'New Forms of Capital Formation',
                    networkStates: 'Explore innovative ways to pool and deploy capital.',
                    untrading: 'Collaborative wealth creation through ERC-5173 represents a new model of capital formation.'
                },
                {
                    title: 'Digital-Physical Hybridization',
                    networkStates: 'Envision a blend of digital and physical presence.',
                    untrading: 'While primarily digital, Kingdoms could potentially organize physical meetups or shared spaces.'
                }
            ]
        }
    },
    components: { Footer, Theme },
    created(){

    },
    mounted() {
    },
    computed: {
        ...mapGetters(['token', 'responsive'])
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {
    }
}
</script>
<style scoped>

</style>