<template>
	<div class="markdown prose">
        <p>Got it! Strategic traders know: patience pays. But even the most calculated plans face market manipulation and sudden reversals.</p>
        <p >Discover Untrading—where strategy meets sustainability.</p>
        <ul class="ml-8 custom-ul">
            <li>Keep earning after you sell</li>
            <li>No fees, no predictions needed</li>
            <li>Future Rewards on every trade</li>
        </ul>
		<p >Trade your way, without the usual pitfalls. Because smart traders deserve smart solutions.</p>
        <p >Welcome to trading on your terms.</p>
        <br><hr>
		<p>Now that you’ve explored your trading style and discovered how Untrading can help you win, who inspires your trading journey today?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>