<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">Awesome! You're all about that market buzz, the thrill of trading for quick wins.</p>
        <p class="text-black01 font-weight-bold">The Upside:</p>
        <ul class="ml-8 custom-ul">
            <li>The chance for rapid, substantial profits.</li>
            <li>Turn market swings into your advantage.</li>
            <li>Leverage to amplify your returns.</li>
        </ul>
        <p class="text-black01 font-weight-bold">The Downside:</p>
        <ul class="ml-8 custom-ul">
            <li>The flip side is high risk; markets can crash fast.</li>
            <li>Constant watchfulness is necessary; one slip can cost you big.</li>
            <li>Psychological traps like overconfidence can blindside you.</li>
        </ul>
        <p class="text-black01 font-weight-bold">The Reality Check:</p>
        <p >Don't be fooled by charts; they're merely historical data. You're up against:</p>
        <ul class="ml-8 custom-ul">
            <li>Stop hunts that can wipe out your gains.</li>
            <li>Manipulations by those with deeper pockets.</li>
            <li>Liquidity traps where your trades might get stuck.</li>
        </ul>
        <p class="text-black01 font-weight-bold">The Untrading Advantage:</p>
        <p >Step into the world of Untrading, where your financial story doesn't end at the sale:</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">Selling is Just the Start: </span>Your connection with your investments persists, allowing you to benefit from their ongoing success.</li>
            <li><span class="text-black01 font-weight-bold">Transparent, Commission-Free Trading: </span>No more worrying about hidden charges or unfair practices; what you see is what you get.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs): </span>Even after you've sold, you can still earn from the appreciation of those assets.</li>
        </ol>
        <p>Untrading offers you a way to keep your financial growth alive and well, transcending the traditional end of a trade. Your journey with your investments is just beginning, not ending, when you decide to sell.</p>
        <br><hr>
		<p>Having discovered your trading style and how Untrading can lead you to victory, who guides your trading journey now? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>