<template>
	<div class="align-center bg-f2f mb-16 r-m-b-0" :class="nft.setId ? (hasMoreNFTs ? 'pb-600' : '') : ''">
        <!-- ------------------- 信息 -------------------- -->
        <v-container  id="fadeIn" class="nft-detail bg-f2f r-p-b-0">
            <v-card color="f2f" rounded="0" elevation="0" max-width="1440" class="mx-auto pt-9 pr-16 r-p-r-1">
                <!-- 返回按钮 -->
                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-13 r-m-l-3" @click="$router.back(-1)"></v-icon>
                <v-row no-gutters justify="center">

                    <!-- ------------------- 图像 -------------------- -->
                    <div class="pr-16 r-p-r-0">
                        <NFTPriceLineChart :nftId="id" :tokenId="nft.tokenId" v-if="responsive == 'mobile'" class="r-p-l-3"></NFTPriceLineChart>
                        <div v-if="responsive == 'mobile'" class="r-p-l-2">
                            <v-col cols="12" class="px-0 sub-page-h1 text-primaryGrey">
                                {{ nft.name }}
                            </v-col>
                            <v-col cols="12" class="px-0 body-h5 text-primaryGrey mt-5">
                                <span v-if="nft.contractFunction == 'UNWRAP'">Unwrapped</span>
                                <span v-else>{{ nftListInfo.isListed ? 'Current price' : 'Unlisted' }}</span>
                            </v-col>
                            <v-col cols="12" class="px-0 text-primaryGrey mb-8" v-if="nft.contractFunction == 'UNWRAP'">
                                <span class="body-p-small">This wrapped unNFT has been unwrapped & burned.</span>
                            </v-col>
                            <v-col cols="12" lg="10" sm="6" class="px-0 text-primaryGrey" v-if="nft.contractFunction != 'UNWRAP'">
                                <span class="hero-h2">
                                    {{ nftListInfo.isListed ? $tools.formatNumber(nftListInfo.price) : '--' }}
                                </span>
                                <span class="sub-page-h1" v-if="nft.paymentToken">
                                    {{ ' ' + nft.paymentToken }}
                                </span>
                            </v-col>
                            <v-col cols="12" lg="2" sm="6" class="px-0 text-primaryGrey mb-8 align-center" v-if="nft.contractFunction != 'UNWRAP'">
                                <span v-if="showSell">
                                    <Button variant="primary" :to="'/unnft/list/' + id" rounded="lg" color="black03"  width="80" aria-label="Sell">Sell</Button>
                                </span>
                            </v-col>
                        </div>
                        <UnNftOwner :nftId="id" :listInfo="nftListInfo" :totalNFRPaid="nfr.totalNFRPaid" @preview="preview" class="r-p-l-2"></UnNftOwner>
                        <!-- ------------------- 出处 -------------------- -->
                        <Provenance :nftId="id" v-if="responsive == 'pc'"></Provenance>
                    </div>
                    
                    <!-- ------------------- 信息 -------------------- -->
                    <v-card max-width="600" class="pa-8 pt-0 mb-16 pr-0 r-p-x-0" elevation="0" rounded="0" color="transparent">
                        <v-row no-gutters>
                        
                            <!-- ------------------- 价格历史 -------------------- -->
                            <v-col cols="12" class="px-0 pt-0">
                                <NFTPriceLineChart :nftId="id" :tokenId="nft.tokenId" v-if="responsive == 'pc'"></NFTPriceLineChart>
                            </v-col>
                            <!-- ------------------- 名称 -------------------- -->
                            <v-row no-gutters align="center" v-if="responsive == 'pc'">
                                <v-col cols="12" class="px-0">
                                    <h1 class="sub-page-h1 text-grey04">{{ nft.name }}</h1>
                                </v-col>
                                <v-col cols="12" class="px-0 body-h5 text-primaryGrey mt-10">
                                    <h5 v-if="nft.contractFunction == 'UNWRAP'">Unwrapped</h5>
                                    <h5 v-else>{{ nftListInfo.isListed ? 'Current price' : 'Unlisted' }}</h5>
                                </v-col>
                                <v-col cols="12" class="px-0 text-primaryGrey" v-if="nft.contractFunction == 'UNWRAP'">
                                    <span class="body-p-small">This wrapped unNFT has been unwrapped & burned.</span>
                                </v-col>
                                <v-col cols="12" lg="10" sm="6" class="px-0 text-primaryGrey" v-if="nft.contractFunction != 'UNWRAP'">
                                    <span class="hero-h2">
                                        {{ nftListInfo.isListed ? $tools.formatNumber(nftListInfo.price) : '--' }}
                                    </span>
                                    <span class="sub-page-h1" v-if="nft.paymentToken">
                                        {{ ' ' + nft.paymentToken }}
                                    </span>
                                </v-col>
                                <v-col cols="12" lg="2" sm="6" class="px-0 text-primaryGrey mt-3 align-center" v-if="nft.contractFunction != 'UNWRAP'">
                                    <span v-if="showSell">
                                        <Button variant="primary" :to="'/unnft/list/' + id" rounded="lg" color="black03" width="80" aria-label="Sell">Sell</Button>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider class="my-10"></v-divider>
                            <!-- ------------------- 稀有度 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2" v-if="nft.rarity">
                                <h2 class="body-text-btn text-primaryGrey">Rarity</h2>
                                <v-divider class="my-3"></v-divider>
                                <div class="body-p-small text-primaryGrey">
                                    <p class="pre-wrap" v-html="nft.rarity"></p>
                                </div>
                            </v-col>
                            <!-- ------------------- 描述 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2" v-if="nft.description">
                                <h2 class="body-text-btn text-primaryGrey">Description</h2>
                                <v-divider class="my-3"></v-divider>
                                <div class="body-p-small text-primaryGrey">
                                    <p class="pre-wrap" v-html="nft.description"></p>
                                </div>
                            </v-col>
                            <!-- ------------------- 部门 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2">
                                <h2 class="body-text-btn text-primaryGrey">Department</h2>
                                <v-divider class="my-3"></v-divider>
                                <div class="body-p-small gradient-underline-hover">
                                    <router-link :to="'/unnfts/search?text=' + nft.department" class="del-underline gradient-text-hover text-primaryGrey ">
                                        {{ nft.department }}
                                    </router-link>
                                </div>
                            </v-col>
                            <!-- ------------------- 详情 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2">
                                <h2 class="body-text-btn text-primaryGrey">Details</h2>
                                <v-divider class="my-3"></v-divider>
                                <div class="body-p-small">
                                    <v-row no-gutters v-if="nft.contractFunction == 'WRAP' || nft.contractFunction == 'UNWRAP'">
                                        <v-col>
                                            <div class="text-primaryGrey">Origianl Contract Address</div>
                                            <div class="text-primaryGrey">
                                                <span class="gradient-underline-hover">
                                                    <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/address/' + nft.originalContractAddress" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                        {{ $tools.privacyField(nft.originalContractAddress) }}
                                                    </a>
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="text-primaryGrey">Origianl Token ID</div>
                                            <div class="text-primaryGrey">
                                                <span class="gradient-underline-hover">
                                                    <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/token/' + nft.originalContractAddress + '?a=' + nft.originalTokenId" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                        {{ nft.originalTokenId }}
                                                    </a>
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="text-primaryGrey">Origianl Token Standard</div>
                                            <div class="text-primaryGrey">ERC721</div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters :class="(nft.contractFunction == 'WRAP' || nft.contractFunction == 'UNWRAP') ? 'mt-8' : ''">
                                        <v-col>
                                            <div class="text-primaryGrey">Contract Address</div>
                                            <div class="text-primaryGrey">
                                                <span class="gradient-underline-hover">
                                                    <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/address/' + nft.contractAddress" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                        {{ $tools.privacyField(nft.contractAddress) }}
                                                    </a>
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="text-primaryGrey">Token ID</div>
                                            <div class="text-primaryGrey">
                                                <span class="gradient-underline-hover">
                                                    <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/token/' + nft.contractAddress + '?a=' + nft.tokenId" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                        {{ nft.tokenId }}
                                                    </a>
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="text-primaryGrey">Token Standard</div>
                                            <div class="text-primaryGrey">ERC721, ERC-5173</div>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row no-gutters class="mt-8">
                                        <v-col>
                                            <div class="text-primaryGrey">Blockchain</div>
                                            <div class="text-primaryGrey">{{ nft.blockchain }}</div>
                                        </v-col>
                                            <v-col>
                                            <div class="text-primaryGrey">Medium</div>
                                            <div class="text-primaryGrey">{{ nft.media.mimeType }}</div>
                                        </v-col>
                                        <v-col>
                                            <div class="text-primaryGrey">Dimensions</div>
                                            <div class="text-primaryGrey">{{ nft.media.dimensions }}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-8">
                                        <v-col cols="4">
                                            <div class="text-primaryGrey">File Size</div>
                                            <div class="text-primaryGrey">{{ $tools.formatFileSize(nft.media.size) }}</div>
                                        </v-col>
                                        <v-col cols="8" class="text-left" v-if="nft.externalUrl">
                                            <div class="text-primaryGrey">External URL</div>
                                            <div class="text-primaryGrey ellipsis-line">
                                                <span class="gradient-underline-hover">
                                                    <a :href="nft.externalUrl" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                        {{ nft.externalUrl }}
                                                    </a>
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <!-- pc -->
                                    <v-row no-gutters class="mt-8" align="center" v-if="responsive == 'pc'">
                                        <v-col cols="4" v-if="nft.transactionHash">
                                            <!-- <v-icon icon="mdi:mdi-ethereum" class="text-primaryGrey"></v-icon> -->
                                            <v-icon color="text-primaryGrey" size="16" v-if="nft.blockchain == 'Ethereum' || nft.blockchain == 'Goerli' || nft.blockchain == 'Sepolia'">icon-etherscan</v-icon>
                                            <v-icon color="text-primaryGrey" size="16" v-if="nft.blockchain == 'Arbitrum One'">icon-arbitrum</v-icon>
                                            <v-icon color="text-primaryGrey" size="16" v-if="nft.blockchain == 'Polygon' || nft.blockchain == 'Mumbai'">icon-polygonscan</v-icon>
                                            <span class="gradient-underline-hover ml-1">
                                                <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/tx/' + nft.transactionHash" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                    View on {{ currentBlockchain.blockchainExplorer }}
                                                </a>
                                            </span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-icon color="text-primaryGrey" size="16">icon-metadata</v-icon>
                                            <span class="gradient-underline-hover ml-1">
                                                <a :href="nft.metadata" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                    View metadata
                                                </a>
                                            </span>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-icon color="text-primaryGrey" size="16">icon-ipfs</v-icon>
                                            <span class="gradient-underline-hover ml-1">
                                                <a :href="nft.media.ipfsFileUrl" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                    View on IPFS
                                                </a>
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <!-- mobile -->
                                    <v-row no-gutters class="mt-8" align="center" v-if="responsive == 'mobile'">
                                        <v-col cols="12" sm="6" class="d-flex align-center" v-if="nft.transactionHash">
                                            <!-- <v-icon icon="mdi:mdi-ethereum" class="text-primaryGrey"></v-icon> -->
                                            <v-icon color="text-primaryGrey" size="16" v-if="nft.blockchain == 'Ethereum' || nft.blockchain == 'Goerli' || nft.blockchain == 'Sepolia'">icon-etherscan</v-icon>
                                            <v-icon color="text-primaryGrey" size="16" v-if="nft.blockchain == 'Arbitrum One'">icon-arbitrum</v-icon>
                                            <v-icon color="text-primaryGrey" size="16" v-if="nft.blockchain == 'Polygon' || nft.blockchain == 'Mumbai'">icon-polygonscan</v-icon>
                                            <span class="gradient-underline-hover ml-1">
                                                <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/tx/' + nft.transactionHash" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                    View on {{ currentBlockchain.blockchainExplorer }}
                                                </a>
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="d-flex align-center my-3">
                                            <v-icon color="text-primaryGrey" size="16">icon-metadata</v-icon>
                                            <span class="gradient-underline-hover ml-1">
                                                <a :href="nft.metadata" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                    View metadata
                                                </a>
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="d-flex align-center">
                                            <v-icon color="text-primaryGrey" size="16">icon-ipfs</v-icon>
                                            <span class="gradient-underline-hover ml-1">
                                                <a :href="nft.media.ipfsFileUrl" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                                    View on IPFS
                                                </a>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <!-- ------------------- 执照 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2">
                                <h2 class="body-text-btn text-primaryGrey">License</h2>
                                <v-divider class="my-3"></v-divider>
                                <div class="body-h5 text-primaryGrey">{{ nft.license }}</div>
                                <div class="body-p-small text-primaryGrey mt-2">
                                    {{ licenseDescription }}
                                </div>
                            </v-col>
                            <!-- ------------------- 类别 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2" v-if="nft.category">
                                <h2 class="body-text-btn text-primaryGrey">Category</h2>
                                <v-divider class="my-3"></v-divider>
                                <div class="body-p-small gradient-underline-hover">
                                    <router-link :to="'/unnfts/search?text=' + nft.category" class="del-underline gradient-text-hover text-primaryGrey">
                                        {{ nft.category }}
                                    </router-link>
                                </div>
                            </v-col>
                            <!-- ------------------- 标签 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2" v-if="nft.tags != null && nft.tags.length > 0">
                                <h2 class="body-text-btn text-primaryGrey">Tags</h2>
                                <v-divider class="my-3"></v-divider>
                                <v-sheet class="body-p-small text-primaryGrey d-flex flex-wrap justify-start" color="transparent">
                                    <v-sheet color="transparent" class="mr-10 mb-2 gradient-underline-hover" v-for="(tag, i) in nft.tags" :key="i">
                                        <router-link :to="'/unnfts/search?text=' + tag" class="del-underline gradient-text-hover text-primaryGrey">
                                            {{ tag }}
                                        </router-link>
                                    </v-sheet>
                                </v-sheet>
                            </v-col>
                            <!-- ------------------- 黑名单 -------------------- -->
                            <v-col cols="12" class="mt-10 px-0 r-p-x-2" v-if="nft.contractFunction != 'UNWRAP'">
                                <h2 class="body-text-btn text-primaryGrey">Blacklist of this Flow currently in effect</h2>
                                <v-divider class="my-3"></v-divider>
                                <Blacklist :nftId="id"></Blacklist>
                            </v-col>
                        </v-row>
                        <Provenance :nftId="id" v-if="responsive == 'mobile'" class="r-p-x-2"></Provenance>
                    </v-card>
                </v-row>
                  <!-- ------------------- nFR -------------------- -->
                    <NonFungibleFutureRewards :nftId="id" :nfr="nfr" :unwrap="nft.contractFunction == 'UNWRAP'" class="ml-n1" v-if="responsive == 'pc'"></NonFungibleFutureRewards>
                    <NonFungibleFutureRewards :nftId="id" :nfr="nfr" :unwrap="nft.contractFunction == 'UNWRAP'" v-if="responsive == 'mobile'"></NonFungibleFutureRewards>
                <!-- 更多推荐 -->
                <v-card max-width="1330" class="mt-16 mx-auto" elevation="0" rounded="0" color="f2f"  v-if="nft.setId" v-show="hasMoreNFTs">
                    <v-row no-gutters justify="start">
                        <v-col cols="12" class="text-primaryGrey ml-8 r-m-l-2">
                            <h3 class="sub-page-h1">More from this Set</h3>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-container>
        <!-- 更多推荐 -->
        <v-container class="more-set bg-grey01" v-if="nft.setId" v-show="hasMoreNFTs">
            <v-card color="transparent" rounded="0" elevation="0" max-width="1350" class="mx-auto">
                <v-row no-gutters justify="center" class="my-10 r-m-y-0">
                    <SetMoreUnNft :setId="nft.setId" :excludedNftId="id" @emitHasMoreNFTs="callHasMoreNFTs"></SetMoreUnNft>
                </v-row>
            </v-card>
        </v-container>
        <!-- 全屏图片弹窗 -->
        <v-dialog v-model="imageDialog" fullscreen scrollable content-class="v-echarts-dialog">
            <v-card elevation="12" rounded="0" class="zoom-out bg-000" @click="imageDialog = false">
                <v-img :src="nft.file" v-if="nft.file"></v-img>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { Web3 } from "web3";
import NFRAPI from '@/api/nfr.js'
import NFTAPI from '@/api/nft.js'
import NFTStatAPI from '@/api/nft-stat.js'
import UnNftOwner from '@/components/nft/UnNftOwner.vue'
import Provenance from '@/components/nft/Provenance.vue'
import NFTPriceLineChart from '@/components/charts/NFTPriceLineChart.vue'
import NonFungibleFutureRewards from '@/components/nft/NonFungibleFutureRewards.vue'
import SetMoreUnNft from '@/components/set/SetMoreUnNft.vue'
import Blacklist from '@/components/nft/Blacklist.vue'
export default {
    data(){
        return {
            // NFT 主键
            id: this.$route.params.id,
            // nft参数
            nft: {
                user: {},
                set: {},
                media: {},
                tags: []
            },
            // 全屏图片弹窗
            imageDialog: false,
            // NFT 上市信息
            nftListInfo: {
                price: null,
                lister: null,
                isListed: false
            },
            // 执照描述
            licenseDescription: null,
            // NFR 数据
            nfr: {
                totalNFRPaid: 0,
                originatorsRewards: [],
                futureRewards: []
            },
            // 有更多推荐
            hasMoreNFTs: false,
            // 当前的区块链
            currentBlockchain: {},
            web3: {},
            // 循环任务刷新数据
            refreshInterval: null
        }
    },
    components: { UnNftOwner, Provenance, NFTPriceLineChart, NonFungibleFutureRewards, SetMoreUnNft, Blacklist },
    created(){
        
    },
    mounted(){
        // 是否存在该 NFT
        this.exists();
        // 循环任务刷新数据,5分钟刷新一次
        this.refreshInterval = setInterval(() => {
            // 根据 NFT 主键查询 NFT
            this.getNFTById();
            // 根据NFT主键查询NFR信息
            this.getNfrByNftId();
        }, 5 * 60 * 1000)
    },
    computed: {
        ...mapGetters(['user', 'licenses', 'blockchains', 'responsive']),
        // 是否有售卖权限
        showSell() {
            // 是我的 && 未上市
            return this.user.id == this.nft.ownerUserId && !this.nftListInfo.isListed;
        }
    },
    watch:{
        $route(to, from) {
            // 要去的页面还是当前页面，则更新id，否则是去别的页面，不更新
            if(to.name == "UnNFT") {
                this.id = this.$route.params.id;
            } else {
                // 离开页面时停止循环任务
                clearInterval(this.refreshInterval);
            }
        },
        id: {
            handler(newVal, oldVal) {
                // 根据 NFT 主键查询 NFT
                this.getNFTById();
                // 根据NFT主键查询NFR信息
                this.getNfrByNftId();
            },
            immediate: true
        }
    },
    methods: {
        // 根据 NFT 主键查询 NFT
        async getNFTById() {
            if(this.id == null) {
                return;
            }
            let res = await NFTAPI.getNFTById(this.id);
            let data = res.data;
            if(data.success) {
                // 更新对象
                this.nft = data.data;
                // 显示价格
                this.nftListInfo.price = this.nft.price;
                this.nftListInfo.isListed = this.nft.price > 0;
                // 更新标题
                this.$route.meta.name = this.nft.name
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 获取执照描述
                this.getLicenseDescription(this.nft.license);
                // 查询上市信息
                this.getListInfo();
                // 设置网站元信息
                // this.setWebsiteMeta();
                // 查询FR信息
                // this.getFRInfo();
                // 查询已分配的FR
                // this.getAllottedFR();
                // 查询已分配的OR
                // this.getAllottedOR();
                // 查询O代币余额
                // this.balanceOfOTokens();
                // 查询余额
                // this.balanceOf();
            } else {
                // 错误则导航到404
                this.$router.push('/404');
            }
        },
        // 浏览
        async view() {
            await NFTStatAPI.view(this.id);
        },
        // 大屏预览
        async preview() {
            // this.imageDialog = true;
        },
        // 是否存在该 NFT
        async exists() {
            let res = await NFTAPI.exists(this.id);
            let data = res.data;
            if(data.success && data.data) {
                // 浏览
                this.view();
            } else  {
                // 不存在则导航到404
                this.$router.push('/404');
            }
        },
        // 查询上市信息
        async getListInfo() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            // {0: '0', 1: '0x0000000000000000000000000000000000000000', 2: false}
            let listInfo = await NFTContract.methods.getListInfo(this.nft.tokenId).call();
            this.nftListInfo.price = this.web3.utils.fromWei(listInfo[0], 'ether');
            this.nftListInfo.lister = listInfo[1];
            this.nftListInfo.isListed = listInfo[2];
        },
        // 查询FR信息
        async getFRInfo() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let FRInfo = await NFTContract.methods.getFRInfo(this.nft.tokenId).call();
            console.log(FRInfo);
        },
        // 查询已分配的FR
        async getAllottedFR() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let allottedFR = await NFTContract.methods.getAllottedFR(this.user.wallet).call();
            console.log(this.web3.utils.fromWei(allottedFR, 'ether'));
        },
        // 查询已分配的OR
        async getAllottedOR() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let allottedOR = await NFTContract.methods.getAllottedOR(this.user.wallet).call();
            console.log(this.web3.utils.fromWei(allottedOR, 'ether'));
        },
        // 查询O代币余额
        async balanceOfOTokens() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let OTokenBalance = await NFTContract.methods.balanceOfOTokens(this.nft.tokenId, this.user.wallet).call();
            // console.log(this.web3.utils.fromWei(OTokenBalance, 'ether'));
        },
        // 查询余额
        async balanceOf() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            let balance = await NFTContract.methods.balanceOf(this.user.wallet).call();
            // console.log(balance);
        },
        // 获取执照描述
        getLicenseDescription(license) {
            this.licenseDescription = this.licenses.filter(item => item.name == license)[0].description;
        },
        // 设置网站元信息
        setWebsiteMeta() {
            // og
            let ogTitle = document.getElementsByName('og:title')[0];
            ogTitle.setAttribute('property', this.nft.name);
            let ogImage = document.getElementsByName('og:image')[0];
            ogImage.setAttribute('property', this.nft.file);
            // Twitter
            let twitterTitle = document.getElementsByName('twitter:title')[0];
            twitterTitle.setAttribute('property', this.nft.name);
            let twitterImage = document.getElementsByName('twitter:image')[0];
            twitterImage.setAttribute('property', this.nft.file);
        },
        // 根据NFT主键查询NFR信息
        async getNfrByNftId() {
            if(this.id == null) {
                return;
            }
            let res = await NFRAPI.getNfrByNftId(this.id);
            let data = res.data;
            if(data.success) {
                this.nfr = data.data;
            }
        },
        // 有更多推荐
        callHasMoreNFTs() {
            this.hasMoreNFTs = true;
        }
    }
}
</script>
<style scoped>
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
.h-120 {
    height: 120vh;
}
.mt-300 {
    padding-top: 500px !important;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0; /*初始状态 透明度为0*/
    }
    50% {
        opacity: 0.3; /*中间状态 透明度为0*/
    }
    100% {
        opacity: 1; /*结尾状态 透明度为1*/
    }
}

#fadeIn {
    -webkit-animation-name: fadeIn; /*动画名称*/
    -webkit-animation-duration: 0.5s; /*动画持续时间*/
    -webkit-animation-iteration-count: 1; /*动画次数*/
}
/* ::v-deep(.v-echarts-dialog) {
     width: auto;
} */

.nft-detail {
    position: relative;
    z-index: 1;
}

.more-set {
    position: fixed;
    z-index: 0;
    bottom: 0px;
}
</style>

