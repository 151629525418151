<template>
	<div class="markdown prose">
        <p>Exciting choice! Manual trading gives you full control, but it also comes with higher responsibility and risk.</p>
        <p>To maximize your success:</p>
        <ul class="ml-8 custom-ul">
            <li>Begin with a smaller portion of your investable funds to minimize risk.</li>
            <li>Use our educational resources to sharpen your skills and strategies.</li>
            <li>Consider pairing manual trading with autopilot for balance—it’s a great way to hedge against volatility.</li>
        </ul>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        <br><hr>
        <p>Ready to jump in? Let’s show you the tools and insights you’ll need to thrive. </p>
        <div class="mt-6 flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-6">
            <div>
                <Button variant="primary" rounded="xl" color="black03" height="40" width="320" aria-label="Switch to Autopilot - Learn More" @click="requestAutopilotDialog = true">
                    <span class="px-4">Switch to Autopilot - Learn More<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
            <div>
                <Button variant="secondary" :href="`https://testnet.untrading.org/uncryptos?inspiration=${inspiration}`" rounded="xl" color="black01" height="40" width="280" aria-label="">
                    <span class="px-4">Access Manual Trading<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
            <div>
                <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over" @click="$emit('startOver')">
                    <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['inspiration']),
    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>