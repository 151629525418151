<template>
	<div class="markdown prose">
        <p>I appreciate it! Imagine stumbling upon a coin that rockets 100x in days. Sounds amazing, right? Unfortunately, stories like these are the exception, not the rule. Let’s break it down:</p>
        <p class="text-black01 font-weight-bold">The Dream:</p>
        <ul class="ml-8 custom-ul">
            <li>Instant riches with minimal effort.</li>
        </ul>
        <p class="text-black01 font-weight-bold">The Reality Check:</p>
        <ul class="ml-8 custom-ul">
            <li>Markets don’t reward impatience; they punish it. High-risk bets often lead to heavy losses.</li>
            <li>Projects hyped for quick gains frequently lack substance, leaving investors empty-handed when the hype fades.</li>
            <li>FOMO (fear of missing out) drives reckless decisions, which rarely pay off in the long run.</li>
        </ul>
        <p class="text-black01 font-weight-bold">Untrading rewrites the rules:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Your gains don’t end at selling. </span>Earn Future Rewards (FRs) as assets appreciate.</li>
            <li><span class="text-black01 font-weight-bold">No hidden fees, no middlemen. </span>Trade transparently, commission-free.</li>
            <li><span class="text-black01 font-weight-bold">Aligned incentives. </span>The community shares success instead of competing against each other.</li>
        </ul>
        <p>Untrading isn’t just a platform. It’s a mindset shift. Ready to break free?</p>
        <br><hr>
        <p>Instead of gambling on fleeting trends, would you consider exploring how Untrading builds wealth steadily and sustainably? It’s about stacking small wins into big results.</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore sustainable trading:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Understanding real market dynamics</li>
                            <li>Building lasting wealth</li>
                            <li>Protecting your assets</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="180" aria-label="Start Learning">
                                <span class="px-4">Start Learning<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" class="ml-6" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>Thank you for being upfront about your goals. While Untrading focuses on sustainable, collaborative wealth-building, we respect your decision to pursue other paths. If you ever change your mind, we’ll be here to support you.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="280" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes, tell me more about Untrading' },
                { code: 'No', message: 'No, I’m sticking with quick gains' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>