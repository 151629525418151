<template>
	<div class="layoutContentVideo justify-center r-p-x-0">
        <div class="Grid_root__iRfoa grid-style-revolution">
            <div class="Grid_a__vY7M8">
                <div class="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ZhfqFUJzE_w?si=2UiTvglQC620GZFU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	/* 3:2 Aspect Ratio */
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>