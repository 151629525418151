<template>
	<div class="bg-f2f h-100">
        <div class="h-100">
            <v-container class="px-0 mb-9">
                <!---------------------------- 基本信息 ---------------------------->
                <v-card max-width="1400" color="transparent" rounded="0" elevation="0" class="mx-auto pt-7 pb-7">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" class="r-m-l-4">
                            <div class="text-primaryGrey">
                                <v-icon size="28" icon="mdi:mdi-chevron-left-circle gradient-text-hover mb-3" @click="$router.back(-1)"></v-icon>
                            </div>
                            <h1 class="sub-page-h1 text-grey04">My Dashboard</h1>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="r-m-r-4">
                        <!---------------------------- 过滤条件 ---------------------------->
                        <v-col cols="12">
                            <v-menu>
                                <template v-slot:activator="{ props }">
                                    <v-btn aria-label="menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p float-right" height="100%" v-bind="props">
                                        <Blockchain :blockchain="currentBlockchain.blockchain" size="18" text></Blockchain>
                                        <v-icon end icon="mdi:mdi-menu-down" />
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain)">
                                        <v-list-item-title>
                                            <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                    <!---------------------------- 余额 ---------------------------->
                    <Balance></Balance>
                    <!---------------------------- type ---------------------------->
                    <v-row no-gutters :class="responsive == 'pc'?'mt-10':'mt-10 px-4'">
                        <v-col cols="12">
                            <v-tabs hide-slider v-model="type" density="compact" height="50" class="font-weight-bold text-primaryGrey">
                                <v-tab value="cryptos" rounded="0" width="200" class="px-0" :class="type == 'cryptos' ? 'bg-primary text-white' : ''">
                                    <h4>CRYPTOS</h4>
                                </v-tab>
                                <v-tab value="nfts" rounded="0" width="200" class="px-0" :class="type == 'nfts' ? 'bg-primary text-white' : ''">
                                    <h4>NFTS</h4>
                                </v-tab>
                            </v-tabs>
                        </v-col>
                        <v-divider class="border-opacity-100" thickness="3" color="primary"></v-divider>
                    </v-row>
                    <!---------------------------- window ---------------------------->
                    <v-window v-model="type" :touch="false" :class="responsive == 'pc'?'mt-10':'mt-10 px-4'">
                        <v-window-item value="cryptos">
                            <DashboardCryptos></DashboardCryptos>
                        </v-window-item>
                        <v-window-item value="nfts">
                            <DashboardNFTs></DashboardNFTs>
                        </v-window-item>
                    </v-window>
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Balance from '@/components/user/dashboard/Balance.vue';
import DashboardCryptos from '@/components/user/dashboard/DashboardCryptos.vue';
import DashboardNFTs from '@/components/user/dashboard/DashboardNFTs.vue';
export default {
    data(){
        return {
            // 类型
            type: this.$route.query?.type?.toLowerCase(),
            // 当前的区块链
            currentBlockchain: {},
        }
    },
    components: { Balance, DashboardCryptos, DashboardNFTs },
    created(){
        
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['blockchains', 'blockchain']),
    },
    watch:{
        // 监听区块链
        blockchain: {
            handler(n, o) {
                if(n) {
                    this.currentBlockchain = this.blockchains.filter(b => b.blockchain == n)[0];  
                } else {
                    this.currentBlockchain = this.blockchains[0];
                }
            },
            immediate: true
        }
    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain.blockchain);
            this.currentBlockchain = blockchain;
        }
    }
}
</script>
<style scoped>

</style>