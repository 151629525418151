<template>
	<div class="markdown prose">
        <p>I appreciate it! We hear you—dreaming big is exciting! But let’s take a moment to analyze this strategy:</p>
        <p class="text-black01 font-weight-bold">The Reality:</p>
        <ul class="ml-8 custom-ul">
            <li>Assets promising massive gains in short periods are typically highly speculative and volatile.</li>
            <li>The odds of finding such opportunities are astronomically low, and losses tend to outweigh wins over time.</li>
            <li>Many traders chasing “100x” end up burned by scams, pump-and-dump schemes, or market crashes.</li>
        </ul>
        <p class="text-black01 font-weight-bold">Why It’s Risky:</p>
        <p>This approach relies on luck rather than skill, discipline, or research. Even experienced traders struggle to consistently predict markets, let alone achieve exponential returns overnight.</p>
        <p class="text-black01 font-weight-bold">The Untrading Solution: </p>
        <p>Our proprietary platform introduces revolutionary concepts in market participation:</p>
        <p class="text-black01 font-weight-bold">Core Benefits:</p>
        <ul class="ml-8 custom-ul">
            <li>Continuous Value Accrual</li>
            <li>Zero Commission Structure</li>
            <li>Future Rewards Integration</li>
            <li>Transparent Operations</li>
        </ul>
        <p class="text-black01 font-weight-bold">Risk Mitigation Features:</p>
        <ol class="ml-8 custom-ol">
            <li>Post-Sale Earnings Continuation</li>
            <li>Market Manipulation Protection</li>
            <li>Psychological Pressure Reduction</li>
            <li>Sustainable Growth Model</li>
        </ol>
        <p class="text-black01 font-weight-bold">Implementation Strategy: </p>
        <p>The platform leverages advanced technology to maintain value connection between traders and assets beyond the point of sale, ensuring continued benefit from market appreciation.</p>
        <p class="text-black01 font-weight-bold">Value Proposition:</p>
        <ul class="ml-8 custom-ul">
            <li>Reduced Operational Costs</li>
            <li>Enhanced Return Potential</li>
            <li>Sustainable Growth Model</li>
            <li>Strategic Market Positioning</li>
        </ul>
        <p>Enter Untrading:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Profits Beyond Selling: </span>Keep earning after you exit.</li>
            <li><span class="text-black01 font-weight-bold">Zero Fees, Zero Games: </span>No commissions, no hidden tricks.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs): </span>Stay connected to assets post-sale.</li>
        </ul>
        <p>Trade smarter. Earn longer. Join Untrading.</p>
        <br><hr>
        <p>Would you like to explore how Untrading helps build sustainable wealth through smarter strategies? If so, we’d love to guide you toward proven methods designed for lasting success.</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore sustainable trading:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Understanding real market dynamics</li>
                            <li>Building lasting wealth</li>
                            <li>Protecting your assets</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="180" aria-label="Start Learning">
                                <span class="px-4">Start Learning<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" class="ml-6" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>We understand. Untrading is designed for those seeking sustainable success through proven methods. If you change your approach, we'll be here to help.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="280" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes, show me more about Untrading' },
                { code: 'No', message: 'No, I’ll stick with my plan' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>