<template>
	<div class="markdown prose">
        <p>Imagine this: You’re at the starting line of a high-speed race. Do you grab the steering wheel yourself, feeling the rush of every decision as you navigate the market curves? Or do you trust a world-class autopilot system to drive for you, using strategies perfected by some of the greatest minds in investing?</p>
        <p>Introducing our Autopilot program, designed with precision and inspired by legends:</p>
        <ul class="ml-8 custom-short-dash-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Low and frequent profit-taking, ideal for tactical moves.</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Balanced diversification for steady, reliable growth.</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Large, calculated plays for life-changing long-term gains.</li>
        </ul>
        <p>Most users start with Autopilot—it’s a stress-free way to grow smarter while learning the ropes. Once you’ve built confidence, you can take the wheel manually with part of your portfolio.</p>
        <p>Which path excites you more?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot – Sit Back & Relax' },
                { code: 'Manual', message: 'Race Car Driver – Manual Thrills' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>