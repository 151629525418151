<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Awesome! You Love the Game—But the Game Doesn’t Love You Back</h3>
        <p >Trading is a rush—until the market turns. You dodge stop hunts, liquidity traps, and price manipulation, only to realize: charts reflect the past, not the future.</p>
        <p >At Untrading, we believe your gains shouldn’t end at the sell button.</p>
        <h3 class="text-black01 font-weight-bold">Enter Untrading:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Selling is just the beginning: </span>Stay linked to your assets post-sale.</li>
            <li><span class="text-black01 font-weight-bold">No commissions, no gimmicks: </span>Trade without the usual traps.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs): </span>Keep earning even after exit.</li>
        </ul>
        <p >Because in a broken system, the smartest traders refuse to play by old rules.</p>
        <br><hr>
		<p>Now that you’ve identified your trading style and seen how Untrading can elevate your results, who inspires your trading journey today?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>