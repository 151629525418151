<template>
	<div class="bg-f2f h-100">
        <div class="h-100" v-if="!loading">
            <v-container class="mb-9">
                <v-card max-width="1400" elevation="0" rounded="0" color="transparent" class="pt-7 pb-7 mx-auto">
                    <v-row no-gutters justify="center">
                        <v-col cols="12" align="center" class="mb-3">
                            <v-card width="840" elevation="0" rounded="0" color="transparent" class="text-left">
                                <router-link to="/dashboard" class="del-underline">
                                    <v-icon size="28" class="text-primaryGrey" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                </router-link>
                            </v-card>
                        </v-col>
                        <v-card elevation="0" rounded="0" color="transparent" :class="responsive == 'pc' ? 'w-280 ml-n5' :'w-100'">
                            <UnNftRewardInfo :nftId="nftId"></UnNftRewardInfo>
                            <UnNftHoldingHistory :nftId="nftId"></UnNftHoldingHistory>
                            <div class="body-p font-weight-bold ml-5 mt-15 r-m-l-1">
                                <span class="gradient-underline-hover">
                                    <router-link :to="'/dashboard/asset/unnft/ors/received/' + nftId" class="gradient-underline gradient-text-hover text-primaryGrey">
                                        TOTAL ORs RECEIVED
                                    </router-link>
                                </span>
                            </div>
                            <div class="body-p font-weight-bold ml-5 mt-5 r-m-l-1">
                                <span class="gradient-underline-hover">
                                    <router-link :to="'/dashboard/asset/unnft/nfrs/paid/' + nftId" class="gradient-underline gradient-text-hover text-primaryGrey">
                                        TOTAL nFR PAYOUTS
                                    </router-link>
                                </span>
                            </div>
                        </v-card>
                        <v-card elevation="0" rounded="lg" color="transparent" class="ml-16 r-m-l-0 r-m-t-16 r-p-x-1">
                            <h2 class="sidebar-h2 text-primaryGrey d-flex flex-nowrap">
                                <span class="mr-2">FRs Received by @{{ user.name }}</span>
                                <UserAvatar :avatar="user.avatar" :username="user.username" :size="24"></UserAvatar>
                            </h2>
                            <UnNftAssetDetail :nftId="nftId"></UnNftAssetDetail>
                            <FRPaymentsReceived class="mt-10" :nftId="nftId"></FRPaymentsReceived>
                        </v-card>
                    </v-row>
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UnNftRewardInfo from '@/components/nft/UnNftRewardInfo.vue'
import UnNftHoldingHistory from '@/components/nft/UnNftHoldingHistory.vue'
import UnNftAssetDetail from '@/components/nft/UnNftAssetDetail.vue'
import FRPaymentsReceived from '@/components/nft/FRPaymentsReceived.vue'
export default {
    data(){
        return {
            // NFT 主键
            nftId: this.$route.params.nftId,
            // 加载中
            loading: false,
            
        }
    },
    components: { UnNftRewardInfo, UnNftHoldingHistory, UnNftAssetDetail, FRPaymentsReceived },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user','responsive'])
    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
</style>