<template>
	<div>
        <v-row no-gutters>
            <!---------------------------- tab ---------------------------->
            <v-col cols="12" sm="6" class="px-0" v-if="responsive == 'mobile'">
                <v-btn width="120" @click="changeTab('owned')" :variant="tab == 'owned' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': tab == 'owned' }" class="mr-2">
                    <v-badge :content="stat.cryptoOwned" inline>
                        <h4>Owned</h4>
                    </v-badge>
                </v-btn>
                <v-btn width="150" @click="changeTab('wrapped')" :variant="tab == 'wrapped' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': tab == 'wrapped' }">
                    <v-badge :content="stat.cryptoWrapped" inline>
                        <h4>Wrapped</h4>
                    </v-badge>
                </v-btn>
            </v-col>
            <v-col cols="8" v-if="responsive == 'pc'" class="pl-4">
                <v-tabs slider-color="primary" v-model="tab" density="compact" class="w-90">
                    <v-tab value="owned" rounded="0" class="px-0" @click="$refs.owned?.changeCondition()">
                        <v-badge :content="stat.cryptoOwned" inline>
                            <h4>Owned</h4>
                        </v-badge>
                    </v-tab>
                    <v-tab value="wrapped" rounded="0" class="px-0 ml-4" @click="$refs.wrapped?.changeCondition()">
                        <v-badge :content="stat.cryptoWrapped" inline>
                            <h4>Wrapped</h4>
                        </v-badge>
                    </v-tab>
                </v-tabs>
            </v-col>
            <!---------------------------- 过滤条件 ---------------------------->
            <v-col cols="4" v-if="responsive == 'pc'">
                <div class="float-right">
                    <!-- 区块链 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn aria-label="menu-down" variant="plain" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="changeBlockchainCondition(null)">
                                <v-list-item-title>
                                    <Blockchain text all-chains></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                                <v-list-item-title>
                                    <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <!-- 排序 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn aria-label="menu-down" variant="plain" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                {{ sortCondition }}
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
            <v-col cols="12" class="pr-4" v-if="responsive == 'mobile'">
                <div class="r-m-t-8">
                    <!-- 区块链 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn variant="plain" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="changeBlockchainCondition(null)">
                                <v-list-item-title>
                                    <Blockchain text all-chains></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                                <v-list-item-title>
                                    <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <!-- 排序 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn aria-label="Menu down" variant="plain" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                {{ sortCondition }}
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
            <v-divider class="ml-4 r-m-l-0" thickness="1" color="black01"></v-divider>
        </v-row>
        <v-window v-model="tab" :touch="false">
            <v-window-item value="owned">
                <ProfileCryptosOwned ref="owned" class="pl-4 r-p-l-0" :username="username" :blockchain="blockchain" :sortBy="sortCondition"></ProfileCryptosOwned>
            </v-window-item>
            <v-window-item value="wrapped">
                <ProfileCryptosWrapped ref="wrapped" class="pl-4 r-p-l-0" :username="username" :blockchain="blockchain" :sortBy="sortCondition"></ProfileCryptosWrapped>
            </v-window-item>
        </v-window>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ProfileCryptosOwned from '@/components/user/profile/ProfileCryptosOwnedV1.1.vue';
import ProfileCryptosWrapped from '@/components/user/profile/ProfileCryptosWrappedV1.1.vue';
export default {
    props: {
        username: {
            type: String,
            required: true,
            default: null
        },
        stat: {
            type: Object,
            required: true,
            default: {
                // 拥有的加密货币总数
                cryptoOwned: 0,
                // 封装的加密货币总数
                cryptoWrapped: 0
            }
        }
    },
    data(){
        return {
            // 标签
            tab: this.$route.query?.tab?.toLowerCase() || 'owned',
            // 排序条件
            sortCondition: 'Newest',
            // 排序条件
            sortConditions: ['Newest', 'Oldest', 'Highest Price', 'Lowest Price', 'Highest Views', 'Lowest Views'],
        }
    },
    components: { ProfileCryptosOwned, ProfileCryptosWrapped },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'blockchain', 'responsive']),
    },
    watch:{
        
    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
        },
        // 改变排序条件
        changeSortCondition(sortCondition) {
            this.sortCondition = sortCondition;
        },
        // 改变标签
        changeTab(tab) {
            this.tab = tab;
            this.$refs?.[tab]?.changeCondition();
        },
        // 刷新
        refresh() {
            this.$emit('refresh', null);
        }
    }
}
</script>
<style scoped>
.v-btn{
    text-transform:capitalize!important;
}
</style>