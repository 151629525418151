<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Awesome! You're a visionary, investing for the long haul.</h3>
        <h3 class="text-black01 font-weight-bold">The Upside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Reduced stress with less constant market watching.</li>
            <li>Potential for substantial long-term gains.</li>
            <li>Aligns with broader economic growth trends.</li>
            <li>Control over your assets without counterparty risks.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Downside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Demands patience; capital is less liquid.</li>
            <li>Market downturns test your resolve.</li>
            <li>No immediate returns like dividends or interest.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The hidden risk:</h3>
        <p >Long-term holding isn't just about patience; it's about surviving market cycles:</p>
        <ul class="ml-8 custom-ul">
            <li>Emotional wear during bear markets.</li>
            <li>The temptation to sell at the wrong time due to market stagnation.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Untrading Innovation:</h3>
        <p >Welcome to Untrading—where selling is just the beginning.</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">Wrap Your Assets: </span>Convert your BTC (or other assets) into wrapped Untrading tokens (e.g., unBTC).</li>
            <li><span class="text-black01 font-weight-bold">Sell & Stay Connected: </span>Sell your unBTC, but keep earning Future Rewards (FRs) as others profit later.</li>
            <li><span class="text-black01 font-weight-bold">Rebuy & Repeat: </span>Use the proceeds to buy the same asset (e.g., BTC) on the open market.</li>
            <li><span class="text-black01 font-weight-bold">Maximize Upside: </span>Wrap, sell, and repeat—staying in control while unlocking additional rewards.</li>
        </ol>
        <p >With Untrading, you never truly exit the game.</p>
        <br><hr>
        <p>Now that you’ve seen how Untrading can boost your success, who inspires your trading journey today? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>