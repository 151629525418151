<template>
	<div class="main-container">
        <v-container class="mx-0-auto r-p-x-0 bg-black">
            <v-row no-gutters justify="center" align="center" class="py-120 bg-black">
                <Hero></Hero>
            </v-row>
            <v-row no-gutters class="bg-black" justify="center" align="center">
                <Redefining></Redefining>
             </v-row>
            <!-- CryptoScreenshot -->
            <v-row no-gutters class="bg-black pt-100" justify="center" align="center">
                <CryptoScreenshot></CryptoScreenshot>
            </v-row>
            <v-row no-gutters class="bg-black" justify="center" align="center">
                <NftScreenshot></NftScreenshot>
            </v-row>
            <v-row no-gutters class="py-120 bg-black" justify="center" align="center">
                <Faq></Faq>
                
            </v-row>
            <Footer class="bg-black"></Footer>
        </v-container>
       
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CryptoScreenshot from './v3/components/CryptoScreenshot.vue';
import Faq from './v3/components/Faq.vue';
import Footer from './v3/components/Footer.vue';
import Hero from './v3/components/Hero.vue';
import NftScreenshot from './v3/components/NftScreenshot.vue';
import Redefining from './v3/components/Redefining.vue';
export default {
    data(){
        return {

        }
    },
    components: { Hero, Redefining, CryptoScreenshot, NftScreenshot, Faq, Footer },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>