<template>
	<div class="markdown prose">
        <p class="font-italic">"Diversification, balance, and risk-adjusted returns create lasting wealth."</p>
        <p >Untrading aligns with your mindset by allowing you to build collaborative financial ecosystems—where success is shared, not extracted.</p>
        <ul class="ml-8 custom-ul">
            <li>Built-in Future Rewards (FRs).</li>
            <li>Decentralized and transparent profit-sharing.</li>
            <li>A trading framework designed for stability and continued growth.</li>
        </ul>
        <p >Build collaborative ecosystems where success is shared—not extracted.</p>
    </div>
</template>