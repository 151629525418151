<template>
	<div class="markdown prose">
        <p>Alright, time to choose your adventure! Would you rather:</p>
        <p>🚗 Be the ultimate race car driver, gripping the wheel and making lightning-fast decisions as you chase opportunities?</p>
        <p>🤖 Or sit back and let our Autopilot system handle everything for you, using battle-tested strategies inspired by legends like Soros, Dalio, and Buffett?</p>
        <p>Here’s how Autopilot works:</p>
        <ul class="ml-8 custom-short-dash-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Small wins, often—perfect for tactical players.</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Balanced growth, no drama—ideal for steady climbers.</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Big wins, patiently earned—for those playing the long game.</li>
        </ul>
        <p>Autopilot is the easiest way to get started—it’s like having a co-pilot who knows the road ahead. Later, you can jump into manual mode for a taste of the action.</p>
        <p>Ready to pick your ride?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot – Effortless Wins' },
                { code: 'Manual', message: 'Race Car Driver – Full Manual Control' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>