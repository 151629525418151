<template>
	<div class="markdown prose">
        <p>I appreciate it! It’s natural to dream of instant success—but let’s talk about how realistic that really is:</p>
        <p class="text-black01 font-weight-bold">The Appeal of Quick Wins:</p>
        <ul class="ml-8 custom-ul">
            <li>Who wouldn’t want to multiply their investment overnight?</li>
        </ul>
        <p class="text-black01 font-weight-bold">The Hidden Costs:</p>
        <ul class="ml-8 custom-ul">
            <li>Chasing “100x” often means ignoring fundamentals, falling victim to scams, or buying at peaks and selling at lows.</li>
            <li>Emotional stress from constant volatility can take a toll on your mental health.</li>
            <li>Most importantly, this approach rarely leads to sustainable wealth—it’s more likely to leave you worse off than before.</li>
        </ul>
        <p>With Untrading, they don’t.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell, but stay in the game.</span></li>
            <li><span class="text-black01 font-weight-bold">Zero commissions, total fairness.</span></li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you earning.</span></li>
        </ul>
        <p>Trade smarter. Keep winning. <span class="text-black01 font-weight-bold">Untrade.</span></p>
        <br><hr>
        <p>At <span class="text-black01 font-weight-bold">Untrading</span>, we believe in empowering traders with strategies that work over the long term. Would you like to discover how our platform helps you grow smarter and stronger, step by step?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore sustainable trading:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Understanding real market dynamics</li>
                            <li>Building lasting wealth</li>
                            <li>Protecting your assets</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="180" aria-label="Start Learning">
                                <span class="px-4">Start Learning<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" class="ml-6" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>Thank you for being upfront about your goals. While Untrading focuses on sustainable, collaborative wealth-building, we respect your decision to pursue other paths. If you ever change your mind, we’ll be here to support you.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="280" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes, I’d like to learn more' },
                { code: 'No', message: 'No, I’ll stay focused on quick gains' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>