<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">Got it! The Reality Check: </p>
        <p>While the idea of quick, massive gains is exciting, the truth is that chasing 100x returns often leads to heavy losses. The crypto market is highly volatile, and most "moon-shot" coins are driven by hype, not fundamentals. The odds of picking the right coin at the right time are slim—and the risks are enormous.</p>
        <p class="text-black01 font-weight-bold">Why It’s Not Sustainable:</p>
        <ul class="ml-8 custom-ul">
            <li>High Risk, Low Reward: Most speculative coins crash as fast as they rise.</li>
            <li>Emotional Rollercoaster: Constant chasing leads to stress and poor decision-making.</li>
            <li>No Long-Term Strategy: Without a plan, you’re gambling, not investing.</li>
        </ul>
        <p>At Untrading, we built a different way:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Selling isn’t the end. </span>It’s just the beginning.</li>
            <li><span class="text-black01 font-weight-bold">No fees, no tricks, no unfair edges. </span>Trade in a system designed for traders, not against them.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you connected to your assets. </span>Your success continues even after you sell.</li>
        </ul>
        <p>Because the best traders don’t just trade—they <span class="text-black01 font-weight-bold">Untrade.</span></p>
        <br><hr>
        <p>Would you like to learn how Untrading can help you build sustainable wealth instead?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore sustainable trading:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Understanding real market dynamics</li>
                            <li>Building lasting wealth</li>
                            <li>Protecting your assets</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="180" aria-label="Start Learning">
                                <span class="px-4">Start Learning<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" class="ml-6" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>Untrading is designed for traders and investors who value fairness, security, and long-term growth. If you’re looking for quick riches, Untrading may not be the right fit for you.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="280" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes, show me how' },
                { code: 'No', message: 'No, I’m not interested' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>