<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Got it! You Don’t Need to Be Right Every Time—Just When It Matters</h3>
        <p >The market isn’t about perfection. It’s about exploiting opportunities when they come. But friction, fees, and manipulation eat into your wins.</p>
        <h3 class="text-black01 font-weight-bold">Untrading puts you back in control:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">No commissions—keep every dollar of your edge.</span></li>
            <li><span class="text-black01 font-weight-bold">No reliance on predictions—trade tactically, not blindly.</span></li>
            <li><span class="text-black01 font-weight-bold">Asymmetric upside—maximize your wins, limit your downside.</span></li>
        </ul>
        <p >Because trading isn’t a game of being right—it’s a game of capitalizing <span class="text-black01 font-weight-bold">when you are.</span></p>
    </div>
</template>