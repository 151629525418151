<template>
	<div class="markdown prose">
        <p class="font-italic">"Patience, conviction, and discipline define long-term success."</p>
        <p >With Untrading, you can hold strong while still earning passive Future Rewards (FRs)—ensuring that your vision and wealth continue to grow over time.</p>
        <ul class="ml-8 custom-ul">
            <li>Sell high, but keep earning.</li>
            <li>Own assets fully, with no interruptions.</li>
            <li>Build generational wealth—without giving up control.</li>
        </ul>
        <p >Your vision grows, even after you sell.</p>
    </div>
</template>