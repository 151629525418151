<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">LONG-TERM VALUE OPTIMIZATION PROTOCOL</h3>
        <p class="text-black01 font-weight-bold">System Architecture:</p>
		<ul class="ml-8 custom-ul">
            <li>Asset Wrapping Mechanism</li>
            <li>Future Rewards Distribution</li>
            <li>Value Stream Multiplication</li>
            <li>Perpetual Position Management</li>
        </ul>
        <p class="text-black01 font-weight-bold">Implementation Flow:</p>
		<ol class="ml-8 custom-ol">
            <li>
                <span>Asset Conversion Process</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Native to Wrapped Token</li>
                    <li>Security Layer Integration</li>
                    <li>Value Stream Initialization</li>
                </ul>
            </li>
            <li>
                <span>Position Management</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Strategic Exit Capability</li>
                    <li>Continuous Reward Generation</li>
                    <li>Market Reconnection Protocol</li>
                </ul>
            </li>
            <li>
                <span>Value Maximization</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Multiple Layer Stacking</li>
                    <li>Compound Reward Creation</li>
                    <li>Position Regeneration</li>
                </ul>
            </li>
        </ol>
        <p class="text-black01 font-weight-bold">Technical Advantages</p>
        <ul class="ml-8 custom-ul">
            <li>Zero Value Loss</li>
            <li>Perpetual Connection</li>
            <li>Reward Multiplication</li>
            <li>Strategic Flexibility</li>
        </ul>
        <p >Transform traditional holding into dynamic value creation with Untrading's innovative protocol stack.</p>
        <br><hr>
        <p>Now that you’ve identified your trading style and seen how Untrading can elevate your results, who inspires your trading journey today?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>