<template>
	<div class="markdown prose">
        <p>Got it! Let's look at the data:</p>
        <p>Analysis of 1000 "moonshot" tokens:</p>
        <ul class="ml-8 custom-ul">
            <li>92% lost all value within 3 months</li>
            <li>7% showed moderate gains but eventually declined</li>
            <li>< 1% maintained significant gains</li>
        </ul>
        <p>The math is clear: chasing 100x gains is more likely to result in 100% losses.</p>
        <p>Introducing Untrading, a groundbreaking concept that flips traditional trading on its head. Instead of walking away empty-handed after a sale, you stay linked to the asset’s journey. Through Future Rewards (FRs), you earn a share of its continued appreciation—all without paying extra fees or dealing with murky terms. Whether you’re battling FOMO or navigating choppy markets, Untrading ensures your success story doesn’t have to end at “sell.”</p>
        <br><hr>
        <p>Would you like to see how successful traders actually generate consistent returns?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Here's what sustainable trading looks like:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Consistent growth over time</li>
                            <li>Risk management strategies</li>
                            <li>Protected positions</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="200" aria-label="Show me how">
                                <span class="px-4">Show me how<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" class="ml-6" height="40" aria-label="" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>Fair enough. Untrading focuses on proven strategies, not speculative plays. We wish you success, but we're probably not what you're looking for.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="260" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes' },
                { code: 'No', message: 'No' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>