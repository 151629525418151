<template>
	<div class="markdown prose">
        <p><span class="text-black01 font-weight-bold">Got it! </span>Tired of the trading rollercoaster? We get it. The thrill of the perfect entry. The agony of stop hunts. The endless cycle of watching profits vanish.</p>
        <p>But what if your gains never had to end?</p>
        <p class="text-black01 font-weight-bold">Untrading changes everything:</p>
        <ul class="ml-8 custom-ul">
            <li>Keep earning after selling</li>
            <li>Say goodbye to hidden fees</li>
            <li>Build lasting value</li>
        </ul>
        <p >Because you deserve more than just moments of success. You deserve continuous growth.</p>
        <p >Join us in the future of trading, where your expertise creates lasting rewards.</p>
        <p >Untrade today. Profit tomorrow.</p>
        <br><hr>
		<p>You’ve found your trading style and seen how Untrading can help—so, who motivates your trading journey today? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>