<template>
    <div class="bg-black main-container">
        <article class="flex flex-col gap-2xl @md:gap-3xl">
            <!--$-->
            <div class="p-sm @md:p-md @md:h-[calc(100vh-12px-var(--header-h))] @md:py-0 @md:-mb-3xl py-3xl relative flex flex-col justify-center">
                <div class="@container relative z-50 mx-auto w-full max-w-[768px]">
                    <Guide></Guide>
                </div>
                <div class="@container bottom-xs @md:block px-sm @md:px-md @lg:px-0 absolute left-0 hidden w-full transition" style="opacity: 1;">
                    <div class="@lg:max-w-container">
                        <button type="button" class="text-meta text-black01 text-hover-1 opacity-40 duration-short ease-curve-a flex items-center transition-colors" @click="scrollTo()">
                            <svg class="mr-1" width="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.00001 2.14661V13.8534M8.00001 13.8534L12.5619 8.97555M8.00001 13.8534L3.4381 8.97555" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            Scroll to explore
                        </button>
                    </div>
                </div>
            </div>
            <div id="revolution">
                <Revolution></Revolution>
            </div>
            <Partner></Partner>
            <div class="@container w-full">
                <div class="@lg:max-w-container @lg:grid-cols-4 gap-sm grid w-full grid-cols-1">
                    <div class="@lg:col-span-4 @lg:sticky @lg:mb-0 mb-sm duration-medium grid-cols-1 self-start transition-[top] @lg:top-header-h">
                        <div class="px-sm block md:hidden">
                            <Versus></Versus>
                        </div>
                        <div class="px-sm @md:px-md @lg:px-0 relative hidden md:block px-6">
                            <Versus></Versus>
                        </div>
                    </div>
                </div>
            </div>
        </article>
        <Footer class="mt-3xl"></Footer>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import Guide from './components/Guide.vue';
    import Footer from './components/Footer.vue';
    import Revolution from './components/Revolution.vue';
    import Partner from './components/Partner.vue';
    import Versus from './components/Versus.vue';
    export default {
        data(){
            return {
            }
        },
        components: { Guide, Revolution, Partner, Versus, Footer },
        created(){
          
        },
        mounted() {
        },
        computed: {
                ...mapGetters(["darkTheme",'responsive'])
            },
        watch:{
    
        },
        methods: {
            // 滚动
            scrollTo() {
                // 获取元素
                const element = document.getElementById('revolution');
                // 计算元素距离顶部的距离
                const offset = element.getBoundingClientRect().top + window.pageYOffset;
                // 滚动到指定位置，留出 64px 的 header 间距
                window.scrollTo({
                    top: offset - 64,
                    behavior: 'smooth' // 平滑滚动
                });
            }
        },
    }
</script>
<style scoped>
    /* Default order for all items */
    .order-first-mobile {
        order: 2;
    }
    /* On mobile devices, make this item first */
    @media only screen and (max-width: 600px) {
        .order-first-mobile {
            order: -1;
        }
        .mobile-text-center {
            text-align: center;
        }
    }
</style>