<template>
	<span>
        <!-- a 链接 -->
        <span v-if="href">
            <a :href="href" :target="target" rel="noopener" class="del-underline">
                <button type="button" :disabled="disabled" :height="height" :style="`width: ${width}px; height: ${height}px`" :class="[variantClass, blockClass, `text-${color}`,`justify-${justify}`,`align-${align}`,`rounded-${rounded}`, disabled ? 'cursor-not-allowed' : '']"  rel="noopener">
                    <span v-if="loading">
                        <v-progress-circular :size="24" color="black01" indeterminate></v-progress-circular>
                    </span>
                    <slot v-else />
                </button>
            </a>
        </span>
        <!-- router-link 链接 -->
        <span v-else-if="to">
            <router-link :to="to" rel="noopener" class="del-underline">
                <button type="button" :disabled="disabled" :height="height" :style="`width: ${width}px; height: ${height}px`" :class="[variantClass, blockClass, `text-${color}`,`justify-${justify}`,`align-${align}`,`rounded-${rounded}`, disabled ? 'cursor-not-allowed' : '']"  rel="noopener">
                    <span v-if="loading">
                        <v-progress-circular :size="24" color="black01" indeterminate></v-progress-circular>
                    </span>
                    <slot v-else />
                </button>
            </router-link>
        </span>
        <!-- 普通按钮 -->
        <span v-else>
            <button type="button" :disabled="disabled" :height="height" :style="`width: ${width}px; height: ${height}px`" :class="[variantClass, blockClass, `text-${color}`,`justify-${justify}`,`align-${align}`,`rounded-${rounded}`, disabled ? 'cursor-not-allowed' : '']"  rel="noopener">
                <span v-if="loading">
                    <v-progress-circular :size="24" color="black01" indeterminate></v-progress-circular>
                </span>
                <slot v-else />
            </button>
        </span>
        <!-- 80 100 180 200 240 280 300 -->
	</span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            variantPrimaryStyle: "btn-primary button_root button_variant",
            variantSecondaryStyle: "bg-hover03 btn-2 button_root button_variant-secondary button_size-small button_variant link_root",
            variantOutlinedStyle: "border-primary border-opacity-100 border-md",
            disabledStyle: "bg-hover03 text-black01",
            blockStyle: "block-1"
        }
    },
    props:{
        variant: {
            type: String,
            default: "primary",
            validator: (value) => {
                return ['primary', 'secondary', 'outlined'].includes(value);
            }
        },
        color: {
            type: String,
            default: "black01", // 默认黑色
        },
        rounded: {
            type: String,
            default: "lg"
        },
        block: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        justify:{
            type: String,
            default: "center",
        },
        align:{
            type: String,
            default: "center",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [Number, String],
            default: 180
        },
        height: {
            type: [Number, String],
            default: 40
        },
        href: {
            type: String,
            default: null
        },
        to: {
            type: String,
            default: null
        },
        target: {
            type: String,
            default: '_blank',
            validator: (value) => {
                return ['_self', '_blank', '_parent', '_top', 'framename'].includes(value);
            }
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters([]),
        variantClass() {
            if(this.disabled) {
                return this.disabledStyle;
            }
            const variantMap = {
                primary: this.variantPrimaryStyle,
                secondary: this.variantSecondaryStyle,
                outlined: this.variantOutlinedStyle
            };
            return variantMap[this.variant];
        },
        blockClass() {
            return this.block ? this.blockStyle : '';
        }
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>