<template>
	<div>
        <v-dialog v-model="dialog" content-class="v-echarts-dialog" width="462">
            <v-card max-height="600" class="pa-8" rounded="lg" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="d-flex">
                            <span class="sidebar-h2 text-primaryGrey">Request Autopilot Now</span>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="dialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12">
                            <div class="sidebar-h2 text-primaryGrey">Post a public tweet that requests an invite code.</div>
                        </v-col>
                        <v-col cols="12">
                            <p class="pre-wrap body-p text-primaryGrey" v-html="tweetText"></p>
                        </v-col>
                    </v-col>
                </v-row>
                <div class="mt-16 text-center">
                    <Button variant="primary" rounded="xl" color="black03" height="52" width="180" aria-label="Post" @click="postTweet">Post</Button>
                </div>
            </v-card>
        </v-dialog>
	</div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data(){
        return {
            dialog: false
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        // 推文文本
        tweetText() {
            return "🚀 Thrilled for @untradingOrg Autopilot — earning-while-you-sleep insanity!\n\n🙏 Please, hook me up with an invite code — NOW!\n\n❤️ Obsessed with post-sale profits and effortless elite trading — pure #DeFi dynamite! ✨ ";
        }
    },
    watch:{
        $route: {
            handler(n, o) {
                this.dialog = false;
            },
            immediate: true
        },
        show: {
            handler(n, o) {
                this.dialog = n;
            },
            immediate: true
        },
        dialog: {
            handler(n, o) {
                if(!n) {
                    this.$emit('closeDialog', false);
                }
            },
            immediate: true
        },
    },
    methods: {
        // 发送推特链接
        postTwitterUrl(tweetText) {
            return "https://x.com/intent/tweet?text=" + encodeURIComponent(tweetText);
        },
        // 发送推文
        postTweet() {
            window.open(this.postTwitterUrl(this.tweetText), '_blank');
        },
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>