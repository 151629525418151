<template>
	<div class="Grid_root__iRfoa ga-8">
        <div class="gImbdz" v-for="(author, index) in authors" :key="index">
            <v-menu v-model="author.menu" :close-on-content-click="false" location="end" open-on-hover v-if="author.desc">
              <template v-slot:activator="{ props }">
                <div class="align-center d-inline-flex" style="gap:8px" v-bind="props">
                  <div class="cdTGNn">
                    <img :src="author.avatar" width="18" height="18" alt="Avatar of {{ author.name }}" class="fOsZEH">
                  </div>
                  <span class="about-n">{{ author.name }}</span>
                </div>
              </template>
              <div class="Author_content__Cst82 pointer">
                <div class="flex_root align-center" style="gap: 12px;">
                  <div class="cxQKIr">
                    <img :src="author.avatar" width="32" height="32" alt="" class="fOsZEH">
                  </div>
                  <div class="flex_root flex-column" style="gap: 8px;">
                    <span class="text-tertiary eEPVsj">{{ author.name }}</span>
                    <span class="text-tertiary eEPVsj">{{ author.desc }}</span>
                  </div>
                  <div class="d-flex flex-wrap ga-2">
                    <a :href="author.xLink" rel="noopener noreferrer" target="_blank" class="del-underline d-flex Link_root__cNtak">
                      <v-img :src="author.x" aria-label="X (Twitter)" role="img" width="24" height="24"></v-img>
                    </a>
                    <a :href="author.linkedinLink" rel="noopener noreferrer" target="_blank" class="del-underline d-flex Link_root__cNtak">
                        <v-img :src="author.linkedin" aria-label="X (Twitter)" role="img" width="24" height="24"></v-img>
                    </a>
                  </div>
                </div>
              </div>
            </v-menu>
            <div class="align-center d-inline-flex" style="gap:8px" v-if="!author.desc">
                <div class="cdTGNn">
                <img :src="author.avatar" width="18" height="18" alt="Avatar of {{ author.name }}" class="fOsZEH">
                </div>
                <span class="about-n">{{ author.name }}</span>
            </div>
         </div>
	</div>
</template>
<script>
export default {
    data(){
        return {
            menu: false,
            authors: [
                {
                name: 'Yale ReiSoleil',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2b513598-43d5-4b80-5a60-c6daa728fb00/public',
                desc: 'CEO',
                xLink: 'https://x.com/ReiSoleil',
                x: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public',
                linkedinLink: 'https://www.linkedin.com/in/reisoleil/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Yale ReiSoleil, Jr.',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/7206f302-0fe3-4d56-e085-4cf490d58200/public',
                desc: 'CTO',
                xLink: 'https://x.com/ReiSoleilJr',
                x: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public',
                linkedinLink: 'https://www.linkedin.com/in/yale-reisoleil-jr-681b252a2/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'BENNY NEO',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c15eec98-fd21-4422-4597-7f4cfb90d800/public',
                desc: 'President',
                xLink: 'https://x.com/n_plus_1_',
                x: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public',
                linkedinLink: 'https://www.linkedin.com/in/benny-neo-945540265/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'DAVID WANG PhD',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2e949a3c-d728-4089-d8b7-e6cf94dfa500/public',
                desc: 'Co-Founder',
                linkedinLink: 'https://www.linkedin.com/in/david-wang-montreal/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Fred Fei',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/dcc21e8b-9893-4090-66ff-7feaab34eb00/public',
                desc: 'Senior Quantitative Analyst & Systems Engineer',
                xLink: 'https://x.com/fei_fred',
                x: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public',
                linkedinLink: 'https://www.linkedin.com/in/feizhenhao/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Louis Liu',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d5784466-8096-4bc2-f614-8895f7e51700/public',
                desc: 'Senior Full-Stack Engineer',
                xLink: 'https://x.com/LouisLiuOneself',
                x: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public',
                linkedinLink: 'https://www.linkedin.com/in/louisliuoneself/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Shirley Yu',
                avatar: 'https://media.licdn.com/dms/image/v2/C5103AQHZda3BUhBevA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1556505463987?e=1735776000&v=beta&t=roAuyQCekvz0O-mlvDf_l1xqFB1-AVTdnW_NkALh_II',
                desc: 'Senior Web Designer',
                xLink: 'https://twitter.com/shirleyxl0731',
                x: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public',
                linkedinLink: 'https://www.linkedin.com/in/shirley0731/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Simon Telfer',
                avatar: 'https://media.licdn.com/dms/image/v2/D4E03AQF4KOI6Db4InQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1680036528333?e=1735776000&v=beta&t=hqStbuhKadCjHj7_QLxowjplqlOapACPwYDZ_Q5yAzs',
                desc: 'advisor',
                linkedinLink: 'https://www.linkedin.com/in/simongtelfer/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Tal Elyashiv',
                avatar: 'https://media.licdn.com/dms/image/v2/D4D03AQEVyMCTrMd89w/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1694090911740?e=1735776000&v=beta&t=KvTPfml98TPzS3VmY21_z1hqPUL-bMl8FL6FuiT9bT8',
                desc: 'advisor',
                linkedinLink: 'https://www.linkedin.com/in/talelyashiv/',
                linkedin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public',
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Francois Baechtold',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/fcf423cc-64b0-44ac-0fe9-b96204153800/public',
                desc: null,
                linkedinLink: null,
                linkedin: null,
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'LG Yeap',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/fcf423cc-64b0-44ac-0fe9-b96204153800/public',
                desc: null,
                linkedinLink: null,
                linkedin: null,
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Alvin Zhang',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/fcf423cc-64b0-44ac-0fe9-b96204153800/public',
                desc: null,
                linkedinLink: null,
                linkedin: null,
                menu: false, // 控制每个菜单的打开状态
                },
                {
                name: 'Amauri Mao',
                avatar: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/fcf423cc-64b0-44ac-0fe9-b96204153800/public',
                desc: null,
                linkedinLink: null,
                linkedin: null,
                menu: false, // 控制每个菜单的打开状态
                }
            ],
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>