<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Long-Term Investors Know: The Best Returns Take Time</h3>
        <p >You don’t get shaken by market noise. You invest with conviction, patience, and discipline—knowing that true wealth is built over decades, not days.</p>
        <p >But traditional markets penalize selling. Once you exit, <span class="text-black01 font-weight-bold">you lose all future upside.</span></p>
        <h3 class="text-black01 font-weight-bold">Untrading fixes that:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell high, but continue earning through Future Rewards (FRs).</span></li>
            <li><span class="text-black01 font-weight-bold">Keep full ownership of your assets—no middlemen, no restrictions.</span></li>
            <li><span class="text-black01 font-weight-bold">Build sustainable, generational wealth without compromise.</span></li>
        </ul>
        <p >Because great investors don’t just buy and hold—they <span class="text-black01 font-weight-bold">own their future.</span></p>
    </div>
</template>