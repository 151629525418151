<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">We get it! You plan your trades. You time your exits. But why should profits stop when you sell?</h3>
        <p >With <span class="text-black01 font-weight-bold">Untrading</span>, they don’t.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell and stay in the game.</span></li>
            <li><span class="text-black01 font-weight-bold">Zero commissions, total transparency.</span></li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you earning.</span></li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading flips the script:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Your profits don’t stop at selling. </span> Earn Future Rewards (FRs) post-sale.</li>
            <li><span class="text-black01 font-weight-bold">No commissions, no middlemen. </span>Keep more of what you earn.</li>
            <li><span class="text-black01 font-weight-bold">Trade on your terms, without market makers exploiting you.</span></li>
        </ul>
        <p >Trade smarter. Stay ahead. <span class="text-black01 font-weight-bold">Untrade.</span></p>
        <br><hr>
		<p>Now that you’ve explored your trading style and discovered how Untrading can help you win, who inspires your trading journey today?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>