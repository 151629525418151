<template>
	<div class="markdown prose">
        <p>Great! Strategic trading rewards patience and precision—but even the best-laid plans can falter. Enter Untrading : a smarter way to trade where selling isn’t the end. Earn Future Rewards (FRs) as assets appreciate post-sale, with no fees or middlemen. Trade confidently, stay ahead of manipulation, and keep more of what you earn.</p>
		<br><hr>
		<p>Now that you’ve explored your trading style and discovered how Untrading can help you win, who inspires your trading journey today?</p>
		<!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>