<template>
	<div class="markdown prose">
        <p>We Get It.The allure of quick riches through rapid coin appreciation is strong, but it's essential to be aware of the high risks involved. Most investors who pursue this path end up losing money. At Untrading, we believe in building wealth through strategic, informed trading.</p>
        <p class="text-black01 font-weight-bold">The Untrading Advantage:</p>
        <p>Step into the world of Untrading, where your financial story doesn't end at the sale:</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">Selling is Just the Start: </span>Your connection with your investments persists, allowing you to benefit from their ongoing success.</li>
            <li><span class="text-black01 font-weight-bold">Transparent, Commission-Free Trading: </span>No more worrying about hidden charges or unfair practices; what you see is what you get.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs): </span>Even after you've sold, you can still earn from the appreciation of those assets.</li>
        </ol>
        <p>Untrading offers you a way to keep your financial growth alive and well, transcending the traditional end of a trade. Your journey with your investments is just beginning, not ending, when you decide to sell.</p>
        <br><hr>
        <p>If you're interested in learning more about how Untrading can help you achieve your financial goals, let us know!</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore sustainable trading:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Understanding real market dynamics</li>
                            <li>Building lasting wealth</li>
                            <li>Protecting your assets</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="180" aria-label="Start Learning">
                                <span class="px-4">Start Learning<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" class="ml-6" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>We understand that Untrading might not be the right fit for everyone. If you're solely interested in quick, high-risk gains, Untrading may not align with your goals. We wish you the best in your investment journey.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="280" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes, tell me how Untrading works.' },
                { code: 'No', message: 'No, I’m not interested.' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>