<template>
	<div class="layoutContent">
        <section class="@container group/component-group">
            <div class="@container grid w-full grid-cols-12">
                <div class="col-span-12 grid w-full grid-cols-1 items-start gap-lg @md:gap-xl">
                    <div class="@container multi-columns:px-0 grid w-full grid-cols-12 max-w-container">
                        <div class="col-span-12 @md:col-span-10 @md:col-start-2 @lg:col-span-8 @lg:col-start-3">
                            <div class="relative flex flex-col items-center text-center">
                                <h2 class="text_h2 scroll-mt-header-h max-w-[62.5rem] text-primary-100 text-balance">Redefining What It Means to Sell a Token.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="w-full grid grid-cols-12 @container max-w-container multi-columns:px-0 multi-columns:flex">
                        <div class="col-span-12 @md:col-span-6 @md:col-start-4 max-w-none prose">
                            <p class="mb-sm last:mb-0 text-center text-balance">
                                <span> Ever kick yourself for selling too early? Not anymore. Your profits keep flowing even after you sell.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div style="background:rgb(var(--v-theme-bg-cfd));" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU mt-12"></div>
        <div class="Grid_root__iRfoa grid-style-6">
            <div class="Grid_a__vY7M8 page_bento">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Kingdoms</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">
                        On-chain communities united by shared goals and interests. Build and grow alongside members who share your vision.
                    </p>
                </div>
            </div>
            <div class="Grid_b___vQi_ page_bento">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Flows</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">
                        A reward system where sellers share profits with previous token holders. Earn rewards from future sales across multiple generations.
                    </p>
                </div>
            </div>
            <div class="Grid_c__Tv5Qw page_bento">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Together</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">
                        Strategic trading within communities to maximize shared rewards. Choose your trading partners to optimize value for everyone.
                    </p>
                </div>
            </div>
        </div>
        <div style="background:rgb(var(--v-theme-bg-cfd));" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU mb-16"></div>
        <div class="text-center">
            <p class="fs-21-bold text-black01">Kingdoms + Flows = <span class="text-primary">Continued Growth</span></p>
            <p class="fs-17 text-tertiary mt-8">Our core technologies are our novel Kingdoms and Flows. </p>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {
           
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
    }
}
</script>
<style scoped>

</style>