<template>
    <div>
        <div class="markdown prose">
            <p>Great choice! You’re opting for simplicity, discipline, and consistent growth with minimal effort.</p>
            <p>Our <span class="text-black01 font-weight-bold">Autopilot</span> program uses cutting-edge technology powered by the ERC-5173 protocol to implement strategies tailored to your inspirations:</p>
            <ul class="ml-8 custom-short-dash-ul">
                <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Frequent, low-risk profit-taking.</li>
                <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Balanced diversification for steady returns.</li>
                <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Large, strategic exits for long-term gains.</li>
            </ul>
            <p>The best part? Autopilot eliminates emotional decision-making, reduces the risk of total capital loss, and ensures shared success across the ecosystem. It’s designed to make trading effortless while delivering results.</p>
            <p>Right now, Autopilot is in its invite-only private preview phase. Would you like to join the waitlist and be among the first to access this game-changing solution?</p>
            <!-- 响应操作 -->
            <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
            <br><hr>
            <div class="mt-6 flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                <Button variant="primary" rounded="xl" color="black03" height="40" width="300" aria-label="Join Autopilot Waitlist" @click="requestAutopilotDialog = true">
                    <span class="px-4">Join Autopilot Waitlist<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
                <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over"  @click="$emit('startOver')">
                    <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>