<template>
	<div class="markdown prose">
        <p class="font-italic">"Diversification, balance, and risk-adjusted returns create lasting wealth."</p>
        <h3 class="text-black01 font-weight-bold">Untrading aligns with your mindset by allowing you to build collaborative financial ecosystems—where success is shared, not extracted.</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Built-in Future Rewards (FRs): </span>With Untrading, your gains don't end when you sell; you continue to earn as the assets you've sold appreciate, creating a diversified income stream beyond traditional trading.</li>
            <li><span class="text-black01 font-weight-bold">Decentralized and Transparent Profit-Sharing: </span>Through Untrading's structure, you participate in a model where profits are distributed fairly among all participants, echoing the principles of balance and fairness in wealth creation.</li>
            <li><span class="text-black01 font-weight-bold">A Trading Framework Designed for Stability and Continued Growth: </span>Untrading promotes a system where your investments are part of a larger, stable ecosystem, reducing the volatility risks while fostering an environment conducive to long-term growth.</li>
        </ul>
        <p >This platform embodies Ray Dalio's philosophy by fostering an investment community where the benefits of growth are distributed, ensuring your wealth-building strategy is both effective and ethical.</p>
    </div>
</template>