<template>
	<div class="markdown prose">
        <p>"It’s not about being right—it’s about maximizing when you are."</p>
        <h3 class="text-black01 font-weight-bold">Untrading:</h3>
        <ul class="ml-8 custom-ul">
            <li>No fees.</li>
            <li>No predictions.</li>
            <li>Just capitalize on your wins.</li>
        </ul>
        <p >The Soros way, reimagined.</p>
    </div>
</template>