<template>
	<div class="markdown prose">
        <p>"It’s not about being right—it’s about maximizing when you are."</p>
        <h3 class="text-black01 font-weight-bold">Untrading empowers tactical traders like you by eliminating friction, fees, and market inefficiencies.</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">No Commissions: </span>Trade without the burden of fees eating into your profits, allowing you to keep every cent of your gains.</li>
            <li><span class="text-black01 font-weight-bold">No Market Predictions: </span>Forget about the guesswork of market trends. Untrading focuses on leveraging the moments when your strategy pays off without the need for constant forecasting.</li>
            <li><span class="text-black01 font-weight-bold">Just Pure Asymmetric Opportunities: </span>With Untrading, you engage in trades where the potential for reward far outweighs the risk, capitalizing on market movements in a way that maximizes your winning trades.</li>
        </ul>
        <p >Untrading aligns with the Soros strategy of focusing on the maximization of gains rather than the accuracy of predictions. It's about making the market work for you, at the moments it matters most, without the traditional constraints of trading.</p>
    </div>
</template>