<template>
	<div class="my-12 bg-green-1">
        <v-row no-gutters class="py-10">
            <v-col cols="12" sm="6">
                <div class="item ml-5">
                    <h2 class="body-h4 text-white">A Revolutionary NFT Marketplace Framework</h2>
                    <h6 class="mt-3 body-p-small text-white">Discover a groundbreaking NFT marketplace framework that transforms the way sellers, buyers, and platforms interact, revolutionizing the trading experience.</h6>
                </div>
            </v-col>
            <v-col cols="12" sm="6" class="mt-10">
                <div class="item ml-5">
                    <h2 class="body-h4 text-white">Upgrade your crypto assets and maximize your returns</h2>
                    <h6 class="mt-3 body-p-small text-white">Wrapping your ERC-20 tokens and experiencing the power of Future Rewards (FRs) even after selling. Unlock The Full Potential Of Provenance Value Amplification (PVA) DeFi.</h6>
                </div>
            </v-col>
            <v-col cols="12" sm="6" class="mt-10">
                <div class="item ml-5">
                    <h2 class="body-h4 text-white">On-chain NFT royalties & on-chain licenses</h2>
                    <h6 class="mt-3 body-p-small text-white">On-chain nFR discourages royalty circumvention, and the platform implements Can't Be Evil licenses released by a16z.</h6>
                </div>
            </v-col>
            <v-col cols="12" sm="6" class="mt-10 text-center">
                <Button variant="secondary" href="https://docs.untrading.org" target="_blank" rounded="lg" color="black03" width="140" aria-label="Learn more">Learn more</Button>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* .hero {
    height: 236px; 
    background-color: black;
    margin-top: 50px;
    margin-bottom: 50px;
} */
</style>