<template>
	<div class="markdown prose">
        <p>Got it—you want the thrill of taking full control! Our <span class="text-black01 font-weight-bold">Crypto Racetrack</span> offers powerful tools for hands-on traders who love tactical decisions and navigating markets themselves.</p>
        <p>That said, many self-directed traders struggle with losses caused by emotional decisions, market volatility, or lack of discipline. With <span class="text-black01 font-weight-bold">Autopilot</span>, you can let proven strategies inspired by legends like Soros, Dalio, and Buffett work for you—without lifting a finger. Powered by the ERC-5173 protocol, Autopilot ensures shared success and makes total capital loss a thing of the past.</p>
        <p>Still want to explore Crypto Racetrack? No problem—it’s a great way to sharpen your skills. Just keep in mind that most users find Autopilot easier, safer, and far more rewarding over time.</p>
        <p>Ready to dive into Crypto Racetrack? Or would you reconsider giving Autopilot a shot?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        <br><hr>
        <div class="mt-6">
            👉 <span class="mx-2">Switch to</span>
            <Button variant="primary" rounded="xl" color="black03" width="200" aria-label="Autopilot Waitlist" @click="requestAutopilotDialog = true">
                <span class="px-4">Autopilot Waitlist<v-icon>mdi mdi-chevron-right</v-icon></span>
            </Button>
            or brave the 
            <Button variant="secondary" :href="`https://testnet.untrading.org/uncryptos?inspiration=${inspiration}`" rounded="xl" width="140" class="r-m-t-4" color="black01" aria-label="Racetrack">Racetrack</Button>
            <span class="mx-2">|</span>
            <Button variant="secondary" rounded="xl" class="r-m-t-4" color="black01" height="40" width="140" aria-label="Start Over" @click="$emit('startOver')">Start Over</Button>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['inspiration']),
    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>