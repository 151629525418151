<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Great! Smart Traders Think Ahead—Untrading Rewards That</h3>
        <p >You strategize. You analyze. You wait for the right moment. But even the best-planned trades fall to market surprises, liquidity traps, and institutional games.</p>
        <h3 class="text-black01 font-weight-bold">Untrading changes the equation:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell, but keep earning with Future Rewards (FRs).</span></li>
            <li><span class="text-black01 font-weight-bold">No fees, no middlemen, no tricks.</span></li>
            <li><span class="text-black01 font-weight-bold">Trade smarter—without getting played.</span></li>
        </ul>
        <p>Because patience should pay off—<span class="text-black01 font-weight-bold">even after you sell.</span></p>
        <br><hr>
		<p>With your trading style defined and Untrading’s advantages clear, who inspires your trading journey at this stage? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>