<template>
	<div class="bg-ffffff-212121">
        <article class="flex flex-col gap-2xl @md:gap-3xl">
            <div class="p-sm @md:p-md @md:h-[calc(100vh-64px-var(--header-h))] @md:py-0 relative flex flex-col justify-center">
                <div class="@container relative z-50 mx-auto w-full max-w-[768px] mb-280">
                    <div id="container" class="text-0D0D0D-ECECEC">
                        <div class="tex-base mt-n6">
                            <!-- ------------------ 交易经验---------------------- -->
                            <div v-if="!hideTradingExperience">
                                <!-- 发送的消息 -->
                                <div id="ai-tradingExperience" v-if="step >= 2" class="mt-6 d-flex r-justify-end">
                                    <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                                        <div v-for="e in tradingExperiences" :key="e.level">
                                            <div v-if="tradingExperience == e.level">{{ `${e.name}: ${e.message}` }}</div>
                                        </div>
                                    </v-card>
                                </div>
                                <!-- 响应 -->
                                <div v-if="step >= 2" class="mt-6 @md:px-xs">
                                    <div v-if="tradingExperienceResponseLoading" class="mt-6">
                                        <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                                    </div>
                                    <div v-else>
                                        <component :is="currentTradingExperienceResponses[currentTradingExperienceResponsePage - 1]" @watchMessageBoxWidth="watchMessageBoxWidth" @updateResponse="chooseRandomTradingExperienceResponse()" @startOver="startOver()"></component>
                                        <!-- <div class="mt-4 mb-6">
                                            <ChatPage v-show="currentTradingExperienceResponses.length > 1" :page="currentTradingExperienceResponsePage" :total="currentTradingExperienceResponses.length" @updatePage="updateTradingExperienceResponsePage"></ChatPage>
                                            <v-menu open-on-hover location="bottom center">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn v-bind="props" icon variant="text" size="28" @click="chooseRandomTradingExperienceResponse()">
                                                        <v-icon size="18">mdi mdi-autorenew</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-card class="pa-2 mt-2" color="#000000" rounded="lg" elevation="0">Remix</v-card>
                                            </v-menu>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- ------------------ 交易灵感---------------------- -->
                            <div v-if="!hideInspiration">
                                <!-- 消息框 -->
                                <MessageBox v-if="step == 2" :messages="inspirations" @onSent="setInspiration"></MessageBox>
                                <!-- 发送的消息 -->
                                <div id="ai-inspiration" v-if="step >= 3" class="mt-6 d-flex r-justify-end">
                                    <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                                        <div v-for="(i, iIndex) in inspirations" :key="`inspiration-${iIndex}`">
                                            <div v-if="i.code == inspiration">{{ i.message }}</div>
                                        </div>
                                    </v-card>
                                </div>
                                <!-- 响应 -->
                                <div v-if="step >= 3" class="mt-6 @md:px-xs text-base markdown prose">
                                    <div v-if="inspirationResponseLoading" class="mt-6">
                                        <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                                    </div>
                                    <div v-else>
                                        <component :is="currentInspirationResponses[currentInspirationResponsePage - 1]"></component>
                                        <!-- 响应操作 -->
                                        <ResponseAction @updateResponse="chooseRandomInspirationResponse()"></ResponseAction>
                                        <br><hr><br>
                                        <div v-if="tradingTypeQuestionLoading" class="mt-6">
                                            <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                                        </div>
                                        <div v-else>
                                            <component :is="currentTradingTypeQuestions[currentTradingTypeQuestionPage - 1]" @updateResponse="chooseRandomTradingTypeQuestion()" @onSent="setTradingTypeAnswer"></component>
                                        </div>
                                    </div>
                                    <!-- <div v-if="!inspirationResponseLoading" class="markdown prose">
                                        <h3 class="text-black01 font-weight-bold">Ready to Untrade?</h3>
                                        <p class="font-italic">Get your customized Untrading solutions and start earning beyond selling.</p>
                                        <div class="mt-6">
                                            <a :href="`https://testnet.untrading.org/uncryptos?inspiration=${inspiration}`">
                                                <button type="button" class="btn-primary bg-primary text-black01 rounded-xl button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener">
                                                    <span class="px-4">Get My Untrading Plan<v-icon>mdi mdi-chevron-right</v-icon></span>
                                                </button>
                                            </a>
                                            <button type="button" class="bg-hover03 btn-2 rounded-xl button_root button_variant-secondary button_size-small button_variant link_root ml-6" rel="noopener" @click="startOver()">
                                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                                            </button>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <!-- ------------------ 交易类型问题---------------------- -->
                            <div>
                                <!-- 响应 -->
                                <div v-if="step >= 4" class="mt-6 @md:px-xs text-base markdown prose">
                                    <div v-if="tradingTypeResponseLoading" class="mt-6">
                                        <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                                    </div>
                                    <div v-else>
                                        <component :is="currentTradingTypeResponses[currentTradingTypeResponsePage - 1]" @updateResponse="chooseRandomTradingTypeResponse()" @startOver="startOver()"></component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
        <!-- <div style="opacity: 1; will-change: auto;">
            <button class="cursor-pointer absolute z-10 rounded-full bg-clip-padding border text-token-text-secondary border-token-border-light right-1/2 translate-x-1/2 bg-token-main-surface-primary w-8 h-8 flex items-center justify-center bottom-5">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md text-token-text-primary">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C11.7348 21 11.4804 20.8946 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.5196 20.8946 12.2652 21 12 21Z" fill="currentColor"></path>
                </svg>
            </button>
        </div> -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ChatPage from './components/ChatPage.vue';
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
// level1
import TradingExperienceLevel1Response1 from './data/trading/level1/1.vue';
import TradingExperienceLevel1Response2 from './data/trading/level1/2.vue';
import TradingExperienceLevel1Response3 from './data/trading/level1/3.vue';
import TradingExperienceLevel1Response4 from './data/trading/level1/4.vue';
import TradingExperienceLevel1Response5 from './data/trading/level1/5.vue';
import TradingExperienceLevel1Response6 from './data/trading/level1/6.vue';
import TradingExperienceLevel1Response7 from './data/trading/level1/7.vue';
import TradingExperienceLevel1Response8 from './data/trading/level1/8.vue';
import TradingExperienceLevel1Response9 from './data/trading/level1/9.vue';
import TradingExperienceLevel1Response10 from './data/trading/level1/10.vue';
import TradingExperienceLevel1Response11 from './data/trading/level1/11.vue';
import TradingExperienceLevel1Response12 from './data/trading/level1/12.vue';
import TradingExperienceLevel1Response13 from './data/trading/level1/13.vue';
import TradingExperienceLevel1Response14 from './data/trading/level1/14.vue';
import TradingExperienceLevel1Response15 from './data/trading/level1/15.vue';
// level2
import TradingExperienceLevel2Response1 from './data/trading/level2/1.vue';
import TradingExperienceLevel2Response2 from './data/trading/level2/2.vue';
import TradingExperienceLevel2Response3 from './data/trading/level2/3.vue';
import TradingExperienceLevel2Response4 from './data/trading/level2/4.vue';
import TradingExperienceLevel2Response5 from './data/trading/level2/5.vue';
import TradingExperienceLevel2Response6 from './data/trading/level2/6.vue';
import TradingExperienceLevel2Response7 from './data/trading/level2/7.vue';
import TradingExperienceLevel2Response8 from './data/trading/level2/8.vue';
import TradingExperienceLevel2Response9 from './data/trading/level2/9.vue';
import TradingExperienceLevel2Response10 from './data/trading/level2/10.vue';
import TradingExperienceLevel2Response11 from './data/trading/level2/11.vue';
import TradingExperienceLevel2Response12 from './data/trading/level2/12.vue';
import TradingExperienceLevel2Response13 from './data/trading/level2/13.vue';
import TradingExperienceLevel2Response14 from './data/trading/level2/14.vue';
import TradingExperienceLevel2Response15 from './data/trading/level2/15.vue';
// level3
import TradingExperienceLevel3Response1 from './data/trading/level3/1.vue';
import TradingExperienceLevel3Response2 from './data/trading/level3/2.vue';
import TradingExperienceLevel3Response3 from './data/trading/level3/3.vue';
import TradingExperienceLevel3Response4 from './data/trading/level3/4.vue';
import TradingExperienceLevel3Response5 from './data/trading/level3/5.vue';
import TradingExperienceLevel3Response6 from './data/trading/level3/6.vue';
import TradingExperienceLevel3Response7 from './data/trading/level3/7.vue';
import TradingExperienceLevel3Response8 from './data/trading/level3/8.vue';
import TradingExperienceLevel3Response9 from './data/trading/level3/9.vue';
import TradingExperienceLevel3Response10 from './data/trading/level3/10.vue';
import TradingExperienceLevel3Response11 from './data/trading/level3/11.vue';
import TradingExperienceLevel3Response12 from './data/trading/level3/12.vue';
import TradingExperienceLevel3Response13 from './data/trading/level3/13.vue';
import TradingExperienceLevel3Response14 from './data/trading/level3/14.vue';
import TradingExperienceLevel3Response15 from './data/trading/level3/15.vue';
// level4
import TradingExperienceLevel4Response1 from './data/trading/level4/1.vue';
import TradingExperienceLevel4Response2 from './data/trading/level4/2.vue';
import TradingExperienceLevel4Response3 from './data/trading/level4/3.vue';
import TradingExperienceLevel4Response4 from './data/trading/level4/4.vue';
import TradingExperienceLevel4Response5 from './data/trading/level4/5.vue';
import TradingExperienceLevel4Response6 from './data/trading/level4/6.vue';
import TradingExperienceLevel4Response7 from './data/trading/level4/7.vue';
import TradingExperienceLevel4Response8 from './data/trading/level4/8.vue';
import TradingExperienceLevel4Response9 from './data/trading/level4/9.vue';
import TradingExperienceLevel4Response10 from './data/trading/level4/10.vue';
import TradingExperienceLevel4Response11 from './data/trading/level4/11.vue';
import TradingExperienceLevel4Response12 from './data/trading/level4/12.vue';
import TradingExperienceLevel4Response13 from './data/trading/level4/13.vue';
import TradingExperienceLevel4Response14 from './data/trading/level4/14.vue';
import TradingExperienceLevel4Response15 from './data/trading/level4/15.vue';
// Soros
import inspirationSorosResponse1 from './data/inspiration/soros/1.vue';
import inspirationSorosResponse2 from './data/inspiration/soros/2.vue';
import inspirationSorosResponse3 from './data/inspiration/soros/3.vue';
import inspirationSorosResponse4 from './data/inspiration/soros/4.vue';
import inspirationSorosResponse5 from './data/inspiration/soros/5.vue';
import inspirationSorosResponse6 from './data/inspiration/soros/6.vue';
import inspirationSorosResponse7 from './data/inspiration/soros/7.vue';
import inspirationSorosResponse8 from './data/inspiration/soros/8.vue';
import inspirationSorosResponse9 from './data/inspiration/soros/9.vue';
import inspirationSorosResponse10 from './data/inspiration/soros/10.vue';
import inspirationSorosResponse11 from './data/inspiration/soros/11.vue';
import inspirationSorosResponse12 from './data/inspiration/soros/12.vue';
import inspirationSorosResponse13 from './data/inspiration/soros/13.vue';
import inspirationSorosResponse14 from './data/inspiration/soros/14.vue';
import inspirationSorosResponse15 from './data/inspiration/soros/15.vue';
// Dalio
import inspirationDalioResponse1 from './data/inspiration/dalio/1.vue';
import inspirationDalioResponse2 from './data/inspiration/dalio/2.vue';
import inspirationDalioResponse3 from './data/inspiration/dalio/3.vue';
import inspirationDalioResponse4 from './data/inspiration/dalio/4.vue';
import inspirationDalioResponse5 from './data/inspiration/dalio/5.vue';
import inspirationDalioResponse6 from './data/inspiration/dalio/6.vue';
import inspirationDalioResponse7 from './data/inspiration/dalio/7.vue';
import inspirationDalioResponse8 from './data/inspiration/dalio/8.vue';
import inspirationDalioResponse9 from './data/inspiration/dalio/9.vue';
import inspirationDalioResponse10 from './data/inspiration/dalio/10.vue';
import inspirationDalioResponse11 from './data/inspiration/dalio/11.vue';
import inspirationDalioResponse12 from './data/inspiration/dalio/12.vue';
import inspirationDalioResponse13 from './data/inspiration/dalio/13.vue';
import inspirationDalioResponse14 from './data/inspiration/dalio/14.vue';
import inspirationDalioResponse15 from './data/inspiration/dalio/15.vue';
// Buffett
import inspirationBuffettResponse1 from './data/inspiration/buffett/1.vue';
import inspirationBuffettResponse2 from './data/inspiration/buffett/2.vue';
import inspirationBuffettResponse3 from './data/inspiration/buffett/3.vue';
import inspirationBuffettResponse4 from './data/inspiration/buffett/4.vue';
import inspirationBuffettResponse5 from './data/inspiration/buffett/5.vue';
import inspirationBuffettResponse6 from './data/inspiration/buffett/6.vue';
import inspirationBuffettResponse7 from './data/inspiration/buffett/7.vue';
import inspirationBuffettResponse8 from './data/inspiration/buffett/8.vue';
import inspirationBuffettResponse9 from './data/inspiration/buffett/9.vue';
import inspirationBuffettResponse10 from './data/inspiration/buffett/10.vue';
import inspirationBuffettResponse11 from './data/inspiration/buffett/11.vue';
import inspirationBuffettResponse12 from './data/inspiration/buffett/12.vue';
import inspirationBuffettResponse13 from './data/inspiration/buffett/13.vue';
import inspirationBuffettResponse14 from './data/inspiration/buffett/14.vue';
import inspirationBuffettResponse15 from './data/inspiration/buffett/15.vue';
// 交易类型问题
import TradingTypeQuestion1 from './data/trading/type/question/1.vue';
import TradingTypeQuestion2 from './data/trading/type/question/2.vue';
import TradingTypeQuestion3 from './data/trading/type/question/3.vue';
import TradingTypeQuestion4 from './data/trading/type/question/4.vue';
import TradingTypeQuestion5 from './data/trading/type/question/5.vue';
import TradingTypeQuestion6 from './data/trading/type/question/6.vue';
import TradingTypeQuestion7 from './data/trading/type/question/7.vue';
import TradingTypeQuestion8 from './data/trading/type/question/8.vue';
import TradingTypeQuestion9 from './data/trading/type/question/9.vue';
import TradingTypeQuestion10 from './data/trading/type/question/10.vue';
import TradingTypeQuestion11 from './data/trading/type/question/11.vue';
import TradingTypeQuestion12 from './data/trading/type/question/12.vue';
import TradingTypeQuestion13 from './data/trading/type/question/13.vue';
import TradingTypeQuestion14 from './data/trading/type/question/14.vue';
import TradingTypeQuestion15 from './data/trading/type/question/15.vue';
// 交易类型 Autopilot 响应
import TradingTypeAutopilotResponse1 from './data/trading/type/response/autopilot/1.vue';
import TradingTypeAutopilotResponse2 from './data/trading/type/response/autopilot/2.vue';
import TradingTypeAutopilotResponse3 from './data/trading/type/response/autopilot/3.vue';
import TradingTypeAutopilotResponse4 from './data/trading/type/response/autopilot/4.vue';
import TradingTypeAutopilotResponse5 from './data/trading/type/response/autopilot/5.vue';
import TradingTypeAutopilotResponse6 from './data/trading/type/response/autopilot/6.vue';
import TradingTypeAutopilotResponse7 from './data/trading/type/response/autopilot/7.vue';
import TradingTypeAutopilotResponse8 from './data/trading/type/response/autopilot/8.vue';
import TradingTypeAutopilotResponse9 from './data/trading/type/response/autopilot/9.vue';
import TradingTypeAutopilotResponse10 from './data/trading/type/response/autopilot/10.vue';
import TradingTypeAutopilotResponse11 from './data/trading/type/response/autopilot/11.vue';
import TradingTypeAutopilotResponse12 from './data/trading/type/response/autopilot/12.vue';
import TradingTypeAutopilotResponse13 from './data/trading/type/response/autopilot/13.vue';
import TradingTypeAutopilotResponse14 from './data/trading/type/response/autopilot/14.vue';
import TradingTypeAutopilotResponse15 from './data/trading/type/response/autopilot/15.vue';
// 交易类型 Manual 响应
import TradingTypeManualResponse1 from './data/trading/type/response/manual/1.vue';
import TradingTypeManualResponse2 from './data/trading/type/response/manual/2.vue';
import TradingTypeManualResponse3 from './data/trading/type/response/manual/3.vue';
import TradingTypeManualResponse4 from './data/trading/type/response/manual/4.vue';
import TradingTypeManualResponse5 from './data/trading/type/response/manual/5.vue';
import TradingTypeManualResponse6 from './data/trading/type/response/manual/6.vue';
import TradingTypeManualResponse7 from './data/trading/type/response/manual/7.vue';
import TradingTypeManualResponse8 from './data/trading/type/response/manual/8.vue';
import TradingTypeManualResponse9 from './data/trading/type/response/manual/9.vue';
import TradingTypeManualResponse10 from './data/trading/type/response/manual/10.vue';
import TradingTypeManualResponse11 from './data/trading/type/response/manual/11.vue';
import TradingTypeManualResponse12 from './data/trading/type/response/manual/12.vue';
import TradingTypeManualResponse13 from './data/trading/type/response/manual/13.vue';
import TradingTypeManualResponse14 from './data/trading/type/response/manual/14.vue';
import TradingTypeManualResponse15 from './data/trading/type/response/manual/15.vue';
export default {
    data(){
        return {
            // 步骤
            step: 2,
            // 隐藏交易经验
            hideTradingExperience: false,
            // 隐藏交易灵感
            hideInspiration: false,
            // 交易经验集合
            tradingExperiences: [
                { level: 'level-1', name: 'I Master Short-term Chart Patterns', message: 'High-energy, quick in-and-out trades!' },
                { level: 'level-2', name: 'I Navigate Daily/Weekly Trends', message: 'For the strategic, medium-term trend-following!' },
                { level: 'level-3', name: 'I\'m a HODL/Diamond Hand', message: 'Visionary, long-term investing with patience and conviction!' },
                { level: 'level-4', name: 'I want coins that will skyrocket 100x', message: 'Show me the fastest path to massive profits!' },
            ],
            // 期望人物集合
            inspirations: [
                { code: 'Soros', message: 'Soros inspires me today - Tactical, high-risk, high-reward moves!' },
                { code: 'Dalio', message: 'Dalio inspires me today - Risk-adjusted, data-driven, diversified!' },
                { code: 'Buffett', message: 'Buffett inspires me today - Long-term wealth through conviction!' }
            ],
            // 交易经验响应加载中
            tradingExperienceResponseLoading: false,
            // 当前交易经验响应页码
            currentTradingExperienceResponsePage: 1,
            // 当前交易经验响应集合
            currentTradingExperienceResponses: [],
            // 交易经验响应集合
            tradingExperienceLevel1Responses: [TradingExperienceLevel1Response1, TradingExperienceLevel1Response2, TradingExperienceLevel1Response3, TradingExperienceLevel1Response4, TradingExperienceLevel1Response5, TradingExperienceLevel1Response6, TradingExperienceLevel1Response7, TradingExperienceLevel1Response8, TradingExperienceLevel1Response9, TradingExperienceLevel1Response10, TradingExperienceLevel1Response11, TradingExperienceLevel1Response12, TradingExperienceLevel1Response13, TradingExperienceLevel1Response14, TradingExperienceLevel1Response15],
            tradingExperienceLevel2Responses: [TradingExperienceLevel2Response1, TradingExperienceLevel2Response2, TradingExperienceLevel2Response3, TradingExperienceLevel2Response4, TradingExperienceLevel2Response5, TradingExperienceLevel2Response6, TradingExperienceLevel2Response7, TradingExperienceLevel2Response8, TradingExperienceLevel2Response9, TradingExperienceLevel2Response10, TradingExperienceLevel2Response11, TradingExperienceLevel2Response12, TradingExperienceLevel2Response13, TradingExperienceLevel2Response14, TradingExperienceLevel2Response15],
            tradingExperienceLevel3Responses: [TradingExperienceLevel3Response1, TradingExperienceLevel3Response2, TradingExperienceLevel3Response3, TradingExperienceLevel3Response4, TradingExperienceLevel3Response5, TradingExperienceLevel3Response6, TradingExperienceLevel3Response7, TradingExperienceLevel3Response8, TradingExperienceLevel3Response9, TradingExperienceLevel3Response10, TradingExperienceLevel3Response11, TradingExperienceLevel3Response12, TradingExperienceLevel3Response13, TradingExperienceLevel3Response14, TradingExperienceLevel3Response15],
            tradingExperienceLevel4Responses: [TradingExperienceLevel4Response1, TradingExperienceLevel4Response2, TradingExperienceLevel4Response3, TradingExperienceLevel4Response4, TradingExperienceLevel4Response5, TradingExperienceLevel4Response6, TradingExperienceLevel4Response7, TradingExperienceLevel4Response8, TradingExperienceLevel4Response9, TradingExperienceLevel4Response10, TradingExperienceLevel4Response11, TradingExperienceLevel4Response12, TradingExperienceLevel4Response13, TradingExperienceLevel4Response14, TradingExperienceLevel4Response15],
            // 当前期望人物
            currentInspiration: {},
            // 期望人物响应加载中
            inspirationResponseLoading: false,
            // 当前期望人物响应页码
            currentInspirationResponsePage: 1,
            // 当前期望人物响应集合
            currentInspirationResponses: [],
            // 期望人物响应集合
            inspirationSorosResponses: [inspirationSorosResponse1, inspirationSorosResponse2, inspirationSorosResponse3, inspirationSorosResponse4, inspirationSorosResponse5, inspirationSorosResponse6, inspirationSorosResponse7, inspirationSorosResponse8, inspirationSorosResponse9, inspirationSorosResponse10, inspirationSorosResponse11, inspirationSorosResponse12, inspirationSorosResponse13, inspirationSorosResponse14, inspirationSorosResponse15],
            inspirationDalioResponses: [inspirationDalioResponse1, inspirationDalioResponse2, inspirationDalioResponse3, inspirationDalioResponse4, inspirationDalioResponse5, inspirationDalioResponse6, inspirationDalioResponse7, inspirationDalioResponse8, inspirationDalioResponse9, inspirationDalioResponse10, inspirationDalioResponse11, inspirationDalioResponse12, inspirationDalioResponse13, inspirationDalioResponse14, inspirationDalioResponse15],
            inspirationBuffettResponses: [inspirationBuffettResponse1, inspirationBuffettResponse2, inspirationBuffettResponse3, inspirationBuffettResponse4, inspirationBuffettResponse5, inspirationBuffettResponse6, inspirationBuffettResponse7, inspirationBuffettResponse8, inspirationBuffettResponse9, inspirationBuffettResponse10, inspirationBuffettResponse11, inspirationBuffettResponse12, inspirationBuffettResponse13, inspirationBuffettResponse14, inspirationBuffettResponse15],
            // 是否发送禁用
            isSendDisabled: true,
            // 持续财富答案集合
            lastingWealthAnswers: [
                { answer: 'Yes', message: 'Yes, tell me more' },
                { answer: 'No', message: 'No, not interested' }
            ],
            // 当前持续财富答案
            currentLastingWealthAnswer: {},
            // 响应加载中
            responseLoading: false,
            // 交易类型问题集合
            tradingTypeQuestions: [TradingTypeQuestion1, TradingTypeQuestion2, TradingTypeQuestion3, TradingTypeQuestion4, TradingTypeQuestion5, TradingTypeQuestion6, TradingTypeQuestion7, TradingTypeQuestion8, TradingTypeQuestion9, TradingTypeQuestion10, TradingTypeQuestion11, TradingTypeQuestion12, TradingTypeQuestion13, TradingTypeQuestion14, TradingTypeQuestion15],
            // 交易类型问题加载中
            tradingTypeQuestionLoading: false,
            // 当前交易类型问题页码
            currentTradingTypeQuestionPage: 1,
            // 当前交易类型问题集合
            currentTradingTypeQuestions: [],
            // 交易类型响应 Autopilot 集合
            tradingTypeAutopilotResponses: [TradingTypeAutopilotResponse1, TradingTypeAutopilotResponse2, TradingTypeAutopilotResponse3, TradingTypeAutopilotResponse4, TradingTypeAutopilotResponse5, TradingTypeAutopilotResponse6, TradingTypeAutopilotResponse7, TradingTypeAutopilotResponse8, TradingTypeAutopilotResponse9, TradingTypeAutopilotResponse10, TradingTypeAutopilotResponse11, TradingTypeAutopilotResponse12, TradingTypeAutopilotResponse13, TradingTypeAutopilotResponse14, TradingTypeAutopilotResponse15],
            // 交易类型响应 Manual 集合
            tradingTypeManualResponses: [TradingTypeManualResponse1, TradingTypeManualResponse2, TradingTypeManualResponse3, TradingTypeManualResponse4, TradingTypeManualResponse5, TradingTypeManualResponse6, TradingTypeManualResponse7, TradingTypeManualResponse8, TradingTypeManualResponse9, TradingTypeManualResponse10, TradingTypeManualResponse11, TradingTypeManualResponse12, TradingTypeManualResponse13, TradingTypeManualResponse14, TradingTypeManualResponse15],
            // 交易类型响应加载中
            tradingTypeResponseLoading: false,
            // 当前交易类型响应页码
            currentTradingTypeResponsePage: 1,
            // 当前交易类型响应集合
            currentTradingTypeResponses: [],
        }
    },
    components: { ChatPage, ResponseAction, MessageBox },
    created(){

    },
    mounted(){
        // 加载交易经验
        this.loadTradingExperience();
        // 加载交易灵感
        this.loadInspiration();
        // 更新交易灵感
        // this.updateCurrentInspiration();
        // 监听消息框宽度
        this.watchMessageBoxWidth();
        // 页面加载时清除选择的答案
        this.$store.dispatch("lastingWealthAnswerHandler", null);
    },
    computed: {
        ...mapGetters(['tradingExperience', 'inspiration', 'tradingTypeAnswer']),
        inspirationTexts() {
            return this.inspirations.map(inspiration => `${inspiration.code}: ${inspiration.message}`);
        },
    },
    watch:{
        
    },
    methods: {
        // 监听消息框宽度
        watchMessageBoxWidth() {
            // 获取父级主体元素
            const parent = document.querySelector('#container');
            // 获取消息框元素集合
            const messageBoxs = document.querySelectorAll('.message-box');
            // 循环给每一个设置宽度
            messageBoxs.forEach(messageBox => {
                // 设置消息框宽度
                messageBox.style.width = `${parent.offsetWidth}px`;
                // 监听窗口大小变化重新设置消息框宽度
                window.addEventListener('resize', () => {
                    messageBox.style.width = `${parent.offsetWidth}px`;
                });
            });
        },
        // 加载交易经验
        loadTradingExperience() {
            if (!this.tradingExperience) {
                this.$router.push('/');
                return;
            }
            // 选择随机的交易经验响应
            this.chooseRandomTradingExperienceResponse();
        },
        // 加载交易灵感
        loadInspiration() {
            if (this.inspiration) {
                // 隐藏交易经验
                this.hideTradingExperience = true;
                // 查询当前代码的对象
                let data = this.inspirations.filter(i => i.code == this.inspiration)[0];
                // 设置交易灵感
                this.setInspiration(data);
            } else {
                if (this.tradingExperience == 'level-4') {
                    // 隐藏交易灵感
                    this.hideInspiration = true;
                }
            }
        },
        // 选择随机的交易经验响应
        async chooseRandomTradingExperienceResponse() {
            this.tradingExperienceResponseLoading = true;
            await this.sleep(3000);
            let responses = [];
            switch (this.tradingExperience) {
                case 'level-1': responses = this.tradingExperienceLevel1Responses; break;
                case 'level-2': responses = this.tradingExperienceLevel2Responses; break;
                case 'level-3': responses = this.tradingExperienceLevel3Responses; break;
                case 'level-4': responses = this.tradingExperienceLevel4Responses; break;
            }
            // 生成随机数
            const randomIndex = Math.floor(Math.random() * responses.length);
            // 追加到最后
            this.currentTradingExperienceResponses.push(responses[randomIndex]);
            // 默认页码为最后一个
            this.currentTradingExperienceResponsePage = this.currentTradingExperienceResponses.length;
            this.tradingExperienceResponseLoading = false;
        },
        // 更新交易经验响应页码
        updateTradingExperienceResponsePage(page) {
            this.currentTradingExperienceResponsePage = page;
        },
        // 设置交易灵感
        setInspiration(data) {
            this.step = 3;
            this.$store.dispatch("inspirationHandler", data.code);
            // 清除交易类型问题
            this.$store.dispatch("tradingTypeAnswerHandler", null);
            // 选择随机的期望人物响应
            this.chooseRandomInspirationResponse();
            // 选择随机的交易类型问题
            this.chooseRandomTradingTypeQuestion();
        },
        // 选择随机的期望人物响应
        async chooseRandomInspirationResponse() {
            this.inspirationResponseLoading = true;
            await this.sleep(3000);
            let responses = [];
            switch (this.inspiration) {
                case 'Soros': responses = this.inspirationSorosResponses; break;
                case 'Dalio': responses = this.inspirationDalioResponses; break;
                case 'Buffett': responses = this.inspirationBuffettResponses; break;
            }
            // 生成随机数
            const randomIndex = Math.floor(Math.random() * responses.length);
            // 追加到最后
            this.currentInspirationResponses.push(responses[randomIndex]);
            // 默认页码为最后一个
            this.currentInspirationResponsePage = this.currentInspirationResponses.length;
            this.inspirationResponseLoading = false;
        },
        // 更新交易灵感响应页码
        updateInspirationResponsePage(page) {
            this.currentInspirationResponsePage = page;
        },
        // 选择随机的交易类型问题
        async chooseRandomTradingTypeQuestion() {
            this.tradingTypeQuestionLoading = true;
            await this.sleep(5000);
            // 生成随机数
            const randomIndex = Math.floor(Math.random() * this.tradingTypeQuestions.length);
            // 追加到最后
            this.currentTradingTypeQuestions.push(this.tradingTypeQuestions[randomIndex]);
            // 默认页码为最后一个
            this.currentTradingTypeQuestionPage = this.currentTradingTypeQuestions.length;
            this.tradingTypeQuestionLoading = false;
        },
        // 设置交易类型答案
        setTradingTypeAnswer(data) {
            this.step = 4;
            this.$store.dispatch("tradingTypeAnswerHandler", data.code);
            // 选择随机的交易类型响应
            this.chooseRandomTradingTypeResponse();
        },
        // 选择随机的交易类型响应
        async chooseRandomTradingTypeResponse() {
            this.tradingTypeResponseLoading = true;
            await this.sleep(3000);
            let responses = [];
            switch (this.tradingTypeAnswer) {
                case 'Autopilot': responses = this.tradingTypeAutopilotResponses; break;
                case 'Manual': responses = this.tradingTypeManualResponses; break;
            }
            // 生成随机数
            const randomIndex = Math.floor(Math.random() * responses.length);
            // 追加到最后
            this.currentTradingTypeResponses.push(responses[randomIndex]);
            // 默认页码为最后一个
            this.currentTradingTypeResponsePage = this.currentTradingTypeResponses.length;
            this.tradingTypeResponseLoading = false;
        },
        // 睡眠
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        // 重新开始
        startOver() {
            this.$store.dispatch("tradingExperienceHandler", null);
            this.$store.dispatch("inspirationHandler", null);
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$store.dispatch("tradingTypeAnswerHandler", null);
            localStorage.removeItem('untrading-welcome');
            window.location.href = '/';
        }
    }
}
</script>
<style scoped>
/* 固定在底部的输入框容器 */
.input-container {
      position: absolute;
      bottom: 0;
}
.v-application {
    display: flex;
    background: rgb(var(--v-theme-ffffff-212121)) !important;
    color: rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity));
}
</style>