<template>
	<div class="markdown prose">
        <p>I appreciate it! We appreciate your honesty—but let's talk reality for a moment.</p>
        <p>The dream of 100x gains in days is what drives many to crypto. But here's what the data shows:</p>
        <ul class="ml-8 custom-ul">
            <li>95% of traders seeking quick massive gains lose money.</li>
            <li>Most "moon shots" end up as rug pulls or pump-and-dumps.</li>
            <li>Sustainable wealth rarely comes from single trades.</li>
        </ul>
        <p class="text-black01 font-weight-bold">The Untrading Advantage:</p>
        <p>Welcome to Untrading—where your gains don’t stop when you sell.</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">Selling is Just the Beginning: </span>Stay connected to your assets even after exiting.</li>
            <li><span class="text-black01 font-weight-bold">Commission-Free, Transparent, and Fair: </span>Trade without hidden fees or unfair practices.</li>
            <li><span class="text-black01 font-weight-bold">Earn Future Rewards (FRs): </span>Continue to benefit as assets appreciate post-sale.</li>
        </ol>
        <p>With Untrading, your success keeps growing—even after you’ve moved on.</p>
        <br><hr>
        <p>The real question: Would you rather chase a 1% chance of getting rich quick, or build a system that consistently grows your wealth?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore how Untrading can help you:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Capture gains systematically</li>
                            <li>Build sustainable wealth</li>
                            <li>Stay protected from market manipulation</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="200" aria-label="Show me how">
                                <span class="px-4">Show me how<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" class="ml-6" height="40" aria-label="" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>We understand. Untrading focuses on sustainable wealth creation, not quick gambles. We wish you the best, but we may not be the right fit for your goals.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="260" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes, tell me more' },
                { code: 'No', message: 'No, not interested' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>