<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">Hey, How Do You Want to Trade?</p>
        <p>Let’s figure out your style:</p>
        <p>Are you the type who loves jumping into the action, racing through the Crypto Racetrack? Or do you want to chill and let <span class="text-black01 font-weight-bold">Autopilot</span> handle the heavy lifting for you?</p>
        <p>Autopilot’s loaded with smart modes:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Fast, bite-sized profits—think Soros flair.</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Steady wins with less worry—Dalio’s cool-headed way.</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Big gains over time—Buffett’s long-game magic.</li>
        </ul>
        <p>It’s perfect for easy, reliable growth, and you can always switch to manual if you feel like getting hands-on later.</p>
        <p>What sounds like you?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot - Relax and Grow' },
                { code: 'Manual', message: 'Crypto Racetrack - Drive the Action' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>