<template>
	<div class="markdown prose">
        <p>Now, let’s talk about how hands-on you want to be with your trades:</p>
        <p>Would you prefer the thrill of taking full control—like a race car driver navigating every twist and turn—or would you rather sit back and let our autopilot system handle things for you?</p>
        <p>The autopilot program uses proven strategies inspired by legends like <span class="text-black01 font-weight-bold">Planetary Mode</span> (frequent small wins), <span class="text-black01 font-weight-bold">Stellar Mode</span> (balanced diversification), or <span class="text-black01 font-weight-bold">Galactic Mode</span> (large, strategic exits). It’s perfect for beginners and experienced traders alike who want consistent results without constant monitoring.</p>
        <p>We encourage everyone to start with autopilot—it’s a great way to build confidence and see steady growth. Once you’re ready, you can always take the wheel manually with a portion of your funds.</p>
        <p>Based on your inspiration, let’s find the perfect trading method for you. Which approach suits your style today? </p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'AUTOPILOT - Set It and Forget It: Hands-off wealth-building made simple.' },
                { code: 'Manual', message: 'CRYPTO RACETRACK - Hands-On and Tactical: Take the wheel and steer your crypto trades.' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>