<template>
    <div class="flex flex-col mt-10 gap-2xl @md:gap-3xl">
        <div class="@container w-full max-w-container">
            <div class="relative w-full">
                <div class="relative z-0 grid grid-cols-12 mt-lg @md:mt-xl">
                    <div class="col-span-12 @md:col-span-10 @md:col-start-2">
                        <div class="col-span-12 overflow-hidden">
                            <div class="w-full mx-auto ease-curve-c duration-normal transition-opacity max-w-container-desktop relative">
                                <div class="relative h-full w-full" style="aspect-ratio: 16 / 9;">
                                    <div class="h-full w-full">
                                        <div class="relative h-full w-full overflow-hidden rounded-md aspect-16/9 ease-curve-d duration-normal bg-transparent transition-[background]">
                                         <img cover src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/bca01b6f-dcfe-495b-f29a-5ac021302100/public"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="@container max-w-container grid w-full">
            <div class="mb-lg @container multi-columns:px-0 grid w-full grid-cols-12 max-w-container">
                <div class="col-span-12 @md:col-span-10 @md:col-start-2 @lg:col-span-8 @lg:col-start-3">
                    <div class="relative flex flex-col items-center text-center">
                        <div class="d-flex">
                            <div v-show="this.darkTheme == 0">
                                <Logo type="unNFTs" app="unNFTs" color="light" responsive="pc" size="150"></Logo>
                            </div>
                            <div v-show="this.darkTheme == 1" >
                                <Logo type="unNFTs" app="unNFTs" color="dark" responsive="pc" size="150"></Logo>
                            </div>
                        </div>
                        <h3 class="text_h3 scroll-mt-header-h max-w-[62.5rem] text-balance mt-md">Mint your original NFTs</h3>
                        <div class="mt-md">
                            <p class="col-span-12 text-balance"> As a revolutionary platform, Untrading combines a decentralized exchange (DEX) with an innovative NFT marketplace, compliant with the additional Future Rewards protocol.</p>
                        </div>
                        <h3 class="text_h3 scroll-mt-header-h max-w-[62.5rem] text-balance mt-md"> Or Wrap white-listed top NFT collections</h3>
                        <div class="mt-md">
                            <p class="col-span-12 text-balance"> Unlock the full potential of your digital collectibles while maintaining their security. With Untrading, your NFTs work harder for you.</p>
                        </div>
                        <div class="mt-md">
                            <Button href="https://testnet.untrading.org/unnfts" variant="primary" rounded="lg" color="black03" width="200" aria-label="Launch unNFTs">Launch unNFTs</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Logo from '@/components/common/Logo';
export default {
    data(){
        return {

        }
    },
    components: {  Logo },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* Default order for all items */
.order-first-mobile {
    order: 2;
  }
  
  /* On mobile devices, make this item first */
  @media only screen and (max-width: 600px) {
    .order-first-mobile {
      order: -1;
    }
  }
  @media only screen and (max-width: 1024px) {
    .order-first-mobile {
      order: -1;
    }
  }
</style>