<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Got it! Your inspiration: Dalio. “Diversification, balance, and risk-adjusted returns create lasting wealth.”</h3>
        <p >As an investor inspired by Dalio, you know that true wealth is built through collaboration, balance, and shared success. Untrading aligns with your philosophy by creating financial ecosystems where success is shared—not extracted:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Built-in Future Rewards (FRs): </span>Earn as assets grow, even after you sell.</li>
            <li><span class="text-black01 font-weight-bold">Decentralized & Transparent Profit-Sharing: </span>Fair systems that prioritize community over extraction.</li>
            <li><span class="text-black01 font-weight-bold">Stability & Growth: </span>A trading framework designed for long-term resilience and prosperity.</li>
        </ul>
        <p >With Untrading, you don’t just build wealth—you build ecosystems that thrive together. It’s investing, reimagined.</p>
    </div>
</template>