<template>
	<div class="markdown prose">
        <p>Great! You’re not here for quick flips. You invest in the long-term vision—weathering market cycles, ignoring short-term noise. But holding isn’t just about patience; it’s about endurance.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Bear markets test your conviction.</span></li>
            <li><span class="text-black01 font-weight-bold">Market stagnation makes selling tempting.</span></li>
            <li><span class="text-black01 font-weight-bold">You watch others profit while you wait.</span></li>
        </ul>
        <p>With <span class="text-black01 font-weight-bold">Untrading</span>, you don’t just hold—you evolve:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Wrap your assets </span>(BTC → unBTC).</li>
            <li><span class="text-black01 font-weight-bold">Sell unBTC, but keep earning Future Rewards (FRs).</span></li>
            <li><span class="text-black01 font-weight-bold">Rebuy and repeat—staying in the game while unlocking new upside.</span></li>
        </ul>
        <p >Long-term vision should come with long-term benefits. <span class="text-black01 font-weight-bold">It’s time to Untrade.</span></p>
        <br><hr>
        <p>Now that you’ve explored your trading style and discovered how Untrading can help you win, who inspires your trading journey today?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>