<template>
	<div class="markdown prose">
        <p>Now, let’s talk about how much control feels right for you:</p>
        <p>Some traders thrive on the thrill of manual trading—the excitement of being in charge, making quick decisions, and seizing opportunities as they arise. Others prefer to relax and let technology do the heavy lifting, ensuring their investments grow steadily without requiring constant attention.</p>
        <p>That’s why we offer two paths:</p>
        <ol class="ml-8 custom-ol">
            <li>
                <span class="text-black01 font-weight-bold">Autopilot System: </span>Powered by three proven modes inspired by top investors:
                <ul class="ml-8 custom-short-dash-ul">
                    <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Frequent, small wins (Soros-style).</li>
                    <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Balanced growth (Dalio-inspired).</li>
                    <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Large, strategic exits (Buffett-level patience).</li>
                </ul>
            </li>
            <li><span class="text-black01 font-weight-bold">Manual Trading: </span>For those who love the challenge of navigating markets themselves.</li>
        </ol>
        <p>We suggest starting with Autopilot—it’s a fantastic way to achieve predictable results while building confidence. When you’re ready, you can explore manual trading with a portion of your funds.</p>
        <p>What’s your choice?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Relax & Grow – Autopilot' },
                { code: 'Manual', message: 'Take Full Control – Manual Trading' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>