<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Awesome! Long-Term Wealth, Without Compromise</h3>
        <p >You don’t chase hype—you build wealth <span class="text-black01 font-weight-bold">slowly, steadily, and wisely.</span> But traditional markets force a trade-off: <span class="text-black01 font-weight-bold">sell and lose your upside, or hold and wait.</span></p>
        <h3 class="text-black01 font-weight-bold">Untrading removes that limitation:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Strategic Position Management</span></li>
            <li><span class="text-black01 font-weight-bold">Future Rewards Generation</span></li>
            <li><span class="text-black01 font-weight-bold">Legacy Wealth Protection</span></li>
            <li><span class="text-black01 font-weight-bold">Perpetual Growth Engine</span></li>
        </ul>
        <p >Why should selling mean missing out? <span class="text-black01 font-weight-bold">Invest smarter. Untrade.</span></p>
    </div>
</template>