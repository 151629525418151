<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Great! Trading’s Thrill, Without the Traps</h3>
        <p >High-speed trading promises big wins—but also relentless stress, market manipulations, and costly missteps. Charts only tell the past; your future profits shouldn’t stop when you sell.</p>
        <h3 class="text-black01 font-weight-bold">Enter Untrading:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Profits Beyond Selling: </span>Keep earning after you exit.</li>
            <li><span class="text-black01 font-weight-bold">Zero Fees, Zero Games: </span>No commissions, no hidden tricks.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs): </span>Stay connected to assets post-sale.</li>
        </ul>
        <p >Trade smarter. Earn longer. Join Untrading.</p>
        <br><hr>
		<p>You’ve unlocked your trading style and discovered how Untrading can boost your success. Who’s the rockstar inspiring your trading journey today? 🎸</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>