<template>
    <div class="flex flex-col gap-2xl @md:gap-3xl">
        <div class="@container w-full max-w-container">
            <div class="relative w-full">
                <div class="@container multi-columns:px-0 grid w-full grid-cols-12">
                    <div class="col-span-12 @md:col-span-10 @md:col-start-2 @lg:col-span-8 @lg:col-start-3">
                        <div class="relative flex flex-col items-center text-center">
                            <p class="text-meta mb-md gradient-underline-hover">
                                Powered by the
                                <a href="https://erc-5173.org" target="_blank" class="pointer text-secondary hero-underline-hover del-underline">
                                    <span class="gradient-text-hover">ERC-5173</span>
                                </a> 
                            Standard
                            </p>
                            <h1 class="text_h1 scroll-mt-header-h max-w-[62.5rem] text-balance">Win Together.</h1>
                            <h1 class="text_h1 scroll-mt-header-h max-w-[62.5rem] text-balance">Build States.</h1>
                            <div class="mt-md">
                                <p class="col-span-12 text-primary-100 @md:col-span-8 @md:col-start-3 text-balance">Untrading is the new economic foundation for community-driven wealth creation. At its core, untrading realizes the “win and help win” philosophy in DeFi.</p>
                                <p class="col-span-12 text-primary-100 @md:col-span-8 @md:col-start-3 text-balance">Profits continue after selling assets.</p>
                            </div>
                            <div class="mt-md min-h-sm flex justify-between">
                                <div class="gap-xs flex flex-row flex-wrap items-start justify-center items-center">
                                    <!-- <a href="https://untrading.medium.com/mastering-untradings-profit-expectancy-76166f6abcfb" target="_blank" type="button" class="button_root button_variant-ghost button_size-large button_variant_icon link_root" rel="noopener">
                                        <span class="gjcrSl" style="padding: 0px;"><span class="text-black01"></span>New: Profit Expectancy Setting</span><v-icon>mdi mdi-chevron-right</v-icon>
                                    </a> -->
                                    <Button  href="https://untrading.medium.com/mastering-untradings-profit-expectancy-76166f6abcfb" variant="primary" rounded="lg" height="52" color="black03" width="280" aria-label="New: Profit Expectancy Setting">New: Profit Expectancy Setting</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: { },
    created(){

    },
    mounted(){
        if (typeof WOW !== 'undefined') new WOW().init();
        if (typeof Splitting !== 'undefined') Splitting({ target: "[data-splitting]" });
    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>