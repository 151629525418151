<template>
	<div>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="3" lg="3" xl="3" xxl="2" class="d-flex flex-column px-0 r-m-t-16 n_inside  r-p-x-6 r-text-right">
                <div class="ga-2 d-flex flex-column">
                    <div class="hide-tablet">
                        <div class="float-right">
                            <div v-show="this.darkTheme == 0">
                                <Logo type="unNFTs" app="unNFTs" color="light" responsive="pc" size="150"></Logo>
                            </div>
                            <div v-show="this.darkTheme == 1" >
                                <Logo type="unNFTs" app="unNFTs" color="dark" responsive="pc" size="150"></Logo>
                            </div>
                        </div>
                    </div>
                    <div class="show-tablet">
                        <div v-show="this.darkTheme == 0">
                            <Logo type="unNFTs" app="unNFTs" color="light" responsive="pc" size="150"></Logo>
                        </div>
                        <div v-show="this.darkTheme == 1" >
                            <Logo type="unNFTs" app="unNFTs" color="dark" responsive="pc" size="150"></Logo>
                        </div>
                    </div>
                    <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                    <h3 class="fs-21-bold text-secondary">
                        Mint your original NFTs
                    </h3>
                    
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="text-tertiary fs-17">
                        As a revolutionary platform, Untrading combines a decentralized exchange (DEX) with an innovative NFT marketplace, compliant with the additional Future Rewards protocol.
                    </p>
                </div>
                <div class="ga-2 d-flex flex-column mt-16">
                    <h3 class="fs-21-bold text-secondary">
                        Or Wrap white-listed top NFT collections
                    </h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="text-tertiary fs-17">
                        Unlock the full potential of your digital collectibles while maintaining their security. With Untrading, your NFTs work harder for you.
                    </p>
                </div>
                <p class="mt-16 text-tertiary fs-17">Ready to build your Kingdom and trade smarter?</p>
                <div class="mt-16">
                    <!-- <a href="/unnfts" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                        <span class="text-tertiary" style="padding: 0px;">Launch unNFTs</span><v-icon class="text-tertiary">mdi mdi-chevron-right</v-icon>
                    </a> -->
                    <a href="https://testnet.untrading.org/unnfts" class="del-underline">
                        <button class="btn button_root button_size-default button_variant">Launch unNFTs</button>
                    </a>
                    
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="9" lg="9" xl="9" xxl="5" class="px-0 p-l-4 order-first-mobile">
                <div class="Spacer_root__uoSvA" style="--height:48px"></div>
                <v-img contain  class="nftImg" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/7cb7efd1-a404-422c-60f6-20ec246bb000/public" v-show="this.darkTheme == 1" ></v-img>
                <v-img contain  class="nftImg" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f6b5795e-d7e8-4362-d912-1710aa8c1600/public" v-show="this.darkTheme == 0"></v-img>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Logo from '@/components/common/Logo';
export default {
    data(){
        return {

        }
    },
    components: {  Logo },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* Default order for all items */
.order-first-mobile {
    order: 2;
  }
  
  /* On mobile devices, make this item first */
  @media only screen and (max-width: 600px) {
    .order-first-mobile {
      order: -1;
    }
  }
  @media only screen and (max-width: 1024px) {
    .order-first-mobile {
      order: -1;
    }
  }
</style>