<template>
	<div class="bg-f2f align-center">
        <v-container class="h-100">
            <v-card color="transparent" elevation="0" max-width="1400" class="mx-auto pt-7">
                <v-row no-gutters justify="center">
                    <!--------------------------- 同意规则 --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'AGREE_TO_RULES'">
                        <v-row no-gutters>
                            <v-col md="5" cols="12" sm="6" offset-md="1">
                                <Step :steps="steps" :action="action" class="ml-13 r-m-l-0"></Step>
                            </v-col>
                            <v-col cols="12" md="5" sm="6" class="r-m-t-8">
                                <v-icon size="28" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-11 r-m-l-0" @click="$router.back(-1)"></v-icon>
                                <div class="ml-11 r-m-l-0">
                                    <h1 class="sub-page-h1 mb-6 text-grey04">Wrap an NFT you own</h1>
                                </div>
                                <!--------------------------- unNFT info --------------------------->
                                <v-card max-width="462" color="transparent" class="ml-11 r-m-l-0" elevation="0" rounded="0">
                                    <h5 class="text_h6 text-primaryGrey">
                                        By uploading an item to this website, importing, minting or wrapping an unNFT, I represent and warrant that I am the owner of the item and have the right to upload, mint and wrap the unNFT to my wallet address. I also acknowledge that the untrading Protocol does not check or verify the copyright holders of the item. In the event that I do not own the copyright to the item or do not have the right to upload and mint the unNFT, I agree to indemnify and hold the Protocol and its affiliates harmless for any damages or losses resulting from my actions. I also acknowledge that any copyright disputes that may arise in the future are between myself and the third party, and the Protocol will not be held liable for any such disputes.
                                    </h5>
                                    <div class="mt-5">
                                        <v-checkbox v-model="agreeToRules" density="compact" class="my-checkbox font-weight-bold" color="primaryGrey">
                                            <template v-slot:label>
                                              <div>I have read and agree to the rules</div>
                                            </template>
                                        </v-checkbox>
                                    </div>
                                </v-card>
                                <div class="ml-11 mt-16 r-m-l-0">
                                    <Button variant="primary" :disabled="!agreeToRules" rounded="lg" color="black03" height="52" width="300" aria-label="Next" @click="action = 'OBTAIN_INFO'">Next</Button>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--------------------------- 选择文件 --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'OBTAIN_INFO'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <div class="ml-13 r-m-l-0">
                                    <div v-if="nft.file">
                                        <MintMedia :src="nft.file" @watchMediaFile="watchMediaFile"></MintMedia>
                                    </div>
                                    <div v-else>
                                        <el-upload class="mt-2 mb-10" ref="upload" disabled action="" accept="">
                                            <div :class="responsive == 'pc' ? 'upload ' : 'upload-m'"></div>
                                        </el-upload>
                                    </div>
                                    <Step :steps="steps" :action="action" class="mt-16"></Step>
                                </div>
                            </v-col>
                            <!--------------------------- Info--------------------------->
                            <v-col cols="12" md="5" sm="6">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'AGREE_TO_RULES'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 mt-3 ml-11 r-m-l-0">
                                    Wrap an NFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Obtain the Contract Info</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-primaryGrey mb-6">
                                        Upgrade an NFT you bought elsewhere!
                                    </h3>
                                    <h5 class="body-p text-primaryGrey">
                                        By wrapping and upgrading the NFTs you purchased on other platforms, you will become a originator on untrading and will receive new royalty payments and Future Rewards distributions. 
                                    </h5>
                                    <!-- 选择区块链 -->
                                    <h3 class="body-h4 mt-16 text-uppercase text-primaryGrey">Blockchain<span class="ml-1 text-red">*</span></h3>
                                    <h5 class="mt-3 mb-6">
                                        <span class="body-p text-primaryGrey">
                                            Choosing from supported blockchains
                                        </span>
                                    </h5>
                                    <v-select v-model="selectedBlockchain" :label="isDisabledBlockchain ? '' : 'Blockchain *'" :items="blockchains" item-title="blockchain" :variant="isDisabledBlockchain ? 'solo' : 'outlined'" density="comfortable" :disabled="isDisabledBlockchain" hide-details return-object @update:modelValue="updateSelectedBlockchain">
                                        <template v-slot:selection="{ item }" >
                                            <img class="mr-3" :src="item.raw.icon" width="24" />
                                            <span>{{ item?.raw?.blockchain }}</span>
                                        </template>
                                    </v-select>
                                    <!-- 选择 NFT -->
                                    <h3 class="body-h4 mt-16 mb-6 text-primaryGrey">Select an NFT<span class="ml-1 text-red">*</span></h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Select an NFT in your wallet from the drop down menu below.
                                    </h5>
                                    <v-select v-model="selectedNft" label="Select an NFT *" :items="ownedNfts" item-title="normalizedMetadata.name" height="100" return-object clearable variant="outlined" density="comfortable" :loading="ownedNftsLoading" hide-details @update:modelValue="updateSelectedNft"></v-select>
                                    <h3 class="body-h4 mt-16 mb-6 text-primaryGrey">NFT contract address<span class="ml-1 text-red">*</span></h3>
                                    <v-text-field v-model="nft.originalContractAddress" density="comfortable" variant="solo" hide-details readonly></v-text-field>
                                    <h3 class="body-h4 mt-16 mb-6 text-primaryGrey">NFT Token ID<span class="ml-1 text-red">*</span></h3>
                                    <v-text-field v-model="nft.originalTokenId" density="comfortable" variant="solo" hide-details readonly></v-text-field>
                                </v-card>
                                <!--------------------------- Confirm --------------------------->
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" rounded="lg" color="black03" height="52" width="300" :disabled="isDisabledObtainInfoButton" @click="action = 'EXTRACTED_DATA';updateSelectedNft(selectedNft)" aria-label="Confirm">Confirm</Button>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pb-16" v-if="action == 'EXTRACTED_DATA' || action == 'NEW_LICENSE' || action == 'REWARD_RATIOS' || action == 'WRAP' || action == 'PERMISSION_REQUEST' || action == 'APPROVE' || action == 'CONFIRM' || action == 'CREATED'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <MintMedia :src="nft.file" @watchMediaFile="watchMediaFile" class="ml-13 r-m-l-0"></MintMedia>
                                <Step :steps="steps" :action="action" class="mt-16 ml-13 r-m-l-0"></Step>
                            </v-col>
                            <!--------------------------- EXTRACTED_DATA --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'EXTRACTED_DATA'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'OBTAIN_INFO'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 mt-3 ml-11 r-m-l-0">
                                    Wrap an NFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Extracted data</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                   <h3 class="body-h4 mb-6 text-uppercase text-primaryGrey">
                                    Name<span class="ml-1 text-red">*</span>
                                   </h3>
                                   <v-text-field v-model="nft.name" variant="outlined" label="Name *" hide-details density="comfortable"></v-text-field>
                                   <h3 class="body-h4 mt-16 mb-6 text-uppercase text-primaryGrey">
                                    External link
                                   </h3>
                                   <v-text-field v-model="nft.externalUrl" variant="outlined" label="External link" hide-details density="comfortable"></v-text-field>
                                   <h3 class="body-h4 mt-16 mb-6 text-uppercase text-primaryGrey">
                                    Description
                                   </h3>
                                   <v-textarea v-model="nft.description" label="Description" variant="outlined" persistent-counter :counter="1000"></v-textarea>
                                </v-card>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-10" color="paleGreen" elevation="2" :rounded="0" v-if="false">
                                    <div class="mb-6">
                                        <span class="sidebar-h2 text-primaryGrey"></span>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 mb-6 text-uppercase text-primaryGrey">
                                     Properties
                                   </h3>
                                   <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Textual traits that show up as rectangles
                                   </h5>
                                   <v-row no-gutters>
                                        <v-col cols="2" v-for="n in 8" :key="n" class="ma-2">
                                            <v-text-field clearable v-model="nft.properties" variant="outlined" hide-details density="comfortable" disabled></v-text-field>
                                    </v-col>
                                   </v-row>
                                   <h3 class="body-h4 mb-6 mt-16 text-uppercase text-primaryGrey">
                                     Levels
                                   </h3>
                                   <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Numerical traits that show up as a progress bar
                                   </h5>
                                   <v-row no-gutters>
                                        <v-col cols="2" v-for="n in 8" :key="n" class="ma-2">
                                            <v-text-field clearable v-model="nft.levels" variant="outlined" hide-details density="comfortable" disabled></v-text-field>
                                    </v-col>
                                   </v-row>
                                   <h3 class="body-h4 mt-16 mb-6 text-uppercase text-primaryGrey">
                                     Stats
                                   </h3>
                                   <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Numerical traits that just show as numbers
                                   </h5>
                                   <v-row no-gutters>
                                        <v-col cols="2" v-for="n in 8" :key="n" class="ma-2">
                                            <v-text-field clearable v-model="nft.stats" variant="outlined" hide-details density="comfortable" disabled></v-text-field>
                                    </v-col>
                                   </v-row>
                                </v-card>
                                <!--------------------------- NEXT --------------------------->
                                <div class="ml-12 r-m-l-0 mt-16">
                                    <a class="del-underline gradient-left-red-purple-300">
                                        <v-btn rounded="0" width="300" elevation="2" height="66" color="button01" class="text-none text-grey05 body-p-small-b" :disabled="isDisabledExtractedDataButton" @click="action = 'NEW_LICENSE'" aria-label="Next">Next</v-btn>
                                    </a>
                                </div>
                            </v-col>
                            <!--------------------------- NEW_LICENSE --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'NEW_LICENSE'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'EXTRACTED_DATA'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 mt-3 ml-11">
                                    Wrap an NFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Additional Attributes</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <div class="mb-6">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">department<span class="ml-1 text-red">*</span></h3>
                                    </div>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        Choose one of the following department
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                As an NFT-framework-based investment platform, untrading.org is organized in four departments: Artwork Dept, Membership Dept, Collectible Dept and Crypto Dept.
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <div>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none body-p" :class="nft.department == 'Artwork' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Artwork'" aria-label="Artwork">Artwork</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none ml-2 body-p" :class="nft.department == 'Membership' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Membership'" aria-label="Membership">Membership</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none ml-2 r-m-l-0 body-p" :class="nft.department == 'Collectible' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Collectible'" aria-label="Collectible">Collectible</v-btn>
                                        <v-btn :rounded="0" width="128" height="66" class="text-none mt-2 r-m-l-2 body-p" disabled :class="nft.department == 'Cryptos' ? 'gradient-left-red-purple text-white' : 'text-primaryGrey'" @click="nft.department = 'Cryptos'" aria-label="Cryptos">Cryptos</v-btn>
                                    </div>
                                    <!-- ---------- Rarity ---------- -->
                                    <div class="mb-6 mt-16">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">Rarity</h3>
                                    </div>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        <span>The rarity rating of an unNFT is ultimately determined by the creator and the market. The criteria being used to evaluate it can vary. untrading allows you to assign the token's rarity.</span>
                                    </h5>
                                    <div class="mt-3">
                                        <v-select v-model="nft.rarity" label="Rarity" :items="rarityNames" variant="outlined" density="comfortable" hide-details clearable></v-select>
                                    </div>
                                    <!-- ---------- Sensitive ---------- -->
                                    <div class="mt-16">
                                        <h3 class="body-h4 text-uppercase text-primaryGrey">Explicit & Sensitive Content</h3>
                                    </div>
                                    <div class="mt-3 body-p mb-6 align-center d-flex">
                                        <span class="mr-10 text-primaryGrey">Set this item as explicit and sensitive content
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Setting your asset as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing untrading.org.
                                                </v-card>
                                            </v-menu>
                                        </span>
                                        <span class="font-weight-medium text-grey-darken-1">
                                            <v-switch v-model="nft.sensitiveContent" color="primary" density="comfortable" hide-details inset></v-switch>
                                        </span>
                                    </div>
                                </v-card>
                                <!-- ---------- License ---------- -->
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <span class="sidebar-h2 text-primaryGrey"></span>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">License<span class="ml-1 text-red">*</span></h3>
                                    <h5 class="mt-3 mb-6">
                                        <span class="body-p text-primaryGrey">
                                           Choosing from one of six NFT-specific licenses
                                           <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    We support the “Can’t Be Evil” NFT Licenses by a16z that are inspried by the guiding principle in Web3 and Creative Commons. You must choose one of the six licenses for your unNFT.
                                                </v-card>
                                            </v-menu>
                                        </span>
                                    </h5>
                                    <v-select v-model="nft.license" label="License *" :items="licenses" item-title="name" item-value="name" variant="outlined" density="comfortable" hide-details></v-select>
                                    <div class="mt-3 mb-6">
                                        <span class="body-p text-primaryGrey" v-for="license in licenses" :key="license.id">
                                           <span v-if="license.name == nft.license">{{ license.description }}</span>
                                        </span>
                                    </div>
                                </v-card>
                                <!--------------------------- NEXT --------------------------->
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" rounded="lg" color="black03" height="52" width="300" :disabled="isDisabledAdditionalAttributesButton" @click="action = 'REWARD_RATIOS'" aria-label="Next">Next</Button>
                                </div>
                            </v-col>
                            <!--------------------------- REWARD_RATIOS --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'REWARD_RATIOS'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'NEW_LICENSE'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-primaryGrey ml-11 r-m-l-0 mt-3">
                                    Wrap an NFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Reward Ratios</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h3 class="body-h4 text-uppercase text-primaryGrey">
                                        Total Reward Ratio <span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT. Recommended 35%.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    Once created, the Total Reward Ratio (f) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must wrap a new unNFT.
                                                </div>
                                                <div class="mt-2">
                                                    You may find yourself unable to sell your unNFT if this value is too low or too high. 
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <v-text-field v-model="nft.totalRewardRatio" label="Total Reward Ratio" type="number" density="comfortable" min="5" max="50" placeholder="Between 5% to 50%" variant="outlined" hide-details :reverse="Number(nft.totalRewardRatio) > 0">
                                        <template v-slot:append-inner>%</template>
                                    </v-text-field>
                                    <h3 class="mt-16 mb-6 body-h4 text-uppercase text-primaryGrey">
                                        Originator Reward Ratio (OR)<span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The percentage of the total Rewards available for distribution among the creator(s), or originator(s), and untrading.org (a 100/0 split). Recommended 40%.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    The Originators' Token (o-token) may be viewed and managed on the Dashboard. Each unNFT consists of 100 o-tokens, 70 of which are sent to the originator(s) and 30 to untrading.org (For a limited time, all o-tokens designed for untrading.org are waived).
                                                </div>
                                                <div class="mt-2">
                                                    On your Dashboard, you can transfer o-tokens to other creators or originators.
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <v-text-field v-model="nft.originatorRewardRatio" label="Originator Reward Ratio (OR)" density="comfortable" type="number" min="5" max="50" placeholder="Between 5% to 50%" variant="outlined" hide-details :reverse="Number(nft.originatorRewardRatio) > 0">
                                        <template v-slot:append-inner>%</template>
                                    </v-text-field>
                                    <h3 class="mt-16 mb-6 body-h4 text-uppercase text-primaryGrey">
                                        Number of Generations <span class="ml-1 text-red">*</span>
                                    </h3>
                                    <h5 class="mt-3 body-p mb-6 text-primaryGrey">
                                        The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit. Recommended 10.
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <div>
                                                    Once created, the Number of Generation (w) cannot be modified. It is forever recorded on the blockchain, so if you want to update it, you must wrap a new unNFT.
                                                </div>
                                                <div class="mt-2">
                                                    You may find yourself unable to sell your unNFT if this value is too low or too high. 
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </h5>
                                    <v-text-field v-model="nft.numberOfGenerations" label="Number of Generations" density="comfortable" type="number" min="5" max="20" placeholder="Between 5 to 20" variant="outlined" hide-details :reverse="Number(nft.numberOfGenerations) > 0"></v-text-field>
                                </v-card>
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" rounded="lg" color="black03" height="52" width="300" :disabled="isDisabledRewardRatiosButton" @click="gotoWrap()" aria-label="Next">Next</Button>
                                </div>
                            </v-col>
                            <!--------------------------- WRAP --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'WRAP'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'REWARD_RATIOS'" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 ml-11 r-m-l-0 mt-3">
                                    Wrap an NFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <div class="mb-6">
                                        <h2 class="sidebar-h2 text-primaryGrey">Wrap Your unNFT</h2>
                                    </div>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                        Finallze your unNFT by wrapping it on the blockchain. Once you wrap your unNFT, it cannot be updated or changed.
                                    </h5>
                                </v-card>
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" :loading="wrapLoading" rounded="lg" color="black03" height="52" width="300" @click="confirmUnNftInfo" aria-label="Wrap">Wrap</Button>
                                </div>
                            </v-col>
                            <!--------------------------- Permission request || Waiting for Approval --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'PERMISSION_REQUEST' || action == 'APPROVE'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'WRAP';wrapLoading = false" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <h1 class="sub-page-h1 mb-6 text-grey04 ml-11 r-m-l-0 mt-3">
                                    Wrap an NFT you own
                                </h1>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <i class="iconfont icon-refresh text-primaryGrey"></i>
                                    <h2 class="sidebar-h2 mt-5 text-primaryGrey">
                                        <span v-if="action == 'PERMISSION_REQUEST'">NFT token spending permission request</span>
                                        <span v-if="action == 'APPROVE'">Waiting for Approval...</span>
                                    </h2>
                                    <v-divider class="my-7"></v-divider>
                                    <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                        <p>Allow access to and transfer of your NFT?</p><br/>
                                        <p>This allows a third party to access and transfer the following NFTs without further notice until you revoke its access.</p><br/>
                                        <p>Approved asset: {{ (selectedNft != null && selectedNft.normalizedMetadata != null) ? selectedNft.normalizedMetadata.name : '' }}(#{{ selectedNft != null ? selectedNft.tokenId : '' }})</p><br/>
                                        <p>Granted to: Contract({{ $tools.privacyField(nft.contractAddress) }})</p>
                                    </h5>
                                </v-card>
                                <div class="ml-12 mt-16 r-m-l-0">
                                    <Button variant="primary" :loading="wrapLoading" rounded="lg" color="black03" height="52" width="300" @click="confirmUnNftInfo" aria-label="Wrap">Wrap!</Button>
                                </div>
                            </v-col>
                            <!--------------------------- New unNFT token minting request --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'CONFIRM'">
                                <v-icon size="28" class="ml-11 r-m-l-0 text-primaryGrey gradient-text-hover" @click="action = 'WRAP';wrapLoading = false" icon="mdi:mdi-chevron-left-circle"></v-icon>
                                <div class="sub-page-h1 mb-6 text-primaryGrey ml-11 r-m-l-0 mt-3">
                                    Wrap an NFT you own
                                </div>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                     <i class="iconfont icon-refresh text-primaryGrey"></i>
                                     <div class="sidebar-h2 mt-5 text-primaryGrey">New unNFT token minting request...</div>
                                    <v-divider class="my-7"></v-divider>
                                    <div class="mt-3 body-p mb-16 text-primaryGrey">
                                        Confirm this transaction in your wallet. Gas will also need to be approved afterwards. The gas fee is the cost of interacting with the blockchain. In no way are they created or collected by untrading.org.
                                    </div>
                                </v-card>
                                <div class="ml-12 mt-8 r-m-l-0">
                                    <Button variant="primary" :loading="wrapLoading" rounded="lg" color="black03" height="52" width="300" @click="confirmUnNftInfo" aria-label="Wrap">Wrap!</Button>
                                </div>
                            </v-col>
                            <!--------------------------- Created --------------------------->
                            <v-col md="5" cols="12" sm="6" v-if="action == 'CREATED'">
                                <div class="sub-page-h1 mb-6 text-primaryGrey ml-11 r-m-l-0">
                                    Wrap an NFT you own
                                </div>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                     <i class="iconfont icon-refresh text-primaryGrey"></i>
                                    <div class="sidebar-h2 mt-5 text-primaryGrey">Your unNFT is being created...</div>
                                    <v-divider class="my-7"></v-divider>
                                    <div class="body-p mb-16 text-primaryGrey">
                                        The unNFT is being wrapped on the blockchain.
                                    </div>
                                    <div class="gradient-underline-hover">
                                        <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/tx/' + nft.transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p" target="_blank">
                                            <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ selectedBlockchain.blockchainExplorer }}
                                        </a>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--------------------------- Completion --------------------------->
                    <v-col cols="12" class="pb-16" v-if="action == 'COMPLETION'">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1">
                                <UnNftMedia :nftId="nftId" class="ml-13 r-m-l-0"></UnNftMedia>
                            </v-col>
                            <v-col md="5" cols="12" sm="6" class="r-m-t-16">
                                    <div class="sub-page-h1 mb-6 text-primaryGrey ml-11 r-m-l-0">
                                    Wrap an NFT you own
                                </div>
                                <v-card max-width="560" class="ml-12 r-m-l-0 pa-8 mt-8" color="paleGreen" elevation="2" :rounded="0">
                                    <i class="mb-5 iconfont icon-complete text-primaryGrey"></i>
                                    <div class="sidebar-h2 mt-5 text-primaryGrey">Your unNFT has been wrapped !</div>
                                    <v-divider class="my-7"></v-divider>
                                    <div class="body-p text-primaryGrey">
                                        Congratulations! Your artwork has offically been wrapped as an unNFT on the Polygon (Ethereum, Arbitrum One) Network to your set.
                                    </div>
                                    <div class="mt-5 body-p text-primaryGrey">
                                        Now it’s time to list it for sale on untrading!
                                    </div>
                                </v-card>
                                <div class="ml-12 r-m-l-0 mt-8">
                                    <div class="d-flex justify-space-between" :class="responsive == 'mobile' ? ' flex-column' : ''">
                                        <!-- <router-link :to="'/unnft/list/' + nftId" class="del-underline gradient-left-red-purple-200">
                                            <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="66" aria-label="Sell">Sell</v-btn>
                                        </router-link> -->
                                        <Button variant="primary" :to="'/unnft/list/' + nftId" rounded="lg" color="black03" width="200" aria-label="Sell">Sell</Button>
                                        <!-- <router-link :to="$tools.getUnNFTUrl(selectedBlockchain.blockchain, nftId)" class="del-underline gradient-left-red-purple-200 r-m-t-4">
                                            <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="66" aria-label="View">View</v-btn>
                                        </router-link> -->
                                        <Button variant="secondary" :to="$tools.getUnNFTUrl(selectedBlockchain.blockchain, nftId)" rounded="lg" class="r-m-t-4" color="black01" width="200" aria-label="View">View</Button>
                                    </div>
                                    <Step :steps="steps" :action="action" class="mt-16"></Step>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 弹窗组件 -->
        <!-- Loading media file... -->
        <v-dialog v-model="loadingMediaFileDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-progress-linear indeterminate bg-opacity="1" bg-color="paleYellow" color="primary"></v-progress-linear>
            <v-card class="pa-8 bg-paleYellow" :rounded="0">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <div class="sidebar-h2 text-primaryGrey">Loading media file...</div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <div class="body-p mb-16 text-primaryGrey">The InterPlanetary File System, known as IPFS, is a peer-to-peer network for sharing data in a distributed file system.</div>
                            <div class="body-p gradient-underline-hover">
                                <a href="https://ipfs.io" aria-label="Ipfs" target="_black" class="gradient-text-hover text-primaryGrey del-underline">Learn about IPFS</a><v-icon class="ml-2 text-primaryGrey" icon="fa-solid fa-arrow-right-long" size="14"></v-icon>
                            </div>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" width="200" height="48" color="button01" class="text-none text-grey05 body-p-small-b" @click="reload" aria-label="Interrupt upload">Interrupt upload</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 无效的媒体文件弹窗组件 -->
        <v-dialog v-model="invalidMediaFileDialog" persistent content-class="v-echarts-dialog" width="462">
            <v-card class="pa-8" color="pinkTone" :rounded="0">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <div class="sidebar-h2 text-primaryGrey">Invalid media file</div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <div class="body-p text-primaryGrey">{{ uploadErrorMessage }}</div>
                            <div class="body-p mb-16 text-primaryGrey">Please select a supported NFT to wrap!</div>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="OK" @click="closeInvalidMediaFileDialog">OK</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 保存草稿 -->
        <SaveDraft :show="saveDraftDialog" @watchSaveDraft="watchSaveDraft"></SaveDraft>
        <!-- 确认信息弹窗 -->
        <ConfirmUnNftInfoDialog :show="confirmUnNftInfoDialog" :nft="nft" @watchConfirmUnNftInfoDialog="watchConfirmUnNftInfoDialog"></ConfirmUnNftInfoDialog>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose" @approveTransactionClose="approveTransactionClose"></MetaMask>
        <Reown ref="Reown" @transactionClose="transactionClose" @approveTransactionClose="approveTransactionClose"></Reown>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import TestData from './data/test-data.json'
import IPFSAPI from '@/api/ipfs.js'
import SetAPI from '@/api/set.js'
import NFTAPI from '@/api/nft.js'
import NFTRarityAPI from '@/api/nft-rarity.js'
import MoralisAPI from '@/api/moralis.js'
import UnNftMedia from '@/components/nft/UnNftMedia.vue'
import MintMedia from '@/components/nft/MintMedia.vue'
import SaveDraft from '@/components/nft/SaveDraft.vue'
import ConfirmUnNftInfoDialog from '@/components/nft/ConfirmUnNftInfoDialog.vue'
import { Web3 } from "web3";
export default {
    data(){
        return {
            // 操作
            action: 'AGREE_TO_RULES',
            // 步骤
            steps: [
                { title: 'Agree to rules', codes: ['AGREE_TO_RULES'] },
                { title: 'Obtain contract info', codes: ['OBTAIN_INFO'] },
                { title: 'Display extracted data', codes: ['EXTRACTED_DATA'] },
                { title: 'Select a new license', codes: ['NEW_LICENSE'] },
                { title: 'Set reward ratios', codes: ['REWARD_RATIOS'] },
                { title: 'Wrap your unNFT', codes: ['WRAP'] },
                { title: 'Confirm in your wallet', codes: ['PERMISSION_REQUEST', 'APPROVE', 'CONFIRM', 'CREATED'] },
                { title: 'Completion', codes: ['COMPLETION'] }
            ],
            // 同意规则
            agreeToRules: false,
            // 查询拥有的 NFT 加载中
            ownedNftsLoading: false,
            // 拥有的 NFT 集合
            ownedNfts: [],
            // 禁用区块链
            isDisabledBlockchain: false,
            // 选中的区块链
            selectedBlockchain: {},
            // 选中的 NFT 对象
            selectedNft: null,
            // 稀有度名称集合
            rarityNames: [],
            // 创建对象
            nft:{
                // 读取
                originalContractAddress: null,
                originalTokenId: null,
                properties: null,
                levels: null,
                stats: null,
                // 输入
                setId: this.$route.query.setId,
                name: null,
                externalUrl: null,
                description: null,
                license: null,
                image: null,
                file: null,
                ipfsFileUrl: null,
                ipfsCid: null,  // ipfsCid 用于删除时使用
                totalRewardRatio: null,
                originatorRewardRatio: null,
                numberOfGenerations: null,
                blockchain: null,
                contractAddress: null,
                transactionHash: null,
                department: null,
                rarity: null,
                sensitiveContent: false,
                metadata: null
            },
            // 元数据的 IPFS 链接
            metadataIpfsUrl: null,
            // 铸造加载中
            wrapLoading: false,
            // 创建成功后的平台主键
            nftId: null,
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 保存草稿弹窗
            saveDraftDialog: false,
            // 确认信息弹窗
            confirmUnNftInfoDialog: false,
            // 路由要去的地方
            routeTo: null,
            // 加载媒体文件弹窗
            loadingMediaFileDialog: false,
            // 无效的媒体文件弹窗
            invalidMediaFileDialog: false,
            // 上传失败信息
            uploadErrorMessage: null,
            // 批准交易哈希
            approveTransactionHash: null,
        }
    },
    components: { UnNftMedia, MintMedia, SaveDraft, ConfirmUnNftInfoDialog },
    created(){
        // 加载缓存
        this.loadCache();
        // 获取所有的稀有度名称集合
        this.getRarityNames();
        // 查询套装
        this.getSetById(this.nft.setId);
        // 监听
        this.$bus.on('callGetApproveTransactionReceipt', data => {
            if (data.type == "NFT_WRAP") {
                this.callGetApproveTransactionReceipt(data.data);
            }
        })
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "NFT_WRAP") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user', 'walletType', 'metaMask', 'licenses', 'blockchains','responsive']),
        // 禁用获得信息按钮
        isDisabledObtainInfoButton() {
            return !this.nft.blockchain || !this.nft.originalContractAddress || !this.nft.originalTokenId || !this.nft.file;
        },
        // 禁用提取信息按钮
        isDisabledExtractedDataButton() {
            return !this.nft.name || (this.nft.description != null && this.nft.description.length > 1000);
        },
        // 禁用添加属性按钮
        isDisabledAdditionalAttributesButton() {
            return !this.nft.department || !this.nft.license;
        },
        // 禁用奖励比例按钮
        isDisabledRewardRatiosButton() {
            let totalRewardRatio = Number(this.nft.totalRewardRatio);
            if(totalRewardRatio < 5 || totalRewardRatio > 50) {
                return true;
            }
            let originatorRewardRatio = Number(this.nft.originatorRewardRatio);
            if(originatorRewardRatio < 5 || originatorRewardRatio > 50) {
                return true;
            }
            let numberOfGenerations = Number(this.nft.numberOfGenerations);
            if(numberOfGenerations < 5 || numberOfGenerations > 20) {
                return true;
            }
            return false;
        },
    },
    watch:{
        // 监听步骤做缓存
        action(newVal, oldVal) {
            // 不是最后一步时候缓存，最后一步不缓存
            if(this.steps[this.steps.length - 1].codes.indexOf(newVal) == -1) {
                localStorage.setItem('untrading-wrap-step', newVal)
            }
        },
        // 监听参数变化做缓存
        nft: {
            handler(newVal, oldVal) {
                localStorage.setItem('untrading-wrap', JSON.stringify(newVal))
            },
            deep: true
        },
        'user.wallet': {
            handler(newVal, oldVal) {
                // 根据钱包地址分页查询所有的 NFTs
                this.getNFTsByWallet();
            },
            deep: true,
            immediate:true
        }
    },
    methods: {
        // 刷新页面
        reload() {
            location.reload();
        },
        // 加载缓存
        loadCache() {
            let action = localStorage.getItem('untrading-wrap-step');
            if(action) {
                this.action = action;
            }
            let nft = localStorage.getItem('untrading-wrap');
            if(nft) {
                // 更新数据，同时更新为新的套装主键
                let cacheNft = JSON.parse(nft);
                cacheNft.setId = this.nft.setId;
                this.nft = cacheNft;
                // 查询套装
                this.getSetById(this.nft.setId);
                // 选中的区块链
                this.selectedBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 根据钱包地址分页查询所有的 NFTs
                this.getNFTsByWallet();
            } else {
                localStorage.removeItem('untrading-wrap-step');
                this.action = 'AGREE_TO_RULES';
            }
            // 如果当前正在 CREATED 则查询交易收据
            if(this.action == 'CREATED') {
                this.getTransactionReceipt();
            }
        },
        // 更新选择的区块链
        updateSelectedBlockchain(item) {
            //  更新区块链
            this.selectedBlockchain = item;
            this.nft.blockchain = item.blockchain;
            this.nft.originalContractAddress = null;
            this.nft.originalTokenId = null;
            this.nft.file = null;
            this.nft.contractAddress = item.unNFTContract;
            // 所拥有的 NFTs
            this.ownedNfts = [];
            // 重新查询 NFTs 数据
            this.getNFTsByWallet();
        },
        // 更新选择的 NFT
        updateSelectedNft(selectedNft) {
            this.selectedNft = selectedNft;
            if(!selectedNft) {
                selectedNft = {
                    normalizedMetadata: {}
                };
            }
            this.nft.originalContractAddress = selectedNft.tokenAddress;
            this.nft.originalTokenId = selectedNft.tokenId;
            this.nft.name = selectedNft.normalizedMetadata.name;
            this.nft.description = selectedNft.normalizedMetadata.description;
            this.nft.image = selectedNft.normalizedMetadata.image;
            this.nft.file = selectedNft.normalizedMetadata.file;
            this.nft.externalUrl = selectedNft.normalizedMetadata.externalLink;
        },
        // 根据主键查询套装
        async getSetById(setId) {
            if(!setId) {
                return;
            }
            let res = await SetAPI.getSetById(setId);
            let data = res.data;
            if(data.success) {
                this.isDisabledBlockchain = true;
                // 套装的区块链就是 NFT 的区块链
                this.nft.blockchain = data.data.blockchain;
                // 选中的区块链
                this.selectedBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0];
                // 合约地址
                this.nft.contractAddress = this.selectedBlockchain.unNFTContract;
            } else {
                this.isDisabledBlockchain = false;
                this.nft.setId = null;
                this.nft.blockchain = null;
                this.nft.contractAddress = null;
                this.selectedBlockchain = {};
            }
        },
        // 根据钱包地址分页查询所有的 NFTs
        async getNFTsByWallet() {
            if(this.selectedBlockchain.blockchain == null || this.user.wallet == null) {
                return;
            }
            this.selectedNft = null;
            this.ownedNftsLoading = true;
            // 下一次分页的光标
            let cursor = null;
            while(true) {
                // this.user.wallet
                // '0x8d894500675C860D86619cC77742b8Dca7eF74e2'
                let res = await MoralisAPI.getNFTsByWallet(this.selectedBlockchain.blockchain, this.user.wallet, cursor);
                let data = res.data;
                // let data = TestData;
                if(data.success) {
                    // 所拥有的 NFTs
                    this.ownedNfts = [...this.ownedNfts, ...data.data.records];
                    // 当前选中的 NFT
                    this.selectedNft = this.ownedNfts.filter(nft => nft.tokenAddress == this.nft.originalContractAddress && nft.tokenId == this.nft.originalTokenId)[0];
                    // 分页光标
                    cursor = data.data.countId;
                } else {
                    break;
                }
                // 光标为 null,停止循环
                if(!cursor) {
                    break;
                }
            }
            // 如果没有数据，那已经填入的参数也恢复初始
            if(this.selectedNft == null || this.selectedNft.tokenAddress == null) {
                // 更新选择的 NFT
                this.updateSelectedNft(null);
            } 
            this.ownedNftsLoading = false;
        },
        // 上传 URL 到 IPFS
        async uploadUrl() {
            // 加载媒体文件
            this.loadingMediaFileDialog = true;
            let paramsData = {
                url: this.selectedNft.normalizedMetadata.file,
                name: this.nft.name
            };
            let res = await IPFSAPI.uploadUrl(paramsData);
            let data = res.data;
            if(data.success) {
                this.nft.file = data.data.fileUrl;
                this.nft.ipfsFileUrl = data.data.httpUrl;
                this.nft.image = data.data.ipfsUrl;
                this.nft.ipfsCid = data.data.cid;
                // 关闭弹窗
                this.loadingMediaFileDialog = false;
                return true;
            } else {
                // this.$store.dispatch('snackbarMessageHandler', data.message);
                // 上传失败
                if(data.data) {
                    this.uploadErrorMessage = data.data[0];
                } else {
                    this.uploadErrorMessage = data.message;
                }
                this.invalidMediaFileDialog = true;
                // 关闭弹窗
                this.loadingMediaFileDialog = false;
                return false;
            }
        },
        // 监听媒体文件
        watchMediaFile(invalidMediaFileDialog) {
            // 打开弹窗
            this.invalidMediaFileDialog = invalidMediaFileDialog;
            this.selectedNft = null;
            this.nft.originalContractAddress = null;
            this.nft.originalTokenId = null;
            this.nft.file = null;
        },
        // 关闭无效媒体文件弹窗
        closeInvalidMediaFileDialog() {
            // 关闭弹窗
            this.invalidMediaFileDialog = false
            // 
            this.action = 'OBTAIN_INFO';
        },
        // 获取所有的稀有度名称集合
        async getRarityNames() {
            let res = await NFTRarityAPI.getRarityNames();
            let data = res.data;
            if(data.success) {
                this.rarityNames = data.data;
            }
        },
        // 下一步
        gotoWrap() {
            let totalRewardRatio = Number(this.nft.totalRewardRatio);
            if(totalRewardRatio < 5 || totalRewardRatio > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Total Reward Ratio");
                return;
            }
            let originatorRewardRatio = Number(this.nft.originatorRewardRatio);
            if(originatorRewardRatio < 5 || originatorRewardRatio > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Originator Reward Ratio");
                return;
            }
            let numberOfGenerations = Number(this.nft.numberOfGenerations);
            if(numberOfGenerations < 5 || numberOfGenerations > 20) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild Number Of Generations");
                return;
            }
            this.action = 'WRAP';
        },
        // 确认信息
        confirmUnNftInfo() {
            if(!this.nft.blockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            if(!this.nft.contractAddress) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild contract address");
                return;
            }
            this.confirmUnNftInfoDialog = true;
        },
        // 批准
        async approve() {
            // 加载中
            this.wrapLoading = true;
            // 权限请求
            this.action = 'PERMISSION_REQUEST';
            let web3 = new Web3(new Web3.providers.HttpProvider(this.selectedBlockchain.RPCUrl));
            // 编码参数列表
            try {
                // 合约方法
                let method = web3.eth.abi.encodeFunctionSignature('approve(address,uint256)');
                // 将参数编码
                let param = web3.eth.abi.encodeParameters(['address', 'uint256'], [this.selectedBlockchain.unNFTContract, this.nft.originalTokenId]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].approve(this.nft.blockchain, this.user.wallet, this.nft.originalContractAddress, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.wrapLoading = false;
                    // 失败后回到上一步
                    this.action = 'WRAP';
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.wrapLoading = false;
                // 失败后回到上一步
                this.action = 'WRAP';
            }
        },
        // 封装NFT
        async wrapNFT() {
            // 加载中
            this.wrapLoading = true;
            // 上传 URL 到 IPFS
            let uploaded = await this.uploadUrl();
            // 文件加载失败
            if(!uploaded) {
                this.wrapLoading = false;
                return;
            }
            // 确认中
            this.action = 'CONFIRM';
            // 创建元数据结构
            let metadata = {
                originator: this.user.wallet,
                name: this.nft.name,
                externalUrl: this.nft.externalUrl,
                description: this.nft.description,
                license: this.nft.license,
                image: this.nft.image,
                totalRewardRatio: this.nft.totalRewardRatio,
                originatorRewardRatio: this.nft.originatorRewardRatio,
                numberOfGenerations: this.nft.numberOfGenerations,
                blockchain: this.nft.blockchain,
                department: this.nft.department,
                sensitiveContent: this.nft.sensitiveContent
            };
            // 上传元数据到 IPFS
            let res = await IPFSAPI.uploadJson(metadata);
            let data = res.data;
            if(data.success) {
                // 更新 metadata 链接
                this.nft.metadata = data.data.httpUrl;
                this.metadataIpfsUrl = data.data.ipfsUrl;
                let web3 = new Web3(new Web3.providers.HttpProvider(this.selectedBlockchain.RPCUrl));
                // 编码参数列表
                try {
                    // 合约方法
                    let method = web3.eth.abi.encodeFunctionSignature('wrap(address,uint256,uint8,uint256,uint256,uint8,string)');
                    let totalRewardRatioWei = web3.utils.toWei((Number(this.nft.totalRewardRatio) / 100) + '', 'ether');
                    let originatorRewardRatioWei = web3.utils.toWei((Number(this.nft.originatorRewardRatio) / 100) + '', 'ether');
                    // 执照的下标
                    let license = this.licenses.filter(license => license.name == this.nft.license)[0].id;
                    // 将参数编码
                    let param = web3.eth.abi.encodeParameters(['address', 'uint256', 'uint8', 'uint256', 'uint256', 'uint8', 'string'], [this.nft.originalContractAddress, this.nft.originalTokenId, this.nft.numberOfGenerations, totalRewardRatioWei, originatorRewardRatioWei, license, this.metadataIpfsUrl]).substring(2);
                    // 组装数据
                    let data = method + param;
                    // 调起钱包发送交易
                    if(this.walletType){
                        this.$refs[this.walletType].sendTransaction(this.nft.blockchain, this.user.wallet, this.selectedBlockchain.unNFTContract, data);
                    } else {
                        // 错误通知
                        this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                        // 加载完成
                        this.wrapLoading = false;
                    }
                } catch (error) {
                    console.error(error);
                    this.$store.dispatch('snackbarMessageHandler', error);
                    // 加载完成
                    this.wrapLoading = false;
                    // 失败后回到上一步
                    this.action = 'WRAP';
                }
            } else {
                // 错误通知
                this.$store.dispatch('snackbarMessageHandler', data.message);
                // 加载完成
                this.wrapLoading = false;
                // 失败后回到上一步
                this.action = 'WRAP';
            }
        },
        // 批准交易关闭：成功/失败
        async approveTransactionClose(success, transactionHash) {
            this.approveTransactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 批准
                this.action = 'APPROVE';
                // 查询批准交易收据
                this.getApproveTransactionReceipt();
            } else {
                // 失败后回到上一步
                this.action = 'WRAP';
                // 加载完成
                this.wrapLoading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.nft.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                await this.wrap();
                // 成功后进入下一步
                this.action = 'CREATED';
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 失败后回到上一步
                this.action = 'WRAP';
            }
            // 加载完成
            this.wrapLoading = false;
        },
        // 封装
        async wrap() {
            let res = await NFTAPI.wrap(this.nft);
            let data = res.data;
            // 清除缓存
            localStorage.removeItem('untrading-wrap-step');
            localStorage.removeItem('untrading-wrap');
            if(data.success) {
                this.nftId = data.data;
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 查询批准交易收据
        getApproveTransactionReceipt() {
            // 轮训查询交易
            this.transactionReceiptInterval = setInterval(() => {
                let params = {
                    type: "NFT_WRAP",
                    blockchain: this.nft.blockchain,
                    transactionHash: this.approveTransactionHash
                }
                this.$bus.emit('emitGetApproveTransactionReceipt', params);
            }, 3000)
        },
        // 回调批准交易收据
        callGetApproveTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            console.log(data);
            if(data) {
                if(data.status) {
                    // 清除定时器
                    clearInterval(this.transactionReceiptInterval);
                    // 封装NFT
                    this.wrapNFT();
                } else {
                    // 交易失败
                    this.$store.dispatch('snackbarMessageHandler', "Transaction Failed!");
                    // 失败后回到上一步
                    this.action = 'WRAP';
                    // 加载完成
                    this.wrapLoading = false;
                }
            }
        },
        // 查询交易收据
        getTransactionReceipt() {
            // 轮训查询交易
            this.transactionReceiptInterval = setInterval(() => {
                let params = {
                    type: "NFT_WRAP",
                    blockchain: this.nft.blockchain,
                    transactionHash: this.nft.transactionHash
                }
                this.$bus.emit('emitGetTransactionReceipt', params);
            }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                if(data.status) {
                    // 清除定时器
                    clearInterval(this.transactionReceiptInterval);
                    // 无论交易成功还是失败，都转到下一步
                    this.action = 'COMPLETION';
                    // 清除缓存
                    localStorage.removeItem('untrading-wrap-step');
                    localStorage.removeItem('untrading-wrap');
                } else {
                    // 交易失败
                    this.$store.dispatch('snackbarMessageHandler', "Transaction Failed!");
                    // 失败后回到上一步
                    this.action = 'WRAP';
                    // 加载完成
                    this.wrapLoading = false;
                }
            }
        },
        // 监听保存草稿
        watchSaveDraft(save) {
            this.saveDraftDialog = false;
            // 不保存
            if(!save) {
                // 删除文件
                this.deleteFile(this.nft.ipfsCid);
                // 清除缓存
                localStorage.removeItem('untrading-wrap-step');
                localStorage.removeItem('untrading-wrap');
            }
            if(this.routeTo) {
                this.$router.push(this.routeTo.fullPath);
            }
        },
        // 监听确认信息弹窗
        watchConfirmUnNftInfoDialog(confirmed) {
            this.confirmUnNftInfoDialog = false;
            if(confirmed) {
                this.approve();
            }
        },
        // 删除文件
        async deleteFile(cid) {
            // 发出删除请求
            await IPFSAPI.delete(cid);
        }
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        // 如果有没有步骤数据，则本身无缓存，不提示
        if(!localStorage.getItem('untrading-wrap-step')) {
            next();
        }
        if(this.routeTo) {
            next();
        } else {
            this.routeTo = to;
            this.saveDraftDialog = true;
            // 不加 next(); 就会阻止离开页面的
        }
    }
}
</script>
<style scoped>
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
.upload{
    width: 414px;
    height: 414px;
    border: 3px dashed #7b7b7b;
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    cursor: default;
}
:deep(.v-echarts-dialog){
    width: auto;
}
::v-deep .my-checkbox .v-label {
 font-size: 14px!important;
}
</style>