<template>
    <div>
        <div class="markdown prose">
            <p>Smart move! Autopilot is perfect for consistent results and learning the ropes before diving into manual trading.</p>
            <p>Here’s what happens next:</p>
            <ul class="ml-8 custom-ul">
                <li>Choose your preferred style: <span class="text-black01 font-weight-bold">Planetary</span> (frequent small wins), <span class="text-black01 font-weight-bold">Stellar</span> (balanced diversification), or <span class="text-black01 font-weight-bold">Galactic</span> (large strategic exits).</li>
                <li>Sit back as our system executes trades aligned with your goals.</li>
                <li>Watch your investments grow steadily over time.</li>
            </ul>
            <!-- 响应操作 -->
            <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
            <br><hr>
            <p class="text-black01 font-weight-bold">Ready to Untrade?</p>
            <p class="font-italic">Get your customized Untrading solutions and start earning beyond selling.</p>
            <div class="mt-6 flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                <Button variant="primary" rounded="xl" color="black03" height="40" width="300" aria-label="Request Autopilot Now" @click="requestAutopilotDialog = true">
                    <span class="px-4">Request Autopilot Now<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
                <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over" @click="$emit('startOver')">
                    <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>