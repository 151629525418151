<template>
	<div class="markdown prose">
        <p>Got it! Before you proceed, consider this market wisdom:</p>
        <p>Warning Signs of Unsustainable Gains:</p>
        <ul class="ml-8 custom-ul">
            <li>Promises of specific return percentages</li>
            <li>Urgency to "buy now"</li>
            <li>Claims of "guaranteed" profits</li>
            <li>Pressure to bring in others</li>
        </ul>
        <p>The Untrading Protocol Solution:</p>
        <ol class="ml-8 custom-ol">
            <li>Participatory Value Amplification</li>
            <li>Post-Exit Reward Mechanisms</li>
            <li>Anti-Manipulation Safeguards</li>
            <li>Continuous Earning Potential</li>
        </ol>
        <p>Technical Implementation:</p>
        <ul class="ml-8 custom-ul">
            <li>Smart contract-based position tracking</li>
            <li>Automated Future Rewards distribution</li>
            <li>Real-time value assessment algorithms</li>
            <li>Zero-fee transaction processing</li>
        </ul>
        <p>Risk Mitigation Through Innovation: Traditional risks like stop hunts and sudden reversals are countered by our revolutionary approach to market participation.</p>
        <p>Transform your trading strategy with Untrading—where technical analysis meets sustained value creation.</p>
        <br><hr>
        <p>Ready to learn how successful traders actually build wealth?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore sustainable trading:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Understanding real market dynamics</li>
                            <li>Building lasting wealth</li>
                            <li>Protecting your assets</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="180" aria-label="Start Learning">
                                <span class="px-4">Start Learning<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" class="ml-6" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>We understand. Untrading is designed for those seeking sustainable success through proven methods. If you change your approach, we'll be here to help.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="280" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes' },
                { code: 'No', message: 'No' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>