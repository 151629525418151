<template>
    <div class="max-w-container @container multi-columns:px-0 w-full">
        <div class="bg-f5f5f5-1f1f1f py-3xl grid grid-cols-12 rounded-md">
            <div class="px-2xs @md:px-0 @md:col-span-8 @md:col-start-3 col-span-12 flex flex-col">
                <h2 class="text_h2 text-balance text-center mb-2xs"> Stop Trading Alone. </h2>
                <h2 class="text_h2 text-balance text-center"> Start Untrading and Winning Together.</h2>
                <div class="flex items-center justify-center flex-col">
                    <div class="gap-3xs pt-md flex flex-row flex-wrap items-center justify-center">
                        <Button href="https://testnet.untrading.org/uncryptos" variant="primary" rounded="lg" color="black03" width="180" aria-label="Get started">Get started</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.Grid_root__iRfoa {
	grid-template-areas: "a a a a a a a a b b b b";
    align-items: end;
}


@media (max-width: 768px) {
	.Grid_root__iRfoa {
		grid-template-columns: repeat(8, 1fr);
		grid-template-areas: "a a a a b b b b";
	}
}

@media (max-width: 480px) {
	.Grid_root__iRfoa {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas: "a a a a""b b b b";
	}
}
</style>