<template>
	<div class="markdown prose">
        <p class="font-italic">"Patience, conviction, and discipline define long-term success."</p>
        <h3 class="text-black01 font-weight-bold">Untrading supports your Buffett-inspired strategy:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Passive Earnings: </span>Sell, yet continue to gain with FRs.</li>
            <li><span class="text-black01 font-weight-bold">Unbroken Control: </span>Keep your investment plan intact.</li>
            <li><span class="text-black01 font-weight-bold">Legacy Building: </span>Grow wealth for generations.</li>
        </ul>
        <p >Patience and discipline rewarded.</p>
    </div>
</template>