<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Awesome! Strategic Trading Evolved</h3>
        <p >You know the feeling: Perfect analysis. Patient execution. Precise entry. Then the market shifts, and your carefully planned position unravels.</p>
        <p >What if your strategy could work beyond the sell button?</p>
        <p >Untrading delivers:</p>
        <ul class="ml-8 custom-ul">
            <li>Continuous returns post-exit</li>
            <li>Protection from market games</li>
            <li>Freedom from emotional pressure</li>
        </ul>
		<p >Because strategic traders deserve more than just moments of validation. You deserve sustained success.</p>
        <p >Join the evolution of strategic trading, where your analysis creates lasting value.</p>
        <p >Trade smarter. Earn longer. Succeed consistently.</p>
        <p >Welcome to Untrading.</p>
        <br><hr>
		<p>With your trading style explored and Untrading’s benefits in mind, who is your source of trading inspiration today? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>