<template>
	<div>
        <div v-if="!token" class="text-center mt-16">
            <!-- 链接钱包 -->
            <Button to="/connectwallet?redirectUrl=/leaderboards?type=quest" variant="primary" rounded="lg" color="black01" height="52" width="200" aria-label="Connect Wallet">Connect Wallet</Button>
        </div>
        <div v-else-if="$config.env == 'testnet'">
            <div class="body-h5 text-primaryGrey text-center">No questions on testnet!</div>
        </div>
        <div v-else-if="loading">
            <v-skeleton-loader color="transparent" elevation="0" rounded="0" type="heading, text@4, divider" v-for="i in 20" :key="i"></v-skeleton-loader>
        </div>
        <div v-else-if="!loading && questionInfos.length == 0">
            <NoDataAvailable></NoDataAvailable>
        </div>
        <div v-else v-for="(question, index) in questionInfos" :key="question.id">
            <v-card elevation="0" rounded="0" variant="text">
                <v-row no-gutters align="center">
                    <v-col cols="12" class="text-primaryGrey">
                        <div>
                            <v-card rounded="0" :title='`Listen to the Podcast "EP ${question?.episodes}" on YouTube to earn 100 UN tokens`' :href="question.episodesUrl"
                                append-icon="mdi mdi-open-in-new" prepend-icon="mdi mdi-youtube" color="black01" variant="outlined" rel="noopener" target="_blank">
                                <v-card-text class="body-p-medium">
                                    <v-divider></v-divider>
                                    <div class="pt-2">Q: {{ question?.question }}</div>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="mt-2 body-p-medium">
                            <!-- <v-alert
                                v-model="alert"
                                border="start"
                                title="Closable Alert"
                                variant="outlined"
                                >
                                
                            </v-alert> -->
                            
                        </div>
                        <div>
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-alert v-bind="props" rounded="xl" variant="flat" class="pa-2" :color="optionBgColor(question, 'A', isHovering)">
                                        <template v-slot:append>
                                            <!-- <v-icon color="white">{{ (question.selectedOption == null && question.selectedOption != question.correctOption) ? null : (question.correctOption == 'A' ? 'mdi mdi-check' : 'mdi mdi-close')}}</v-icon> -->
                                            <v-icon color="white" v-if="answerLoading && question.answerLoadingOption == 'A'">fa:fas fa-circle-notch fa-spin</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption != null && question.correctOption == 'A'">mdi mdi-check</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption == 'A'">mdi mdi-close</v-icon>
                                        </template>
                                        <div @click="answer(question, 'A')" :class="[question.selectedOption == null ? 'cursor-pointer' : undefined, question.selectedOption == 'A' || question.correctOption == 'A' ? 'text-white' : 'text-primaryGrey']">
                                            A: {{ question.optionA }}
                                        </div>
                                    </v-alert>
                                </template>
                            </v-hover>
                        </div>
                        <div class="mt-2">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-alert v-bind="props" rounded="xl" variant="flat" class="pa-2" :color="optionBgColor(question, 'B', isHovering)">
                                        <template v-slot:append>
                                            <v-icon color="white" v-if="answerLoading && question.answerLoadingOption == 'B'">fa:fas fa-circle-notch fa-spin</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption != null && question.correctOption == 'B'">mdi mdi-check</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption == 'B'">mdi mdi-close</v-icon>
                                        </template>
                                        <div @click="answer(question, 'B')" :class="[question.selectedOption == null ? 'cursor-pointer' : undefined, question.selectedOption == 'B' || question.correctOption == 'B' ? 'text-white' : 'text-primaryGrey']">
                                            B: {{ question.optionB }}
                                        </div>
                                    </v-alert>
                                </template>
                            </v-hover>
                        </div>
                        <div class="mt-2">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-alert v-bind="props" rounded="xl" variant="flat" class="pa-2" :color="optionBgColor(question, 'C', isHovering)">
                                        <template v-slot:append>
                                            <v-icon color="white" v-if="answerLoading && question.answerLoadingOption == 'C'">fa:fas fa-circle-notch fa-spin</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption != null && question.correctOption == 'C'">mdi mdi-check</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption == 'C'">mdi mdi-close</v-icon>
                                        </template>
                                        <div @click="answer(question, 'C')" :class="[question.selectedOption == null ? 'cursor-pointer' : undefined, question.selectedOption == 'C' || question.correctOption == 'C' ? 'text-white' : 'text-primaryGrey']">
                                            C: {{ question.optionC }}
                                        </div>
                                    </v-alert>
                                </template>
                            </v-hover>
                        </div>
                        <div class="mt-2">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-alert v-bind="props" rounded="xl" variant="flat" class="pa-2" :color="optionBgColor(question, 'D', isHovering)">
                                        <template v-slot:append>
                                            <v-icon color="white" v-if="answerLoading && question.answerLoadingOption == 'D'">fa:fas fa-circle-notch fa-spin</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption != null && question.correctOption == 'D'">mdi mdi-check</v-icon>
                                            <v-icon color="white" v-else-if="question.selectedOption == 'D'">mdi mdi-close</v-icon>
                                        </template>
                                        <div @click="answer(question, 'D')" :class="[question.selectedOption == null ? 'cursor-pointer' : undefined, question.selectedOption == 'D' || question.correctOption == 'D' ? 'text-white' : 'text-primaryGrey']">
                                            D: {{ question.optionD }}
                                        </div>
                                    </v-alert>
                                </template>
                            </v-hover>
                        </div>













                        <!-- <div class="mt-2 body-p">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-card v-bind="props" rounded="xl" variant="flat" :color="optionBgColor(question, 'A', isHovering)">
                                        <v-card-text class="pa-2"
                                            :class="[
                                                question.selectedOption == null ? 'cursor-pointer' : undefined,
                                                question.selectedOption == 'A' || question.correctOption == 'A' ? 'text-white' : 'text-primaryGrey'
                                            ]"
                                            
                                            @click="answer(question, 'A')">
                                            A: {{ question.optionA }}
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </v-hover>
                        </div>
                        <div class="mt-2 body-p">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-card v-bind="props" rounded="xl" variant="flat" :color="optionBgColor(question, 'B', isHovering)">
                                        <v-card-text class="pa-2"
                                            :class="[
                                                question.selectedOption == null ? 'cursor-pointer' : undefined,
                                                question.selectedOption == 'B' || question.correctOption == 'B' ? 'text-white' : 'text-primaryGrey'
                                            ]">
                                            B: {{ question.optionB }}
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </v-hover>
                        </div>
                        <div class="mt-2 body-p">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-card v-bind="props" rounded="xl" variant="flat" :color="optionBgColor(question, 'C', isHovering)">
                                        <v-card-text class="pa-2"
                                            :class="[
                                                question.selectedOption == null ? 'cursor-pointer' : undefined,
                                                question.selectedOption == 'C' || question.correctOption == 'C' ? 'text-white' : 'text-primaryGrey'
                                            ]">
                                            C: {{ question.optionC }}
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </v-hover>
                        </div>
                        <div class="mt-2 body-p">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <v-card v-bind="props" rounded="xl" variant="flat" :color="optionBgColor(question, 'D', isHovering)">
                                        <v-card-text class="pa-2"
                                            :class="[
                                                question.selectedOption == null ? 'cursor-pointer' : undefined,
                                                question.selectedOption == 'D' || question.correctOption == 'D' ? 'text-white' : 'text-primaryGrey'
                                            ]">
                                            D: {{ question.optionD }}
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </v-hover>
                        </div> -->
                    </v-col>
                    <v-col cols="12">
                        <v-divider v-show="index != questionInfos.length - 1" class="my-5"></v-divider>
                    </v-col>
                </v-row>
            </v-card>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ChallengeQuestionAPI from '@/api/challenge-question.js';
import ChallengeUserAnswerAPI from '@/api/challenge-user-answer.js';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // 回答加载中
            answerLoading: false,
            // 问题详情列表
            questionInfos: []
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        // 分页查询挑战问题及用户答案列表
        this.getQuestionsByPage();
    },
    computed: {
        ...mapGetters(['user', 'token']),
    },
    watch:{

    },
    methods: {
        // 分页查询挑战问题及用户答案列表
        async getQuestionsByPage() {
            if(!this.token) {
                return;
            }
            // 加载中
            this.loading = true;
            this.questionInfos = [];
            let res = await ChallengeQuestionAPI.getQuestionsByPage(1, 100);
            let data = res.data;
            if(data.code == 200) {
                this.questionInfos = data.data.records;
            }
            // 加载结束
            this.loading = false;
        },
        // 回答问题
        async answer(question, option) {
            // 判断是否已经回答过
            if (question.selectedOption != null || this.answerLoading) {
                return;
            }
            // 回答加载中
            this.answerLoading = true;
            // 临时记录回答选项
            question.answerLoadingOption = option;
            let param = {
                challengeQuestionId: question.id,
                selectedOption: option
            };
            let res = await ChallengeUserAnswerAPI.answer(param);
            let data = res.data;
            if(data.code == 200) {
                question.correctOption = data.data.correctOption;
                question.selectedOption = data.data.selectedOption;
                question.correct = data.data.correct;
                if (data.data.correct) {
                    this.$store.dispatch('snackbarMessageHandler', 'Well done!');
                } else {
                    this.$store.dispatch('snackbarMessageHandler', 'Oops, wrong answer.');
                }
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
            // 删除临时回答选项
            question.answerLoadingOption = null;
            // 回答加载结束
            this.answerLoading = false;
        },
        // 选项背景颜色
        optionBgColor(question, currentOption, isHovering) {
            if(question.selectedOption == null) {
                return isHovering ? 'd9d' : 'dark-grey';
            } else {
                if (question.selectedOption == currentOption) {
                    return question.correct ? 'primary' : 'error';
                } else {
                    return question.correctOption == currentOption ? 'primary' : 'dark-grey';
                }
                
            }
        }
    }
}
</script>
<style scoped>

</style>