<template>
	<div class="markdown prose">
        <p>Great! As a diamond-hand investor, you play the long game—but what if you could unlock rewards while holding? Enter Untrading : wrap your assets, sell them as unBTC (or similar), and keep earning Future Rewards (FRs) even after selling. Rebuy the asset, repeat the process, and maximize upside—all without losing control.</p>
        <br><hr>
        <p>Now that you’ve identified your trading style and discovered how Untrading can enhance your results, who inspires your approach to trading today? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>