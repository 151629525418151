<template>
	<div class="markdown prose">
        <p>I appreciate it! A message from our risk management team:</p>
        <p>In over a decade of crypto market analysis, we've found:</p>
        <ul class="ml-8 custom-ul">
            <li>Quick wealth schemes typically result in total losses</li>
            <li>Market manipulation targets "get rich quick" traders</li>
            <li>Sustainable wealth requires systematic approach</li>
        </ul>
        <p class="text-black01 font-weight-bold">Introducing Untrading: Evolution Beyond the Exit</p>
        <p>We've reimagined the relationship between traders and their assets. With Untrading, your connection to value doesn't end at the sell button.</p>
        <p>REVOLUTIONARY FEATURES:</p>
        <ul class="ml-8 custom-ul">
            <li>Post-Sale Earnings: Your profits keep growing even after exit</li>
            <li>Zero Hidden Costs: Complete transparency in every transaction</li>
            <li>Future Rewards System: Benefit from asset appreciation indefinitely</li>
            <li>Community-Driven Growth: Success builds upon success</li>
        </ul>
        <p>Why start from zero when you can build lasting value? Join the Untrading revolution—where every trade becomes a stepping stone to sustained growth.</p>
        <br><hr>
        <p>Would you like to learn about proven strategies for building crypto wealth?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Discover how professional traders build wealth:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Strategic position management</li>
                            <li>Risk-adjusted returns</li>
                            <li>Long-term value creation</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/no-commission#pe" rounded="xl" color="black03" height="40" aria-label="View Professional Strategies">
                                <span class="px-4">View Professional Strategies<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>Understood. Untrading's professional approach may not align with your current goals. We're here if your strategy evolves.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="260" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes' },
                { code: 'No', message: 'No' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>