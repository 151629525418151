<template>
    <div>
        <v-dialog v-model="followersDialog" content-class="v-echarts-dialog" width="462">
            <v-card max-height="600" class="pa-8" rounded="lg" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="d-flex">
                            <span class="sidebar-h2 text-primaryGrey">Followers</span>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="followersDialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0" v-infinite-scroll="getFollowers" :infinite-scroll-disabled="noMoreData">
                            <div class="mb-3" v-for="user in users" :key="user.id">
                                <div class="d-flex flex-nowrap align-center justify-space-between">
                                    <div class="body-p text-primaryGrey d-flex flex-nowrap align-center">
                                        <UserAvatar :avatar="user.avatar" :username="user.username" :size="48"></UserAvatar>
                                        <div class="ml-5">
                                            <div class="font-weight-bold">{{ user.name }}</div>
                                            <div class="mt-1" v-username="[user.username, user.wallet]"></div>
                                        </div>
                                    </div>
                                    <div v-if="!isMe(user.username)">
                                        <Follow :followedUserId="user.id"></Follow>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <v-progress-circular color="primaryGrey" :size="60" :width="6" indeterminate v-if="loading"></v-progress-circular>
                                <NoDataAvailable v-if="noDataAvailable"></NoDataAvailable>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import FollowAPI from '@/api/follow.js'
import Follow from '@/components/user/Follow.vue'
export default {
    data(){
        return {
            followersDialog: false,
            page: 1,
            // 加载中
            loading: false,
            // 无可用数据
            noDataAvailable: false,
            // 无更多数据
            noMoreData: false,
            users: []
        }
    },
    props: ['show', 'username'],
    components: { Follow },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['user'])
    },
    watch:{
        $route: {
            handler(n, o) {
                this.followersDialog = false;
            },
            immediate: true
        },
        show: {
            handler(n, o) {
                this.followersDialog = n;
            },
            immediate: true
        },
        followersDialog: {
            handler(n, o) {
                if(!n) {
                    this.$emit('closeFollowersDialog', false);
                }
            },
            immediate: true
        },
    },
    methods: {
        // 是我
        isMe(username){
            return this.user && this.user.username == username;
        },
        // 根据用户名分页查询粉丝用户列表
        async getFollowers() {
            if(this.noDataAvailable || this.noMoreData || this.loading) {
                return;
            }
            this.noDataAvailable = false;
            this.noMoreData = false;
            this.loading = true;
            let res = await FollowAPI.getFollowers(this.username, this.page, 10);
            let data = res.data;
            if(data.success) {
                this.users.push(...data.data.records);
                this.page++;
                // 当前页码等于总页码则没有更多数据
                if(data.data.current == data.data.pages) {
                    this.noMoreData = true;
                }
            } else {
                if(this.users.length == 0) {
                    this.noDataAvailable = true;
                    this.noMoreData = false;
                } else if(this.users.length > 0) {
                    this.noDataAvailable = false;
                    this.noMoreData = true;
                }
            }
            this.loading = false;
        }
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>