<template>
	<div class="bg-f2f">
        <!-- 设置 -->
        <div :class="responsive == 'mobile' ? 'fill-height h-v' : 'h-v'">
            <v-container class="h-100">
                <v-card max-width="1400" rounded="0" elevation="0" color="transparent" align="center" class="mx-auto">
                    <v-card max-width="740" rounded="0" elevation="0" color="transparent" class="mb-8">
                        <div class="text-center text-grey04">
                            <h1 class="text_h1">404</h1>
                        </div>
                    </v-card>
                    <v-card max-width="840" color="black" elevation="12" align="start" class="pa-8" :rounded="0">
                        <!----------- Email Notifications ----------->
                        <h3 class="text_h3 text-primaryGrey">Oops! You've ventured into uncharted territory.</h3>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="text-primaryGrey body-p">
                                <div class="markdown prose-1">
                                    <p>We apologize for the detour! It seems you've stumbled upon a page that doesn't exist. Our imaginary traders must be on vacation.</p>
                                    <p>Don't worry, we've got you covered! Here are a few options to get you back on track:</p>
                                    <ul class="custom-ul ml-8">
                                        <li><span class="font-weight-bold">Return to Safety :</span> Hit that shiny button below to safely navigate back to our homepage and explore our fascinating world of untrade.
                                            <div class="my-4">
                                                <Button variant="primary" to="/" rounded="lg" color="black03" width="240" aria-label="Return to Homepage">
                                                    <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                                                </Button>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="font-weight-bold">Need Assistance? : </span>If you're feeling lost or need a guiding hand, our friendly team is here to help. Feel free to reach out to us by clicking on the Chat button at the lower-right corner  with any questions or concerns.
                                        </li>
                                    </ul>
                                    <p class="text-primaryGrey body-p">
                                        Remember, life's too short for boring trades! We're sorry for any inconvenience caused, and we hope you enjoy your journey back to the heart of untrading!
                                    </p>
                                    <p class="text-primaryGrey body-p">Happy exploring,</p>
                                    <p class="text-primaryGrey body-p">The untrading Team</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-container>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            
        }
    },
    components: {  },
    created(){
   
    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(["responsive"])
    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>