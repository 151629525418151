<template>
	<div class="main-container bg-black">
        <div class="flex flex-col mt-10 gap-2xl @md:gap-3xl">
            <section class="@container group/component-group">
                <div class="@container grid w-full grid-cols-12">
                    <div class="col-span-12 grid w-full grid-cols-1 items-start gap-lg @md:gap-xl">
                        <div class="@container w-full max-w-container">
                            <div class="relative w-full">
                                <div class="@container multi-columns:px-0 grid w-full grid-cols-12">
                                    <div class="col-span-12 @md:col-span-10 @md:col-start-2 @lg:col-span-8 @lg:col-start-3">
                                        <div class="relative flex flex-col items-center text-center">
                                            <h1 class="text_h1 scroll-mt-header-h max-w-[62.5rem] text-balance">Maximize Your Earnings with the Untrading Calculator</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div class="w-full grid grid-cols-12 @container max-w-container multi-columns:px-0 multi-columns:flex">
                            <div class="col-span-12 @md:col-span-6 @md:col-start-4 max-w-none markdown">
                                <p>The Untrading Extra Earnings Calculator helps you estimate how much additional profit you could earn using Untrading—on a single transaction or across multiple future sales.</p>
                                <p>How It Works:</p>
                                <ul class="pl-8 custom-ul">
                                    <li>Input Your Buy and Sell Prices: Start with your basic trade details.</li>
                                    <li>Adjust Profit Expectancy Levels: Customize the calculator to match your trading strategy.</li>
                                    <li>See Potential Earnings: Discover how much extra you could earn through Future Rewards (FRs).</li>
                                </ul>
                                <p>Go Deeper with the Advanced Calculator:</p>
                                <p class="gradient-underline-hover">For a more detailed analysis, use the <a href="https://5173.foundation/" target="_blank" class="del-underline link-underline-hover text-tertiary pointer gradient-text-hover"><span class="gradient-text-hover">Advanced Calculator</span></a> to explore in-depth scenarios and optimize your trading potential.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <v-row no-gutters align="end" justify="center" class="hero_section pb-150">
                <div class="layoutContentCal">
                    <Calculator></Calculator>
                </div>
            </v-row>
        </div>
        <Footer class="bg-black"></Footer>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Calculator from './v3/components/Calculator.vue';
import Footer from './v3/components/Footer.vue';
export default {
    data(){
        return {

        }
    },
    components: { Calculator,Footer },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>