<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Awesome! High-energy trading? Here's the catch:</h3>
        <ul class="ml-8 custom-ul">
            <li>Fast gains, but high risk.</li>
            <li>Charts don't predict; beware manipulators.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading changes the game:</h3>
        <ul class="ml-8 custom-ul">
            <li>Earn beyond the sale.</li>
            <li>No hidden fees.</li>
            <li>Gain from future asset value.</li>
        </ul>
        <p >Your wealth keeps growing with Untrading.</p>
        <br><hr>
		<p>Now that you’ve explored your trading style and discovered how Untrading can help you win, who inspires your trading journey today? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>