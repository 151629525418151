<template>
	<div class="markdown prose">
        <p>Your inspiration: Buffett. <span class="font-italic">"Patience, conviction, and discipline define long-term success."</span></p>
        <p >Untrading aligns with your mindset by letting you hold strong while earning passive Future Rewards (FRs):</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell High, Keep Earning: </span>Continue benefiting as assets grow.</li>
            <li><span class="text-black01 font-weight-bold">Own Assets Fully: </span>No interruptions or middlemen.</li>
            <li><span class="text-black01 font-weight-bold">Build Generational Wealth: </span>Maintain control while growing your legacy.</li>
        </ul>
        <p >With Untrading, your vision and wealth grow together—over time.</p>
    </div>
</template>