<template>
	<div class="bg-f2f align-center h-100">
        <!-- 暂无数据 -->
        <!-- <NoDataAvailable v-if="!loading && !isMyNFT"></NoDataAvailable> -->
        <v-row justify="center" align="center" v-if="!loading && !isMyNFT">
            You are not the owner, go buy it first!
        </v-row>
        <v-container class="h-100" v-if="isMyNFT">
            <v-card color="transparent" max-width="1400" rounded="0" elevation="0" class="mx-auto pt-7">
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-16">
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="6" offset-md="1" class="justify-center">
                                <!-- 返回按钮 -->
                                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-13 r-m-l-0" @click="$router.back(-1)"></v-icon>
                                <h1 class="sub-page-h1 ml-13 text-grey04 r-m-l-0">List your unNFT</h1>
                                <v-card max-width="560" class="ml-13 pa-8 mb-16 mt-8 r-m-l-0" color="paleYellow" rounded="0" elevation="2">
                                    <!-- ------------------------- Price ------------------------- -->
                                    <h3 class="body-h4 mb-6 text-uppercase text-primaryGrey">Price<span class="ml-1 text-red">*</span></h3>
                                    <v-row align="center">
                                        <v-col cols="8" class="pa-0">
                                            <v-text-field clearable v-model="nftListInfo.price" type="number" min="0" variant="outlined" hide-details density="comfortable"></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="text-primaryGrey">
                                            {{ nft.paymentToken }}
                                        </v-col>
                                    </v-row>
                                    <!-- ------------------------- Set ------------------------- -->
                                    <h3 class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">
                                        Sets
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <h5 v-if="!isDisabledSets">
                                                    It may take up to 30 minutes to move items between sets. You can manage your sets under your profile.
                                                </h5>
                                                <h5 v-if="isDisabledSets">
                                                    Moving items to a different set may take up to 30 minutes. You can manage your sets here.
                                                 </h5>
                                            </v-card>
                                        </v-menu>
                                    </h3>
                                    <h5 class="body-p text-primaryGrey">
                                        <!-- <v-btn size="x-small" :rounded="0" class="text-none gradient-left-red-purple text-white mr-3" width="34" height="19">new</v-btn> -->
                                        You can add this unNFT to an existing Set you have already created.
                                    </h5>
                                    <div class="mt-6">
                                        <v-select v-if="!isDisabledSets" v-model="listNFT.setId" :items="currentBlockchainSets" :label=" isDisabledSets ? '' : 'Sets'" :variant=" isDisabledSets ? 'solo' : 'outlined'" item-title="name" item-value="id" hide-details density="comfortable" :disabled="isDisabledSets" @update:modelValue="moveNftToSet"></v-select>
                                        <v-text-field v-if="isDisabledSets" v-model="nft.set.name" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                    </div>
                                    <!-- ------------------------- Category ------------------------- -->
                                    <h3 class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">Category</h3>
                                    <div class="mt-6">
                                        <v-select v-if="!isDisabledCategory" v-model="listNFT.category" :label=" isDisabledCategory ? '' : 'Category'" :items="categories" :variant=" isDisabledCategory ? 'solo' : 'outlined'" hide-details density="comfortable" :disabled="isDisabledCategory" @update:modelValue="updateCategoryNFT"></v-select>
                                        <v-text-field v-if="isDisabledCategory" v-model="nft.category" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                    </div>
                                    <!-- ------------------------- Tags ------------------------- -->
                                    <h3 class="body-h4 mt-13 mb-6 text-uppercase text-primaryGrey">Tags</h3>
                                    <div class="body-p text-primaryGrey" v-if="isMyNFT">
                                        <div class="d-flex justify-space-between">
                                            <div class="d-flex">
                                                <h5>You can add up to 10 unique tags.</h5>
                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                    <template v-slot:activator="{ props }" >
                                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                    </template>
                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                        To tag your artwork, type any word, or word combination, to be associated with the work’s discoverability across untrading.org. Tags are editable, and you can update them as often as you’d like to.
                                                    </v-card>
                                                </v-menu>
                                            </div>
                                            <div>
                                                <v-btn aria-label="Plus" :rounded="1" icon size="medium" variant="text" color="#ff43b0" @click="openTagsDialog">
                                                    <v-icon icon="mdi:mdi-plus" size="28"></v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <v-chip :rounded="0" label class="mr-3 mb-3" v-for="(tag, i) in listNFT.tags" :key="i">{{ tag }}</v-chip>
                                    </div>
                                    <!-- ------------------------- Required fields ------------------------- -->
                                    <div class="mt-6 fs-14 mt-100 d-flex">
                                        <span class="text-red mr-2 mt-1">*</span>
                                        <h5 class="body-p text-primaryGrey">Required fields</h5>
                                    </div>
                                </v-card>
                                <div class="ml-13 r-m-l-0">
                                    <!-- 未上市则显示上市 -->
                                    <div v-if="!nftListInfo.isListed" class="gradient-block">
                                        <Button variant="primary" :loading="listLoading" rounded="lg" block color="black03" height="52" @click="list()" aria-label="List for sale">List for sale</Button>
                                        <div class="my-4">
                                            <Button variant="secondary" :to="$tools.getUnNFTUrl(nft.blockchain, id)" :disabled="listLoading || unlistLoading" rounded="lg" block color="black01" height="52" width="300" aria-label="View">View</Button>
                                        </div>
                                    </div>
                                    <!-- 已上市显示修改价格 -->
                                    <div v-if="nftListInfo.isListed && responsive == 'pc'">
                                        <div class="my-4">
                                            <Button variant="primary" :to="$tools.getUnNFTUrl(nft.blockchain, id)" :disabled="listLoading || unlistLoading" rounded="lg" block color="black03" height="52" aria-label="View">View</Button>
                                        </div>
                                        <div class="d-flex justify-space-between">
                                            <span><Button variant="secondary" :loading="listLoading" :disabled="unlistLoading" rounded="lg" color="black01" block height="52" @click="list()" aria-label="Change price">Change price</Button></span>
                                            <span><Button variant="secondary" :loading="unlistLoading" :disabled="listLoading" block rounded="lg" color="black01" height="52" @click="unlist()" aria-label="Unlist">Unlist</Button></span>
                                        </div>
                                    </div>
                                    <!-- 已上市显示修改价格 -->
                                    <div v-if="nftListInfo.isListed && responsive != 'pc'">
                                        <span>
                                            <Button variant="primary" :to="$tools.getUnNFTUrl(nft.blockchain, id)" :disabled="listLoading || unlistLoading" rounded="lg" block color="black03" height="52" aria-label="View">View</Button>
                                        </span>
                                        <span><Button variant="secondary" :loading="listLoading" :disabled="unlistLoading" rounded="lg" color="black01" block height="52" @click="list()" aria-label="Change price">Change price</Button></span>
                                        <span><Button variant="secondary" :loading="unlistLoading" :disabled="listLoading" block rounded="lg" color="black01" height="52" @click="unlist()" aria-label="Unlist">Unlist</Button></span>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="5" sm="6" cols="12" class="ml-12 mt-2 r-m-l-0 r-m-t-16">
                                <UnNftMedia :nftId="id"></UnNftMedia>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <!-- 编辑标签弹窗 -->
        <v-dialog v-model="tagsDialog" persistent content-class="v-echarts-dialog">
            <v-card width="462" class="pa-8 bg-paleCyan" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-card-title class="d-flex">
                            <h2 class="sidebar-h2 text-primaryGrey">Tags</h2>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="tagsDialog = false"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="px-0">
                            <h5 class="body-p text-primaryGrey">You can add up to 10 unique tags.</h5>
                            <div class="mt-10">
                                <v-text-field v-model="tagsText" variant="outlined" label="Tags(up to 10), separated by commas" hide-details density="comfortable"></v-text-field>
                            </div>
                            <div class="pt-16 mt-16 text-center">
                               <Button variant="primary" :loading="loading" rounded="lg" color="black03"  width="100" aria-label="Save" @click="updateTagNFT">Save</Button>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
        <Reown ref="Reown" @transactionClose="transactionClose"></Reown>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import NFTAPI from '@/api/nft.js';
import NFTCategoryAPI from '@/api/nft-category.js';
import NFTTagAPI from '@/api/nft-tag.js';
import NFTProvenanceAPI from '@/api/nft-provenance.js';
import SetAPI from '@/api/set.js';
import { Web3 } from "web3";
import UnNftMedia from '@/components/nft/UnNftMedia.vue';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 是我的 NFT 吗
            isMyNFT: false,
            // 套装主键
            setId: this.$route.query.setId,
            // 加载中
            loading: false,
            // 上市加载中
            listLoading: false,
            // 下市加载中
            unlistLoading: false,
            // 类别集合
            categories: [],
            // nft上市/下市参数
            listNFT: {
                id: null,
                setId: null,
                category: null,
                // 本次交易的哈希
                transactionHash: null,
                tags: []
            },
            // 标签文本
            tagsText: null,
            // 编辑标签弹窗
            tagsDialog: false,
            // NFT 信息
            nft: {
                set: {},
                user: {},
                owner: {}
            },
            // NFT 上市信息
            nftListInfo: {
                price: null,        // 上市价格
                lister: null,       // 上市人
                isListed: false     // 是否已经上市
            },
            // 操作: LIST/UNLIST
            action: null,
            // 禁用套装
            isDisabledSets: true,
            // 禁用类别
            isDisabledCategory: true,
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 当前的区块链
            currentBlockchain: {},
            web3: {}
        }
    },
    components: { UnNftMedia },
    created(){
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "NFT_LIST") {
                this.callGetTransactionReceipt(data.data);
            }
        })
        this.$bus.on('callGetListInfo', data => {
            if (data.type == "NFT_LIST") {
                this.callGetListInfo(data.data);
            }
        })
    },
    mounted(){
        // 查询全部类别
        this.getCategories();
    },
    computed: {
        ...mapGetters(['user', 'sets', 'walletType', 'blockchains', 'responsive']),
        // 我是发起人吗
        isOriginator() {
            return this.user && this.listNFT && this.user.id == this.nft.userId;
        },
        // 当前的区块链的套装集合
        currentBlockchainSets() {
            return this.sets.filter(set => set.blockchain == this.nft.blockchain);
        }
    },
    watch:{
        id: {
            handler(n, o) {
                this.checkIsMyNFT();
            },
            immediate: true
        },
        setId: {
            handler(n, o) {
                if(n && this.sets.filter(s => s.id == n).length > 0) {
                    this.listNFT.setId = n;
                }
            },
            immediate: true
        },
        tagsText(n, o){
            if(n) {
                // 过滤出逗号数组，用于判断数量
                let commasArray = n.match(/,/g);
                // 控制最多10个
                if(commasArray && commasArray.length >= 10) {
                    let lastComma = n.lastIndexOf(',');
                    this.tagsText = n.substring(0, lastComma);
                }
                this.listNFT.tags = n.split(',');
            } else {
                this.listNFT.tags = [];
            }
        }
    },
    methods: {
        // 这是我的 NFT 吗
        async checkIsMyNFT() {
            this.loading = true;
            let res = await NFTAPI.isMyNFT(this.id);
            let data = res.data;
            if(data.success) {
                this.isMyNFT = data.data;
                if(this.isMyNFT) {
                    this.getNFTById();
                } else {
                    this.exists();
                }
            }
            this.loading = false;
        },
        // 禁用套装
        checkIsDisabledSets() {
            // 是 Originator 一直可以编辑
            // this.isDisabledSets = !this.isOriginator || (this.isOriginator && this.listNFT.setId);
            this.isDisabledSets = !this.isOriginator;
        },
        // 禁用类别
        checkIsDisabledCategory() {
            // 是 Originator 一直可以编辑
            // this.isDisabledCategory = !this.isOriginator || (this.isOriginator && this.listNFT.category);
            this.isDisabledCategory = !this.isOriginator;
        },
        // 查询全部类别
        async getCategories() {
            let res = await NFTCategoryAPI.getCategories();
            let data = res.data;
            if(data.success) {
                this.categories = data.data;
            }
        },
        // 移动 NFT 到套装
        async moveNftToSet() {
            let res = await SetAPI.moveNftToSet(this.id, this.listNFT.setId);
            let data = res.data;
            if(data.success) {
                // 通知刷新套装数据
                this.$bus.emit('emitRefreshSet', {});
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 更新 NFT 的类别
        async updateCategoryNFT() {
            let params = {
                nftId: this.id,
                category: this.listNFT.category
            };
            let res = await NFTCategoryAPI.updateCategoryNFT(params);
            let data = res.data;
            if(!data.success) {
                this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
        },
        // 更新 NFT 的标签
        async updateTagNFT() {
            this.tagsDialog = false;
            let params = {
                nftId: this.id,
                tags: this.listNFT.tags
            };
            let res = await NFTTagAPI.updateTagNFT(params);
            let data = res.data;
            if(!data.success) {
                this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
        },
        // 是否存在该 NFT
        async exists() {
            let res = await NFTAPI.exists(this.id);
            let data = res.data;
            if(data.success && data.data) {
                setTimeout(() => {
                    // 存在则去购买
                    this.$router.push('/unnft/buy/' + this.id);
                }, 1500);
            } else  {
                // 不存在则导航到404
                this.$router.push('/404');
            }
        },
        // 根据主键查询 NFT
        async getNFTById() {
            let res = await NFTAPI.getNFTById(this.id);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                this.listNFT.category = this.nft.category;
                this.listNFT.tags = this.nft.tags;
                if(!this.setId) {
                    this.listNFT.setId = this.nft.setId;
                }
                // 写在方法调用会只执行一次，如果写在computed会实时监听
                // 检查是否禁用 Set 和 Category
                this.checkIsDisabledSets();
                this.checkIsDisabledCategory();
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0]
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 查询上市信息
                let listParams = {
                    type: "NFT_LIST",
                    blockchain: this.currentBlockchain.blockchain,
                    tokenId: this.nft.tokenId
                }
                this.$bus.emit('emitGetListInfo', listParams);
            }
        },
        // 回调上市详情数据
        callGetListInfo(data) {
            this.nftListInfo.price = this.web3.utils.fromWei(data[0], 'ether');
            this.nftListInfo.lister = data[1];
            this.nftListInfo.isListed = data[2];
        },
        // 上市
        async list() {
            if(Number(this.nftListInfo.price) <= 0) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild price");
                return;
            }
            this.action = 'LIST';
            this.listLoading = true;
            // 编码参数列表
            try {
                let method  = this.web3.eth.abi.encodeFunctionSignature('list(uint256,uint256)');
                let priceWei = this.web3.utils.toWei(Number(this.nftListInfo.price) + '', 'ether');
                // 将参数编码
                let param = this.web3.eth.abi.encodeParameters(['uint256', 'uint256'], [this.nft.tokenId, priceWei]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.nft.blockchain, this.user.wallet, this.currentBlockchain.unNFTContract, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.listLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.listLoading = false;
            }
        },
        // 下市
        async unlist() {
            this.action = 'UNLIST';
            this.unlistLoading = true;
            // 编码参数列表
            try {
                let method  = this.web3.eth.abi.encodeFunctionSignature('unlist(uint256)');
                let priceWei = this.web3.utils.toWei(Number(this.nftListInfo.price) + '', 'ether');
                // 将参数编码
                let param = this.web3.eth.abi.encodeParameter('uint256', this.nft.tokenId).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.nft.blockchain, this.user.wallet, this.currentBlockchain.unNFTContract, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.unlistLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.unlistLoading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 查询交易收据
                this.getTransactionReceipt();
                // 20230912: 服务端订阅合约，前端取消请求
                // 更新参数
                this.listNFT.id = this.id;
                this.listNFT.blockchain = this.nft.blockchain;
                this.listNFT.transactionHash = this.transactionHash;
                this.listNFT.price = this.nftListInfo.price;
                this.listNFT.paymentToken = this.nft.paymentToken;
                // 上市
                if(this.action == 'LIST') {
                    this.nftListInfo.isListed = true;
                    // await NFTAPI.list(this.listNFT);
                }
                // 下市
                if(this.action == 'UNLIST') {
                    this.nftListInfo.isListed = false;
                    this.nftListInfo.price = 0;
                    // await NFTAPI.unlist(this.listNFT);
                }
            } else {
                // 加载完成
                this.listLoading = false;
                this.unlistLoading = false;
            }
        },
        // 查询交易收据
        getTransactionReceipt(){
            // 轮训查询交易
            this.transactionReceiptInterval = setInterval(() => {
                let params = {
                    type: "NFT_LIST",
                    blockchain: this.listNFT.blockchain,
                    transactionHash: this.listNFT.transactionHash
                }
                this.$bus.emit('emitGetTransactionReceipt', params);
            }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                // 加载完成
                this.listLoading = false;
                this.unlistLoading = false;
            }
        },
        // 打开标签弹窗
        openTagsDialog() {
            this.tagsText = this.listNFT.tags.join();
            this.tagsDialog = true;
        }
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        next();
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
</style>