<template>
	<div class="align-center pb-16">
        <v-container class="h-100 r-p-x-0">
            <v-card color="transparent" elevation="0" rounded="0" class="mx-auto pt-8">
                <div v-if="loading">
                    <SkeletonUnCryptos bg-color="bg-primaryGrey"></SkeletonUnCryptos>
                </div>
                <div v-else-if="!loading && cryptoProfitExpectancySummaries.length == 0">
                    <NoDataAvailable></NoDataAvailable>
                </div>
                <div v-else v-for="(summary, i) in cryptoProfitExpectancySummaries" :key="summary.name">
                    <!-- 摘要信息 -->
                    <v-card elevation="0" rounded="0" color="transparent" align="left" class="pb-7 mx-auto">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card elevation="0" rounded="0" color="transparent">
                                    <v-row no-gutters class="pb-8">
                                        <v-col cols="12">
                                            <h1 class="sub-page-h1 gradient-underline-hover">
                                                <Blockchain :blockchain="summary.blockchain"></Blockchain>
                                                <a :href="`${$tools.getBlockchainExplorerUrl(summary.blockchain)}/address/${summary.address}`" :aria-label="summary.name"
                                                    class="del-underline text-primaryGrey gradient-text-hover" :class="summary.blockchain ? 'pointer' : 'pointer-none'" target="_blank">
                                                    <span>Profit Expectancy (PE): </span>
                                                    <span class="text-primary">{{ summary.name }} (+{{ $tools.formatNumber(summary.profitLowestPriceChangeRatio * 100, -1) }}%)</span>
                                                </a>
                                            </h1>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters >
                                        <v-col cols="4">
                                            <h4 class="body-h4 text-primaryGrey">
                                                {{ $tools.formatNumber(summary.totalCrypto, 0) }} Total
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- 表格 -->
                    <v-card color="transparent" rounded="0" elevation="0" class="mx-auto">
                        <div v-for="cryptoSymbolSummary in summary.cryptoSymbolSummaries" :key="cryptoSymbolSummary.symbol" class="mb-16">
                            <div class="mb-8 r-m-x-0">
                                <v-divider></v-divider>
                                <v-row no-gutters class="mt-8 r-p-r-0">
                                    <v-col cols="6">
                                        <div class="text-primaryGrey body-h4">
                                            {{ cryptoSymbolSummary.symbol }}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <div class="float-right text-primaryGrey body-p">
                                            {{ $tools.formatNumber(cryptoSymbolSummary.total, 0) }} results
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- 数据表格 -->
                            <v-data-table-server width="100%" fluid :headers="headers" :items="cryptoSymbolSummary.results" :items-length="cryptoSymbolSummary.total" :page="cryptoSymbolSummary.page" :items-per-page="cryptoSymbolSummary.size" :loading="cryptoSymbolSummary.loading" item-value="name" 
                                class="body-p text-primaryGrey" :class="responsive != 'pc' ? 'overflow-x-scroll' : ''" color="transparent" density="compact" hover
                                @update:page="updatePage($event, cryptoSymbolSummary)" @update:itemsPerPage="updateSize($event, cryptoSymbolSummary)">
                                <template v-slot:headers>
                                    <tr class="body-h5">
                                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-50'">ID</td>
                                        <!-- <td>Originator</td> -->
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-150'">Price</td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-50'"></td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-150'">Size</td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-120'" width="100">Total R
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unCrypto.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-100'" width="100">FR
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-100'" width="100">Gen
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-100'" width="100">OR
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Share of Rewards available for distribution among untrading.org and community treasurers.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="text-right" :class="responsive == 'pc' ? '' : 'td-width-120'" width="120">Flow
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The Length of Flow is calculated as the sum of all prior owners along with the present holder of this token, starting from the time it was initially minted.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td  :class="responsive == 'pc' ? '' : 'td-width-150'" width="150">Owner</td>
                                    </tr>
                                    <tr>
                                        <td colspan="10" class="px-0 text-center">
                                            <v-divider color="primaryGrey" :thickness="3"></v-divider>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:item="{ index, item }">
                                    <v-hover>
                                        <template v-slot:default="{ isHovering, props }">
                                            <tr class="pointer" v-bind="props" :class="isHovering ? 'bg-primary text-white' : ''" @click="goToUnCrypto(item)">
                                                <td class="px-0">{{ item.tokenId }}</td>
                                                <!-- <td>
                                                    <UserAvatar :avatar="item.user?.avatar" :username="item.user?.username" :name="item.user?.name" :text-color="isHovering ? 'text-white' : ''" :underline-color="isHovering ? 'white-underline-hover' : ''" :size="24"></UserAvatar>
                                                </td> -->
                                                <td class="px-0 text-right">{{ (item.limitPrice > 0 ? $tools.formatNumber(item.limitPrice, item.cryptoWrapped?.priceDecimals) : '--') + ' ' + item.quoteCurrency }}</td>
                                                <td class="px-0">
                                                    <div v-if="item.owner?.id == user?.id && item.contractFunction != 'UNWRAP' && item.listedSize == 0" class="ml-2">
                                                        <router-link :to="`/uncrypto/list/${item.id}`" class="gradient-left-red-purple-60 del-underline" @click.stop>
                                                            <Button variant="primary" rounded="lg" color="black03" height="32" width="80" aria-label="Sell">Sell</Button>
                                                        </router-link>
                                                    </div>
                                                </td>
                                                <td class="px-0 text-right">{{ item.listedSize > 0  ? $tools.formatNumber(item.listedSize, item.cryptoWrapped?.sizeDecimals) : $tools.formatNumber(item.currentSize, item.cryptoWrapped?.sizeDecimals) }}</td>
                                                
                                                <td class="px-0 text-right">{{ item.totalRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ 100 - item.originatorRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ item.numberOfGenerations }}</td>
                                                <td class="px-0 text-right">{{ item.originatorRewardRatio }}%</td>
                                                <td class="text-right">{{ item.maxOwnerNo }}</td>
                                                <td>
                                                    <UserAvatar :avatar="item.owner?.avatar" :username="item.owner?.username" :name="item.owner?.name" :text-color="isHovering ? 'text-white' : ''" :underline-color="isHovering ? 'white-underline-hover' : ''" :size="24"></UserAvatar>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-hover>
                                </template>
                                <template v-slot:loading>
                                    <!-- <tr>
                                        <td colspan="10">
                                            <div class="text-center">
                                                <v-progress-circular color="primary" class="my-7" :size="30" :width="3" indeterminate v-if="cryptoSymbolSummary.loading"></v-progress-circular>
                                            </div>
                                        </td>
                                    </tr> -->
                                    <v-skeleton-loader :type="`table-row@${cryptoSymbolSummary.size}`" color="transparent"></v-skeleton-loader>
                                </template>
                                <template v-slot:no-data>
                                    <NoDataAvailable></NoDataAvailable>
                                </template>
                                <template v-slot:tfoot>
                                    <tr>
                                        <td colspan="10" class="px-0">
                                            <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:bottom>
                                    <v-data-table-footer key="title" :items-per-page-options="perPageOptions"></v-data-table-footer>
                                </template>
                            </v-data-table-server>
                        </div>
                    </v-card>
                </div>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SkeletonUnCryptos from '@/components/util/skeleton/SkeletonUnCryptos.vue';
import CryptoAPI from '@/api/crypto.js';
import CryptoProfitExpectancyAPI from '@/api/crypto-profit-expectancy.js';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // 表头
            headers: [{},{},{},{},{},{},{},{},{},{},{}],
            // 每页数量配置
            perPageOptions: [
                { title: '10', value: 10 },
                { title: '20', value: 20 },
                { title: '50', value: 50 },
                { title: '100', value: 100 }
            ],
            // 加密货币盈利预期摘要信息
            cryptoProfitExpectancySummaries: [],
        }
    },
    props: {
        username: {
            type: String,
            default: null
        },
        sortBy: {
            type: String,
            default: null
        },
        blockchain: {
            type: String,
            default: null
        }
    },
    components: { SkeletonUnCryptos },
    created(){

    },
    mounted(){
        // 根据用户名查询封装的加密货币盈利预期摘要信息
        this.getWrappedCryptoProfitExpectancySummaries();
    },
    computed: {
        ...mapGetters(['responsive', 'user']),
    },
    watch: {
        sortBy(n, o) {
            this.changeCondition();
        },
        blockchain(n, o) {
            this.changeCondition();
        }
    },
    methods: {
        // 改变条件
        changeCondition() {
            // 恢复data定义的所有参数
            Object.assign(this.$data, this.$options.data.call(this));
            // 根据用户名查询封装的加密货币盈利预期摘要信息
            this.getWrappedCryptoProfitExpectancySummaries();
        },
        // 根据用户名查询封装的加密货币盈利预期摘要信息
        async getWrappedCryptoProfitExpectancySummaries() {
            this.loading = true;
            this.cryptoProfitExpectancySummaries = [];
            let res = await CryptoProfitExpectancyAPI.getWrappedCryptoProfitExpectancySummaries(this.username, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.cryptoProfitExpectancySummaries = data.data;
                this.cryptoProfitExpectancySummaries.forEach(summary => {
                    summary.cryptoSymbolSummaries.forEach(cryptoSymbolSummary => {
                        cryptoSymbolSummary.profitExpectancy = summary.name;
                        cryptoSymbolSummary.blockchain = this.blockchain;
                        cryptoSymbolSummary.loading = false;
                        cryptoSymbolSummary.total = 0;
                        cryptoSymbolSummary.page = 1;
                        cryptoSymbolSummary.size = 10;
                        cryptoSymbolSummary.results = [];
                        // 根据用户名分页查询封装的加密货币列表
                        this.getWrappedCryptosByPage(cryptoSymbolSummary);
                    });
                });
            }
            this.loading = false;
        },
        // 根据用户名分页查询封装的加密货币列表
        async getWrappedCryptosByPage(cryptoSymbolSummary) {
            cryptoSymbolSummary.loading = true;
            // cryptoSymbolSummary.total = 0;
            cryptoSymbolSummary.results = [];
            let res = await CryptoAPI.getWrappedCryptosByPage(this.username, cryptoSymbolSummary.page, cryptoSymbolSummary.size, this.sortBy, cryptoSymbolSummary.profitExpectancy, cryptoSymbolSummary.blockchain, cryptoSymbolSummary.wrappedCurrency, cryptoSymbolSummary.quoteCurrency);
            let data = res.data;
            if(data.success) {
                cryptoSymbolSummary.total = data.data.total;
                cryptoSymbolSummary.results = data.data.records;
            }
            cryptoSymbolSummary.loading = false;
        },
        // 更新页码
        updatePage(page, cryptoSymbolSummary) {
            // 更新参数
            cryptoSymbolSummary.page = page;
            // 根据用户名分页查询封装的加密货币列表
            this.getWrappedCryptosByPage(cryptoSymbolSummary);
        },
        // 更新每页数量
        updateSize(size, cryptoSymbolSummary) {
            // 更新参数
            cryptoSymbolSummary.page = 1;
            cryptoSymbolSummary.size = size;
            // 根据用户名分页查询封装的加密货币列表
            this.getWrappedCryptosByPage(cryptoSymbolSummary);
        },
        // 跳转到加密货币详情页
        goToUnCrypto(unCrypto) {
            this.$router.push(`/uncrypto/${unCrypto.wrappedCurrency.toLowerCase()}/${unCrypto.id}`);
        }
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
/* .v-data-table > .v-table__wrapper > table > .v-data-table__tbody > .pointer:hover{
  background: #03DAC5 !important;
} */
</style>