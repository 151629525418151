<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Got it! The Art of Beautiful Balance</h3>
        <p >True wealth isn't built on single bets. It's created through systems that work in harmony.</p>
        <p >Untrading brings balance to your strategy:</p>
        <ul class="ml-8 custom-ul">
            <li>Earn Future Rewards naturally</li>
            <li>Participate in shared success</li>
            <li>Build self-reinforcing positions</li>
        </ul>
        <p >Because real returns don't come from extraction—they come from ecosystems.</p>
        <p >Transform your portfolio into a self-sustaining machine.</p>
    </div>
</template>