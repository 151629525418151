<template>
	<div class="main-container">
        <v-container class="h-60vh bg-black r-p-x-0">
            <div class="d-flex align-center justify-center h-50vh">
                <div class="layoutContent">
                    <div class="Grid_root__iRfoa grid-style-17">
                        <div class="Grid_a__vY7M8">
                            <div class="d-flex flex-column ga-10">
                                <h1 class="text_h1 text-quaternary">
                                    Own Your Community's Success
                                </h1>
                                <p class="text-p1 text-tertiary">
                                    Bring your followers to Untrading and transform your influence into a sustainable, growing asset. It's time to truly own the value you create.
                                </p>
                                <div v-if="!token">
                                    <Button to="/connectwallet?redirectUrl=/uncryptos" variant="primary" rounded="lg" color="black03" width="200" aria-label="Connect Wallet">Connect Wallet</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
        <!-- Estimator -->
        <v-container class="pa-0 bg-black">
            <div class="layoutContentCal r-p-x-0">
                <Estimator class="estimator"></Estimator>
            </div>
        </v-container>
        <v-container class="pa-0 bg-black r-p-x-0">
            <v-row no-gutters class="py-120" justify="center" align="center">
                <Payout></Payout>
            </v-row>
            <!-- Kingdom -->
            <v-row no-gutters justify="center" align="end" class="py-120 bg-black">
                <div class="layoutContent">
                    <div class="d-flex flex-column mb-16">
                        <h2 class="text_h2 text-quaternary">Build Your Kingdom on Untrading</h2>
                        <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                        <p class="fs-21-bold text-secondary">Become a King, or Queen, serve your realm, bridge the on-chain Silk Road.</p>
                        <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                        <p class="fs-14 text-tertiary">Kingdoms on Untrading allow you to utilize our technologies to build your collaborative decentralized hedge pools for your community.</p> 
                    </div>
                    <Kingdom></Kingdom>
                </div>
            </v-row>
            <Footer class="bg-black"></Footer>
        </v-container>
      
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Estimator from './components/Estimator.vue';
import Footer from '@/views/home/v3/components/Footer.vue';
import Kingdom from './components/Kingdom.vue';
import Payout from './components/Payout.vue';
import Theme from '@/components/common/Theme';
export default {
    data(){
        return {
            currentUrl: window.location.href,
        }
    },
    components: { Footer, Theme, Estimator, Kingdom, Payout },
    created(){

    },
    mounted() {
       
    },
   
    computed: {
        ...mapGetters(['token', 'responsive'])
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {

    }
}
</script>
<style scoped>

</style>