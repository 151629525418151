<template>
    <div>
        <v-bottom-navigation class="bottom-navigation-bg navigation-b" v-if="path">
          <v-btn value="Home" :to="items.linkType == 'to' ? items.link : null" :href="items.linkType == 'a' ? items.link : null"  v-for="(items,index) in data" :key="index">
            <v-icon size="24" class="bottom-navigation-text">{{items.icon}}</v-icon>
            <span class="bottomNavigation-mobile mt-1 bottom-navigation-text">{{items.title}}</span>
          </v-btn>
        </v-bottom-navigation>
    </div>
</template>
<script>
export default {
   name:'',
   props:['pathData','pathState'],
   components: {
   },
   data() {
      return {
        data:[{
          icon: 'mdi mdi-home',
          title: 'Home',
          link: 'https://untrading.org/',
          linkType: 'a'
        },{
          icon: 'mdi mdi-trending-up',
          title: 'Market',
          link: '/uncryptos',
          linkType: 'to'
        },{
          icon: 'mdi mdi-magnify',
          title: 'Search',
          link: '/uncryptos/search',
          linkType: 'to'
        },{
          icon: 'mdi mdi-view-dashboard-outline',
          title: 'Dashboard',
          link: '/dashboard',
          linkType: 'to'
        },{
          icon: 'mdi mdi-cog',
          title: 'Settings',
          link: '/settings',
          linkType: 'to'
        }],
        path:true,
      }
   },
   watch:{
        $route: {
            handler(n, o) {
                // 过滤出当前的路由集合，可能没有，可能有1个
                let currentPaths = this.pathData.filter(item => item == n.path);
                this.path = currentPaths.length == 0;
            },
            immediate: true
        },
    },
   created(){
   },
   computed:{
   },
   mounted(){
   },
   methods:{
   },
}
</script>
<style lang="scss" scoped>
.navigation-b{
  position:fixed;
  bottom: 0;
}
</style>
