<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">Define Your Trading Experience</p>
        <p>Let’s decide your level of involvement:</p>
        <p>Do you thrive on making real-time decisions, steering through the Crypto Racetrack? Or would you rather entrust your trades to <span class="text-black01 font-weight-bold">Autopilot</span>, optimized for your goals?</p>
        <p>Autopilot leverages expert-inspired strategies:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Frequent, low-risk profits—echoing Soros’ tactical edge.</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Diversified, data-driven returns—Dalio’s risk mastery.</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Long-term wealth creation—Buffett’s proven patience.</li>
        </ul>
        <p>Ideal for consistent results and learning the ropes, Autopilot lets you switch to manual trading whenever you choose.</p>
        <p>Your preference?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot - Hands-Off Precision' },
                { code: 'Manual', message: 'Crypto Racetrack - Active Control' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>