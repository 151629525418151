<template>
	<div class="bg-f2f h-100">
        <v-container class="h-100" align="center">
            <!-- ----------------------------- Back -------------------------------- -->
            <v-card max-width="604" rounded="0" elevation="0" color="transparent" class="pt-4" align="start">
                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3" :class="responsive != 'pc' ? 'ml-2' : ''" @click="$router.back(-1)"></v-icon>
            </v-card>
            <!-- ----------------------------- List -------------------------------- -->
            <v-card max-width="1400" rounded="0" elevation="0" color="transparent" class="mx-auto">
                <v-card max-width="604" elevation="6"  color="transparent" class="rounded-lg py-0" :loading="loading">
                    <template v-slot:loader="{ isActive }">
                        <v-progress-linear :active="isActive" color="primary" indeterminate></v-progress-linear>
                    </template>
                    <v-row no-gutters class="py-4 bg-black" :class="responsive == 'pc' ? 'px-8' : 'px-4'">
                        <v-col cols="8" class="text-left">
                            <h3 class="body-h4 text-primaryGrey">
                                <span v-if="unCrypto.contractFunction == 'UNWRAP'">Unwrapped</span>
                                <span v-else-if="!listInfo.isListed">Sell - Limit</span>
                                <span v-else="listInfo.isListed">Change price or unlist</span>
                            </h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="text-right text-primaryGrey body-p">
                            <!-- 区块链 -->
                            <Blockchain :blockchain="unCrypto.blockchain" size="24" text></Blockchain>
                        </v-col>
                    </v-row>
                    <!-- <v-divider class="my-4"></v-divider> -->
                    <div class="bg-f2f2f2-666666 pt-2 pb-4 text-left elevation-show" :class="responsive == 'pc' ? 'px-8' : 'px-4'">
                        <!-- <h1 class="sub-page-h1 text-grey05">
                            {{ assetInfo.currentAssetAmount > 0 ? $tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals) : '' }}
                            {{ unCrypto.wrappedCurrency }} #{{ $tools.formatNumber(unCrypto.tokenId, 0) }}
                        </h1> -->
                        <v-row no-gutters class="my-2">
                            <v-col cols="12" class="text-grey05 body-p-large mb-1">
                                <div class="d-flex justify-space-between">
                                    <div class="gradient-underline-hover">
                                        <router-link :to="`/uncrypto/${unCrypto.wrappedCurrency?.toLowerCase()}/${unCrypto.id}`" class="text-grey05 gradient-text-hover">
                                            {{ unCrypto.wrappedCurrency }} #{{ $tools.formatNumber(unCrypto.tokenId, 0) }}
                                        </router-link>
                                    </div>
                                    <div class="title-h3">{{ (unCrypto?.cryptoProfitExpectancy && unCrypto?.cryptoProfitExpectancy?.profitLowestPriceChangeRatio) ? `${unCrypto?.cryptoProfitExpectancy?.name} (+${unCrypto?.cryptoProfitExpectancy?.profitLowestPriceChangeRatio * 100}%)` : unCrypto?.cryptoProfitExpectancy?.name }}</div>
                                </div>
                            </v-col>
                            <v-col cols="12" class="text-grey05">
                                <div class="d-flex justify-space-between">
                                    <div class="home-h3">{{ assetInfo.currentAssetAmount > 0 ? $tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals) : '' }}</div>
                                    <div class="title-h3" v-if="responsive == 'pc'">{{ unCrypto.wrappedCurrency }}/{{ unCrypto.quoteCurrency }}</div>
                                </div>
                            </v-col>
                            <!-- <v-col cols="12" lg="4" class="text-grey05 title-h3" v-if="responsive == 'pc'">
                                {{ unCrypto.wrappedCurrency }}/{{ unCrypto.quoteCurrency }}
                            </v-col> -->
                        </v-row>
                        <v-divider class="py-1"></v-divider>
                        <v-row no-gutters justify="space-between">
                            <h3 class="text-grey05 body-h5">Non-fungible Future Rewards (nFR) Parameters</h3>
                            <v-col cols="12" class="px-0">
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <TotalRewardValue :value="unCrypto.totalRewardRatio" text-color="text-grey05"></TotalRewardValue>
                                    </v-col>
                                    <v-col cols="6">
                                        <FutureRewardValue :value="100 - unCrypto.originatorRewardRatio" text-color="text-grey05"></FutureRewardValue>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <OriginatorRewardValue :value="unCrypto.originatorRewardRatio" text-color="text-grey05"></OriginatorRewardValue>
                                    </v-col>
                                    <v-col cols="6">
                                        <GenerationValue :value="unCrypto.numberOfGenerations" text-color="text-grey05"></GenerationValue>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="unCrypto.contractFunction != 'UNWRAP'" class="bg-black text-primaryGrey py-15 text-left elevation-show" :class="responsive == 'pc' ? 'px-8' : 'px-4'">
                        <!-- 数量 -->
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h5 class="text-primaryGrey body-h5">I want to sell</h5>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-space-between align-center">
                                <v-text-field v-model="listCrypto.size" :hide-details="true" type="number" placeholder="0" :step="$tools.getTickStep(unCrypto?.cryptoWrapped?.sizeDecimals)" :min="0" density="comfortable" class="text-primaryGrey list-input-font" variant="plain"></v-text-field>  
                                <div class="ml-2">
                                    <span class="list-p text-primaryGrey">{{ unCrypto.wrappedCurrency }}</span>
                                </div>
                            </v-col>
                            <!-- <v-col cols="4" class="text-right">
                                <span class="list-p text-primaryGrey">{{ unCrypto.wrappedCurrency }}</span>
                            </v-col> -->
                            <v-col cols="12">
                                <div class="body-p-small">
                                    <!-- <span>Supply: {{ $tools.formatNumber(assetInfo.currentAssetAmount, cryptoWrapped.sizeDecimals) }}</span> -->
                                    <span>
                                        <span class="pointer" :class="(listCrypto.size != 0 && !listCrypto.max && listCrypto.size == (assetInfo.currentAssetAmount * 0.25).toFixed(cryptoWrapped.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="listCrypto.size = assetInfo.currentAssetAmount * 0.25">25%</span>
                                        <span class="pointer ml-3" :class="(listCrypto.size != 0 && !listCrypto.max && listCrypto.size == (assetInfo.currentAssetAmount * 0.5).toFixed(cryptoWrapped.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="listCrypto.size = assetInfo.currentAssetAmount * 0.5">50%</span>
                                        <span class="pointer ml-3" :class="(listCrypto.size != 0 && !listCrypto.max && listCrypto.size == (assetInfo.currentAssetAmount * 0.75).toFixed(cryptoWrapped.sizeDecimals)) ? 'text-primary font-weight-bold': ''" @click="listCrypto.size = assetInfo.currentAssetAmount * 0.75">75%</span>
                                        <span class="pointer ml-3" :class="(listCrypto.size != 0 && (listCrypto.max || listCrypto.size == (assetInfo.currentAssetAmount * 1).toFixed(cryptoWrapped.sizeDecimals))) ? 'text-primary font-weight-bold': ''" @click="listCrypto.size = assetInfo.currentAssetAmount">Max</span>
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-5"></v-divider>
                        <!-- 价格 -->
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h5 class="text-primaryGrey body-h5">at a Limit Price of</h5>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-space-between align-center">
                                <v-text-field v-model="listCrypto.limitPrice" :hide-details="true" type="number" placeholder="0" :step="$tools.getTickStep(unCrypto?.cryptoWrapped?.priceDecimals)" :min="0" density="comfortable" class="list-input-font text-primaryGrey" variant="plain"></v-text-field>
                                <div class="ml-2">
                                    <span class="list-p text-primaryGrey">{{ unCrypto.quoteCurrency }}</span>
                                </div>
                            </v-col>
                            <!-- <v-col cols="4" class="text-right">
                                <span class="list-p text-primaryGrey">{{ unCrypto.quoteCurrency }}</span>
                            </v-col> -->
                            <v-col cols="12">
                                <div class="body-p-small">
                                    <span v-if="allowSetToMarket" class="pointer" :class="equalsLimitPrice(0) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(0)">Set to market</span>
                                    <span v-else>Market</span>
                                    <span> ~ ${{ $tools.formatNumber(marketPrice, cryptoWrapped.priceDecimals) }}</span>
                                </div>
                                <div class="body-p-small mt-2">
                                    <!-- <span>
                                        <span class="pointer" :class="equalsLimitPrice(0.025) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(0.025)">+2.5%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(0.05) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(0.05)">+5.0%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(0.10) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(0.1)">+10%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(0.15) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(0.15)">+15%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(0.25) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(0.25)">+25%</span>
                                        <span class="ml-3">|</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(-0.025) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(-0.025)">-2.5%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(-0.05) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(-0.05)">-5.0%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(-0.10) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(-0.1)">-10%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(-0.15) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(-0.15)">-15%</span>
                                        <span class="pointer ml-3" :class="equalsLimitPrice(-0.25) ? 'text-primary font-weight-bold': ''" @click="calcLimitPrice(-0.25)">-25%</span>
                                    </span> -->
                                    <span v-show="priceRange.enabled">
                                        <span class="pointer" :class="listCrypto.limitPrice == priceRange?.profitLowestPrice ? 'text-primary font-weight-bold': ''" @click="setLimitPrice(priceRange?.profitLowestPrice)">+{{ $tools.formatNumber(priceRange?.profitLowestPriceChangeRatio * 100, -1) }}%</span>
                                        <span class="mx-3">|</span>
                                        <span class="pointer" :class="listCrypto.limitPrice == priceRange?.lossPrice ? 'text-primary font-weight-bold': ''" @click="setLimitPrice(priceRange?.lossPrice)">{{ $tools.formatNumber(priceRange?.lossPriceChangeRatio * 100, -1) }}%</span>
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- 比率 -->
                        <v-row no-gutters class="mt-2">
                            <v-col cols="12">
                                <!-- <v-text-field density="comfortable" :model-value="`1 ${unCrypto.wrappedCurrency} = ${$tools.formatNumber(listCrypto.limitPrice, cryptoWrapped.priceDecimals)} ${unCrypto.quoteCurrency}`" class="body-p-small text-primaryGrey" variant="solo" readonly></v-text-field> -->
                                <span class="body-p-small">{{ `1 ${unCrypto.wrappedCurrency} = ${$tools.formatNumber(listCrypto.limitPrice, cryptoWrapped.priceDecimals)} ${unCrypto.quoteCurrency}` }}</span>
                            </v-col>
                            <!-- <v-col cols="4">
                                <v-select :items="Expirations" density="comfortable" class="text-primaryGrey" label="Expiration"  variant="outlined"></v-select>
                            </v-col> -->
                        </v-row>
                        <!-- 摘要 -->
                        <v-row no-gutters class="mt-2">
                            <!-- <v-col cols="12">
                                <h5 class="text-primaryGrey body-h5 mb-1">at a Limit Price of</h5>
                            </v-col> -->
                            <v-col cols="12">
                                <!-- <v-text-field density="comfortable" :model-value="`You will sell ${$tools.formatNumber(listCrypto.size, cryptoWrapped.sizeDecimals)} ${unCrypto.wrappedCurrency} for ${$tools.formatNumber(listCrypto.price, cryptoWrapped.priceDecimals)} ${unCrypto.quoteCurrency}`" class="body-p-small text-primaryGrey" variant="solo" readonly></v-text-field> -->
                                <span class="text-primaryGrey body-h5">{{ `You will sell ${$tools.formatNumber(listCrypto.size, cryptoWrapped.sizeDecimals)} ${unCrypto.wrappedCurrency} for ${$tools.formatNumber(listCrypto.price, cryptoWrapped.priceDecimals)} ${unCrypto.quoteCurrency} (~ $${$tools.formatNumber(listCrypto.price * quoteCurrencyMarketPrice, 2)})` }}</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
                <!-- 操作 -->
                <v-card max-width="588" elevation="0" color="transparent" align="start" :class="responsive == 'pc' ? 'px-9' : 'px-7'">
                    <!-- 没有被解封则显示操作按钮 -->
                    <div v-if="unCrypto.contractFunction != 'UNWRAP'" class="my-4">
                        <!-- 未上市则显示上市 -->
                        <div v-if="!listInfo.isListed">
                            <Button variant="primary" rounded="lg" :loading="listLoading" block color="black03" height="52" :disabled="isDisabledList" @click="list()" aria-label="List for sale">List for sale</Button>
                            <div class="my-4">
                                <Button variant="secondary" :to="`/uncrypto/${unCrypto.wrappedCurrency?.toLowerCase()}/${unCrypto.id}`" :disabled="listLoading || unlistLoading" block rounded="lg" color="black01" height="52" aria-label="View">View</Button>
                            </div>
                        </div>
                        <!-- 已上市显示修改价格 -->
                        <div v-if="listInfo.isListed && responsive == 'pc'">
                            <div class="my-4">
                                <Button variant="primary" :to="`/uncrypto/${unCrypto.wrappedCurrency?.toLowerCase()}/${unCrypto.id}`" :disabled="listLoading || unlistLoading" block rounded="lg" color="black03" height="52" aria-label="View">View</Button>
                            </div>
                            <div class="d-flex justify-space-between">
                                <Button variant="secondary" :loading="listLoading" :disabled="unlistLoading || isDisabledList" rounded="lg" color="black01" height="52" width="245" @click="list()" aria-label="Change price">Change price</Button>
                                <Button variant="secondary" :loading="unlistLoading" :disabled="listLoading" rounded="lg" color="black01" height="52"  width="245" @click="unlist()" aria-label="Unlist">Unlist</Button>
                            </div>
                        </div>
                        <!-- 已上市显示修改价格 -->
                        <div v-if="listInfo.isListed && responsive != 'pc'">
                            <span>
                                <Button variant="primary" :to="`/uncrypto/${unCrypto.wrappedCurrency?.toLowerCase()}/${unCrypto.id}`" :disabled="listLoading || unlistLoading" block rounded="lg" color="black03" height="52" aria-label="View">View</Button>
                            </span>
                            <span><Button variant="secondary" :loading="listLoading" :disabled="unlistLoading" rounded="lg" color="black01" height="52" width="300" @click="list()" aria-label="Change price">Change price</Button></span>
                            <span><Button variant="secondary" :loading="unlistLoading" :disabled="listLoading" rounded="lg" color="black01" height="52" width="300" @click="unlist()" aria-label="Unlist">Unlist</Button></span>
                        </div>
                    </div>
                    <!-- 提示 -->
                    <v-row no-gutters v-if="unCrypto.contractFunction != 'UNWRAP'">
                        <v-col cols="12">
                            <h6 class="text-primaryGrey body-p-small mb-5">
                                Even if you see that the current market price on the chart matches your limit price, there is no guarantee that your limit order will be filled. In a fast-moving market, you may miss out on an opportunity to sell a security at a better price.
                            </h6>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-container>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
        <Reown ref="Reown" @transactionClose="transactionClose"></Reown>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { Web3 } from "web3";
import CryptoAPI from '@/api/crypto.js';
import CryptoWrappedAPI from '@/api/crypto-wrapped.js';
import CryptoQuoteAPI from '@/api/crypto-quote.js';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 加载中
            loading: false,
            // 封装后的加密货币
            unCrypto: {},
            // 上市/下市参数
            listCrypto: {
                limitPrice: 0,      // 单价
                price: 0,           // 总价
                size: 0,            // 数量
                max: false          // 是否为最大数量
            },
            // 当前的区块链
            currentBlockchain: {},
            // 当前的Web3服务对象
            web3: {},
            // 当前的合约对象
            contract: {},
            // 资产信息
            assetInfo: {
                // wei 单位
                currentAssetAmountWei: 0,
                // 前端显示单位
                currentAssetAmount: 0
            },
            // 上市信息
            listInfo: {
                price: 0,       // 上市单价
                amount: 0,      // 上市数量
                lister: null,   // 上市人
                isListed: false // 是否已经上市
            },
            // 加密货币封装资产
            cryptoWrapped: {},
            // 加密货币报价资产
            cryptoQuote: {},
            // 上市加载中
            listLoading: false,
            // 下市加载中
            unlistLoading: false,
            // 交易哈希
            transactionHash: null,
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 价格范围
            priceRange: {},
            // 是否允许设置为市场价格
            allowSetToMarket: false,
        }
    },
    components: { },
    created(){
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "CRYPTO_LIST") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){
        // 根据主键查询加密货币
        this.getCryptoById();
    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'walletType', 'prices', 'responsive']),
        // 禁用上市按钮
        isDisabledList() {
            return this.listCrypto.limitPrice <= 0 || this.listCrypto.size <= 0 || (this.listCrypto.size > this.assetInfo.currentAssetAmount && !this.listCrypto.max);
        },
        // 是否为拥有者
        isOwner() {
            return this.user?.id == this.unCrypto?.ownerUserId;
        },
        // 市场价格
        marketPrice() {
            return this.prices.filter(P => P.currency == this.unCrypto?.cryptoWrapped?.underlyingCurrency)[0]?.price || 0;
        },
        // 报价代币市场价格
        quoteCurrencyMarketPrice() {
            return this.prices.filter(P => P.currency == this.unCrypto?.quoteCurrency)[0]?.price || 0;
        }
    },
    watch:{
        // 监听数量改变
        'listCrypto.size': {
            handler(n, o) {
                if(n != null && n != "") {
                    this.listCrypto.size = Number(Number(n).toFixed(this.cryptoWrapped.sizeDecimals));
                    this.listCrypto.price = Number((this.listCrypto.limitPrice * this.listCrypto.size).toFixed(this.cryptoWrapped.priceDecimals));
                    this.listCrypto.max = (this.listCrypto.size == this.assetInfo.currentAssetAmount);
                } else {
                    this.listCrypto.size = 0;
                    this.listCrypto.max = false;
                }
            }
        },
        // 监听价格改变
        'listCrypto.limitPrice': {
            handler(n, o) {
                if(n != null && n != "") {
                    this.listCrypto.limitPrice = Number(Number(n).toFixed(this.cryptoWrapped.priceDecimals));
                    this.listCrypto.price = Number((this.listCrypto.limitPrice * this.listCrypto.size).toFixed(this.cryptoWrapped.priceDecimals));
                    this.listCrypto.max = (this.listCrypto.size == this.assetInfo.currentAssetAmount);
                } else {
                    this.listCrypto.limitPrice = 0;
                    this.listCrypto.price = 0;
                    this.listCrypto.max = false;
                }
            }
        },
    },
    methods: {
         // 改变区块链条件
         changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
        },
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.id == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoAPI.getCryptoById(this.id);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
                // 不是拥有者则跳转到详情页面
                if (!this.isOwner) {
                    this.$router.push(`/uncrypto/${this.unCrypto?.wrappedCurrency?.toLowerCase()}/${this.id}`);
                    return;
                }
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.unCrypto.blockchain)[0]
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 创建合约
                this.contract = new this.web3.eth.Contract(this.currentBlockchain.unCryptoAbi, this.unCrypto.contractAddress);
                // 根据区块链和地址查询加密货币封装资产详情
                await this.getCryptoWrappedDetail();
                // 根据区块链和地址查询加密货币报价资产详情
                await this.getCryptoQuoteDetail();
                // 查询价格范围
                await this.getPriceRange();
                // 查询资产信息
                this.getAssetInfo();
                // 查询上市信息
                this.getListInfo();
                // 以下临时测试方法
                // 查询 OR 信息
                // this.getORInfo();
                // 查询 FR 信息
                // this.getFRInfo();
            }
            this.loading = false;
        },
        // 查询资产信息
        async getAssetInfo() {
            let result = await this.contract.methods.getAssetInfo(this.unCrypto.tokenId).call();
            this.assetInfo.currentAssetAmountWei = result;
            this.assetInfo.currentAssetAmount = this.$web3Utils.fromWei(result, this.cryptoWrapped.tokenDecimals);
            this.assetInfo.currentAssetAmount = Number(this.assetInfo.currentAssetAmount.toFixed(this.cryptoWrapped.sizeDecimals));
            this.listCrypto.max = (this.listCrypto.size == this.assetInfo.currentAssetAmount);
            // console.log('AssetInfo: ', result);
            // console.log('AssetInfo: ', this.assetInfo);
        },
        // 查询上市信息
        async getListInfo() {
            let result = await this.contract.methods.getListInfo(this.unCrypto.tokenId).call();
            this.listInfo.price = this.$web3Utils.fromWei(result[0], this.cryptoQuote.tokenDecimals);
            this.listInfo.amount = this.$web3Utils.fromWei(result[1], this.cryptoWrapped.tokenDecimals);
            this.listInfo.lister = result[2];
            this.listInfo.isListed = result[3];
            this.listCrypto.limitPrice = this.listInfo.price;
            this.listCrypto.price = this.listInfo.price * this.listInfo.amount;
            this.listCrypto.size = this.listInfo.amount;
            this.listCrypto.max = (this.listCrypto.size == this.assetInfo.currentAssetAmount);
            // console.log('ListInfo: ', result);
            // console.log('ListInfo: ', this.listInfo);
            // console.log(this.web3.utils.hexToNumberString('0x00000000000000000000000000000000000000000000000000000000d693a400'))
        },
        // 查询 OR 信息
        async getORInfo() {
            let result = await this.contract.methods.getORInfo(this.unCrypto.tokenId).call();
            console.log('OR: ', result);
        },
        // 查询 FR 信息
        async getFRInfo() {
            let result = await this.contract.methods.getFRInfo(this.unCrypto.tokenId).call();
            console.log('FR: ', result);
        },
        // 根据区块链和地址查询加密货币封装资产详情
        async getCryptoWrappedDetail() {
            let res = await CryptoWrappedAPI.getCryptoWrappedDetail(this.unCrypto.blockchain, this.unCrypto.contractAddress);
            let data = res.data;
            if(data.success) {
                this.cryptoWrapped = data.data;
            }
        },
        // 根据区块链和地址查询加密货币报价资产详情
        async getCryptoQuoteDetail() {
            let res = await CryptoQuoteAPI.getCryptoQuoteDetail(this.unCrypto.blockchain, this.unCrypto.paymentTokenAddress);
            let data = res.data;
            if(data.success) {
                this.cryptoQuote = data.data;
            }
        },
        // 计算限价：价格改变比例小数
        calcLimitPrice(priceChange) {
            // 如果没有上一次成交价，则默认为市场价
            if (this.priceRange?.lastPrice == null || this.priceRange?.lastPrice == 0) {
                this.priceRange.lastPrice = this.marketPrice;
            }
            if (priceChange == 0) {
                this.listCrypto.limitPrice = this.marketPrice;
            } else {
                this.listCrypto.limitPrice = Number((this.priceRange?.lastPrice * (1 + priceChange)).toFixed(this.cryptoWrapped.priceDecimals));
            }
        },
        // 设置限价：价格
        setLimitPrice(price) {
            this.listCrypto.limitPrice = price;
        },
        // 计算是否等于限价
        equalsLimitPrice(priceChange) {
            // 如果没有上一次成交价，则默认不匹配
            if (this.priceRange?.lastPrice == null || this.priceRange?.lastPrice == 0) {
                return false;
            }
            // 如果是0，则代表市场价
            if (priceChange == 0) {
                return this.listCrypto.limitPrice == this.marketPrice;
            } else {
                return this.listCrypto.limitPrice == Number((this.priceRange?.lastPrice * (1 + priceChange)).toFixed(this.cryptoWrapped.priceDecimals));
            }
        },
        // 上市
        async list() {
            if(Number(this.listCrypto.size) <= 0 || this.listCrypto.size > this.assetInfo.currentAssetAmount) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild size");
                return;
            }
            if(Number(this.listCrypto.limitPrice) <= 0) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild price");
                return;
            }
            // v1：判断价格范围
            // if (this.priceRange.enabled) {
            //     if (this.priceRange.minUnallowedPrice < Number(this.listCrypto.limitPrice) && Number(this.listCrypto.limitPrice) < this.priceRange.maxUnallowedPrice) {
            //         let priceChange = this.$tools.formatNumber(parseFloat((this.priceRange.priceChange * 100).toPrecision(10)), -1)  + '%'
            //         this.$store.dispatch('snackbarMessageHandler', "Listed price must be greater than ±" + priceChange + " of purchased price.");
            //         return;
            //     }
            // }
            // 20241016：判断盈利预期价格改变范围
            if (this.priceRange.enabled) {
                if ((this.priceRange.lossPrice < Number(this.listCrypto.limitPrice) && Number(this.listCrypto.limitPrice) < this.priceRange.profitLowestPrice) || Number(this.listCrypto.limitPrice) > this.priceRange.profitHighestPrice) {
                    this.$store.dispatch('snackbarMessageHandler', "Listed price must be less than " + this.$tools.formatNumber(this.priceRange.lossPrice, -1) + " or between " + this.$tools.formatNumber(this.priceRange.profitLowestPrice, -1) + " and " + this.$tools.formatNumber(this.priceRange.profitHighestPrice, -1));
                    return;
                }
            }
            // 加载中
            this.listLoading = true;
            // 编码参数列表
            try {
                let method  = this.web3.eth.abi.encodeFunctionSignature('list(uint256,uint256,uint256)');
                let priceWei = this.$web3Utils.toWei(this.listCrypto.limitPrice, this.cryptoQuote.tokenDecimals);
                let sizeWei = this.$web3Utils.toWei(this.listCrypto.size, this.cryptoWrapped.tokenDecimals);
                // 如果设置为购买最大数量，则查询资产信息直接使用剩余全部数量
                if (this.listCrypto.max) {
                    sizeWei = this.assetInfo.currentAssetAmountWei;
                }
                // 将参数编码
                let param = this.web3.eth.abi.encodeParameters(['uint256', 'uint256', 'uint256'], [this.unCrypto.tokenId, sizeWei, priceWei]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.unCrypto.blockchain, this.user.wallet, this.unCrypto.contractAddress, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.listLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.listLoading = false;
            }
        },
        // 下市
        async unlist() {
            // 加载中
            this.unlistLoading = true;
            // 编码参数列表
            try {
                let method  = this.web3.eth.abi.encodeFunctionSignature('unlist(uint256)');
                // 将参数编码
                let param = this.web3.eth.abi.encodeParameters(['uint256'], [this.unCrypto.tokenId]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.unCrypto.blockchain, this.user.wallet, this.unCrypto.contractAddress, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.unlistLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.unlistLoading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 加载完成
                this.listLoading = false;
                this.unlistLoading = false;
            }
        },
        // 查询交易收据
        getTransactionReceipt(){
            let params = {
                type: "CRYPTO_LIST",
                blockchain: this.unCrypto.blockchain,
                transactionHash: this.transactionHash
            }
            this.$bus.emit('emitGetTransactionReceipt', params);
            // // 轮训查询交易
            // this.transactionReceiptInterval = setInterval(() => {
            //     let params = {
            //         type: "CRYPTO_LIST",
            //         blockchain: this.unCrypto.blockchain,
            //         transactionHash: this.transactionHash
            //     }
            //     this.$bus.emit('emitGetTransactionReceipt', params);
            // }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                // 查询资产信息
                this.getAssetInfo();
                // 查询上市信息
                this.getListInfo();
                // 加载完成
                this.listLoading = false;
                this.unlistLoading = false;
            } else {
                // 延时3s再次查询
                setTimeout(() => {
                    // 查询交易收据
                    this.getTransactionReceipt();
                }, 3000);
            }
        },
        // 根据加密货币主键查询价格范围
        async getPriceRange() {
            let res = await CryptoAPI.getPriceRange(this.id);
            let data = res.data;
            if(data.success) {
                this.priceRange = data.data;
                // 判断是否允许设置为市场价格
                this.allowSetToMarket = (this.priceRange.marketPrice <= this.priceRange.lossPrice) || (this.priceRange.profitLowestPrice <= this.priceRange.marketPrice && this.priceRange.marketPrice <= this.priceRange.profitHighestPrice);
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        next();
    }
}
</script>
<style lang="scss">
.list-input-font .v-input__control .v-field__field .v-field__input {
    font-size: 40px !important;
    padding: 0 !important;
    line-height: 40px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700!important;
}
</style>
<style scoped lang="scss">
.elevation-show {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15),
     5px -5px 10px rgba(0, 0, 0, 0.15),
     0px 0px 0px,
     -5px -5px 10px rgba(0, 0, 0, 0.15),
}
</style>