<template>
	<div>
        <div :id="nftId" style="width: 100%; height: 486px; position: relative;" v-if="responsive == 'pc'"></div>
        <div :id="nftId" style="width: 100%; height: 30vh; position: relative;" v-if="responsive == 'mobile'"></div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { Web3 } from "web3";
import ForwardAPI from '@/api/forward.js';
import NFTProvenanceAPI from '@/api/nft-provenance.js';
import NFTs from '@/api/data/nfts.json';
export default {
    data(){
        return {
            nftPriceLineChart: null,
            option : {
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    splitLine:{
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: []
                },
                grid: {
                    top: '2.5%',
                    left: '0%',
                    right: '0%',
                    containLabel: true
                },
                yAxis: [{
                    type: 'value',
                    name: null,
                    nameGap: -7,
                    nameTextStyle: {
                        align: 'left'
                    },
                    position: 'left',
                    axisTick: {
                        show: false,
                    },
                    splitLine:{
                        show: false
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false
                    },
                    scale: true
                },{
                    type: 'value',
                    position: 'right',
                    offset: 20,
                    min: 0,
                    max: 0,
                    interval: 0,
                    axisTick: {
                        show: false,
                    },
                    splitLine:{
                        show: false
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        align: "right",
                        formatter: function (value, index) {           
                            return value.toFixed(2);
                        }
                    },
                    scale: true
                }],
                series: [{
                    data: [],
                    type: 'line',
                    smooth: true,
                    symbolSize: 5,
                    symbol: 'circle',
                    itemStyle: {
                        color: "#95c8ff",
                    },
                    lineStyle: {
                        color: '#95c8ff',
                        width: 5
                    },
                }]
            }
        }
    },
    props: {
        nftId: {
            type: String,
            default: null
        },
        tokenId: {
            type: [Number, String],
            default: null
        }
    },
    components: {  },
    created() {
      
    },
    mounted(){
       
    },
    computed: {

    },
    computed: {
        ...mapGetters(['darkTheme', 'responsive'])
    },
    watch:{
        nftId: {
            handler(newVal, oldVal) {
                this.$nextTick(() => {
                    this.initFN();
                });
            },
            immediate: true
        },
        darkTheme(val) {
            this.option.series[0].lineStyle.color = this.darkTheme == 0 ? '#95c8ff':'#004080';
            this.option.series[0].itemStyle.color = this.darkTheme == 0 ? '#95c8ff':'#0051a9';
            this.getPriceLineChart();
        },
    },
    methods: {
        // 初始化
        initFN() {
            var that = this;
            // 渲染图表
            this.nftPriceLineChart = this.$echarts.init(document.getElementById(this.nftId));
            // 填充数据
            this.nftPriceLineChart.setOption(this.option, true);
            this.nftPriceLineChart.on('click', function(series) {

            })
            // 获取价格折线图数据
            this.getPriceLineChart();
        },
        // 获取价格折线图数据
        async getPriceLineChart() {
            setTimeout( async () => {
                let res = await NFTProvenanceAPI.getPriceHistories(this.nftId);
                let data = res.data;
                if(data.success) {
                    let chartResult = data.data;
                    this.option.yAxis[0].name = chartResult.paymentToken;
                    if(chartResult.data.length == 0) {
                        chartResult.data = [0];
                    }
                    this.option.series[0].data = chartResult.data;
                    // 计算最小值/最大值/间隔
                    let minValue = Math.min(...chartResult.data);
                    let maxValue = Math.max(...chartResult.data);
                    if(minValue == maxValue){
                        this.option.yAxis[1].min = 0;
                        this.option.yAxis[1].max = minValue * 2;
                        this.option.yAxis[1].interval = minValue > 0 ? (minValue * 2) : 1;
                    } else {
                        this.option.yAxis[1].min = minValue;
                        this.option.yAxis[1].max = maxValue;
                        this.option.yAxis[1].interval = maxValue - minValue;
                    }
                    this.option.series[0].lineStyle.color = this.darkTheme == 0 ? '#95c8ff':'#004080';
                    this.option.series[0].itemStyle.color = this.darkTheme == 0 ? '#95c8ff':'#0051a9';
                }
                // 填充数据
                this.nftPriceLineChart.setOption(this.option, true);
            }, 100);
            
        },
    }
}
</script>
<style lang="scss" scoped>
// .nftPriceLineChart {
//     width: 400px;
//     height: 486px;
//     position: relative;
// }
</style>