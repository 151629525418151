<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">The Patient Investor's Path</p>
        <p >You've always known: Markets fluctuate, but value endures. Patience rewards, while haste costs. True wealth builds across generations.</p>
        <p class="text-black01 font-weight-bold">Untrading amplifies your wisdom:</p>
        <ul class="ml-8 custom-ul">
            <li>Capture value at peaks</li>
            <li>Earn perpetually through Future Rewards</li>
            <li>Build lasting family legacies</li>
        </ul>
        <p >Because real investing isn't about quarterly returns. It's about creating value that grows across decades.</p>
        <p >Join the evolution of value investing.</p>
        <ul class="ml-8 custom-ul">
            <li>Where patience meets perpetual prosperity.</li>
            <li>Where conviction creates lasting wealth.</li>
            <li>Where discipline defines generations.</li>
        </ul>
        <p >Welcome to timeless value creation.</p>
    </div>
</template>