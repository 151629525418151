<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">The Trader's Paradox: Chasing Momentum</h3>
        <p>Got it! You live for those moments—when patterns align, indicators flash green, and profits soar. Trading isn't just your strategy; it's your passion. But every experienced trader knows the dark side: sudden reversals, stop hunts, and the constant psychological warfare of the markets.</p>
        <p>What if there was a better way?</p>
        <p class="text-black01 font-weight-bold">THE TRADITIONAL PATH:</p>
        <ul class=" ml-8 custom-ul">
            <li>Lightning-fast gains (when you're right)</li>
            <li>Leverage amplifies your positions</li>
            <li>Profit from market volatility</li>
        </ul>
        <p class="text-black01 font-weight-bold">THE HIDDEN COSTS:</p>
        <ul class=" ml-8 custom-ul">
            <li>Emotional exhaustion from constant vigilance</li>
            <li>Capital erosion from fees and spreads</li>
            <li>The perpetual cycle of starting over</li>
        </ul>
        <p class="text-black01 font-weight-bold">Introducing Untrading: Evolution Beyond the Exit</p>
        <p>We've reimagined the relationship between traders and their assets. With Untrading, your connection to value doesn't end at the sell button.</p>
        <p class="text-black01 font-weight-bold">REVOLUTIONARY FEATURES:</p>
        <ul class=" ml-8 custom-ul">
            <li>Post-Sale Earnings: Your profits keep growing even after exit</li>
            <li>Zero Hidden Costs: Complete transparency in every transaction</li>
            <li>Future Rewards System: Benefit from asset appreciation indefinitely</li>
            <li>Community-Driven Growth: Success builds upon success</li>
        </ul>
        <p>Why start from zero when you can build lasting value? Join the Untrading revolution—where every trade becomes a stepping stone to sustained growth.</p>
        <p>Your next trade could be your last fresh start.</p>
        <br><hr>
		<p>You’ve explored your trading style and learned how Untrading can help you succeed. Who motivates your trading journey today? 😊</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>