<template>
	<div>
        <!-- flaticon - https://www.flaticon.com/ -->
        <div class="rounded-circle pa-1" :class="bgColor" :style="{width: size + 'px', height: size + 'px'}" >
            <v-img v-if="name == 'Hummingbird'" src="/img/profitExpectancies/Hummingbird.png"></v-img>
            <v-img v-if="name == 'Shrew'" src="/img/profitExpectancies/Shrew.png"></v-img>
            <v-img v-if="name == 'Squirrel'" src="/img/profitExpectancies/Squirrel.png"></v-img>
            <v-img v-if="name == 'Rabbit'" src="/img/profitExpectancies/Rabbit.png"></v-img>
            <v-img v-if="name == 'Deer'" src="/img/profitExpectancies/Deer.png"></v-img>
            <v-img v-if="name == 'Fox'" src="/img/profitExpectancies/Fox.png"></v-img>
            <v-img v-if="name == 'Wolf'" src="/img/profitExpectancies/Wolf.png"></v-img>
            <v-img v-if="name == 'Lion'" src="/img/profitExpectancies/Lion.png"></v-img>
            <v-img v-if="name == 'Elephant'" src="/img/profitExpectancies/Elephant.png"></v-img>
            <v-img v-if="name == 'Crocodile'" src="/img/profitExpectancies/Crocodile.png"></v-img>
            <v-img v-if="name == 'Python'" src="/img/profitExpectancies/Python.png"></v-img>
            <v-img v-if="name == 'Whale'" src="/img/profitExpectancies/Whale.png"></v-img>
        </div>
	</div>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            default: null,
            required: true
        },
        size: {
            type: [Number, String],
            default: 24,
            required: false
        },
        bgColor: {
            type: String,
            default: 'bg-primary',
            required: false
        }
    },
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>