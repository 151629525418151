<template>
	<div class="markdown prose">
		<p>Awesome! Imagine this: You’ve made the perfect trade, capitalizing on volatility and walking away with solid profits. But then, days later, you see the asset you sold skyrocketing. Frustrating, right? What if there was a way to still benefit from that upward momentum?</p>
		<p>Introducing Untrading, a groundbreaking concept that flips traditional trading on its head. Instead of walking away empty-handed after a sale, you stay linked to the asset’s journey. Through Future Rewards (FRs), you earn a share of its continued appreciation—all without paying extra fees or dealing with murky terms. Whether you’re battling FOMO or navigating choppy markets, Untrading ensures your success story doesn’t have to end at “sell.”</p>
		<br><hr>
		<p>You’ve found your trading style—now, who motivates your trading journey? 😊</p>
		<!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>