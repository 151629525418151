<template>
	<div class="markdown prose">
        <p>I appreciate it! While aiming high is understandable, here’s what you should know:</p>
        <p class="text-black01 font-weight-bold">What You’re Hoping For:</p>
        <ul class="ml-8 custom-ul">
            <li>A rare asset that skyrockets 100x in days could make life-changing profits.</li>
        </ul>
        <p class="text-black01 font-weight-bold">What Usually Happens:</p>
        <ul class="ml-8 custom-ul">
            <li>Most “moonshot” investments collapse just as quickly as they rise.</li>
            <li>Scammers thrive on this mindset, luring people into traps with false promises.</li>
            <li>Emotional decision-making often leads to panic selling during dips or holding too long during crashes.</li>
        </ul>
        <p>At Untrading, we believe your gains shouldn’t end at the sell button.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Selling is just the beginning: </span>Stay linked to your assets post-sale.</li>
            <li><span class="text-black01 font-weight-bold">No commissions, no gimmicks: </span>Trade without the usual traps.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs): </span>Keep earning even after exit.</li>
        </ul>
        <p>Because in a broken system, the smartest traders refuse to play by old rules.</p>
        <br><hr>
        <p>The truth is, trading isn’t a lottery ticket—it’s a skill-based endeavor. Would you be open to learning how <span class="text-black01 font-weight-bold">Untrading</span> creates consistent value instead of relying on risky gambles?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="updateResponse"></ResponseAction>
        
        
        
        <!-- -------------------------- 下一步问题响应 ----------------------------- -->
        <div v-if="lastingWealthAnswer != null">
            <!-- 发送的消息 -->
            <div id="ai-lastingWealthAnswer" class="d-flex r-justify-end mt-6">
                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                    <div v-for="answer in lastingWealthAnswers" :key="answer.code">
                        <div v-if="lastingWealthAnswer == answer.code">{{ answer.message }}</div>
                    </div>
                </v-card>
            </div>
            <!-- 响应 -->
            <div class="mt-6">
                <div v-if="responseLoading" class="mt-6">
                    <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                </div>
                <div v-else>
                    <!-- -------------------- Yes ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'Yes'">
                        <p>Let's explore sustainable trading:</p>
                        <ul class="ml-8 custom-ul">
                            <li>Understanding real market dynamics</li>
                            <li>Building lasting wealth</li>
                            <li>Protecting your assets</li>
                        </ul>
                        <div class="mt-6">
                            <Button variant="primary" to="/shared-rewards" rounded="xl" color="black03" height="40" width="180" aria-label="Start Learning">
                                <span class="px-4">Start Learning<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                            <Button variant="secondary" rounded="xl" color="black01" height="40" class="ml-6" aria-label="Start Over" @click="$emit('startOver')">
                                <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                    <!-- -------------------- No ---------------------- -->
                    <div v-if="lastingWealthAnswer == 'No'">
                        <p>We understand. Untrading is designed for those seeking sustainable success through proven methods. If you change your approach, we'll be here to help.</p>
                        <div class="mt-6">
                            <Button variant="primary" rounded="xl" color="black03" height="40" width="280" aria-label="Return to Homepage" @click="$emit('startOver')">
                                <span class="px-4">Return to Homepage<v-icon>mdi mdi-chevron-right</v-icon></span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="lastingWealthAnswers" @onSent="setLastingWealthAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            // 持续财富答案集合
            lastingWealthAnswers: [
                { code: 'Yes', message: 'Yes, I’d like to learn more' },
                { code: 'No', message: 'No, thanks—I prefer taking risks' }
            ],
            // 响应加载中
            responseLoading: false
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'lastingWealthAnswer']),
    },
    watch:{

    },
    methods: {
        // 更新响应
        updateResponse() {
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$emit('updateResponse');
        },
        // 设置持续财富答案
        setLastingWealthAnswer(data) {
            this.$store.dispatch("lastingWealthAnswerHandler", data.code);
            this.responseLoading = true;
            setTimeout(() => {
                this.responseLoading = false;
            }, 3000);
        },
    }
}
</script>
<style scoped>

</style>