<template>
	<div class="markdown prose">
        <p>Let’s determine how involved you’d like to be in managing your investments:</p>
        <p>Would you prefer the excitement of actively navigating markets yourself, like a skilled race car driver? Or would you rather rely on our advanced Autopilot system to execute trades automatically based on proven strategies?</p>
        <p>Our Autopilot program offers three distinct modes tailored to your goals:</p>
        <ul class="ml-8 custom-short-dash-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Frequent, low-risk profit-taking (inspired by Soros).</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Balanced risk management for consistent returns (Dalio-inspired approach).</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Strategic, long-term exits for unparalleled gains (Buffett-style conviction).</li>
        </ul>
        <p>We recommend starting with Autopilot—it ensures disciplined execution and helps you build confidence before diving into manual trading. You can always transition to hands-on trading later if desired.</p>
        <p>What’s your preference?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot – Let Us Handle It' },
                { code: 'Manual', message: 'Manual Trading – Take Full Control' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>