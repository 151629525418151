<template>
    <div>
        <div class="markdown prose">
            <p>Excellent decision. Autopilot will execute trades for you based on your selected strategy <span class="text-black01 font-weight-bold">(Planetary, Stellar or Galactic)</span>, ensuring a balanced and stress-free approach.</p>
            <!-- 响应操作 -->
            <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
            <br><hr>
            <p>When you’re ready, you can always switch to manual trading with part of your funds. For now, let’s configure your autopilot settings.</p>
            <div class="mt-6 flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                <Button variant="primary" rounded="xl" color="black03" height="40" width="300" aria-label="Join Autopilot Waitlist" @click="requestAutopilotDialog = true">
                    <span class="px-4">Join Autopilot Waitlist<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
                <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over"  @click="$emit('startOver')">
                    <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>