<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">Let’s Shape Your Trading Path!</p>
        <p>Next, tell us how hands-on you’d like to be with your trades:</p>
        <p>Are you thrilled by the fast-paced thrill of calling the shots, like a racer speeding down the Crypto Racetrack? Or would you rather sit back and let our <span class="text-black01 font-weight-bold">Autopilot</span> system steer you toward success, tailored to your goals?</p>
        <p>With Autopilot, you get proven strategies inspired by trading legends:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Quick, frequent wins—like Soros’ tactical brilliance.</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Balanced growth with smart risk control—Dalio’s data-driven style.</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Big, long-term rewards—Buffett’s conviction playbook.</li>
        </ul>
        <p>Autopilot builds wealth effortlessly, perfect for steady gains or mastering the basics before going manual. Plus, you can switch to hands-on trading anytime you’re ready.</p>
        <p>What’s your pick?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>



        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot - Set It and Forget It' },
                { code: 'Manual', message: 'Crypto Racetrack - Hands-On and Tactical' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>