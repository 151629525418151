<template>
	<div>
        <footer class="@container pb-9">
            <div class="@md:px-md px-sm mb-xl @md:mb-2xl pt-lg text-nav max-w-container">
                <div class="@md:gap-sm @md:flex-row flex flex-col">
                    <div class="mt-lg @md:mt-0 gap-lg flex w-full flex-col first:mt-0">
                        <div class="flex flex-col justify-between">
                            <span class="text-black01 opacity-60" role="heading" aria-level="2">Features</span>
                            <ul class="gap-sm mt-xs flex flex-col" role="list">
                                <li role="listitem"><router-link to="/shared-rewards" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">Shared Rewards</router-link></li>
                                <li role="listitem"><router-link to="/community-empowerment" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">Community Empowerment</router-link></li>
                                <li role="listitem">
                                    <a @click="scrollToSection('transparency')" aria-label="Transparency">
                                        <router-link to="/community-empowerment" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">
                                            Transparency
                                        </router-link>
                                    </a>
                                </li>
                                <li role="listitem"><router-link to="/no-commission" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">No Commission</router-link></li>
                                <li role="listitem">
                                    <a @click="scrollToSection('pe')" aria-label="Profit Expectancy">
                                        <router-link to="/community-empowerment" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">
                                            Profit Expectancy
                                        </router-link>
                                    </a>
                                </li>
                                <li role="listitem"><router-link to="/innovation" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">Innovation</router-link></li>
                                <li role="listitem"><router-link to="/fr-calculator" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">Future Rewards Calculator</router-link></li>
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://kingdoms.untrading.org">Kingdoms</a></li>
                                <li role="listitem"><router-link to="/network-defi" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">Network DeFi</router-link></li>
                                <li role="listitem"><router-link to="/kols" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">KOLs</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-lg @md:mt-0 gap-lg flex w-full flex-col first:mt-0">
                        <div class="flex flex-col justify-between">
                            <span class="text-black01 opacity-60" role="heading" aria-level="2">Company</span>
                            <ul class="gap-sm mt-xs flex flex-col" role="list">
                                <li role="listitem"><router-link to="/about" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">About</router-link></li>
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://docs.untrading.org/faqs">FAQs</a></li>
                                <li role="listitem">
                                    <a href="https://5173.foundation" class="transition ease-curve-a duration-250 flex flex-row items-center gap-x-[0.3em] w-fit hover:text-primary-60" target="_blank" rel="noreferrer">
                                        5173 Foundation
                                        <svg width="9" style="transform:translate(1px, -2px)" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.70985 4.5H7.7804M7.7804 4.5V10.5705M7.7804 4.5L0.780396 11.5" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <span class="sr-only">(opens in a new window)</span>
                                    </a>
                                </li>
                                <li role="listitem"><router-link to="/un" class="transition ease-curve-a duration-250 hover:text-primary-60 del-underline">Claim UN Tokens</router-link></li>
                            </ul>
                        </div>
                        <div class="flex flex-col justify-between">
                            <span class="text-black01 opacity-60" role="heading" aria-level="2">Docs</span>
                            <ul class="gap-sm mt-xs flex flex-col" role="list">
                                <li role="listitem">
                                    <a href="https://eips.ethereum.org/EIPS/eip-5173" class="transition ease-curve-a duration-250 flex flex-row items-center gap-x-[0.3em] w-fit hover:text-primary-60" target="_blank" rel="noreferrer">
                                        ERC-5173
                                        <svg width="9" style="transform:translate(1px, -2px)" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.70985 4.5H7.7804M7.7804 4.5V10.5705M7.7804 4.5L0.780396 11.5" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <span class="sr-only">(opens in a new window)</span>
                                    </a>
                                </li>
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://docs.untrading.org/whitepaper">Whitepaper</a></li>
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://docs.untrading.org/tutorials">Tutorials</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-lg @md:mt-0 gap-lg flex w-full flex-col first:mt-0">
                        <div class="flex flex-col justify-between">
                            <span class="text-black01 opacity-60" role="heading" aria-level="2">Developers</span>
                            <ul class="gap-sm mt-xs flex flex-col" role="list">
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://docs.untrading.org">Documentation</a></li>
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://github.com/untrading">GitHub</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-lg @md:mt-0 gap-lg flex w-full flex-col first:mt-0">
                        <div class="flex flex-col justify-between">
                            <span class="text-black01 opacity-60" role="heading" aria-level="2">Terms &amp; Policies</span>
                            <ul class="gap-sm mt-xs flex flex-col" role="list">
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://docs.untrading.org/terms-of-service">Terms of Use</a></li>
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://docs.untrading.org/privacy-policy">Privacy Policy</a></li>
                                <li role="listitem"><a class="transition ease-curve-a duration-250 hover:text-primary-60" href="https://docs.untrading.org/risks">Legal</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-sm gap-md @md:gap-xl @md:flex-row @md:justify-between @md:items-center max-w-container flex w-full flex-col items-center">
                <div class="gap-2xs @md:w-auto @md:justify-start @lg:gap-sm flex w-full justify-between">
                    <a href="https://x.com/untradingOrg" class="transition ease-curve-a duration-250" target="_blank" rel="noreferrer" aria-label="X">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 17 17" fill="none">
                            <g clip-path="url(#a)">
                                <path fill="currentColor" d="M13.158 2.058h2.248l-4.913 5.435 5.78 7.395h-4.525l-3.545-4.485-4.056 4.485h-2.25l5.255-5.813-5.545-7.017h4.64l3.205 4.1 3.706-4.1Zm-.79 11.527h1.246L5.57 3.293H4.233l8.135 10.292Z"></path>
                            </g>
                        </svg>
                        <span class="sr-only">(opens in a new window)</span>
                    </a>
                    <a href="https://www.youtube.com/@untrading" class="transition ease-curve-a duration-250" target="_blank" rel="noreferrer" aria-label="YouTube">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 17 17" fill="none">
                            <g clip-path="url(#a)">
                                <path fill="currentColor" d="M16.79 5.475s-.156-1.067-.637-1.536c-.61-.617-1.29-.62-1.603-.656-2.238-.158-5.597-.158-5.597-.158h-.006s-3.36 0-5.597.158c-.313.036-.994.039-1.603.656-.481.469-.635 1.536-.635 1.536S.95 6.73.95 7.982v1.174c0 1.252.16 2.507.16 2.507s.156 1.067.634 1.536c.61.617 1.41.596 1.765.662 1.282.118 5.441.154 5.441.154s3.363-.006 5.6-.16c.313-.036.994-.04 1.603-.656.481-.469.638-1.536.638-1.536s.159-1.252.159-2.507V7.982c0-1.252-.16-2.507-.16-2.507ZM7.298 10.58V6.228l4.322 2.184-4.322 2.168Z"></path>
                            </g>
                        </svg>
                        <span class="sr-only">(opens in a new window)</span>
                    </a>
                    <a href="https://www.linkedin.com/company/untrading/" class="transition ease-curve-a duration-250" target="_blank" rel="noreferrer" aria-label="LinkedIn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 17 17" fill="none">
                            <g clip-path="url(#a)">
                                <path fill="currentColor" d="M15.776.83H2.14C1.488.83.96 1.329.96 1.946v13.249c0 .617.528 1.119 1.181 1.119h13.635c.653 0 1.184-.502 1.184-1.116V1.946c0-.617-.531-1.116-1.184-1.116ZM5.706 14.025H3.333V6.633h2.375v7.392ZM4.52 5.626c-.762 0-1.378-.595-1.378-1.33 0-.735.616-1.33 1.378-1.33.76 0 1.375.595 1.375 1.33 0 .732-.615 1.33-1.375 1.33Zm10.075 8.399h-2.371v-3.593c0-.856-.016-1.96-1.235-1.96-1.234 0-1.422.935-1.422 1.9v3.653H7.197V6.633h2.275v1.01h.032c.315-.58 1.09-1.194 2.244-1.194 2.403 0 2.846 1.53 2.846 3.52v4.056Z"></path>
                            </g>
                        </svg>
                        <span class="sr-only">(opens in a new window)</span>
                    </a>
                    <a href="https://github.com/untrading" class="transition ease-curve-a duration-250" target="_blank" rel="noreferrer" aria-label="GitHub">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 17 17" fill="none">
                            <g clip-path="url(#a)">
                                <path fill="currentColor" fill-rule="evenodd" d="M8.977.83C4.549.83.97 4.32.97 8.636c0 3.45 2.293 6.371 5.475 7.405.397.078.543-.168.543-.375 0-.18-.013-.8-.013-1.447-2.227.465-2.691-.93-2.691-.93-.358-.905-.888-1.138-.888-1.138-.73-.478.053-.478.053-.478.808.052 1.233.801 1.233.801.715 1.19 1.869.853 2.333.646.066-.504.278-.853.504-1.046-1.777-.181-3.646-.853-3.646-3.852 0-.853.318-1.55.822-2.093-.08-.194-.358-.995.08-2.068 0 0 .676-.207 2.2.801a7.94 7.94 0 0 1 2.002-.258c.676 0 1.365.09 2.001.258 1.525-1.008 2.2-.801 2.2-.801.438 1.073.16 1.874.08 2.068.517.542.822 1.24.822 2.093 0 2.999-1.869 3.658-3.659 3.852.292.245.544.71.544 1.447 0 1.047-.013 1.887-.013 2.145 0 .207.146.453.543.375 3.182-1.034 5.475-3.955 5.475-7.405C16.983 4.319 13.39.83 8.977.83Z" clip-rule="evenodd"></path>
                            </g>
                        </svg>
                        <span class="sr-only">(opens in a new window)</span>
                    </a>
                    <a href="https://podcasters.spotify.com/pod/show/untrading" class="transition ease-curve-a duration-250" target="_blank" rel="noreferrer" aria-label="Spotify">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="17" height="17" viewBox="0 0 50 50">
                            <path fill="currentColor" d="M25.009,1.982C12.322,1.982,2,12.304,2,24.991S12.322,48,25.009,48s23.009-10.321,23.009-23.009S37.696,1.982,25.009,1.982z M34.748,35.333c-0.289,0.434-0.765,0.668-1.25,0.668c-0.286,0-0.575-0.081-0.831-0.252C30.194,34.1,26,33,22.5,33.001 c-3.714,0.002-6.498,0.914-6.526,0.923c-0.784,0.266-1.635-0.162-1.897-0.948s0.163-1.636,0.949-1.897 c0.132-0.044,3.279-1.075,7.474-1.077C26,30,30.868,30.944,34.332,33.253C35.022,33.713,35.208,34.644,34.748,35.333z M37.74,29.193 c-0.325,0.522-0.886,0.809-1.459,0.809c-0.31,0-0.624-0.083-0.906-0.26c-4.484-2.794-9.092-3.385-13.062-3.35 c-4.482,0.04-8.066,0.895-8.127,0.913c-0.907,0.258-1.861-0.272-2.12-1.183c-0.259-0.913,0.272-1.862,1.184-2.12 c0.277-0.079,3.854-0.959,8.751-1c4.465-0.037,10.029,0.61,15.191,3.826C37.995,27.328,38.242,28.388,37.74,29.193z M40.725,22.013 C40.352,22.647,39.684,23,38.998,23c-0.344,0-0.692-0.089-1.011-0.275c-5.226-3.068-11.58-3.719-15.99-3.725 c-0.021,0-0.042,0-0.063,0c-5.333,0-9.44,0.938-9.481,0.948c-1.078,0.247-2.151-0.419-2.401-1.495 c-0.25-1.075,0.417-2.149,1.492-2.4C11.729,16.01,16.117,15,21.934,15c0.023,0,0.046,0,0.069,0 c4.905,0.007,12.011,0.753,18.01,4.275C40.965,19.835,41.284,21.061,40.725,22.013z"></path>
                        </svg>
                        <span class="sr-only">(opens in a new window)</span>
                    </a>
                    <a href="https://t.me/untrading" class="transition ease-curve-a duration-250" target="_blank" rel="noreferrer" aria-label="Telegram">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="17" height="17" viewBox="0 0 50 50">
                            <path  fill="currentColor" d="M25,2c12.703,0,23,10.297,23,23S37.703,48,25,48S2,37.703,2,25S12.297,2,25,2z M32.934,34.375	c0.423-1.298,2.405-14.234,2.65-16.783c0.074-0.772-0.17-1.285-0.648-1.514c-0.578-0.278-1.434-0.139-2.427,0.219	c-1.362,0.491-18.774,7.884-19.78,8.312c-0.954,0.405-1.856,0.847-1.856,1.487c0,0.45,0.267,0.703,1.003,0.966	c0.766,0.273,2.695,0.858,3.834,1.172c1.097,0.303,2.346,0.04,3.046-0.395c0.742-0.461,9.305-6.191,9.92-6.693	c0.614-0.502,1.104,0.141,0.602,0.644c-0.502,0.502-6.38,6.207-7.155,6.997c-0.941,0.959-0.273,1.953,0.358,2.351	c0.721,0.454,5.906,3.932,6.687,4.49c0.781,0.558,1.573,0.811,2.298,0.811C32.191,36.439,32.573,35.484,32.934,34.375z"></path>
                            </svg>
                        <span class="sr-only">(opens in a new window)</span>
                    </a>
                    <a href="https://discord.gg/D5rZdhzVWh" class="transition ease-curve-a duration-250" target="_blank" rel="noreferrer" aria-label="Discord">
                        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" width="17">
                            <path d="M14.9006 4.06779C13.7837 3.56177 12.6046 3.20279 11.3934 3C11.2277 3.2932 11.0777 3.59486 10.9441 3.90372C9.65398 3.71134 8.34198 3.71134 7.05182 3.90372C6.91819 3.59489 6.76822 3.29323 6.60255 3C5.39058 3.2045 4.21068 3.56434 3.09264 4.07044C0.87304 7.32013 0.271342 10.4891 0.572191 13.6131C1.87204 14.5635 3.32695 15.2862 4.87367 15.75C5.22194 15.2865 5.53012 14.7947 5.79494 14.28C5.29196 14.0941 4.8065 13.8647 4.34417 13.5946C4.46585 13.5072 4.58485 13.4173 4.69985 13.3299C6.04511 13.956 7.51339 14.2806 8.99999 14.2806C10.4866 14.2806 11.9549 13.956 13.3001 13.3299C13.4165 13.4239 13.5355 13.5139 13.6558 13.5946C13.1926 13.8652 12.7062 14.0949 12.2024 14.2813C12.4668 14.7958 12.775 15.2871 13.1236 15.75C14.6717 15.2881 16.1277 14.5657 17.4278 13.6144C17.7808 9.99159 16.8247 6.85173 14.9006 4.06779ZM6.17601 11.6919C5.33765 11.6919 4.64502 10.939 4.64502 10.0128C4.64502 9.08655 5.31358 8.32705 6.17334 8.32705C7.0331 8.32705 7.72037 9.08655 7.70567 10.0128C7.69096 10.939 7.03043 11.6919 6.17601 11.6919ZM11.824 11.6919C10.9843 11.6919 10.2943 10.939 10.2943 10.0128C10.2943 9.08655 10.9629 8.32705 11.824 8.32705C12.6851 8.32705 13.367 9.08655 13.3523 10.0128C13.3376 10.939 12.6784 11.6919 11.824 11.6919Z" fill="currentColor"></path>
                        </svg>
                        <span class="sr-only">(opens in a new window)</span>
                    </a>
                </div>
                <div>
                    <span class="text-nav">
                        <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" class="del-underline pointer" aria-label="Licenses">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8554187d-f5dc-41f7-eb9e-637396d9d100/public" cover width="60" alt="" />
                        </a>
                    </span>
                </div>
                <div class="relative"></div>
            </div>
        </footer>
	</div>
</template>
<script>
import Media from '@/components/common/Media';
import Logo from '@/components/common/Logo';
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: { Logo, Media },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["locale", "responsive"])
    },
    watch:{

    },
    methods: {
        // 滚动到单元
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
    }
}
</script>
<style scoped>

</style>