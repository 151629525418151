<template>
	<div>
        <v-row no-gutters>
            <!---------------------------- tab ---------------------------->
            <v-col cols="12" sm="6" class="px-0 flex overflow-x-auto pb-2" v-if="responsive == 'mobile'">
                <v-btn width="150" @click="changeTab('collected')" :variant="tab == 'collected' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': tab == 'collected' }" class="mr-2">
                    <v-badge :content="stat.collected" inline>
                        <h4>Collected</h4>
                    </v-badge>
                </v-btn>
                <v-btn width="150" @click="changeTab('created')" :variant="tab == 'created' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': tab == 'created' }" class="mr-2">
                    <v-badge :content="stat.created" inline>
                        <h4>Created</h4>
                    </v-badge>
                </v-btn>
                <v-btn width="100" @click="changeTab('sets')" :variant="tab == 'sets' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': tab == 'sets' }" class="mr-2">
                    <v-badge :content="stat.sets" inline>
                        <h4>Sets</h4>
                    </v-badge>
                </v-btn>
                <v-btn width="180" @click="changeTab('collections')" :variant="tab == 'collections' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': tab == 'collections' }">
                    <v-badge :content="stat.collections" inline>
                        <h4>Collections</h4>
                    </v-badge>
                </v-btn>
            </v-col>
            <v-col cols="8" v-if="responsive == 'pc'" class="pl-4">
                <v-tabs slider-color="primary" v-model="tab" density="compact" class="w-90">
                    <v-tab value="collected" rounded="0" class="px-0" @click="$refs.collected && $refs.collected.changeCondition()">
                        <v-badge :content="stat.collected" inline>
                            <h4>Collected</h4>
                        </v-badge>
                    </v-tab>
                    <v-tab value="created" rounded="0" class="px-0 ml-4" @click="$refs.created && $refs.created.changeCondition()">
                        <v-badge :content="stat.created" inline>
                            <h4>Created</h4>
                        </v-badge>
                    </v-tab>
                    <v-tab value="sets" rounded="0" class="px-0 ml-4" @click="$refs.sets && $refs.sets.changeCondition()">
                        <v-badge :content="stat.sets" inline>
                            <h4>Sets</h4>
                        </v-badge>
                    </v-tab>
                    <v-tab value="collections" rounded="0" class="px-0 ml-4" @click="$refs.collections && $refs.collections.changeCondition()">
                        <v-badge :content="stat.collections" inline>
                            <h4>Collections</h4>
                        </v-badge>
                    </v-tab>
                </v-tabs>
            </v-col>
            <!---------------------------- 过滤条件 ---------------------------->
            <v-col cols="4" v-if="responsive == 'pc'">
                <div class="float-right">
                    <!-- 区块链 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn aria-label="menu-down" variant="plain" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="changeBlockchainCondition(null)">
                                <v-list-item-title>
                                    <Blockchain text all-chains></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                                <v-list-item-title>
                                    <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <!-- 排序 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn aria-label="menu-down" variant="plain" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                {{ sortCondition }}
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
            <v-col cols="12" class="pr-4" v-if="responsive == 'mobile'">
                <div class="r-m-t-8">
                    <!-- 区块链 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn variant="plain" rounded="0" color="primaryGrey" class="text-none body-p" height="100%" v-bind="props">
                                <Blockchain :blockchain="blockchain" size="18" text all-chains></Blockchain>
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="changeBlockchainCondition(null)">
                                <v-list-item-title>
                                    <Blockchain text all-chains></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-for="blockchain in blockchains" :key="blockchain.blockchain" :value="blockchain.blockchain" @click="changeBlockchainCondition(blockchain.blockchain)">
                                <v-list-item-title>
                                    <Blockchain :blockchain="blockchain.blockchain" size="24" text></Blockchain>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <!-- 排序 -->
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn aria-label="Menu down" variant="plain" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                {{ sortCondition }}
                                <v-icon end icon="mdi:mdi-menu-down" />
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
            <v-divider class="ml-4 r-m-l-0" thickness="1" color="black01"></v-divider>
        </v-row>
        <v-window v-model="tab" :touch="false">
            <!---------------------------- Collected ---------------------------->
            <v-window-item value="collected">
                <Collected ref="collected" :username="username" :blockchain="blockchain" :sortBy="sortCondition" @receiveCollectedCount="receiveCollectedCount"></Collected>
            </v-window-item>
            <!---------------------------- Created ---------------------------->
            <v-window-item value="created">
                <v-row no-gutters>
                    <v-col sm="6" lg="12" v-if="isMe" :class="responsive == 'mobile' ? 'mt-3' : 'text-right'">
                        <router-link to="/unnft/mint" class="del-underline">
                            <v-menu open-on-hover location="top">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" aria-label="mdi-image-plus-outline" :rounded="1" icon size="medium" variant="text">
                                        <v-icon icon="mdi:mdi-image-plus-outline" size="36" class="text-primary"></v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="pa-3 text-primaryGrey" rounded="0">Create unNFT</v-card>
                            </v-menu>
                        </router-link>
                        <router-link to="/unnft/wrap" class="del-underline ml-3">
                            <v-menu open-on-hover location="top">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" aria-label="image-refresh-outline" :rounded="1" icon size="medium" variant="text">
                                        <v-icon icon="mdi:mdi-image-refresh-outline" size="36" class="text-primary"></v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="pa-3 text-primaryGrey" rounded="0">Wrap NFT</v-card>
                            </v-menu>
                        </router-link>
                    </v-col>
                </v-row>
                <Created ref="created" :username="username" :blockchain="blockchain" :sortBy="sortCondition" @receiveCreatedCount="receiveCreatedCount"></Created>
            </v-window-item>
            <!---------------------------- Sets ---------------------------->
            <v-window-item value="sets">
                <v-row no-gutters>
                    <v-col lg="12" sm="6" v-if="isMe" :class="responsive == 'mobile' ? 'mt-3' : 'text-right'">
                        <router-link to="/set/create" class="del-underline text_right">
                            <v-menu open-on-hover location="top">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" aria-label="mdi-plus" :rounded="1" icon size="medium" variant="text" color="teal-darken-4">
                                        <v-icon icon="mdi:mdi-plus" size="36" class="text-primary"></v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="pa-3 text-primaryGrey" rounded="0">Create Set</v-card>
                            </v-menu>
                        </router-link>
                    </v-col>
                </v-row>
                <Sets ref="sets" :userProfile="userProfile" :blockchain="blockchain" :sortBy="sortCondition" @receiveSetsCount="receiveSetsCount" @refresh="refresh()"></Sets>
            </v-window-item>
            <!---------------------------- Collections ---------------------------->
            <v-window-item value="collections">
                <v-row no-gutters>
                    <v-col lg="12" sm="6" v-if="isMe" :class="responsive == 'mobile' ? 'mt-3' : 'text-right'">
                        <router-link to="/collection/create" class="del-underline text_right">
                            <v-menu open-on-hover location="top">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" aria-label="mdi-plus" :rounded="1" icon size="medium" variant="text" color="teal-darken-4">
                                        <v-icon icon="mdi:mdi-plus" size="36" class="text-primary"></v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="pa-3 text-primaryGrey" rounded="0">Create Collection</v-card>
                            </v-menu>
                        </router-link>
                    </v-col>
                </v-row>
                <Collections ref="collections" :userProfile="userProfile" :blockchain="blockchain" :sortBy="sortCondition" @receiveCollectionsCount="receiveCollectionsCount" @refresh="refresh()"></Collections>
            </v-window-item>
            <!---------------------------- Hidden ---------------------------->
            <!-- <v-window-item value="hidden" v-if="isMe">
                <Hidden ref="hidden" :username="username" :blockchain="blockchain" :sortBy="sortCondition" @receiveHiddenCount="receiveHiddenCount"></Hidden>
            </v-window-item> -->
        </v-window>

	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Collected from '@/components/user/profile/Collected.vue'
import Created from '@/components/user/profile/Created.vue'
import Sets from '@/components/user/profile/Sets.vue'
import Collections from '@/components/user/profile/Collections.vue'
import Hidden from '@/components/user/profile/Hidden.vue'
export default {
    props: {
        username: {
            type: String,
            required: true,
            default: null
        },
        userProfile: {
            type: Object,
            required: true,
            default: {}
        },
        stat: {
            type: Object,
            required: true,
            default: {
                collected: 0,
                created: 0,
                sets: 0,
                collections: 0,
                hidden: 0,
            }
        },
        isMe: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data(){
        return {
            // 标签
            tab: this.$route.query?.tab?.toLowerCase() || 'collected',
            // 排序条件
            sortCondition: 'Newest',
        }
    },
    components: { Collected, Created, Sets, Collections, Hidden },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'blockchain', 'responsive']),
        // 排序条件
        sortConditions() {
            if(this.tab == 'sets' || this.tab == 'collections') {
                return ['Newest', 'Oldest', 'Highest Views', 'Lowest Views'];
            } else {
                return ['Newest', 'Oldest', 'Highest Price', 'Lowest Price', 'Highest Views', 'Lowest Views'];
            }
        }
    },
    watch:{

    },
    methods: {
        // 改变区块链条件
        changeBlockchainCondition(blockchain) {
            // 更新区块链
            this.$store.dispatch('blockchainHandler', blockchain);
        },
        // 改变排序条件
        changeSortCondition(sortCondition) {
            this.sortCondition = sortCondition;
        },
        // 改变标签
        changeTab(tab) {
            this.tab = tab;
            this.$refs?.[tab]?.changeCondition();
        },
        // 刷新
        refresh() {
            this.$emit('refresh', null);
        },
        // 接收 Collected 统计数量
        receiveCollectedCount(count) {
            this.userProfile.stat.collected = count;
        },
        // 接收 Created 统计数量
        receiveCreatedCount(count) {
            this.userProfile.stat.created = count;
        },
        // 接收 Sets 统计数量
        receiveSetsCount(count) {
            this.userProfile.stat.sets = count;
        },
        // 接收 Collections 统计数量
        receiveCollectionsCount(count) {
            this.userProfile.stat.collections = count;
        }
    }
}
</script>
<style scoped>
.v-btn{
    text-transform:capitalize!important;
}
</style>