<template>
	<div class="h-100 pb-16 bg-f2f">
        <!-----------------------------加载中 ----------------------------->
        <div class="h-100" v-if="userProfileLoading">
            <v-container class="h-100" align="center">
                <v-card color="transparent" elevation="0" rounded="0" max-width="1400" class="h-100 mx-auto pt-7">
                    <v-row no-gutters align="center">
                        <v-col>
                            <SkeletonProfile></SkeletonProfile>
                            <!-- <v-progress-circular :size="100" :width="10" indeterminate></v-progress-circular> -->
                        </v-col>
                    </v-row>
                </v-card>
            </v-container>
        </div>
        <!----------------------------- 用户资料 ----------------------------->
        <div class="h-100" v-if="!userProfileLoading">
            <v-container class="h-100" align="center" v-if="!userProfile.user.id">
                <v-card color="transparent" elevation="0" rounded="0" max-width="1400" class="mx-auto pt-7">
                    <!-- 暂无用户 -->
                    <v-row no-gutters class="h-100" align="center">
                        <v-col>
                            <h5 class="body-h5 mt-16 text-primaryGrey">No Data Available</h5>
                        </v-col>
                    </v-row>
                </v-card>
            </v-container>
            <!----------------------------- 存在用户 ----------------------------->
            <v-container v-if="userProfile.user.id" class="px-0">
               <v-card color="transparent" elevation="0" rounded="0" max-width="1400" class="mx-auto pt-7"> 
                    <v-row no-gutters>
                        <v-col cols="12" v-if="isMe && !user.verified">
                            <v-card elevation="0" rounded="0" color="transparent">
                                <v-row no-gutters >
                                    <v-col cols="4">
                                        <div class="body-h5 text-primaryGrey">
                                            <div v-if="isMe" class="mb-3">
                                                <v-icon class="gradient-text-hover mb-3" icon="mdi:mdi-chevron-left-circle" @click="$router.back(-1)"></v-icon>
                                                <h5>PROFILE - UNVERIFIED</h5>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <!----------------------------- 是自己并且已验证 ----------------------------->
                        <v-col cols="12" v-if="responsive == 'pc'">
                            <v-card elevation="0" rounded="0" color="transparent" v-if="userProfile.user.verified">
                                <v-row no-gutters>
                                    <v-col cols="3">
                                        <div class="body-h5 text-primaryGrey">
                                            <div v-if="isMe" class="mb-3">
                                                <v-icon class="gradient-text-hover mb-3" icon="mdi:mdi-chevron-left-circle" @click="$router.back(-1)"></v-icon>
                                                <h5>PROFILE - VERIFIED</h5>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="7" class="r-p-l-0 pl-2">
                                        <h1 class="gradient-underline-hover sub-page-h1 text-grey04 mb-1">
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }">
                                                    <router-link to="/user/rewards" aria-label="User Rewards" class="del-underline text-primaryGrey gradient-text-hover" :class="isMe ? 'pointer' : 'pointer-none'">
                                                        <count-to v-bind="props" :start-val="0" :end-val="userProfile.stat.unBalance" :duration="1500" :decimals="4" separator="," suffix=" UN User Rewards"></count-to>
                                                    </router-link>
                                                    <v-icon v-if="isMe" size="28" class="text-primaryGrey ml-5 mb-2" icon="fa-solid fa-up-right-from-square"></v-icon>
                                                    <!-- <span v-if="isMe" class="fa-solid fa-up-right-from-square fs-28 ml-5"></span> -->
                                                </template>
                                                <v-card max-width="600" class="pa-3 text-primaryGrey body-p" rounded="0" v-if="$config.env != 'prod'">
                                                    <b>Please note: </b>The testnet rewards displayed here, including test USDC, USDT, and WBTC, are for practice, not real rewards. To view and claim your actual UN Token Rewards you have earned here on the testnet, in addition to any rewards you have earned on the mainnet, head over to 
                                                    <span class="gradient-underline-hover gradient-underline">
                                                        <a :href="`https://untrading.org/profile/${username}`" target="_blank" class="text-primaryGrey gradient-text-hover">https://untrading.org/</a>
                                                    </span>
                                                    and log in with the same wallet you're using here.
                                                </v-card>
                                            </v-menu>
                                        </h1>
                                        <h1 class="gradient-underline-hover sub-page-h1 text-grey04 mb-1">
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }">
                                                    <router-link to="/kingdom/rewards" aria-label="Kingdom Rewards" class="del-underline text-primaryGrey gradient-text-hover" :class="isMe ? 'pointer' : 'pointer-none'">
                                                        <count-to v-bind="props" :start-val="0" :end-val="userProfile.stat.unKingdomBalance" :duration="1500" :decimals="4" separator="," suffix=" UN Kingdom Rewards"></count-to>
                                                    </router-link>
                                                    <v-icon v-if="isMe" size="28" class="text-primaryGrey ml-5 mb-2" icon="fa-solid fa-up-right-from-square"></v-icon>
                                                    <!-- <span v-if="isMe" class="fa-solid fa-up-right-from-square fs-28 ml-5"></span> -->
                                                </template>
                                                <v-card max-width="600" class="pa-3 text-primaryGrey body-p" rounded="0" v-if="$config.env != 'prod'">
                                                    <b>Please note: </b>The testnet rewards displayed here, including test USDC, USDT, and WBTC, are for practice, not real rewards. To view and claim your actual UN Token Rewards you have earned here on the testnet, in addition to any rewards you have earned on the mainnet, head over to 
                                                    <span class="gradient-underline-hover gradient-underline">
                                                        <a :href="`https://untrading.org/profile/${username}`" target="_blank" class="text-primaryGrey gradient-text-hover">https://untrading.org/</a>
                                                    </span>
                                                    and log in with the same wallet you're using here.
                                                </v-card>
                                            </v-menu>
                                            
                                        </h1>
                                        <!-- <div v-if="isMe" class="pointer body-p text-primaryGrey gradient-underline gradient-underline-hover" @click="$bus.emit('callWelcomeDialog', true)">
                                            <a class="text-primaryGrey gradient-text-hover">To unlock your Rewards, make sure you follow untrading.org on X, Telegram, and Discord!</a>
                                        </div> -->
                                    </v-col>
                                    <v-col cols="2" class="text-right pr-9 mt-n1 pt-1">
                                        <!---------------------------- 排行榜 ---------------------------->
                                        <div v-if="type == 'cryptos'">
                                            <Button variant="primary" to="/leaderboards?type=quest"  rounded="lg" color="black03"  width="80" aria-label="Quest">Quest</Button>
                                        </div>
                                        <!---------------------------- 收藏家 ---------------------------->
                                        <div class="pointer gradient-text-hover" @click="collectorsDialog = true" v-if="type == 'nfts'">
                                            <div class="body-p-large text-primaryGrey">{{ userProfile.stat.collectors }}</div>
                                            <div class="body-h5 text-primaryGrey">Collectors</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="12" v-if="responsive == 'mobile'">
                            <v-card elevation="0" rounded="0" color="transparent" v-if="userProfile.user.verified">
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <div class="body-h5 text-primaryGrey">
                                            <div v-if="isMe" class="mb-3">
                                                <v-icon class="gradient-text-hover mb-3" icon="mdi:mdi-chevron-left-circle" @click="$router.back(-1)"></v-icon>
                                                <h5>PROFILE - VERIFIED</h5>
                                            </div>
                                            <Button variant="primary" rounded="lg" to="/leaderboards?type=quest" color="black03"  width="80" aria-label="Quest">Quest</Button>
                                        </div>
                                    </v-col>
                                    <!---------------------------- UN ---------------------------->
                                    <v-col cols="6" class="text-right pr-2">
                                        <div class="sidebar-h2 text-primaryGrey gradient-underline-hover mt-1">
                                            <router-link to="/user/rewards" aria-label="User Rewards" class="del-underline text-primaryGrey gradient-text-hover" :class="isMe ? 'pointer' : 'pointer-none'">
                                                <count-to :start-val="0" :end-val="userProfile.stat.unBalance" :duration="1500" :decimals="4" separator="," suffix=" UN"></count-to>
                                                <div class="body-p">User Rewards <span v-if="isMe" class="fa-solid fa-up-right-from-square body-p-smal ml-2"></span></div>
                                            </router-link>
                                        </div>
                                        <div class="sidebar-h2 text-primaryGrey gradient-underline-hover mt-1">
                                            <router-link to="/kingdom/rewards" aria-label="Kingdom Rewards" class="del-underline text-primaryGrey gradient-text-hover" :class="isMe ? 'pointer' : 'pointer-none'">
                                                <count-to :start-val="0" :end-val="userProfile.stat.unKingdomBalance" :duration="1500" :decimals="4" separator="," suffix=" UN"></count-to>
                                                <div class="body-p">Kingdom Rewards <span v-if="isMe" class="fa-solid fa-up-right-from-square body-p-smal ml-2"></span></div>
                                            </router-link>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- <v-row no-gutters v-if="isMe" >
                                    <v-col cols="12" class="px-2">
                                        <div class="pointer body-p text-primaryGrey gradient-underline gradient-underline-hover" @click="$bus.emit('callWelcomeDialog', true)">
                                            <a class="text-primaryGrey gradient-text-hover">To unlock your Rewards, make sure you follow untrading.org on X, Telegram, and Discord!</a>
                                        </div>
                                    </v-col>
                                </v-row> -->
                            </v-card>
                        </v-col>
                        <!----------------------------- 是自己并且未验证 ----------------------------->
                        <div class="c-container flex-nowrap"  :class="responsive == 'pc' ? 'd-flex':'r-m-t-8'">
                            <div class="c-content-p c-grid">
                                <div class="c-item-p r-p-l-0">
                                    <div class="c-p">
                                        <div class="c-h-p d-flex">
                                            <div class="c-m-b-auto">
                                                <!----------------------------- 公共头像 ----------------------------->
                                                <v-hover v-slot="{ isHovering, props }">
                                                    <UserAvatar v-bind="props" :avatar="$tools.bigAvatar(userProfile.user.avatar)" :size="144" style="z-index:1">
                                                        <v-expand-transition>
                                                            <!-- 编辑资料：是自己并且已验证 -->
                                                            <div v-if="isMe && user.verified && isHovering" class="d-flex align-center justify-center bg-darkgray v-card--reveal" style="height:100%;">
                                                                <Button variant="primary" to="/edit" rounded="lg" color="black03" width="100" aria-label="Edit Profile">Edit Profile</Button>
                                                            </div>
                                                        </v-expand-transition>
                                                    </UserAvatar>
                                                </v-hover>
                                                <!-- <UserAvatar :avatar="$tools.bigAvatar(userProfile.user.avatar)" :size="144" style="z-index:1"></UserAvatar> -->
                                                <v-card width="320" elevation="0" rounded="0" class="pb-9" color="transparent">
                                                    <!----------------------------- 该用户未验证 ----------------------------->
                                                    <div v-if="!userProfile.user.verified" class="mt-6">
                                                        <div class="d-flex align-center">
                                                            <!-- <v-card rounded="0" width="100" class="gradient-left-red-purple text-white text-center py-3 ml-n7">#{{ userProfile.user.no }}</v-card> -->
                                                            <span class="body-p text-primaryGrey">{{ $tools.privacyField(userProfile.user.wallet) }}</span>
                                                            <span class="ml-16 pl-10">
                                                                <Clipboard :text="userProfile.user.wallet">
                                                                    <v-icon size="18" icon="mdi:mdi-content-copy" class="text-primaryGrey icon"></v-icon>
                                                                </Clipboard>
                                                            </span>
                                                        </div>
                                                        <div class="body-h2 mt-8 text-primaryGrey">--</div>
                                                        <v-divider class="my-7" thickness="1" color="black01"></v-divider>
                                                        <!-- 关注人数 -->
                                                        <div class="d-flex justify-start mt-9">
                                                            <div class="text-center">
                                                                <div class="body-p-large text-primaryGrey">{{ userProfile.follow.followers }}</div>
                                                                <div class="mt-3 body-h5 text-primaryGrey">Followers</div>
                                                            </div>
                                                            <div class="ml-6 text-center">
                                                                <div class="body-p-large text-primaryGrey">{{ userProfile.follow.following }}</div>
                                                                <div class="mt-3 body-h5 text-primaryGrey">Following</div>
                                                            </div>
                                                            <div>
                                                                
                                                            </div>
                                                            <!-- <div class="ml-12 text-center" v-if="!isMe" >
                                                                <Follow :followedUserId="userProfile.user.id" @refresh="refresh()"></Follow>
                                                            </div> -->
                                                            <div v-if="responsive == 'mobile'">
                                                                <div class="ml-12 text-center" v-if="!isMe" >
                                                                    <Follow :followedUserId="userProfile.user.id" @refresh="refresh()"></Follow>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                        <div class="mt-16 d-flex align-center">
                                                            <h5 class="pr-10 body-p text-primaryGrey">@ --</h5>
                                                            <span>
                                                                <!-- <v-icon class="twitter" size="32" icon="fa-brands fa-square-twitter"></v-icon> -->
                                                                <v-icon :color="darkTheme == 0 ? '#000000' : '#FFFFFF'" size="32" icon="icon-x"></v-icon>
                                                            </span>
                                                        </div>
                                                        <div class="mt-16">
                                                            <!-- 个人简介 -->
                                                            <h2 class="body-h4 text-primaryGrey text-uppercase">Bio</h2>
                                                            <v-divider class="my-2" thickness="1" color="black01"></v-divider>
                                                            <h5 class="body-p text-primaryGrey">--</h5>
                                                        </div>
                                                        <!-- 社交链接 -->
                                                        <div class="my-16">
                                                            <h2 class="body-h4 text-primaryGrey text-uppercase">Links</h2>
                                                            <v-divider class="my-2" thickness="1" color="black01"></v-divider>
                                                        </div>
                                                        <v-divider class="mb-7" thickness="1" color="black01"></v-divider>
                                                        <!-- 加入时间 -->
                                                        <h5 class="body-h5 text-primaryGrey">Joined: {{ $tools.formatEngLishMonth(userProfile.user.timestamp) }}</h5>
                                                    </div>
                                                    <!----------------------------- 该用户已验证 ----------------------------->
                                                    <div v-if="userProfile.user.verified" class="mt-6">
                                                        <!-- 用户 -->
                                                        <div class="d-flex align-center">
                                                            <!-- <v-card rounded="0" width="100" class="gradient-left-red-purple text-white text-center py-3 ml-n7">#{{ userProfile.user.no }}</v-card> -->
                                                            <h5 class="body-p text-primaryGrey">{{ $tools.privacyField(userProfile.user.wallet) }}</h5>
                                                            <span class="ml-16 pl-10">
                                                                <Clipboard :text="userProfile.user.wallet">
                                                                    <v-icon size="18" icon="mdi:mdi-content-copy" class="text-primaryGrey icon"></v-icon>
                                                                </Clipboard>
                                                            </span>
                                                        </div>
                                                        <div class="parent gradient-text-hover">
                                                            <h1 class="body-h2 mt-8 text-primaryGrey ellipsis-line">{{ userProfile.user.name }}</h1>
                                                        </div>
                                                        <div class="body-p mt-6 text-primaryGrey" v-if="userProfile.user.username?.toLowerCase() !== userProfile.user.wallet?.toLowerCase()">
                                                            <Clipboard :text="currentUrl">
                                                                @{{ userProfile.user.username }}
                                                            </Clipboard>
                                                        </div>
                                                        <v-divider class="my-7" thickness="1" color="black01"></v-divider>
                                                        <!-- 关注人数 -->
                                                        <div class="d-flex justify-start mt-9">
                                                            <div class="pointer gradient-text-hover" @click="followersDialog = true">
                                                                <div class="body-p-large text-primaryGrey">{{ userProfile.follow.followers }}</div>
                                                                <div class="mt-3 body-h5 text-primaryGrey">Followers</div>
                                                            </div>
                                                            <div class="ml-10 pointer gradient-text-hover" @click="followingDialog = true">
                                                                <div class="body-p-large text-primaryGrey">{{ userProfile.follow.following }}</div>
                                                                <div class="mt-3 body-h5 text-primaryGrey">Following</div>
                                                            </div>
                                                             <div v-if="responsive == 'pc'">
                                                                <div class="ml-8 mt-9 text-center" v-if="!isMe">
                                                                    <Follow :followedUserId="userProfile.user.id" @refresh="refresh()"></Follow>
                                                                </div>
                                                             </div>
                                                            <div class="ml-14 pointer gradient-text-hover" @click="collectorsDialog = true" v-if="responsive == 'mobile' && type == 'nfts'">
                                                                <div class="body-p-large text-primaryGrey">{{ userProfile.stat.collectors }}</div>
                                                                <div class="body-h5 mt-3 text-primaryGrey">Collectors</div>
                                                            </div>
                                                        </div>
                                                        <div v-if="responsive == 'mobile'" class="mt-8">
                                                            <div class="ml-12  text-center" v-if="!isMe" >
                                                            <Follow :followedUserId="userProfile.user.id" @refresh="refresh()"></Follow>
                                                            </div> 
                                                        </div>
                                                        <!-- 验证账户 -->
                                                        <div class="mt-16 d-flex align-center" v-if="userProfile.social.twitterUsername">
                                                            <span><i class="iconfont fs-28 icon-certification pr-4 text-primaryGrey"></i></span>
                                                            <h5 class="pr-5 title-h4 text-primaryGrey">@{{ userProfile.social.twitterUsername }}</h5>
                                                            <!-- <span v-if="isMe && user.verified" class="pointer" @click="syncTwitterProfile">
                                                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="bottom">
                                                                    <template v-slot:activator="{ props }" >
                                                                        <v-icon v-bind="props" class="twitter" icon="icon-x" size="32"></v-icon>
                                                                    </template>
                                                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                        Sync Profile
                                                                    </v-card>
                                                                </v-menu>
                                                            </span> -->
                                                            <span></span>
                                                            <span>
                                                                <v-icon :color="darkTheme == 0 ? '#000000' : '#FFFFFF'" icon="icon-x" size="28"></v-icon>
                                                            </span>
                                                        </div>
                                                        <!-- 推荐码 -->
                                                        <div class="mt-16" v-if="isMe">
                                                            <h2 class="body-h4 text-primaryGrey text-uppercase">Referral code</h2>
                                                            <v-divider class="my-2" thickness="1" color="black01"></v-divider>
                                                            <div class="d-flex">
                                                                <h5 class="body-p text-primaryGrey">{{ userReferralCode.referralCode }}</h5>
                                                                <span></span>
                                                                <span class="ml-3 body-p text-primaryGrey">
                                                                    <ShareSocialMedia :url="userReferralCodeUrl" :text="userReferralCodeText" :facebook="false" :linkedin="false"></ShareSocialMedia>
                                                                </span>
                                                            </div>
                                                            <h5 class="body-p text-primaryGrey gradient-underline-hover">
                                                                <router-link to="/user/referrals" aria-label="User Referrals" class="del-underline  text-primaryGrey">
                                                                    <span class="gradient-text-hover">Build my kingdom</span> <span class="mdi mdi-arrow-right-thin"></span>
                                                                </router-link>
                                                            </h5>
                                                        </div>
                                                        <!-- 个人简介 -->
                                                        <div class="mt-16">
                                                            <h2 class="body-h4 text-primaryGrey text-uppercase">Bio</h2>
                                                            <v-divider class="my-2" thickness="1" color="black01"></v-divider>
                                                            <h5 class="body-p text-primaryGrey">{{ userProfile.user.bio }}</h5>
                                                        </div>
                                                        <!-- 社交链接 -->
                                                        <div class="my-16">
                                                            <h2 class="body-h4 text-primaryGrey text-uppercase">Links</h2>
                                                            <v-divider class="my-2" thickness="1" color="black01"></v-divider>
                                                            <div class="d-flex align-center">
                                                                <a v-if="$tools.hasText(userProfile.social.websiteLink)" :href="userProfile.social.websiteLink" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon color="primaryGrey" class="icon" icon="icon-link"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.twitterLink)" :href="userProfile.social.twitterLink?.replace('twitter.com', 'x.com')" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon class="icon" color="primaryGrey" icon="icon-x"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.facebookLink)" :href="userProfile.social.facebookLink" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon class="icon" color="primaryGrey" icon="icon-facebook"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.redditLink)" :href="userProfile.social.redditLink" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon class="icon" color="primaryGrey" icon="icon-reddit"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.youtube)" :href="userProfile.social.youtube" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon class="icon" color="primaryGrey" icon="icon-youtube"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.snapchatLink)" :href="userProfile.social.snapchatLink" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon icon="icon-snapchat" class="icon" color="primaryGrey"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.tiktokLink)" :href="userProfile.social.tiktokLink" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon class="icon" color="primaryGrey" icon="icon-tit"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.telegramLink)" :href="userProfile.social.telegramLink" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon class="icon" color="primaryGrey" icon="icon-te"></v-icon>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.discord)" class="del-underline text-black mr-3" target="_black">
                                                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                                        <template v-slot:activator="{ props }" >
                                                                            <Clipboard :text="userProfile.social.discord">
                                                                                <v-icon color="primaryGrey" class="icon" icon="icon-dis"  v-bind="props"></v-icon>
                                                                            </Clipboard>
                                                                        </template>
                                                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                                            {{ userProfile.social.discord }}
                                                                        </v-card>
                                                                    </v-menu>
                                                                </a>
                                                                <a v-if="$tools.hasText(userProfile.social.twitchLink)" :href="userProfile.social.twitchLink" class="del-underline text-black mr-3" target="_black">
                                                                    <v-icon class="twitch" icon="icon-twitch"></v-icon>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <v-divider class="my-2" thickness="1" color="black01"></v-divider>
                                                        <!-- 加入时间 -->
                                                        <h5 class="body-h5 text-primaryGrey">Joined: {{ $tools.formatEngLishMonth(userProfile.user.timestamp) }}</h5>
                                                    </div>
                                                </v-card>
                                                <!-- 编辑资料：是自己并且已验证 -->
                                                <div class="mt-16" v-if="isMe && user.verified">
                                                    <Button variant="primary" to="/edit" rounded="lg" color="black03" height="52" width="300" aria-label="Edit Profile">Edit Profile</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-b c-label-p c-gap">
                                <div class="c-height d-flex flex-column">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-card class="w-100 px-0 pt-10" elevation="0" rounded="0" color="transparent">
                                                <!---------------------------- type ---------------------------->
                                                <v-row no-gutters>
                                                    <v-col cols="12" v-if="responsive == 'pc'">
                                                        <v-tabs hide-slider v-model="type" density="compact" height="50" class="font-weight-bold text-primaryGrey ml-4">
                                                            <v-tab value="cryptos" rounded="0" width="200" class="px-0" :class="type == 'cryptos' ? 'bg-primary text-white' : ''">
                                                                <h4>CRYPTOS</h4>
                                                            </v-tab>
                                                            <v-tab value="nfts" rounded="0" width="200" class="px-0" :class="type == 'nfts' ? 'bg-primary text-white' : ''">
                                                                <h4>NFTS</h4>
                                                            </v-tab>
                                                        </v-tabs>
                                                    </v-col>
                                                    <v-col cols="12" v-if="responsive == 'mobile'" class="flex space-x-2">
                                                        <v-btn size="x-large" @click="changeType('cryptos')" :variant="type === 'cryptos' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': type === 'cryptos' }" class="w-44">
                                                            CRYPTOS
                                                        </v-btn>
                                                         <v-btn size="x-large" @click="changeType('nfts')" :variant="type === 'nfts' ? 'flat' : 'outlined'" :class="{ 'bg-primary text-white': type === 'nfts' }" class="w-44">
                                                            NFTS
                                                        </v-btn>
                                                    </v-col>
                                                    <v-divider v-if="responsive == 'pc'" class="mr-6 ml-4 border-opacity-100" thickness="3" color="primary"></v-divider>
                                                </v-row>
                                                <!---------------------------- 内容 ---------------------------->
                                                <v-card-text class="px-0 pt-10 mr-5" :class="responsive == 'pc' ? 'r-p-t-0 r-m-r-0' : ''">
                                                    <v-window v-model="type" :touch="false">
                                                        <v-window-item value="cryptos">
                                                            <ProfileCryptos :username="username" :stat="userProfile.stat"></ProfileCryptos>
                                                        </v-window-item>
                                                        <v-window-item value="nfts">
                                                            <ProfileNfts :username="username" :stat="userProfile.stat" :userProfile="userProfile" :isMe="isMe"></ProfileNfts>
                                                        </v-window-item>
                                                    </v-window>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </v-row>
                </v-card>
            </v-container>
            <!---------------------------- 推特验证弹窗 ---------------------------->
            <v-dialog v-model="verifyViaTwitterDialog" persistent content-class="v-echarts-dialog">
                <v-card width="462" class="pa-8 text-primaryGrey" color="pinkTone" rounded="0" elevation="12">
                    <v-row no-gutters>
                        <v-col>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <div class="sidebar-h2">Verify via 𝕏</div>
                                </v-col>
                            </v-row>
                            <v-divider class="my-4" thickness="1" color="black01"></v-divider>
                            <v-col cols="12" class="my-2 px-0">
                                <div class="body-p mb-16">Before you can create or collect on untrading.org, you must verify your account first.</div>
                                <div class="mt-16 text-center">
                                    <Button to="/verify/twitter" variant="primary" rounded="lg" color="black03" width="100" aria-label="Verify me" @click="verifyViaTwitterDialog = false">Verify me</Button>
                                </div>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
            <!-- 关注用户 -->
            <Following :username="username" :show="followingDialog" @closeFollowingDialog="closeFollowingDialog"></Following>
            <!-- 粉丝用户 -->
            <Followers :username="username" :show="followersDialog" @closeFollowersDialog="closeFollowersDialog"></Followers>
            <!-- 收藏家 -->
            <Collectors :username="username" :show="collectorsDialog" @closeCollectorsDialog="closeCollectorsDialog"></Collectors>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UserAPI from '@/api/user.js';
import UserReferralCodeAPI from '@/api/user-referral-code.js';
import SocialAPI from '@/api/social.js';
import Follow from '@/components/user/Follow.vue'
import Following from '@/components/user/Following.vue'
import Followers from '@/components/user/Followers.vue'
import Collectors from '@/components/user/Collectors.vue'
import SkeletonProfile from '@/components/util/skeleton/SkeletonProfile.vue'
import ProfileCryptos from '@/components/user/profile/ProfileCryptosV1.1.vue'
import ProfileNfts from '@/components/user/profile/ProfileNFTsV1.1.vue'
export default {
    data(){
        return {
            // 用户名
            username: this.$route.params.username,
            // 页面离开时上一个用户名
            lastUsername: null,
            // 用户对象
            userProfile: {
                user: {},
                social: {},
                stat: {},
                follow: {}
            },
            // 推荐码
            userReferralCode: {},
            // 推荐码链接
            userReferralCodeUrl: null,
            // 推荐码文案
            userReferralCodeText: null,
            // 当前用户是自己
            isMe: false,
            // 加载用户信息
            userProfileLoading: true,
            // 类型
            type: this.$route.query?.type?.toLowerCase() || 'cryptos',
            // 验证推特弹窗
            verifyViaTwitterDialog: false,
            // 当前 URL
            currentUrl: window.location.href,
            // 关注用户弹窗
            followingDialog: false,
            // 粉丝用户弹窗
            followersDialog: false,
            // 收藏家弹窗
            collectorsDialog: false,
       
        }
    },
    components: { Follow, Following, Followers, Collectors, SkeletonProfile, ProfileCryptos, ProfileNfts },
    created(){
        // 根据用户名获取用户信息
        this.getByUsername();
        // 获取登录用户推荐码
        this.getUserReferralCode();
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'user', 'blockchains', 'blockchain', 'responsive']),
    },
    watch:{
        $route(to, from){
            // 代表离开本页面
            if(from.name == 'Profile') {
                this.lastUsername = from.params.username;
            }
            // 代表进入本页面
            if(to.name == 'Profile') {
                this.username = this.$route.params.username;
                // 如果用户名更新则刷新数据
                if(this.username?.toLowerCase() != this.lastUsername?.toLowerCase()) {
                    // 根据用户名获取用户信息
                    this.getByUsername();
                }
                // 如果是自己还未验证则打开弹窗
                this.verifyViaTwitterDialog = this.isMe && !this.userProfile.user.verified;
            }
        },
        // 监听区块链
        blockchain(n, o) {
            // 刷新
            this.refresh();
        }
    },
    methods: {
        // 根据用户名获取用户信息
        async getByUsername() {
            this.userProfileLoading = true;
            let res = await UserAPI.getByUsername(this.username, this.blockchain);
            let data = res.data;
            if(data.success) {
                // 更新对象
                this.userProfile = data.data;
                // 更新标题
                this.$route.meta.name = this.userProfile.user.name
                // 是不是我自己
                if(this.user.id && this.user.id == this.userProfile.user.id) {
                    this.isMe = true;
                } else {
                    this.isMe = false;
                }
                // 如果是自己还未验证则打开弹窗
                this.verifyViaTwitterDialog = this.isMe && !this.userProfile.user.verified;
            } else {
                // 错误则导航到404
                this.$router.push('/404');
            }
            this.userProfileLoading = false;
        },
        // 获取登录用户推荐码
        async getUserReferralCode() {
            let res = await UserReferralCodeAPI.getUserReferralCode();
            let data = res.data;
            if(data.success) {
                this.userReferralCode = data.data;
                this.userReferralCodeUrl = this.$config.domain + '/connectwallet?referralCodeUsed=' + this.userReferralCode.referralCode;
                this.userReferralCodeText = '🚀 Exciting news! 🎁\n\nUse my referral code "' + this.userReferralCode.referralCode + '" to receive a special airdrop reward! 💰💎\n\nSign up at ' + this.userReferralCodeUrl + ' now and claim your $5 & more in $UN! 🤑\n\n💎 Don\'t miss out on this opportunity! 💎\n\n#untrading #Airdrop #Web3 #NFTs #DeFi';
            }
        },
        // 刷新数据
        async refresh() {
            let res = await UserAPI.getByUsername(this.username, this.blockchain);
            let data = res.data;
            if(data.success) {
                this.userProfile = data.data;
            }
        },
        // 同步 Twitter 个人资料
        async syncTwitterProfile() {
            let res = await SocialAPI.syncTwitterProfile();
            let data = res.data;
            if(data.success) {
                // 刷新数据
                this.refresh();
            }
        },
        // 关闭关注用户弹窗
        closeFollowingDialog(show) {
            this.followingDialog = show;
        },
        // 关闭粉丝用户弹窗
        closeFollowersDialog(show) {
            this.followersDialog = show;
        },
        // 关闭收藏家弹窗
        closeCollectorsDialog(show) {
            this.collectorsDialog = show;
        },
        // 选择类型
        changeType(type) {
            this.type = type;
        },
    }
}
</script>
<style scoped>
.align-center {
    height: 100%;
}
:deep(.v-echarts-dialog){
    width: auto;
}
.v-btn{
    text-transform:capitalize!important;
}
/* .v-tab:hover {
    color:rgb(var(--v-theme-primary))!important;
} */
</style>