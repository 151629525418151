<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Great! The Reflexive Edge</h3>
        <p >You know the truth: Markets aren't efficient—they're reactive. Patterns aren't random—they're exploitable. Timing isn't everything—but sizing is.</p>
        <p >Untrading delivers:</p>
        <ul class="ml-8 custom-ul">
            <li>Pure execution without friction</li>
            <li>Maximum position optimization</li>
            <li>Perpetual upside capture</li>
        </ul>
        <p >Because tactical trading isn't about perfect prediction. It's about perfect exploitation.</p>
        <p >Transform your edge into endless opportunity. Where being right once pays forever.</p>
        <p >Welcome to pure alpha generation.</p>
    </div>
</template>