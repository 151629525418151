<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">Your Trading Future Starts Here!</p>
        <p>How do you see yourself trading?</p>
        <p>Craving the excitement of hands-on moves on the Crypto Racetrack? Or ready to soar with <span class="text-black01 font-weight-bold">Autopilot</span>, letting us chart the course to your financial dreams?</p>
        <p>Autopilot brings legendary strategies to life:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Swift, bold profits—Soros’ high-stakes spirit.</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Balanced, visionary growth—Dalio’s calculated wins.</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Monumental long-term rewards—Buffett’s legacy approach.</li>
        </ul>
        <p>Autopilot’s your gateway to effortless success with ERC-5173’s shared prosperity, and you can take control manually anytime.</p>
        <p>What’s your vision?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot - Build Wealth Seamlessly' },
                { code: 'Manual', message: 'Crypto Racetrack - Master the Markets' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>