<template>
	<div>
        <div class="layoutContent">
            <section class="@container group/component-group">
                <div class="@container grid w-full grid-cols-12">
                    <div class="col-span-12 grid w-full grid-cols-1 items-start gap-lg @md:gap-xl">
                        <div class="@container multi-columns:px-0 grid w-full grid-cols-12 max-w-container">
                            <div class="col-span-12 @md:col-span-10 @md:col-start-2 @lg:col-span-8 @lg:col-start-3">
                                <div class="relative flex flex-col items-center text-center">
                                    <h2 class="text_h2 scroll-mt-header-h max-w-[62.5rem] text-primary-100 text-balance"> From Solo Traders to Thriving Communities</h2>
                                </div>
                            </div>
                        </div>
                        <div class="w-full grid grid-cols-12 @container max-w-container multi-columns:px-0 multi-columns:flex">
                            <div class="col-span-12 @md:col-span-6 @md:col-start-4 max-w-none prose">
                                <p class="mb-sm last:mb-0 text-center text-balance">
                                    <span> Turn competitive trading into collaborative success. Untrading creates a space where traders support each other's growth, transforming individual risks into shared rewards.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="Spacer_root__uoSvA" style="--height: 48px;"></div>
        <div class="bleed_root">
        <div class="Carousel_root__TqsO7 Carousel_variant-inset__GHiLJ Carousel_align-center__2bgqN" style="--Carousel-gap: 8px;">
            <div class="Carousel_content__G17d_ utils_hideScrollbars__TzPqp">
                <div class="Carousel_inner__L4Uvf">
                    <div class="Carousel_item__k73Fv">
                        <div style="opacity: 1;">
                            <v-hover v-slot="{ isHovering, props }">
                                <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 2" v-bind="props" rounded="0" >
                                  <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f78ba50e-c59e-4b57-ff10-d7e276ce6400/public"  width="960" height="914" cover class="Image_root__UkRqc" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                  <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2aa0b102-c429-4ca3-c45b-3e7c91ccfd00/public"  width="960" height="914" cover class="Image_root__UkRqc" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                  <div class="text-container d-flex align-start justify-space-between">
                                    <p class="fs-21 text-quaternary marcellus-sc">
                                        Join a Kingdom
                                    </p>
                                    <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                        <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                    </button>
                                  </div>
                                </v-card>
                                <v-dialog scrollable v-model="dialogActive" activator="parent" max-width="960">
                                    <template v-slot:default="{ isActive }">
                                        <v-card rounded="0" elevation="0" class="bg-very-dark">
                                            <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                    <i class="mdi mdi-close"></i>
                                                </button>
                                            </v-card-title>
                                            <v-card-text style="max-height: 1000px;">
                                                <div style="min-width: 100%; display: table;">
                                                    <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                    <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                    <div>
                                                        <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc page_image" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f78ba50e-c59e-4b57-ff10-d7e276ce6400/public" style="color: transparent;">
                                                        <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc page_image" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2aa0b102-c429-4ca3-c45b-3e7c91ccfd00/public" style="color: transparent;">
                                                    </div>
                                                    <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                    <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                    <div style="max-width: 720px; margin: 0px auto;">
                                                        <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                            <span data-br=":r4:" data-brr="1" style="display: inline-block; vertical-align: top; text-decoration: inherit; text-wrap: balance;">
                                                                <h3 class="fs-21-bold text-quaternary">Building Digital Kingdoms Together</h3>
                                                            </span>
                                                        </div>
                                                        <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                            <div class="Spacer_root__uoSvA" style="--height: 24px;"></div>
                                                            <div class="flex_root flex-column prose text-tertiary">
                                                                <ol class="pl-8">
                                                                    <li>Choose Your Asset: Explore a wide range of tokens tied to cryptocurrencies, NFTs, and even real-world assets. Each token forms its own Flow—a mini-community where all members share in the generated rewards, creating a cooperative environment.</li>
                                                                    <li>Join a Flow, Build a Kingdom: When you buy a token, you’re not alone. You join a Flow where members work together for long-term growth. Kingdoms encapsulate multiple Flows with added functionality and customization added on top.</li>
                                                                    <li> Profit & Keep Earning: Sell your token when it makes sense for you, but your earnings don’t stop there. Thanks to Future Rewards built into Untrading’s ERC-5173 standard, you’ll continue to earn from the ongoing success of your Flow and Kingdom—turning every trade into a lasting opportunity for growth.</li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                        <div class="differentCard_bottomSpacer"></div>
                                                    </div>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </v-dialog>
                            </v-hover>
                        </div>
                    </div>
                    <div class="Carousel_item__k73Fv">
                        <div style="opacity: 1;">
                            <v-hover v-slot="{ isHovering, props }">
                                <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 2" v-bind="props"  rounded="0" >
                                  <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c5a016ef-8e7a-4dcd-2d17-0157d25def00/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-1 " v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                  <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/24f7a447-cbac-43ed-6cc4-ac4cbb797700/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-1 " v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                  <div class="text-container d-flex align-start justify-space-between">
                                    <p class="fs-21 text-quaternary marcellus-sc">
                                        Why Untrading?
                                    </p>
                                    <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                        <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                    </button>
                                  </div>
                                </v-card>
                                <v-dialog scrollable activator="parent" max-width="960" style="pointer-events: auto;opacity: 1;will-change: transform;">
                                    <template v-slot:default="{ isActive }">
                                        <v-card rounded="0" elevation="0" class="bg-very-dark">
                                            <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                    <i class="mdi mdi-close"></i>
                                                </button>
                                            </v-card-title>
                                            <v-card-text style="max-height: 1000px;">
                                                <div style="min-width: 100%; display: table;">
                                                    <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                    <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                    <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                        <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc page_image" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c5a016ef-8e7a-4dcd-2d17-0157d25def00/public" style="color: transparent;">
                                                        <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc page_image" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/24f7a447-cbac-43ed-6cc4-ac4cbb797700/public" style="color: transparent;">
                                                    </div>
                                                    <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                    <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                    <div style="max-width: 720px; margin: 0px auto;">
                                                        <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                            <div class="Spacer_root__uoSvA" style="--height: 32px;"></div>
                                                            <div class="flex_root flex-column prose text-tertiary">
                                                                <ol class="pl-8">
                                                                    <li>From Prey to Pack: In traditional trading, lone traders are easy prey in the volatile markets. With Untrading, you join a Kingdom, where collective success is the goal. Whenever you buy an asset, you join a mini-community (called a Flow), where everyone shares in the rewards. Together, the Kingdoms comprise all these mini-communities.</li>
                                                                    <li>Kingdom Building: Each token’s Flow contributes to building a decentralized Kingdom. Over time, leaders emerge and decentralized decisions are made by the Kingdoms, creating a truly decentralized finance (DeFi) ecosystem where power is shared.</li>
                                                                    <li>Earn Beyond the Trade: With the ERC-5173 token standard, you keep earning through Future Rewards, even after you sell. Whether you win or lose on a trade, your community keeps earning for you long-term.</li>
                                                                    <li>Risk is Shared, Not Suffered: Instead of risking total losses, the community supports you through shared profits, creating a more sustainable, less stressful trading experience.</li>
                                                                </ol>
                                                            </div>
                                                            <div role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU" style="margin: 48px 0px;"></div>
                                                            <Data></Data>
                                                        </div>
                                                        <div class="differentCard_bottomSpacer"></div>
                                                    </div>
                                                 </div>
                                            </v-card-text>
                                          </v-card>
                                    </template>
                                  </v-dialog>
                            </v-hover>
                        </div>
                    </div>
                    <div class="Carousel_item__k73Fv">
                        <div style="opacity: 1;">
                            <v-hover v-slot="{ isHovering, props }">
                                <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 2" v-bind="props"  rounded="0" >
                                  <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/64b13d6c-cbe7-4e38-a786-efa3b3019200/public"  width="960" height="914" cover class="Image_root__UkRqc page_image" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                  <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c5220391-5fb4-416a-9148-8648d9260f00/public"  width="960" height="914" cover class="Image_root__UkRqc page_image" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                  <div class="text-container d-flex align-start justify-space-between">
                                    <p class="fs-21 text-quaternary marcellus-sc">
                                        The Untrading Advantage
                                    </p>
                                    <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                        <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                    </button>
                                  </div>
                                </v-card>
                                <v-dialog activator="parent"  max-width="960" scrollable style="pointer-events: auto;opacity: 1;will-change: transform;">
                                    <template v-slot:default="{ isActive }">
                                        <v-card rounded="0" elevation="0" class="bg-very-dark">
                                            <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                    <i class="mdi mdi-close"></i>
                                                </button>
                                            </v-card-title>
                                            <v-card-text style="max-height: 1000px;">
                                                <div style="min-width: 100%; display: table;">
                                                    <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                    <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                    <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                        <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/64b13d6c-cbe7-4e38-a786-efa3b3019200/public" v-show="this.darkTheme == 1" style="color: transparent;">
                                                        <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c5220391-5fb4-416a-9148-8648d9260f00/public" v-show="this.darkTheme == 0" style="color: transparent;">
                                                    </div>
                                                    <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                    <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                    <div style="max-width: 720px; margin: 0px auto;">
                                                        <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                            <span data-br=":r4:" data-brr="1" style="display: inline-block; vertical-align: top; text-decoration: inherit; text-wrap: balance;">
                                                                <h3 class="fs-21-bold text-quaternary">Traditional trading: 90% lose everything</h3>
                                                            </span>
                                                            <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                                                            <p class="fs-17 text-tertiary">
                                                                Untrading: A new era where everyone contributes, and everyone benefits.
                                                            </p>
                                                        </div>
                                                        <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                            <div class="Spacer_root__uoSvA" style="--height: 24px;"></div>
                                                            <div class="flex_root flex-column prose text-tertiary">
                                                                <ol class="pl-8">
                                                                    <li>Cooperative Trading: Say goodbye to the isolation of traditional trading, where success is rare and short-lived. With Untrading, you're part of a larger ecosystem—each token forms a Flow, a mini-community where members work together to achieve shared success. They all act as individual pieces that fit together into a Kingdom, where every trader’s victory benefits the whole. This community-driven model shifts the focus from individual gains to collective growth.</li>
                                                                    <li>No Total Losses: Traditional trading leaves you exposed to the harsh reality of total losses, where bad trades can wipe you out. In Untrading’s model, even if your initial trade didn’t work out, Future Rewards ensure you continue to earn from your token’s ongoing success. Every participant shares in the future profits of the Flow and Kingdom, making total losses a thing of the past.</li>
                                                                    <li>Kingdoms of Growth: As more Flows connect and form Kingdoms, Untrading empowers decentralized decision-making. Kingdom leaders, chosen from the community, drive strategies and decisions, ensuring that the platform evolves based on the needs and goals of its members. This decentralized, cooperative approach fuels the growth of a dynamic, robust DeFi ecosystem that thrives on collaboration.</li>
                                                                    <li>No Fee, No Conflict: The Untrading platform operates on a zero-fee model, removing the conflict of interest seen in traditional exchanges. Here, if you don’t make money, no one else profits either. This aligns everyone’s incentives, ensuring that the platform’s success is tied to the success of its users—creating a fairer, more equitable trading environment.</li>
                                                                </ol>
                                                            </div>
                                                        </div>
                                                        <div class="differentCard_bottomSpacer"></div>
                                                    </div>
                                                 </div>
                                            </v-card-text>
                                          </v-card>
                                    </template>
                                  </v-dialog>
                            </v-hover>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	</div>
</template>
<script>
import Data from './Data.vue'
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            dialogActive: false, 
        }
    },
    components: { Data },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.text-container {
    position: absolute;
    bottom: 0;
    /* 对齐到图片底部 */
    left: 0;
    width: 100%;
    padding: 16px;
    /* 可根据需要调整内边距 */
    box-sizing: border-box;
}


[data-radix-scroll-area-viewport] {
    scrollbar-width: none; /* Firefox隐藏滚动条 */
    -ms-overflow-style: none; /* IE和Edge隐藏滚动条 */
    -webkit-overflow-scrolling: touch; /* 启用触控设备的平滑滚动 */
}

[data-radix-scroll-area-viewport]::-webkit-scrollbar {
    display: none; /* Chrome、Safari等WebKit浏览器隐藏滚动条 */
}

:deep(.v-echarts-dialog){
    width: auto;
}
</style>