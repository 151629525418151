<template>
	<div>
        <!-- <v-card color="transparent" rounded="0" elevation="0" class="mx-auto"> -->
            <!-- 数据表格 -->
            <v-data-table-server width="100%" :height="tableHeight" fluid :headers="headers" :items="unCryptos" :items-length="unCryptos.length" :loading="loading" item-value="name" 
                class="body-p text-white" :class="responsive != 'pc' ? 'overflow-x-scroll' : ''" color="transparent" density="compact" hover>
                <template v-slot:headers>
                    <tr class="body-h5">
                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-80'">ID</td>
                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-150'">Price</td>
                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-150'">Size</td>
                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-120'" width="120">Total R
                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                <template v-slot:activator="{ props }" >
                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-white pointer gradient-text-hover" v-bind="props"></v-icon>
                                </template>
                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                    The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                </v-card>
                            </v-menu>
                        </td>
                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-120'" width="120">FR
                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                <template v-slot:activator="{ props }" >
                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-white pointer gradient-text-hover" v-bind="props"></v-icon>
                                </template>
                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                    Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                </v-card>
                            </v-menu>
                        </td>
                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-120'" width="120">Gen
                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                <template v-slot:activator="{ props }" >
                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-white pointer gradient-text-hover" v-bind="props"></v-icon>
                                </template>
                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                    The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                </v-card>
                            </v-menu>
                        </td>
                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-120'" width="120">OR
                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                <template v-slot:activator="{ props }" >
                                    <v-icon icon="mdi:mdi-alert-circle-outline" color="white" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                </template>
                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                    Share of Rewards available for distribution among untrading.org and community treasurers.
                                </v-card>
                            </v-menu>
                        </td>
                        <td class="text-right" :class="responsive == 'pc' ? '' : 'td-width-120'" width="120">Flow
                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                <template v-slot:activator="{ props }" >
                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-white pointer gradient-text-hover" v-bind="props"></v-icon>
                                </template>
                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                    The Length of Flow is calculated as the sum of all prior owners along with the present holder of this token, starting from the time it was initially minted.
                                </v-card>
                            </v-menu>
                        </td>
                        <td :class="responsive == 'pc' ? '' : 'td-width-150'" width="150">Originator</td>
                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-150'" width="150">Owner</td>
                    </tr>
                    <tr>
                        <td colspan="10" class="px-0 text-center">
                            <v-divider color="white" :thickness="3"></v-divider>
                        </td>
                    </tr>
                </template>
                <template v-slot:item="{ index, item }">
                    <v-hover>
                        <template v-slot:default="{ isHovering, props }">
                            <tr class="pointer" v-bind="props" :class="isHovering ? 'bg-primary text-white' : ''" @click="goToUnCrypto(item)">
                                <td class="px-0">{{ item.tokenId }}</td>
                                <td class="px-0 text-right">{{ (item.listedSize > 0 ? $tools.formatNumber(item.limitPrice, item.cryptoWrapped?.priceDecimals) : '--') + ' ' + item.quoteCurrency }}</td>
                                <td class="px-0 text-right">{{ item.listedSize > 0  ? $tools.formatNumber(item.listedSize, item.cryptoWrapped?.sizeDecimals) : $tools.formatNumber(item.currentSize, item.cryptoWrapped?.sizeDecimals) }} {{ item.wrappedCurrency }}</td>
                                <td class="px-0 text-right">{{ item.totalRewardRatio }}%</td>
                                <td class="px-0 text-right">{{ 100 - item.originatorRewardRatio }}%</td>
                                <td class="px-0 text-right">{{ item.numberOfGenerations }}</td>
                                <td class="px-0 text-right">{{ item.originatorRewardRatio }}%</td>
                                <td class="text-right">{{ item.maxOwnerNo }}</td>
                                <td>
                                    <UserAvatar :avatar="item.user?.avatar" :username="item.user?.username" :name="item.user?.name" :size="24" text-color="text-white" :underline-color="isHovering ? 'white-underline-hover' : ''"></UserAvatar>
                                </td>
                                <td class="px-0">
                                    <UserAvatar :avatar="item.owner?.avatar" :username="item.owner?.username" :name="item.owner?.name" :size="24" text-color="text-white" :underline-color="isHovering ? 'white-underline-hover' : ''"></UserAvatar>
                                </td>
                            </tr>
                        </template>
                    </v-hover>
                </template>
                <template v-slot:loading>
                    <!-- <tr>
                        <td colspan="10">
                            <div class="text-center">
                                <v-progress-circular color="primary" class="my-7" :size="30" :width="3" indeterminate v-if="loading"></v-progress-circular>
                            </div>
                        </td>
                    </tr> -->
                    <v-skeleton-loader type="table-row@5" color="transparent"></v-skeleton-loader>
                </template>
                <template v-slot:no-data>
                    <NoDataAvailable></NoDataAvailable>
                </template>
                <template v-slot:bottom>
                </template>
            </v-data-table-server>
        <!-- </v-card> -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CryptoAPI from '@/api/crypto.js';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // 表头
            headers: [{},{},{},{},{},{},{},{},{},{}],
            // 数据
            unCryptos: [],
        }
    },
    props: {
        // 用户主键
        userId: {
            type: String,
            default: null
        },
        // 排除的加密货币主键
        excludedCryptoId: {
            type: String,
            default: null
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive']),
        // 表格高度
        tableHeight() {
            let h = 0;
            h = 80 + 36 * this.unCryptos.length;
            // 不是pc端则空出底部60的菜单
            if (this.responsive != 'pc') {
                h += 60;
            }
            return h;
        }
    },
    watch:{
        // 用户主键
        userId: {
            handler(newVal, oldVal) {
                this.getMoreCryptosFromOriginator();
            },
            immediate: true
        },
        // 排除的加密货币主键
        excludedCryptoId: {
            handler(newVal, oldVal) {
                this.getMoreCryptosFromOriginator();
            },
            immediate: true
        }
    },
    methods: {
        // 根据用户主键查询该发起人的更多加密货币
        async getMoreCryptosFromOriginator() {
            if(this.userId == null) {
                return;
            }
            let unCryptos = [];
            // 告诉父级组件，没有更多推荐
            this.$emit("emitHasMoreCryptos", false, 0);
            let res = await CryptoAPI.getMoreCryptosFromOriginator(this.userId, this.excludedCryptoId);
            let data = res.data;
            if(data.success) {
                // 数据
                this.unCryptos = data.data;
                // 告诉父级组件，有更多推荐
                this.$emit("emitHasMoreCryptos", true, this.unCryptos.length);
            }
        },
        // 跳转到加密货币详情页
        goToUnCrypto(unCrypto) {
            this.$router.push(`/uncrypto/${unCrypto.wrappedCurrency.toLowerCase()}/${unCrypto.id}`);
        }
    }
}
</script>
<style scoped>
/* .v-data-table > .v-table__wrapper > table > .v-data-table__tbody > .pointer:hover{
  background: #03DAC5 !important;
} */
</style>