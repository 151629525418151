<template>
	<div class="markdown prose">
        <p class="font-italic">"Diversification, balance, and risk-adjusted returns create lasting wealth."</p>
        <h3 class="text-black01 font-weight-bold">Untrading aligns perfectly with your mindset, offering a platform where you can build collaborative financial ecosystems—where success is a shared journey, not a solitary conquest.</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Built-in Future Rewards (FRs): </span>Just as diversification spreads risk, Untrading allows you to spread your rewards over time. Even after selling your assets, you're not cut off from potential gains; you continue to benefit from their growth through FRs. This mirrors the balance in Dalio's approach, where long-term gains are prioritized over short-term wins.</li>
            <li><span class="text-black01 font-weight-bold">Decentralized and Transparent Profit-Sharing: </span>Untrading operates on a principle where the fruits of success are not monopolized but are shared across its network. This transparency and decentralization ensure that every participant in the ecosystem can see, understand, and benefit from the collective growth, aligning with the concept of balanced, risk-adjusted returns where everyone's contribution to the system is recognized.</li>
            <li><span class="text-black01 font-weight-bold">A Trading Framework Designed for Stability and Continued Growth: </span>The platform is engineered to provide a stable environment for your investments, reducing the volatility that can erode wealth in traditional markets. By focusing on long-term growth and stability, Untrading supports your strategy of building wealth through well-managed, diversified portfolios. This framework ensures that your investments are not just about personal gain but about contributing to and benefiting from a growing, healthy financial ecosystem.</li>
        </ul>
        <p >In essence, Untrading offers you a way to practice Dalio's principles in a modern, digital context, where your investment strategies can lead to lasting wealth, not just for you but for all participants in this innovative trading paradigm.</p>
    </div>
</template>