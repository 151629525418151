<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">How do you want to Untrade?</p>
        <p>1️⃣ <span class="text-black01 font-weight-bold">Autopilot (Set & Forget):</span> Automated strategies inspired by legends—no stress, no losses.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode</span> → Soros-style agility (steady gains, frequent profit-taking).</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode</span> → Dalio’s balance (risk-managed, diversified growth).</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode</span> → Buffett’s patience (long-term, conviction-driven wealth).</li>
        </ul>
        <p>2️⃣ <span class="text-black01 font-weight-bold">Crypto Racetrack (Manual):</span> For adrenaline junkies who love split-second decisions. (Warning: 92% of self-traders lose capital. Autopilot protects you.)</p>
        <p>Autopilot’s your gateway to effortless success with ERC-5173’s shared prosperity, and you can take control manually anytime.</p>
        <p>👉 Start with Autopilot—build consistency, then switch to manual later if needed.</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot - Set & Forget' },
                { code: 'Manual', message: 'Crypto Racetrack - Manual' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>