<template>
	<div>
        <div class="flex flex-col mt-10 gap-2xl @md:gap-3xl">
            <div class="@container w-full max-w-container">
                <div class="relative w-full">
                    <div class="@container multi-columns:px-0 grid w-full grid-cols-12">
                        <div class="col-span-12 @md:col-span-10 @md:col-start-2 @lg:col-span-8 @lg:col-start-3">
                            <div class="relative flex flex-col items-center text-center">
                                <h1 class="text_h1 scroll-mt-header-h max-w-[62.5rem] text-balance">Earn Rewards Even After Selling</h1>
                                <div class="mt-md">
                                    <p class="col-span-12 @md:col-span-8 @md:col-start-3 text-balance">Invest in top cryptocurrencies and earn rewards that continue even after selling. Join 68,000+ users creating lasting wealth together.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>