<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Buffett’s Principles, Upgraded for the Digital Era</h3>
        <p >Successful investors know: wealth isn’t made by chasing trends—it’s built through <span class="text-black01 font-weight-bold">patience, discipline, and smart capital allocation.</span></p>
        <p >But traditional trading forces unnecessary limitations:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Selling disconnects you from future upside.</span></li>
            <li><span class="text-black01 font-weight-bold">Passive income is limited to dividends, which not all assets provide.</span></li>
            <li><span class="text-black01 font-weight-bold">Market cycles test even the strongest convictions.</span></li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading offers a better way:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you earning after you sell.</span></li>
            <li><span class="text-black01 font-weight-bold">No interruptions, no middlemen—just pure ownership.</span></li>
            <li><span class="text-black01 font-weight-bold">A smarter way to build long-term, generational wealth.</span></li>
        </ul>
        <p >Because great investors don’t just hold—they <span class="text-black01 font-weight-bold">adapt.</span></p>
    </div>
</template>