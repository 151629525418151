<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Got it! Long-term vision in investing?</h3>
        <h3 class="text-black01 font-weight-bold">Pros:</h3>
        <ul class="ml-8 custom-ul">
            <li>Stress-free monitoring.</li>
            <li>Big future gains.</li>
            <li>Economic alignment.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Cons:</h3>
        <ul class="ml-8 custom-ul">
            <li>Patience required.</li>
            <li>Downturns test faith.</li>
            <li>No quick returns.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading Twist:</h3>
        <ol class="ml-8 custom-ol">
            <li>Wrap, sell, earn FRs.</li>
            <li>Rebuy, keep benefiting.</li>
            <li>Cycle for more.</li>
        </ol>
        <p >With Untrading, you stay invested, always.</p>
        <br><hr>
        <p>Now that you’ve explored your trading style and discovered how Untrading can help you win, who inspires your trading journey today?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>