<template>
	<div>
        <!-- 悬浮回到顶部按钮 -->
        <v-btn v-show="show" elevation="12" icon size="32" color="primaryGrey" class="back-to-top" @click="scrollToTop">
            <v-icon color="white" size="18">mdi mdi-arrow-collapse-up</v-icon>
        </v-btn>
	</div>
</template>
<script>
export default {
    data(){
        return {
            show: false, // 控制按钮显示
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        window.addEventListener("scroll", this.handleScroll);
    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 如果滚动距离超过指定像素，显示按钮，否则隐藏
        handleScroll() {
            this.show = window.scrollY > 1000;
        },
        // 平滑滚动回到顶部
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    beforeDestroy() {
        // 移除滚动事件监听
        window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
<style scoped>
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 14px;
    z-index: 1000;
}
</style>