<template>
	<div class="text-tertiary fs-14 mb-16 pb-16 r-m-b-0 r-p-b-0">
        <div v-if="step == 1">
            <div class="@md:px-xs">
                <p class="text-quaternary text_h4 mb-2xs">Hi, I’m Untrading.</p>
                <p class="text-p2 mb-sm">Trading just got an upgrade—fair, secure, and built for shared success. Here, everyone wins.</p>
                <p class="text-p2">Let me know how you trade, and I’ll craft a solution that fits your vision.</p>
                
            </div>
            <div class="@md:rounded-3xl light:border-primary-12 bg-white-1f1f1f shadow-lg border-sm relative flex w-full cursor-text flex-col overflow-hidden rounded-2xl px-5 pb-[52px] pt-4 mt-6">
                <div class="scroll-container text-p2 text-balance">
                    <VueWriter :array="tradingExperienceTexts" :typeSpeed="30" :eraseSpeed="0" :delay="5000" :intervals="200" @typed="onTypedCurrentTradingExperience" />
                    <!-- <div v-if="currentTradingExperience?.level">{{ `${currentTradingExperience.name}: ${currentTradingExperience.message}` }}</div>
                    <div v-else>&nbsp;</div> -->
                    <!-- <div class="scroll-item" v-for="e in tradingExperiences" :key="e.level">
                        {{ `${e.name}: ${e.message}` }}
                    </div> -->
                </div>
                <div class="absolute bottom-3 right-3 mt-auto flex justify-end">
                    <!-- <v-btn icon size="32" color="primary" :disabled="isSendDisabled" @click="setTradingExperience(currentTradingExperience?.level)">
                        <v-icon color="white" size="18">mdi mdi-arrow-up</v-icon>
                    </v-btn> -->
                    <button :disabled="isSendDisabled" @click="setTradingExperience(currentTradingExperience?.level)" class="btn-primary bg-primary iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                        <v-icon color="white" size="18">mdi mdi-arrow-up</v-icon>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="step == 2" class="mt-6">
            <div class="@md:px-xs">
                <p class="text-quaternary text_h4 mb-2xs">Welcome back to Untrading!</p>
                <p class="text-p2 mb-sm">Great to see you again. Check your FRs, explore opportunities, or dive into the community—it’s all here for you.</p>
                <p class="text-p2">Who inspires your trading today?</p>
            </div>
            <div class="@md:rounded-3xl light:border-primary-12 bg-white-1f1f1f shadow-lg border-sm relative flex w-full cursor-text flex-col overflow-hidden rounded-2xl px-5 pb-[52px] pt-4 mt-6">
                <div class="scroll-container text-p2">
                    <!-- <div v-if="currentInspiration.name">{{ `${currentInspiration.name}: ${currentInspiration.message}` }}</div>
                    <div v-else>&nbsp;</div> -->
                    <VueWriter :array="inspirationTexts" :typeSpeed="30" :eraseSpeed="0" :delay="5000" :intervals="200" @typed="onTypedCurrentInspiration" />
                </div>
                <div class="absolute bottom-3 right-3 mt-auto flex justify-end">
                    <!-- <v-btn icon size="32" color="primary" :disabled="isSendDisabled" @click="setInspiration(currentInspiration?.name)">
                        <v-icon color="white" size="18">mdi mdi-arrow-up</v-icon>
                    </v-btn> -->

                    <button :disabled="isSendDisabled" @click="setInspiration(currentInspiration?.name)" class="btn-primary bg-primary iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                        <v-icon color="white" size="18">mdi mdi-arrow-up</v-icon>
                    </button>
                </div>
                <!-- <v-card-actions class="pt-10 px-0">
                    <div>
                        <v-tooltip location="top left" text="Tactical, high-risk, high-reward moves">
                            <template v-slot:activator="{ props }">
                                <button v-bind="props" type="button" class="button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener" @click="setInspiration('Soros')">
                                    <span class="text-tertiary" style="padding: 16px;">Soros<v-icon class="text-tertiary">mdi mdi-chevron-right</v-icon></span>
                                </button>
                            </template>
                        </v-tooltip>
                        <v-tooltip location="top left" text="Risk-adjusted, data-driven, diversified">
                            <template v-slot:activator="{ props }">
                                <button v-bind="props" type="button" class="button_root button_variant-secondary button_size-small button_variant link_root ml-5" rel="noopener" @click="setInspiration('Dalio')">
                                    <span class="text-tertiary" style="padding: 16px;">Dalio<v-icon class="text-tertiary">mdi mdi-chevron-right</v-icon></span>
                                </button>
                            </template>
                        </v-tooltip>
                        <v-tooltip location="top left" text="Long-term wealth through conviction">
                            <template v-slot:activator="{ props }">
                                <button v-bind="props" type="button" class="button_root button_variant-secondary button_size-small button_variant link_root ml-5" rel="noopener" @click="setInspiration('Buffett')">
                                    <span class="text-tertiary" style="padding: 16px;">Buffett<v-icon class="text-tertiary">mdi mdi-chevron-right</v-icon></span>
                                </button>
                            </template>
                        </v-tooltip>
                    </div>
                </v-card-actions> -->
            </div>
            <!-- <div v-if="inspiration == null" class="mt-16">
                <button type="button" class="button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener" @click="startOver()">
                    <span class="text-tertiary" style="padding: 16px;">Start Over</span>
                </button>
            </div> -->
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            // 步骤
            step: 1,
            // 交易经验集合
            tradingExperiences: [
                { level: 'level-1', name: 'I Master Short-term Chart Patterns', message: 'High-energy, quick in-and-out trades!' },
                { level: 'level-2', name: 'I Navigate Daily/Weekly Trends', message: 'For the strategic, medium-term trend-following!' },
                { level: 'level-3', name: 'I\'m a HODL/Diamond Hand', message: 'Visionary, long-term investing with patience and conviction!' },
                { level: 'level-4', name: 'I want coins that will skyrocket 100x', message: 'Show me the fastest path to massive profits!' },
            ],
            // 当前交易经验
            currentTradingExperience: {},
            // 期望人物集合
            inspirations: [
                { name: 'Soros', message: 'Tactical, high-risk, high-reward moves' },
                { name: 'Dalio', message: 'Risk-adjusted, data-driven, diversified' },
                { name: 'Buffett', message: 'Long-term wealth through conviction' }
            ],
            // 当前期望人物
            currentInspiration: {},
            // 是否发送禁用
            isSendDisabled: true
        }
    },
    components: { },
    created(){

    },
    mounted(){
        // 加载交易经验
        this.loadTradingExperience();
        // 更新交易经验
        // this.updateCurrentTradingExperience();
        // 更新交易灵感
        // this.updateCurrentInspiration();
    },
    computed: {
        ...mapGetters(['darkTheme', 'tradingExperience', 'inspiration']),
        tradingExperienceTexts(){
            return this.tradingExperiences.map(tradingExperience => `${tradingExperience.name}: ${tradingExperience.message}`);
        },
        inspirationTexts() {
            return this.inspirations.map(inspiration => `${inspiration.name}: ${inspiration.message}`);
        },
    },
    watch:{
        step(n, o) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    },
    methods: {
        // 加载交易经验
        loadTradingExperience() {
            if (this.tradingExperience) {
                this.step = 2;
            }
        },
        // 更新交易经验
        updateCurrentTradingExperience() {
            let index = 0;
            this.currentTradingExperience = this.tradingExperiences[0];
            setInterval(async () => {
                index = (index + 1) % this.tradingExperiences.length;
                this.currentTradingExperience = {};
                await this.sleep(200);
                this.currentTradingExperience = this.tradingExperiences[index];
            }, 6000);
        },
        // 睡眠
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        // 设置交易经验
        setTradingExperience(experience) {
            this.$store.dispatch("tradingExperienceHandler", experience);
            setTimeout(() => {
                this.$router.push(`/ai`);
            }, 100);
        },
        // 设置交易灵感
        setInspiration(inspiration) {
            this.$store.dispatch("inspirationHandler", inspiration);
            this.$router.push(`/ai`);
        },
        // 更新交易灵感
        updateCurrentInspiration() {
            let index = 0;
            this.currentInspiration = this.inspirations[0];
            setInterval(async () => {
                index = (index + 1) % this.inspirations.length;
                this.currentInspiration = {};
                await this.sleep(200);
                this.currentInspiration = this.inspirations[index];
            }, 6000);
        },
        startOver() {
            this.$store.dispatch("tradingExperienceHandler", null);
            this.$store.dispatch("inspirationHandler", null);
            this.$store.dispatch("lastingWealthAnswerHandler", null);
            this.$store.dispatch("tradingTypeAnswerArrayIndexHandler", null);
            this.$store.dispatch("tradingTypeAnswerHandler", null);
            localStorage.removeItem('untrading-welcome');
            this.step = 1;
        },
        // 输入交易经验回调
        onTypedCurrentTradingExperience(text) {
            let index = this.tradingExperienceTexts.indexOf(text);
            if (index > -1) {
                this.currentTradingExperience = this.tradingExperiences[index];
                this.isSendDisabled = false;
                setTimeout(() => {
                    this.isSendDisabled = true;
                }, 5200);
            }
        },
        // 输入交易灵感回调
        onTypedCurrentInspiration(text) {
            let index = this.inspirationTexts.indexOf(text);
            if (index > -1) {
                this.currentInspiration = this.inspirations[index];
                this.isSendDisabled = false;
                setTimeout(() => {
                    this.isSendDisabled = true;
                }, 5200);
            }
        },
    }
}
</script>
<style scoped>
.center-text {
    display: grid;
    place-items: center; /* 水平 & 垂直居中 */
    height: 80vh;
}
.scroll-container {
    height: 50px;  /* 确保容器高度固定 */
    overflow: hidden;
    position: relative;
}

.scroll-item {
    opacity: 0;
    transform: translateY(100%);
    animation: slide-fade 3s linear infinite;
    z-index: 0;
}

/* 分别控制每个 item 的延时 */
.scroll-item:nth-child(1) { animation-delay: 0s; }
.scroll-item:nth-child(2) { animation-delay: 3s; }
.scroll-item:nth-child(3) { animation-delay: 6s; }

/* 平滑的上滑动画 */
@keyframes slide-fade {
    0% { opacity: 0; transform: translateY(100%); }
    10% { opacity: 1; transform: translateY(0); }
    40% { opacity: 1; transform: translateY(0); }
    50% { opacity: 0.8; transform: translateY(-50%); } /* 渐渐消失 */
    100% { opacity: 0; transform: translateY(-100%); }
}

/* 让 hover 只在支持鼠标的设备上生效 */
@media (hover: hover) {
    .scroll-container:hover .scroll-item {
        animation-play-state: paused;
    }
}

/* ✅ 在手机上保持滚动动画 */
@media (max-width: 600px) {
    .scroll-item {
        animation: slide-fade 3s linear infinite !important;
    }
}
</style>