<template>
	<div>
        <v-menu open-on-hover location="bottom center">
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon variant="text" size="28" class="ml-n2" @click="$emit('updateResponse')">
                    <v-icon size="18">mdi mdi-autorenew</v-icon>
                </v-btn>
            </template>
            <v-card class="pa-2 mt-2" color="#000000" rounded="lg" elevation="0">Remix</v-card>
        </v-menu>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>