<template>
	<div class="main-container">
        <v-container class="pa-0 r-p-x-0 bg-black">
            <v-row no-gutters justify="center" align="center" class="py-120">
                <Customize></Customize>
            </v-row>
            <Footer class="bg-black"></Footer>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Customize from './v3/components/Customize.vue';
import Footer from './v3/components/Footer.vue';
export default {
    data(){
        return {

        }
    },
    components: { Customize, Footer },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>