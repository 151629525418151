<template>
	<div class="markdown prose">
        <p>You’re jumping straight into the action! Manual trading gives you full control. Remember, you can switch to autopilot anytime. Ready to begin?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        <br><hr>
        <p>Let’s equip you with the tools and knowledge to master the markets.</p>
        <div class="mt-6">
            👉 <span class="mx-2">Switch to</span>
            <Button variant="primary" rounded="xl" color="black03" width="200" aria-label="Autopilot Waitlist" @click="requestAutopilotDialog = true">
                <span class="px-4">Autopilot Waitlist<v-icon>mdi mdi-chevron-right</v-icon></span>
            </Button>
            or brave the 
            <Button variant="secondary" :href="`https://testnet.untrading.org/uncryptos?inspiration=${inspiration}`" rounded="xl" width="140" class="r-m-t-4" color="black01" aria-label="Racetrack">Racetrack</Button>
            <span class="mx-2">|</span>
            <Button variant="secondary" rounded="xl" class="r-m-t-4" color="black01" height="40" width="140" aria-label="Start Over" @click="$emit('startOver')">Start Over</Button>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['inspiration']),
    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>