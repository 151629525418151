<template>
	<div class="markdown prose">
        <p>The Value Creator's Manifesto</p>
        <p >You understand what others miss: True wealth isn't created in days or weeks—it's built over decades of patient, disciplined investing.</p>
        <p class="text-black01 font-weight-bold">TRADITIONAL VALUE INVESTING:</p>
        <ul class="ml-8 custom-ul">
            <li>Patient capital deployment</li>
            <li>Strong conviction holdings</li>
            <li>Disciplined execution</li>
            <li>Generational perspective</li>
        </ul>
        <p >THE EVOLUTION: Now you can combine traditional wisdom with revolutionary earning potential.</p>
        <p class="text-black01 font-weight-bold">UNTRADING PRINCIPLES:</p>
        <ol class="ml-8 custom-ol">
            <li>Value Creation Without Compromise • Sell at peak value • Maintain future earning rights • Control your destiny</li>
            <li>Perpetual Prosperity Engine • Future Rewards generation • Passive income streams • Compound growth potential</li>
            <li>Generational Wealth Building • Long-term value preservation • Multi-generational returns • Legacy protection</li>
            <li>Complete Asset Control • Full ownership rights • Flexible exit options • Strategic position management</li>
        </ol>
        <p >Because true investing isn't about quick wins—it's about building lasting value that grows across generations.</p>
        <p >Transform your value strategy into an endless prosperity machine. With Untrading, patience becomes perpetual power.</p>
    </div>
</template>