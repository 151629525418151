<template>
    <div>
        <div class="markdown prose">
            <p>You’ve made an excellent decision! Autopilot is like having a trusted co-pilot who knows exactly where to steer your investments.</p>
            <p>By choosing this path, you’re setting yourself up for sustainable growth while avoiding the pitfalls of emotional trading. And don’t worry—when you feel ready to take the wheel yourself, we’ll be here to support that transition too.</p>
            <!-- 响应操作 -->
            <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
            <br><hr>
            <p>Let’s set your autopilot preferences so you can start growing smarter today.</p>
            <div class="mt-6 flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                <Button variant="primary" rounded="xl" color="black03" height="40" width="300" aria-label="Request Your Invite Now" @click="requestAutopilotDialog = true">
                    <span class="px-4">Request Your Invite Now<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
                <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over" @click="$emit('startOver')">
                    <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>