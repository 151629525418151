<template>
	<div class="markdown prose">
        <p class="font-italic">"Patience, conviction, and discipline define long-term success."</p>
        <h3 class="text-black01 font-weight-bold">With Untrading, you can uphold your Buffett-inspired investment philosophy while your wealth grows passively through Future Rewards (FRs)—ensuring that your vision for long-term success is not just maintained but amplified.</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell High, But Keep Earning: </span>In traditional investing, once you sell, your connection with the asset ends. Untrading flips this narrative. By converting your investments into Untrading tokens, you can sell at peak value but continue to benefit from any subsequent increase in the asset's value through FRs. This rewards your patience and conviction, allowing you to profit from your disciplined investment choices well into the future.</li>
            <li><span class="text-black01 font-weight-bold">Own Assets Fully, With No Interruptions: </span>Untrading respects your strategy of holding onto quality assets. You retain full ownership and control, without the traditional market's pressures to liquidate or move assets prematurely. Your investment philosophy can remain as disciplined and patient as Buffett's, with the added benefit of ongoing rewards from your initial decisions.</li>
            <li><span class="text-black01 font-weight-bold">Build Generational Wealth—Without Giving Up Control: </span>The essence of Untrading lies in its ability to help you construct a financial legacy. By earning passive income from your sold assets, you're not just building wealth for now but setting up a system where that wealth can grow and be passed down through generations. This aligns with the long-term perspective of investing for the future, without the need to relinquish control over your investments.</li>
        </ul>
        <p >Untrading redefines how you can apply the wisdom of Warren Buffett in today's digital age, ensuring that your patience, conviction, and discipline in investment lead to sustainable, growing wealth that benefits you and your descendants.</p>
    </div>
</template>