<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">You’ve Chosen CRYPTO RACETRACK - Hands-On and Tactical!</p>
        <p>Crypto Racetrack puts you in the driver’s seat, offering a dynamic, hands-on experience to explore and trade cryptocurrencies with precision. Leveraging Untrading’s unique ERC-5173 protocol, you’ll still enjoy shared success with other owners—but it’s all about your tactical moves. That said, a heads-up: many self-trading users face losses due to market volatility and emotional decisions. Want a smoother ride? Our Autopilot option offers a disciplined, hands-off approach, making trading easier and safer, with no risk of wiping out your capital.</p>
        <p class="text-black01 font-weight-bold">Ready to Explore Crypto Racetrack?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        <br><hr>
        <div class="mt-6 flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-6">
            <div>
                <Button variant="primary" rounded="xl" color="black03" height="40" width="320" aria-label="Switch to Autopilot - Learn More" @click="requestAutopilotDialog = true">
                    <span class="px-4">Switch to Autopilot - Learn More<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
            <div>
                <Button variant="secondary" :href="`https://testnet.untrading.org/uncryptos?inspiration=${inspiration}`" rounded="xl" color="black01" height="40" width="320" aria-label="">
                    <span class="px-4">Get Started with Manual Trading<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
            <div>
                <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over" @click="$emit('startOver')">
                    <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['inspiration']),
    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>