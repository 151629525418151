<template>
	<div class="markdown prose">
        <p>Awesome—you’re ready to take the wheel! Manual trading offers unparalleled excitement and control, allowing you to make every decision yourself.</p>
        <p>While it’s thrilling, remember that experience is key. Start small if you’re new to trading, and consider using autopilot for the majority of your portfolio until you’re comfortable going fully manual.</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        <br><hr>
        <p class="text-black01 font-weight-bold">Let’s dive in and explore the tools and resources available to help you succeed.</p>
        <p class="font-italic">Get your customized Untrading solutions and start earning beyond selling.</p>
        <div class="mt-6 flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-6">
            <div>
                <Button variant="primary" rounded="xl" color="black03" height="40" width="320" aria-label="Switch to Autopilot - Learn More" @click="requestAutopilotDialog = true">
                    <span class="px-4">Switch to Autopilot - Learn More<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
            <div>
                <Button variant="secondary" :href="`https://testnet.untrading.org/uncryptos?inspiration=${inspiration}`" rounded="xl" color="black01" height="40" width="320" aria-label="">
                    <span class="px-4">Explore Manual Trading Tools<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
            <div>
                <Button variant="secondary" rounded="xl" color="black01" height="40" aria-label="Start Over" @click="$emit('startOver')">
                    <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                </Button>
            </div>
        </div>
        <!-- 发送请求 Autopilot 邀请码弹窗 -->
        <RequestAutopilot :show="requestAutopilotDialog" @closeDialog="closeRequestAutopilotDialog"></RequestAutopilot>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import RequestAutopilot from "@/views/ai/components/RequestAutopilot.vue";
export default {
    data(){
        return {
            requestAutopilotDialog: false,
        }
    },
    components: { ResponseAction, RequestAutopilot },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['inspiration']),
    },
    watch:{

    },
    methods: {
        closeRequestAutopilotDialog(show) {
            this.requestAutopilotDialog = show;
        },
    }
}
</script>
<style scoped>

</style>