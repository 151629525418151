<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Awesome! High-Frequency, High-Intensity—But Are You Actually Winning?</h3>
        <p >You trade fast. You thrive on volatility. You know the risks. But the truth is, you’re up against:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Market manipulation</span>—Big players hunt your stops.</li>
            <li><span class="text-black01 font-weight-bold">Psychological traps</span>—FOMO, overconfidence, and exhaustion.</li>
            <li><span class="text-black01 font-weight-bold">The limit of selling</span>—Your profit potential ends when you exit.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading rewrites the rules:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Your gains don’t end at selling.</span>Earn Future Rewards (FRs) as assets appreciate.</li>
            <li><span class="text-black01 font-weight-bold">No hidden fees, no middlemen.</span>Trade transparently, commission-free.</li>
            <li><span class="text-black01 font-weight-bold">Aligned incentives.</span>The community shares success instead of competing against each other.</li>
        </ul>
        <p >Untrading isn’t just a platform. It’s a mindset shift. Ready to break free?</p>
        <br><hr>
		<p>You’ve explored your trading style and learned how Untrading can help you succeed. Who motivates your approach to trading today?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
    </div>
</template>
<script>
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
export default {
    data(){
        return {

        }
    },
    components: { ResponseAction },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>