<template>
	<div class="markdown prose">
        <p class="font-italic">"It’s not about being right—it’s about maximizing when you are."</p>
        <p >Untrading empowers tactical traders like you by eliminating friction, fees, and market inefficiencies.</p>
        <ul class="ml-8 custom-ul">
            <li>No commissions.</li>
            <li>No market predictions.</li>
            <li>Just pure asymmetric opportunities.</li>
        </ul>
    </div>
</template>