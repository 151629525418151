<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">Ready to Rev Up Your Trading?</p>
        <p>How do you want to roll?</p>
        <p>Love the rush of dodging market curves like a Crypto Racetrack pro? Or prefer kicking back while <span class="text-black01 font-weight-bold">Autopilot</span> cruises you to profits?</p>
        <p>Autopilot’s got your back with elite strategies:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Planetary Mode: </span>Snag quick wins—Soros-style high-octane moves.</li>
            <li><span class="text-black01 font-weight-bold">Stellar Mode: </span>Smooth, steady gains—Dalio’s balanced genius.</li>
            <li><span class="text-black01 font-weight-bold">Galactic Mode: </span>Epic long-term payoffs—Buffett’s wealth-building vibe.</li>
        </ul>
        <p>Autopilot’s your co-pilot for stress-free success, and you can take the wheel anytime later.</p>
        <p>What’s your vibe?</p>
        <!-- 响应操作 -->
        <ResponseAction @updateResponse="$emit('updateResponse')"></ResponseAction>
        
        
        
        <!-- 发送的消息 -->
        <div id="ai-tradingTypeAnswer" v-if="tradingTypeAnswer != null" class="mt-6 d-flex r-justify-end">
            <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                <div v-for="(answer, index) in answers" :key="`answer-index-${index}`">
                    <div v-if="answer.code == tradingTypeAnswer">{{ answer.message }}</div>
                </div>
            </v-card>
        </div>
        <!-- 消息框 -->
        <MessageBox v-else :messages="answers" @onSent="setAnswer"></MessageBox>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseAction from "@/views/ai/components/ResponseAction.vue";
import MessageBox from "@/views/ai/components/MessageBox.vue";
export default {
    data(){
        return {
            answers: [
                { code: 'Autopilot', message: 'Autopilot - Chill and Win' },
                { code: 'Manual', message: 'Crypto Racetrack - Full Throttle' }
            ],
        }
    },
    components: { ResponseAction, MessageBox },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['tradingTypeAnswer']),
    },
    watch:{

    },
    methods: {
        setAnswer(data) {
            this.$emit('onSent', data);
        }
    }
}
</script>
<style scoped>

</style>