<template>
	<div class="layoutContent">
        <div class="Grid_root__iRfoa">
            <div class="Grid_a__vY7M8">
                <h2 class="text-quaternary typography_h1">
                    Stop Trading Alone. 
                </h2>
                <h2 class="typography_h1 text-quaternary">
                    Start Untrading and Winning Together.
                </h2>
            </div>
            <div class="Grid_b___vQi_ d-flex justify-end align-end r-m-t-8">
                <a href="https://testnet.untrading.org/uncryptos" class="del-underline">
                    <button class="btn button_size-default button_variant">Get started</button>
                </a>
            </div>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.Grid_root__iRfoa {
	grid-template-areas: "a a a a a a a a b b b b";
    align-items: end;
}


@media (max-width: 768px) {
	.Grid_root__iRfoa {
		grid-template-columns: repeat(8, 1fr);
		grid-template-areas: "a a a a b b b b";
	}
}

@media (max-width: 480px) {
	.Grid_root__iRfoa {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas: "a a a a""b b b b";
	}
}
</style>